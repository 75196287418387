import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GlobalState } from "common/global"
import { PayloadActiveSheet, PayloadActiveSheetMerge, PayloadSheetData, PayloadViewId, RootEpic, SheetData } from "common/type-state"
import { catchError, distinctUntilChanged, filter, map, concatMap, switchMap, withLatestFrom } from "rxjs/operators"
import Utils, { Lodash } from "utils/utils"
import { setAppLoading } from "./app.slice"
import { deleteMultiChild } from "./combine.slice"
import { SheetHelper } from './helper'
import { selectViewerMerge } from "./multiViewer.slice"

interface SheetsState {
    data: {
        [viewId: string]: SheetData[]
    };
    active: {
        [viewId: string]: number | string
    };
    loading: boolean
}

const init: SheetsState = {
    data: {},
    active: {},
    loading: false
}

const sheetsSlice = createSlice({
    name: 'sheets',
    initialState: init,
    reducers: {
        setSheetData(state, action: PayloadAction<PayloadSheetData>) {
            const { viewId, data, mergeViewId, childViewIds } = action.payload;            
            state.data[viewId] = data;
            state.loading = false;
            if (typeof state.active[viewId] === 'undefined' && data.length > 0) {
                state.active[viewId] = data[0].viewId ?? data[0].id
            }
            if (mergeViewId) {
                childViewIds?.forEach(id => {
                    const dataTmp = Lodash.cloneDeep(state.data[id]);
                    if (dataTmp && dataTmp.length > 0) {
                        const listCurrentSheet = state.data[mergeViewId];
                        dataTmp.forEach(v => { // change name if exists                            
                            const newName = SheetHelper.findValidSheetName(listCurrentSheet, v.name);
                            if (newName && newName !== '') {
                                v.name = newName;
                            }
                        });
                        state.data[mergeViewId] = [...state.data[mergeViewId], ...dataTmp];
                    }
                });
                
            }
            
            
        },
        
        setSheetDataMulti(state, action: PayloadAction<PayloadSheetData[]>) {
            action.payload.forEach(payload => {
                const { viewId, data, mergeViewId, childViewIds } = payload;            
                state.data[viewId] = data;
                state.loading = false;
                if (typeof state.active[viewId] === 'undefined' && data.length > 0) {
                    state.active[viewId] = data[0].viewId ?? data[0].id
                }
                if (mergeViewId) {
                    childViewIds?.forEach(id => {
                        const dataTmp = Lodash.cloneDeep(state.data[id]);
                        if (dataTmp && dataTmp.length > 0) {
                            const listCurrentSheet = state.data[mergeViewId];
                            dataTmp.forEach(v => { // change name if exists                            
                                const newName = SheetHelper.findValidSheetName(listCurrentSheet, v.name);
                                if (newName && newName !== '') {
                                    v.name = newName;
                                }
                            });
                            state.data[mergeViewId] = [...state.data[mergeViewId], ...dataTmp];
                        }
                    });
                    
                }
            })
            
            
            
        },
        loadSheetDataDefault(state, action: PayloadAction<ViewId>) {
            state.loading = true;
        },
        loadSheetDataMerge(state, action: PayloadAction<PayloadViewId>) {
            state.loading = true;
        },
        loadSheetDataMergeMulti(state, action: PayloadAction<PayloadViewId[]>) {
            state.loading = true;
        },
        unLoadSheetDataMerge(state, action: PayloadAction<PayloadViewId>) {
            state.loading = true;
            const { mergeViewId, childViewIds } = action.payload;
            const dataMerge = state.data[mergeViewId as string];
            if (dataMerge) {
                childViewIds?.forEach(id => {
                    const dataTmp = state.data[id];
                    if (dataTmp && dataTmp.length > 0) {
                        let cloneCurrentCombine = dataMerge;
                        dataTmp.forEach(v => {
                            cloneCurrentCombine = Lodash.remove(cloneCurrentCombine, (item) => item.viewId !== v.viewId);
                        });
                        state.data[mergeViewId as string] = cloneCurrentCombine;    
                    }
                });
            }
            
            state.loading = false;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        activeSheetData(state, action: PayloadAction<PayloadActiveSheet>) {
            return
        },
        activeSheetMergeData(state, action: PayloadAction<PayloadActiveSheetMerge>) {
            return
        },
        activeSheetDataComplete(state, action: PayloadAction<PayloadActiveSheet>) {
            const { viewId, id } = action.payload;
            state.active[viewId] = id
        },
        error(state) {
            state.loading = false
        },
        deleteStateByViewIdSheet(state, action: PayloadAction<ViewId>) {
            const viewId = action.payload;
            const reDeleteData = Utils.unsetPathObject(state.data, viewId);
            reDeleteData && (state.data = reDeleteData);
            const reDeleteActive = Utils.unsetPathObject(state.active, viewId);
            reDeleteActive && (state.active = reDeleteActive)
        },
        activateCadView(state, action: PayloadAction<string>) { return },
    }
})

const loadSheetDataDefault$: RootEpic = (action$, state$) => action$.pipe(
    filter(loadSheetDataDefault.match),
    distinctUntilChanged(),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        return SheetHelper.loadSheetDefaultObser(action.payload, state.filesList.filesOrigin).pipe(
            map(resultLoadSheet => {
                const payloadSetData: PayloadSheetData = {
                    viewId: action.payload,
                    data: resultLoadSheet
                };
                return setSheetData(payloadSetData)
            }),
            catchError(err => [sheetsSlice.actions.error()])
        )
    })
)
const loadSheetDataMerge$: RootEpic = (action$, state$) => action$.pipe(
    filter(loadSheetDataMerge.match),
    distinctUntilChanged(),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        return SheetHelper.loadSheetDefaultObser(action.payload.viewId, state.filesList.filesOrigin, true).pipe(
            map(resultLoadSheet => {
                const payloadSetData: PayloadSheetData = {
                    viewId: action.payload.viewId,
                    mergeViewId: action.payload.mergeViewId,
                    data: resultLoadSheet,
                    childViewIds: action.payload.childViewIds,
                };
                return setSheetData(payloadSetData)
            }),
            catchError(err => [sheetsSlice.actions.error()])
        )
    })
)

const loadSheetDataMergeMulti$: RootEpic = (action$, state$) => action$.pipe(
    filter(loadSheetDataMergeMulti.match),
    distinctUntilChanged(),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const listObs = SheetHelper.loadSheetDefaultObserMulti(action.payload, state.filesList.filesOrigin, true);
        return listObs.pipe(
            map(resultLoadSheet => {
                const pp: PayloadSheetData[] = [];
                resultLoadSheet.forEach(p => {
                    const payloadSetData: PayloadSheetData = {
                        viewId: p.viewId.viewId,
                        mergeViewId: p.viewId.mergeViewId,
                        data: p.sheet,
                        childViewIds: p.viewId.childViewIds,
                    };
                    pp.push(payloadSetData);
                })
                
                return setSheetDataMulti(pp);
            }),
            catchError(err => [sheetsSlice.actions.error()])
        )
    })
)

const activeSheetData$: RootEpic = (action$) => action$.pipe(
    filter(activeSheetData.match),
    distinctUntilChanged((prevAction, currAction) => Lodash.isEqual(prevAction.payload, currAction.payload)),
    switchMap((action) => {
        return SheetHelper.activeSheetDataObser(action.payload).pipe(
            map(_ => {
                const { viewId, id, childsDelete } = action.payload;
                const viewIdParent = GlobalState.getParentMerge(viewId);
                if (viewIdParent) {
                    return sheetsSlice.actions.activeSheetDataComplete({ viewId: viewIdParent, id: viewId, childsDelete })
                }
                return sheetsSlice.actions.activeSheetDataComplete({ viewId, id, childsDelete })
            })
        )
    })
)
const activeSheetMergeData$: RootEpic = (action$, state$) => action$.pipe(
    filter(activeSheetMergeData.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { sheetData, viewId, childsDelete } = action.payload;
        const parentId = GlobalState.getViewId(viewId);
        if (sheetData.viewId && state.sheets.active[parentId] !== sheetData.id) {
            const fileInfoSheetMerge = state.filesList.filesOrigin.find(f => f.viewId === sheetData.viewId);
            if (fileInfoSheetMerge) {
                const webViewerActive = GlobalState.map3DViewer.get(viewId);
                if (webViewerActive) {
                    // unsetnode hightlight and clear the selection when switch sheet
                    webViewerActive.selectPart(null);
                    webViewerActive.model.setNodesHighlighted([webViewerActive.model.getAbsoluteRootNode()], false);
                }
                return [
                    selectViewerMerge(fileInfoSheetMerge),
                    sheetsSlice.actions.activeSheetDataComplete({ viewId: parentId, id: sheetData.viewId, childsDelete })
                ]
            }
        }
        return []
    })
)

const activateCadView$: RootEpic = (action$, state$) => action$.pipe(
    filter(activateCadView.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        const viewer = GlobalState.getViewer3D(viewActive.viewId);
        const id: string = action.payload;
        if (viewer) viewer.model.activateCadView(parseInt(id, 10));
        return []
    })
)

const activeSheetDataComplete$: RootEpic = (action$, state$) => action$.pipe(
    filter(activeSheetDataComplete.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const childsDelete = action.payload.childsDelete;
        if (childsDelete) {
            return [deleteMultiChild(childsDelete), setAppLoading(false)];
        }
        return []
    })
)

export const SheetsEpics = [
    loadSheetDataDefault$,
    loadSheetDataMerge$,
    loadSheetDataMergeMulti$,
    activeSheetData$,
    activeSheetMergeData$,
    activateCadView$,
    activeSheetDataComplete$,
];

export const {
    loadSheetDataDefault,
    loadSheetDataMerge,
    loadSheetDataMergeMulti,
    unLoadSheetDataMerge,
    setSheetData,
    setSheetDataMulti,
    activeSheetData,
    activeSheetMergeData,
    deleteStateByViewIdSheet,
    activateCadView,
    activeSheetDataComplete
} = sheetsSlice.actions;
export default sheetsSlice.reducer;