/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-loop-func */
/* eslint-disable no-param-reassign */
/* eslint-disable indent */

import { IPinMarkerData } from "common/define";
import { GlobalState } from "common/global";
import MathHelper from "container/pdf-viewer/helper/math.helper";
import { fromEvent } from "rxjs";
import { filter, first } from "rxjs/operators";
import { boundPadding, primaryBoundingColor } from "utils/utils";
import { MarkupBaseItem } from "../markup-items/markup.base.item";
import { UndoRedoAction, UndoRedoActionInfor } from "../markup.data";
import { MarkupSettingToolbar } from "./markup.setting-toolbar.element";

export enum GripPoint {
    top = 0,
    topRight = 1,
    right = 2,
    bottomRight = 3,
    bottom = 4,
    bottomLeft = 5,
    left = 6,
    topLeft = 7,
    rotate = 8
}
export class MarkupBaseBounding {
    private viewer: Communicator.WebViewer;
    private boundingBox!: HTMLDivElement;
    private boudingHoverBox!: HTMLDivElement;
    private settingToolbarBox: HTMLElement | null = null;
    private tooltipItem: HTMLDivElement | null = null;
    private gripPoints: HTMLDivElement[] = [];

    private customGrippoints: HTMLDivElement[] = [];

    public currentPosition = Communicator.Point2.zero();

    public currentSize = Communicator.Point2.zero();
    // resize grip
    private gripPointDragMoveCallback: ((point: Communicator.Point2) => void) | undefined = undefined;
    private gripPointDragStartCallback: ((point: Communicator.Point2, type: GripPoint) => void) | undefined = undefined;
    private gripPointDragEndCallback: ((point: Communicator.Point2) => void) | undefined = undefined;
    // rotate grip
    private rotateGripPointDragMoveCallback: ((point: Communicator.Point2, tooltipPos: Communicator.Point2) => void) | undefined = undefined;
    private rotateGripPointDragStartCallback: ((point: Communicator.Point2, type: GripPoint) => void) | undefined = undefined;
    private rotateGripPointDragEndCallback: ((point: Communicator.Point2) => void) | undefined = undefined;
    // bd box
    private boundingBoxDragStartCallback: ((point: Communicator.Point2) => void) | undefined = undefined;
    private boundingBoxDragMoveCallback: ((point: Communicator.Point2) => void) | undefined = undefined;
    private boundingBoxDragEndCallback: ((point: Communicator.Point2) => void) | undefined = undefined;
    private boundingBoxClickCallback: ((point: Communicator.Point2, event: MouseEvent) => void) | undefined = undefined;
    private boundingBoxDoubleClickCallback: any;
    private boundingBoxMouseWheelCallback: any;
    private dragging = false;
    private _isEditingTextbox = false;
    private _padding = boundPadding;
    private _targetingMarkup: MarkupBaseItem | null = null;
    private _rotation = 0;
    protected _gripSize = 8;
    protected _rotateSize = 10;
    protected _rotateLine = 23;
    private _canRotate = false;
    private _center = Communicator.Point2.zero();
    private _gripDirection: number[];
    private _outline = `1px solid ${primaryBoundingColor.hex}`;
    private _zIndex = 3;
    private _borderRounded = false;
    private _boundingTransparent = false;
    private _enableTooltip = false;
    constructor(
        viewer: Communicator.WebViewer,
    ) {
        this.viewer = viewer;
        this._gripDirection = [GripPoint.top, GripPoint.topRight, GripPoint.right, GripPoint.bottomRight, GripPoint.bottom, GripPoint.bottomLeft, GripPoint.left, GripPoint.topLeft];
    }

    setCanRotate(canRotate: boolean): void {
        this._canRotate = canRotate;
    }
    setGripDirection(gripDirections: number[]): void {
        this._gripDirection = gripDirections;
    }

    createTooltip(): void {
        this.tooltipItem = document.createElement('div');
        this.tooltipItem.style.width = 'max-content';
        this.tooltipItem.style.background = 'rgba(60, 60, 60, 100)';
        this.tooltipItem.style.textAlign = 'center';
        this.tooltipItem.style.color = 'rgba(255, 255, 255, 100)';
        this.tooltipItem.style.padding = '3px 7px';
        this.tooltipItem.style.transform = 'translate(-50%, -150%) translateX(15px)';
        this.tooltipItem.style.boxShadow = "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)";
        this.tooltipItem.style.borderRadius = '4px';
        this.tooltipItem.style.visibility = 'visible';
        this.tooltipItem.style.pointerEvents = 'none';
        this.tooltipItem.style.userSelect = 'none';
        this.tooltipItem.classList.add('tooltip-pinmarker');
    }
    createBoundingBox(): void {
        this.boundingBox = document.createElement('div');
        this.boundingBox.classList.add('bdbox-wrap');
        this.boundingBox.style.position = 'absolute';
        this.boundingBox.style.zIndex = `${this._zIndex}`;
        this.boundingBox.style.pointerEvents = 'auto';
        this.boundingBox.style.cursor = 'move';
        this._borderRounded && (this.boundingBox.style.borderRadius = '50%');
        this.boundingBox.style.backgroundColor = `rgba(85,170,170, ${this._boundingTransparent ? '0' : '.08'})`;

        if (this._enableTooltip) {
            this.createTooltip();
            this.tooltipItem && this.boundingBox.append(this.tooltipItem)
        } else {
            this.boundingBox.style.outline = this._outline;
        }

        const redlineBounding = this;
        // Bounding box callback
        const bdBox = this.boundingBox;

        bdBox.onmousedown = (event) => {
            if (event) {
                event.preventDefault();
                // event.stopPropagation();
                GlobalState.isClickOnMarkup = true;
                redlineBounding.dragging = true;
                const parent = bdBox.parentElement;
                if (parent) {
                    parent.style.pointerEvents = 'auto';
                    const originPos = new Communicator.Point2(
                        parseInt(bdBox.style.left, 10) + event.offsetX,
                        parseInt(bdBox.style.top, 10) + event.offsetY)
                    const point = MathHelper.convertPointWithRotation(originPos, this.center, this._rotation);
                    this.toggleTooltipClass(false);
                    if (redlineBounding.boundingBoxDragStartCallback) {
                        redlineBounding.boundingBoxDragStartCallback(point);
                        GlobalState.isMarkupDragging$.next(true);
                    } else {
                        this.draggingStart(point);
                    }
                    const backup = this.getAllSelectedMarkup().map(markup => markup.toJson());
                    parent.onmousemove = ((ev) => {
                        if (ev && redlineBounding.dragging) {
                            ev.stopPropagation();
                            if (redlineBounding.boundingBoxDragMoveCallback) {
                                redlineBounding.boundingBoxDragMoveCallback(redlineBounding.getMousePostion(parent, ev));
                            } else {
                                this.draggingMove(redlineBounding.getMousePostion(parent, ev));
                            }
                        }
                        parent.onmouseup = (ev) => {
                            if (ev && redlineBounding.dragging) {
                                ev.stopPropagation();
                                this.toggleTooltipClass(true);
                                redlineBounding.dragging = false;
                                parent.style.pointerEvents = 'none';

                                const edittedMarkup: UndoRedoActionInfor = {
                                    action: UndoRedoAction.Edit,
                                    item: this.getAllSelectedMarkup(),
                                    backUp: backup,
                                }
                                GlobalState.undoStack$.next(edittedMarkup);
                                if (redlineBounding.boundingBoxDragEndCallback) {
                                    redlineBounding.boundingBoxDragEndCallback(redlineBounding.getMousePostion(parent, ev));
                                    GlobalState.isMarkupDragging$.next(false);
                                } else {
                                    this.draggingEnd(redlineBounding.getMousePostion(parent, ev));
                                }

                            }
                        };
                        parent.onmousedown = (ev) => {
                            // fix right click when drag, resolve by making behavior resemble to mouseup
                            if (ev.button !== 2) return;
                            if (ev && redlineBounding.dragging) {
                                ev.stopPropagation();
                                this.toggleTooltipClass(true);
                                redlineBounding.dragging = false;
                                parent.style.pointerEvents = 'none';

                                const edittedMarkup: UndoRedoActionInfor = {
                                    action: UndoRedoAction.Edit,
                                    item: this.getAllSelectedMarkup(),
                                    backUp: backup,
                                }
                                GlobalState.undoStack$.next(edittedMarkup);
                                if (redlineBounding.boundingBoxDragEndCallback) {
                                    redlineBounding.boundingBoxDragEndCallback(redlineBounding.getMousePostion(parent, ev));
                                    GlobalState.isMarkupDragging$.next(false);
                                } else {
                                    this.draggingEnd(redlineBounding.getMousePostion(parent, ev));
                                }

                            }
                        }
                    });
                    // Add for case "user mouse up in different view"
                    fromEvent<MouseEvent>(document, 'mouseup').pipe(first(), filter(e => redlineBounding.dragging))
                        .subscribe(mouseUp => {
                            const parent = bdBox.parentElement!;
                            this.toggleTooltipClass(true);
                            redlineBounding.dragging = false;
                            parent.style.pointerEvents = 'none';

                            const edittedMarkup: UndoRedoActionInfor = {
                                action: UndoRedoAction.Edit,
                                item: this.getAllSelectedMarkup(),
                                backUp: backup,
                            }
                            GlobalState.undoStack$.next(edittedMarkup);
                            if (redlineBounding.boundingBoxDragEndCallback) {
                                redlineBounding.boundingBoxDragEndCallback(redlineBounding.getMousePostion(parent, mouseUp));
                                GlobalState.isMarkupDragging$.next(false);
                            } else {
                                this.draggingEnd(redlineBounding.getMousePostion(parent, mouseUp));
                            }
                        });
                    parent.onmouseup = (event) => {
                        event.stopPropagation();
                        this.toggleTooltipClass(true);
                        parent.style.pointerEvents = 'none';
                        this.boundingBox.style.cursor = 'move';
                        redlineBounding.dragging = false;
                        GlobalState.isMarkupDragging$.next(false);
                    }
                }
            }
        };
        // Context menu
        bdBox.onclick = (event) => {
            if (!event) return;
            switch (event.button) {
                case 0:
                    if (this.boundingBoxClickCallback) {
                        this.boundingBoxClickCallback(new Communicator.Point2(event.x, event.y), event);
                    }
                    break;
                default:
                    break
            }
        }
        bdBox.onmouseup = (event) => {
            if (!event) return
            GlobalState.isClickOnMarkup = false;
            switch (event.button) {
                case 2:
                    this.viewer.trigger('contextMenu',
                        new Communicator.Point2(parseInt(bdBox.style.left, 10) + event.offsetX,
                            parseInt(bdBox.style.top, 10) + event.offsetY), Communicator.KeyModifiers.None);
                    break;
                default:
                    break
            }
        };

        // Double click & mouse wheel
        if (redlineBounding.boundingBoxDoubleClickCallback && !redlineBounding.dragging) {
            bdBox.ondblclick = (ev) => {
                redlineBounding.boundingBoxDoubleClickCallback();
            };
        }
        this.renderGripPoints();
        this.renderRotateGrippoint();
        this.renderSettingToolbar();
        this.createBoundingHoverBox();
    }
    createBoundingHoverBox(): void {
        this.boudingHoverBox = document.createElement('div');
        this.boudingHoverBox.style.position = 'absolute';
        this.boudingHoverBox.style.zIndex = '1';
        this.boudingHoverBox.style.outline = this._outline;
        this.boudingHoverBox.style.pointerEvents = 'none';
        this.boudingHoverBox.style.backgroundColor = 'rgba(85,170,170, .05)';
        this.boudingHoverBox.onmousedown = (event) => {
            event.preventDefault();
            event.stopPropagation();
            GlobalState.isClickOnMarkup = true;
        }
    }
    renderGripPoints(): void {
        if (this._gripDirection.length > 0) {
            const redlineBounding = this;
            this._gripDirection.forEach(i => {
                const gripPoint = document.createElement('div');
                gripPoint.style.position = 'absolute';
                gripPoint.style.zIndex = '4';
                gripPoint.style.width = `${this._gripSize}px`;
                gripPoint.style.height = `${this._gripSize}px`;
                gripPoint.style.outline = '1px solid gray';
                gripPoint.style.border = '1px solid white';
                gripPoint.style.backgroundColor = '#fff';
                gripPoint.dataset.type = `${i}`;
                gripPoint.style.pointerEvents = 'auto';
                this.setGripPointCursor(gripPoint, i);
                gripPoint.onmousedown = (event) => {
                    if (event) {
                        event.preventDefault();
                        event.stopPropagation();
                        redlineBounding.dragging = true;
                        const target = redlineBounding.getBoundingElement();
                        const parent = gripPoint.parentElement!;
                        this.setGripPointCursor(target, i);
                        this.setGripPointCursor(parent, i);
                        gripPoint.style.backgroundColor = primaryBoundingColor.hex;
                        const backup = this.getAllSelectedMarkup().map(markup => markup.toJson());
                        parent.style.pointerEvents = 'auto';
                        const point = new Communicator.Point2(parseInt(gripPoint.style.left, 10) + event.offsetX,
                            parseInt(gripPoint.style.top, 10) + event.offsetY);
                        if (redlineBounding.gripPointDragStartCallback) {
                            redlineBounding.gripPointDragStartCallback(point, i);
                            GlobalState.isGripDragging$.next(true);
                        }
                        parent.onmousemove = ((ev) => {
                            if (ev && redlineBounding.dragging) {
                                ev.stopPropagation();
                                if (redlineBounding.gripPointDragMoveCallback) {
                                    redlineBounding.gripPointDragMoveCallback(redlineBounding.getMousePostion(parent, ev));
                                }
                            }
                            parent.onmouseup = (ev) => {
                                if (ev && redlineBounding.dragging) {
                                    ev.stopPropagation();
                                    gripPoint.style.backgroundColor = '#fff';
                                    parent.style.pointerEvents = 'none';
                                    this.boundingBox.style.cursor = 'move';
                                    redlineBounding.dragging = false;
                                    if (redlineBounding.gripPointDragEndCallback) {
                                        const edittedMarkup: UndoRedoActionInfor = {
                                            action: UndoRedoAction.Edit,
                                            item: this.getAllSelectedMarkup(),
                                            backUp: backup,
                                        }
                                        GlobalState.undoStack$.next(edittedMarkup);
                                        redlineBounding.gripPointDragEndCallback(redlineBounding.getMousePostion(parent, ev));
                                        GlobalState.isGripDragging$.next(false);
                                    }
                                }
                            };
                        });
                        // Add for case "user mouse up in different view"
                        fromEvent<MouseEvent>(document, 'mouseup').pipe(first(), filter(e => redlineBounding.dragging))
                            .subscribe(mouseUp => {
                                const parent = gripPoint.parentElement!;
                                mouseUp.stopPropagation();
                                gripPoint.style.backgroundColor = '#fff';
                                parent.style.pointerEvents = 'none';
                                this.boundingBox.style.cursor = 'move';
                                redlineBounding.dragging = false;
                                if (redlineBounding.gripPointDragEndCallback) {
                                    const edittedMarkup: UndoRedoActionInfor = {
                                        action: UndoRedoAction.Edit,
                                        item: this.getAllSelectedMarkup(),
                                        backUp: backup,
                                    }
                                    GlobalState.undoStack$.next(edittedMarkup);
                                    GlobalState.isGripDragging$.next(false);
                                    redlineBounding.gripPointDragEndCallback(redlineBounding.getRotateMousePos(parent, mouseUp));
                                }
                            });
                        parent.onmouseup = (event) => {
                            event.stopPropagation();
                            gripPoint.style.backgroundColor = '#fff';
                            parent.style.pointerEvents = 'none';
                            this.boundingBox.style.cursor = 'move';
                            redlineBounding.dragging = false;
                            GlobalState.isGripDragging$.next(false);
                        }
                    }
                };
                this.gripPoints.push(gripPoint);
            })
        }
    }

    renderRotateGrippoint(): void {
        if (!this._canRotate) return;
        const rotateGripPoint = document.createElement('div');
        rotateGripPoint.style.position = 'absolute';
        rotateGripPoint.style.zIndex = '3';
        rotateGripPoint.style.width = `${this._rotateSize}px`;
        rotateGripPoint.style.height = `${this._rotateSize}px`;
        rotateGripPoint.style.borderRadius = '50%';
        rotateGripPoint.style.pointerEvents = 'auto';
        rotateGripPoint.style.backgroundColor = '#fff';
        rotateGripPoint.style.border = '1px solid gray';
        rotateGripPoint.dataset.type = '8';
        rotateGripPoint.style.boxShadow = 'inset 0px 0px 0px 1px white';
        rotateGripPoint.classList.add('rotation-image'); // straight line
        this.setGripPointCursor(rotateGripPoint, 8);
        rotateGripPoint.onmousedown = (event) => {
            if (!event) return;
            event.stopPropagation();
            event.preventDefault();
            this.dragging = true;
            const parent = rotateGripPoint.parentElement!;
            this.setGripPointCursor(parent, 8);
            this.setGripPointCursor(parent, 8);
            rotateGripPoint.style.backgroundColor = primaryBoundingColor.hex;
            const backup = this.getAllSelectedMarkup().map(markup => markup.toJson());
            parent.style.pointerEvents = 'auto';
            const point = new Communicator.Point2(parseInt(rotateGripPoint.style.left, 10) + event.offsetX,
                parseInt(rotateGripPoint.style.top, 10) + event.offsetY);
            if (this.gripPointDragStartCallback) {
                this.gripPointDragStartCallback(point, 8);
                GlobalState.isGripDragging$.next(true);
            }
            parent.onmousemove = ((ev) => {
                if (ev && this.dragging) {
                    ev.stopPropagation();
                    if (this.rotateGripPointDragMoveCallback) {
                        this.rotateGripPointDragMoveCallback(
                            this.getRotateMousePos(parent, ev),
                            this.getMousePostion(parent, ev)
                        );
                    }
                    parent.onmouseup = (ev) => {
                        if (ev && this.dragging) {
                            ev.stopPropagation();
                            rotateGripPoint.style.backgroundColor = '#fff';
                            parent.style.pointerEvents = 'none';
                            this.dragging = false;
                            if (this.rotateGripPointDragEndCallback) {
                                const edittedMarkup: UndoRedoActionInfor = {
                                    action: UndoRedoAction.Edit,
                                    item: this.getAllSelectedMarkup(),
                                    backUp: backup,
                                }
                                GlobalState.undoStack$.next(edittedMarkup);
                                this.rotateGripPointDragEndCallback(this.getRotateMousePos(parent, ev));
                                GlobalState.isGripDragging$.next(false);
                            }
                        }
                    };
                    // Add for case "user mouse up in different view"
                    fromEvent<MouseEvent>(document, 'mouseup').pipe(first(), filter(e => this.dragging))
                        .subscribe(mouseUp => {
                            const parent = rotateGripPoint.parentElement!;
                            mouseUp.stopPropagation();
                            rotateGripPoint.style.backgroundColor = '#fff';
                            parent.style.pointerEvents = 'none';
                            this.dragging = false;
                            if (this.rotateGripPointDragEndCallback) {
                                const edittedMarkup: UndoRedoActionInfor = {
                                    action: UndoRedoAction.Edit,
                                    item: this.getAllSelectedMarkup(),
                                    backUp: backup,
                                }
                                GlobalState.undoStack$.next(edittedMarkup);
                                GlobalState.isGripDragging$.next(false);
                                this.rotateGripPointDragEndCallback(this.getRotateMousePos(parent, mouseUp));
                            }
                        });
                }
            });
            parent.onmouseup = (event) => {
                event.stopPropagation();
                rotateGripPoint.style.backgroundColor = '#fff';
                parent.style.pointerEvents = 'none';
                this.boundingBox.style.cursor = 'move';
                this.dragging = false;
                GlobalState.isGripDragging$.next(false);
            }
        }
        this.gripPoints.push(rotateGripPoint);
    }

    renderSettingToolbar(): void {
        const settingToolbar = new MarkupSettingToolbar();
        if (!settingToolbar) return;
        this.settingToolbarBox = settingToolbar.getSettingToolbarBox();
    }

    setGripPointCallback(dragStartCallback?: (point: Communicator.Point2, type: GripPoint) => void,
        dragMoveCallback?: (point: Communicator.Point2) => void,
        dragEndCallback?: (point: Communicator.Point2) => void): void {
        this.gripPointDragStartCallback = dragStartCallback;
        this.gripPointDragMoveCallback = dragMoveCallback;
        this.gripPointDragEndCallback = dragEndCallback;
    }
    setRotateGripPointCallback(dragStartCallback?: (point: Communicator.Point2, type: GripPoint) => void,
        dragMoveCallback?: (point: Communicator.Point2, tooltipPos: Communicator.Point2) => void,
        dragEndCallback?: (point: Communicator.Point2) => void): void {
        this.gripPointDragStartCallback = dragStartCallback;
        this.rotateGripPointDragMoveCallback = dragMoveCallback;
        this.rotateGripPointDragEndCallback = dragEndCallback;
    }
    setBoudingboxCallback(dragStartCallback?: (point: Communicator.Point2) => void,
        dragMoveCallback?: (point: Communicator.Point2) => void,
        dragEndCallback?: (point: Communicator.Point2) => void): void {
        this.boundingBoxDragStartCallback = dragStartCallback;
        this.boundingBoxDragMoveCallback = dragMoveCallback;
        this.boundingBoxDragEndCallback = dragEndCallback;
    }
    setBoundingBoxClickCallback(boundingBoxClickCallback: (point: Communicator.Point2, event: MouseEvent) => void): void {
        this.boundingBoxClickCallback = boundingBoxClickCallback;
    }

    setBoundingboxDoubleClickCallback(doubleClickCallback: () => void): void {
        this.boundingBoxDoubleClickCallback = doubleClickCallback;
    }
    setBoundingBoxMouseWheelCallback(mouseWheelCallback: (ev: WheelEvent) => void): void {
        this.boundingBoxMouseWheelCallback = mouseWheelCallback;
    }

    setBoundingBoxPointerEvent(isEnable: boolean): void {
        if (this.boundingBox && this.boundingBox.parentElement) {
            if (isEnable) {
                this.boundingBox.parentElement.style.pointerEvents = 'none';
                this.boundingBox.style.pointerEvents = 'auto';
            } else {
                this.boundingBox.parentElement.style.pointerEvents = 'auto';
                this.boundingBox.style.pointerEvents = 'none';
            }
        }
    }
    setTextboxBoundingPointerEvent(isEnable: boolean): void {
        if (this.boundingBox && this.boundingBox.parentElement) {
            this.boundingBox.parentElement.style.pointerEvents = isEnable ? 'auto' : 'none'
        }
    }

    getMousePostion(elem: HTMLElement, event: MouseEvent): Communicator.Point2 {
        const rect = elem.getBoundingClientRect();
        const left = rect.x + window.pageXOffset;
        const top = rect.y + window.pageYOffset;

        return new Communicator.Point2(event.clientX - left, event.clientY - top);
    }

    getRotateMousePos(elem: HTMLElement, event: MouseEvent): Communicator.Point2 {
        return new Communicator.Point2(event.clientX, event.clientY);
    }

    setPosition(point: Communicator.Point2): void {
        this.boundingBox.style.left = `${point.x - this._padding}px`;
        this.boundingBox.style.top = `${point.y - this._padding}px`;

        this.boudingHoverBox.style.left = `${point.x - this._padding}px`;
        this.boudingHoverBox.style.top = `${point.y - this._padding}px`;

        if (this.settingToolbarBox) {
            this.settingToolbarBox.style.left = `${point.x}px`;
            this.settingToolbarBox.style.top = `${point.y - this._padding - 40}px`;
        }


        this.currentPosition = new Communicator.Point2(point.x - this._padding, point.y - this._padding);
        this.updateGripPoints();
    }
    setVisibleGripPoints(vis: boolean): void {
        this.gripPoints.forEach(v => {
            if (vis) {
                v.style.display = 'initial';
                if (v.dataset.type === '8')
                    v.classList.add('rotation-image')
            }
            else {
                v.style.display = 'none';
                if (v.dataset.type === '8')
                    v.classList.remove('rotation-image')
            }
        })
    }

    setSize(size: Communicator.Point2): void {
        this.boundingBox.style.width = `${size.x + this._padding * 2}px`;
        this.boundingBox.style.height = `${size.y + this._padding * 2}px`;

        this.boudingHoverBox.style.width = `${size.x + this._padding * 2}px`;
        this.boudingHoverBox.style.height = `${size.y + this._padding * 2}px`;

        if (this.settingToolbarBox) {
            this.settingToolbarBox.style.width = `${size.x}px`;
        }
        this.currentSize = new Communicator.Point2(size.x + this._padding * 2, size.y + this._padding * 2);
        this.updateGripPoints();
    }

    updateGripPoints(): void {
        if (this.currentPosition && this.currentSize && this.gripPoints) {
            for (let i = 0; i <= 8; i++) {
                const gripPoint = this.gripPoints[i];
                const gripType = gripPoint?.dataset?.type;
                if (!gripType) break;
                switch (Number(gripType)) {
                    case GripPoint.top:
                        this.setGripPointPosition(gripPoint,
                            new Communicator.Point2(this.currentPosition.x + this.currentSize.x / 2, this.currentPosition.y));
                        break;
                    case GripPoint.topRight:
                        this.setGripPointPosition(gripPoint,
                            new Communicator.Point2(this.currentPosition.x + this.currentSize.x, this.currentPosition.y));
                        break;
                    case GripPoint.right:
                        this.setGripPointPosition(gripPoint,
                            new Communicator.Point2(this.currentPosition.x + this.currentSize.x, this.currentPosition.y + this.currentSize.y / 2));
                        break;
                    case GripPoint.bottomRight:
                        this.setGripPointPosition(gripPoint,
                            new Communicator.Point2(this.currentPosition.x + this.currentSize.x, this.currentPosition.y + this.currentSize.y));
                        break;
                    case GripPoint.bottom:
                        this.setGripPointPosition(gripPoint,
                            new Communicator.Point2(this.currentPosition.x + this.currentSize.x / 2, this.currentPosition.y + this.currentSize.y));
                        break;
                    case GripPoint.bottomLeft:
                        this.setGripPointPosition(gripPoint,
                            new Communicator.Point2(this.currentPosition.x, this.currentPosition.y + this.currentSize.y));
                        break;
                    case GripPoint.left:
                        this.setGripPointPosition(gripPoint,
                            new Communicator.Point2(this.currentPosition.x, this.currentPosition.y + this.currentSize.y / 2));
                        break;
                    case GripPoint.topLeft:
                        this.setGripPointPosition(gripPoint,
                            this.currentPosition);
                        break;
                    case GripPoint.rotate:
                        this.setGripPointPosition(gripPoint,
                            new Communicator.Point2(this.currentPosition.x + this.currentSize.x / 2, this.currentPosition.y + this.currentSize.y + this._rotateLine), true);
                        break
                    default:
                        break;
                }
            }
        }
    }

    setGripPointCursor(gripPoint: HTMLDivElement | HTMLElement, type: number): void {
        if (!gripPoint) return;
        switch (type) {
            case GripPoint.top:
                gripPoint.style.cursor = MathHelper.getCursorString(this._rotation, 'n-resize');
                break;
            case GripPoint.topRight:
                gripPoint.style.cursor = MathHelper.getCursorString(this._rotation + 45, 'ne-resize');
                break;
            case GripPoint.right:
                gripPoint.style.cursor = MathHelper.getCursorString(this._rotation + 90, 'e-resize');
                break;
            case GripPoint.bottomRight:
                gripPoint.style.cursor = MathHelper.getCursorString(this._rotation + 135, 'se-resize');
                break;
            case GripPoint.bottom:
                gripPoint.style.cursor = MathHelper.getCursorString(this._rotation, 's-resize');
                break;
            case GripPoint.bottomLeft:
                gripPoint.style.cursor = MathHelper.getCursorString(this._rotation - 135, 'sw-resize');
                break;
            case GripPoint.left:
                gripPoint.style.cursor = MathHelper.getCursorString(this._rotation - 90, 'w-resize');
                break;
            case GripPoint.topLeft:
                gripPoint.style.cursor = MathHelper.getCursorString(this._rotation - 45, 'nw-resize');
                break;
            case GripPoint.rotate:
                gripPoint.style.cursor = MathHelper.getRotateCursor()
                break;
            default:
                break;
        }
    }

    updateGripPointCursor(): void {
        if (this.gripPoints.length > 0) {
            this.gripPoints.forEach(v => {
                const gripType = v?.dataset?.type;
                if (gripType) this.setGripPointCursor(v, Number(gripType));
            })
        }
    }

    setGripPointPosition(gripPoint: HTMLDivElement, position: Communicator.Point2, isRotateGrip = false): void {
        if (!gripPoint || !position) return;
        const centerPt = new Communicator.Point2(
            this.currentPosition.x + this.currentSize.x / 2,
            this.currentPosition.y + this.currentSize.y / 2,
        )
        const radAngle = this.getRotation() * Math.PI / 180;
        const vec = position.copy().subtract(centerPt.copy());
        const vec1 = MathHelper.calVector(vec, radAngle);
        const pos = centerPt.copy().add(vec1.scale(1));
        const gripSize = isRotateGrip ? this._rotateSize : this._gripSize;
        gripPoint.style.left = `${pos.x - gripSize / 2}px`;
        gripPoint.style.top = `${pos.y - gripSize / 2}px`;
    }

    getBoundingGripElement(): HTMLElement[] {
        const rets: HTMLElement[] = [];
        rets.push(this.boundingBox);
        this.gripPoints && this.gripPoints.forEach((value) => {
            if (value) rets.push(value);
        });
        this.customGrippoints && this.customGrippoints.forEach((value) => {
            if (value) rets.push(value);
        });
        return rets;
    }
    getBoundingHoverElement(): HTMLElement {
        return this.boudingHoverBox;
    }
    getBoundingElement(): HTMLElement {
        return this.boundingBox;
    }

    setEditingTextbox(isEdit: boolean): void {
        this._isEditingTextbox = isEdit;
    }
    setTargetingMarkup(markup: MarkupBaseItem): void {
        this._targetingMarkup = markup;
    }

    getAllSelectedMarkup(): MarkupBaseItem[] {
        const allSelectedMarkups: MarkupBaseItem[] = this.viewer.markupManager._getItemManager()._markupItems.toJSON()
            .map((v: any[]) => v[1])
            .filter((v: MarkupBaseItem) => v.isSelected);
        return allSelectedMarkups;
    }
    getAllMarkup(): MarkupBaseItem[] {
        return this.viewer.markupManager._getItemManager()._markupItems.toJSON().map((v: any[]) => v[1]);
    }
    draggingStart(point: Communicator.Point2): void {
        const allSelectedMarkups = this.getAllSelectedMarkup();
        const allVisibledMarkups = allSelectedMarkups.filter(v => v.getMarkupVisible() && !v.isMarkup3D);
        allVisibledMarkups.forEach(v => v.onDragStart(point));
        GlobalState.isMarkupDragging$.next(true);
    }
    draggingMove(point: Communicator.Point2): void {
        const allSelectedMarkups = this.getAllSelectedMarkup();
        const allVisibledMarkups = allSelectedMarkups.filter(v => v.getMarkupVisible() && !v.isMarkup3D);

        allVisibledMarkups.forEach(v => v.onDragMove(point));
    }
    draggingEnd(point: Communicator.Point2): void {
        const allSelectedMarkups = this.getAllSelectedMarkup();
        const allVisibledMarkups = allSelectedMarkups.filter(v => v.getMarkupVisible() && !v.isMarkup3D);
        allVisibledMarkups.forEach(v => v.onDragEnd(point));
        this._targetingMarkup?.triggerOnMarkupUpdated();
        GlobalState.isMarkupDragging$.next(false);
    }
    getCenter(element: HTMLElement): Point2 {
        const { left, top, width, height } = element.getBoundingClientRect();
        return {
            x: left + width / 2,
            y: top + height / 2
        }
    }
    setVisibleBounding(vis: boolean): void {
        this.boudingHoverBox.style.display = vis ? 'initial' : 'none';
        this.boundingBox.style.display = vis ? 'initial' : 'none';
        this.setVisibleGripPoints(vis);
    }

    setRotation(deg: number): void {
        this._rotation = deg;
    }
    getRotation(): number {
        return this._rotation;
    }
    updateRotationTransform(deg: number): void {
        const transformStr = `rotate(${deg}deg)`;
        this.getBoundingGripElement().forEach(el => el.style.transform = transformStr);
        this.getBoundingHoverElement().style.transform = transformStr;
    }

    public get center(): Communicator.Point2 {
        return this._center;
    }
    public set center(point: Communicator.Point2) {
        this._center = point
    }

    public get padding(): number {
        return this._padding;
    }
    public set padding(pad: number) {
        this._padding = pad;
    }
    setCenter(p1: Communicator.Point2, p2: Communicator.Point2): void {
        this.center = new Communicator.Point2(
            (p1.x + p2.x) / 2,
            (p1.y + p2.y) / 2);
    }
    setOutlineVisible(vis: boolean): void {
        this._outline = vis ? `1px solid ${primaryBoundingColor.hex}` : 'none';
    }
    setZIndex(index: number): void {
        this._zIndex = index;
    }
    public set borderRounded(isRounded: boolean) {
        this._borderRounded = isRounded;
    }
    public set boundingTransparent(vis: boolean) {
        this._boundingTransparent = vis;
    }
    public set enableTooltip(vis: boolean) {
        this._enableTooltip = vis;
    }
    toggleTooltipClass(vis: boolean): void {
        if (!this.tooltipItem) return;
        this.tooltipItem.style.visibility = vis ? 'visible' : 'hidden';
    }
    setTooltipContent(data: IPinMarkerData): void {
        if (!this.tooltipItem) return;
        this.tooltipItem.innerHTML = `${data.objectId}`;
    }
    setBoundingBoxMouseEvent(acceptEvent: boolean): void {
        this.boundingBox.style.pointerEvents = acceptEvent ? 'auto' : 'none';
    }
}
