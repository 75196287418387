import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StreamFile } from "common/define";

interface DashboardState {
    arrSelected: StreamFile[],
    searchText: string,
    folders: StreamFile[],
    currentPath: string
}

const initState: DashboardState = {
    arrSelected: [],
    searchText : '',
    folders: [],
    currentPath: ''
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: initState,
    reducers: {
        setArrSelected(state, action: PayloadAction<StreamFile[]>) {
            state.arrSelected = action.payload
        },
        setSearchText(state, action: PayloadAction<string>) {
            state.searchText = action.payload
        },
        setFolders: (state, action: PayloadAction<StreamFile[]>) => {
            state.folders = action.payload;
        },
        pushFolders: (state, action: PayloadAction<StreamFile>) => {
            state.folders.push(action.payload);
        },
        setCurrentPath: (state, action: PayloadAction<string>) => {
            state.currentPath = action.payload;
        }
    }
});

export const {
    setArrSelected, 
    setSearchText, 
    setFolders, 
    pushFolders, 
    setCurrentPath
} = dashboardSlice.actions;
export default dashboardSlice.reducer;