import Utils from 'utils/utils';
import {GroupHandelId } from './cutting-plane.helper';
export default class cuttingHandleOperator extends  Communicator.Operator.HandleOperator {
    viewer : Communicator.WebViewer;
    public mapGroupHandelId_NodeId : Map<number, number> = new Map<number, number>();
    public mapNodeId_GroupNodeIdHandelId : Map<number, number> = new Map<number, number>();
    public mapGroupPlane_GroupNodeIdHandelId : Map<number, Communicator.CuttingSection> = new Map<number, Communicator.CuttingSection>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public activeGroupId :number | undefined
    public activeHandleNodeId :number | undefined
    public myCubeHandleId : number[] = []
    public mapCubeHandleTranslateAbleAxis : Map<number, Communicator.Point3> = new Map<number, Communicator.Point3>();
    removeAble = true;
    typeCutting = '';

    constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.viewer = viewer;
        this.myCubeHandleId = [];
    }
    
    async onMouseDown(event: Communicator.Event.MouseInputEvent):Promise<void> {
        if(this.typeCutting === 'plane-box') {
            this.removeAble = true;
            const pickConfig = new Communicator.PickConfig(Communicator.SelectionMask.All);
            const selectionItem = await this.viewer.view.pickFromPoint(event.getPosition(), pickConfig);
            const nodeId = selectionItem.getNodeId();
            if (nodeId !== null && nodeId < 0) {
                this.activeHandleNodeId = nodeId;
                this.activeGroupId = this.GetHandleGroupIdByNodeId(nodeId);
                if (this.activeGroupId) this.setNodeIds([nodeId], this.activeGroupId);
                // Get node id move with handle same vector -> update position on HandleEvenFunction
                // if (handleGroupId !== undefined) {
                //     const moveIds = this.getNodeIdMoveWithGroupId(handleGroupId);
                //     this.setNodeIds(moveIds, handleGroupId);
                // }
            }
        }
        return super.onMouseDown(event);
    }

    onMouseMove(event: Communicator.Event.MouseInputEvent): Promise<void> {
        if (this.typeCutting === 'plane-box') {
            this.removeAble = false;
        }
        return super.onMouseMove(event);
    }

    onMouseUp(event: Communicator.Event.MouseInputEvent) : void{
        if(this.activeHandleNodeId)
            this.activeHandleNodeId = undefined;
        if (this.typeCutting === 'plane-box') this._dragCount = 3;
        super.onMouseUp(event);
        this.removeAble = true;
    }

    async CreateMeshInstance(meshId : Communicator.MeshId, position: Communicator.Point3, color: Communicator.Color): Promise<NodeId>{
        const meshInstanceData = new Communicator.MeshInstanceData(meshId);
        meshInstanceData.setFaceColor(color);
        meshInstanceData.setCreationFlags(Communicator.MeshInstanceCreationFlags.SuppressCameraScale 
            | Communicator.MeshInstanceCreationFlags.DoNotCut 
            | Communicator.MeshInstanceCreationFlags.DoNotExplode 
            | Communicator.MeshInstanceCreationFlags.ExcludeBounding 
            | Communicator.MeshInstanceCreationFlags.DoNotXRay 
            | Communicator.MeshInstanceCreationFlags.OverrideSceneVisibility 
            | Communicator.MeshInstanceCreationFlags.AlwaysDraw);
        const translationMatrix = new Communicator.Matrix();

        const translationScale = 0.01;
        translationMatrix.setTranslationComponent(position.x, position.y, position.z);
        translationMatrix.setScaleComponent(translationScale, translationScale, translationScale);
        meshInstanceData.setMatrix(translationMatrix);
        const nodeId = await this.viewer.model.createMeshInstance(meshInstanceData, null, false, true);
        return nodeId;
    }

    async addTranlateCubeAxisHandle(position: Communicator.Point3, axis: Communicator.Point3, color: Communicator.Color, positionNormal?: Communicator.Point3 | null, groupId?: number | null): Promise<void>{
        //create meshInstance
        const meshData = Utils.createCubeMeshData();
        const meshId = await this.viewer.model.createMesh(meshData);
        const promise = this.CreateMeshInstance(meshId, position, color);
        if(groupId)
            promise.then((id) => {
                this.mapGroupHandelId_NodeId.set(groupId, id);
                this.mapNodeId_GroupNodeIdHandelId.set(id, groupId);
                //for custom handle
                this.myCubeHandleId.push(id);
                this.mapCubeHandleTranslateAbleAxis.set(id, axis);
            });
    }

    async removeHandles(): Promise<void>{
        if(this.typeCutting === 'plane-box' && this.removeAble) {
            return await Promise.resolve(undefined);
        }
        else
            return await super.removeHandles()
    }

    async deleteHandles(): Promise<void> {
        super.removeHandles();
    }

    getCuttingSectionToUpdateHandlePos(handleGroup : number): Communicator.CuttingSection | undefined{
        switch (handleGroup) {
        case GroupHandelId.Conner_ZXY:
        case GroupHandelId.Conner_ZXmY:
        case GroupHandelId.Conner_ZmXY:
        case GroupHandelId.Conner_ZmXmY:
        case GroupHandelId.Middle_ZX:
        case GroupHandelId.Middle_ZmX:
        case GroupHandelId.Middle_ZY:
        case GroupHandelId.Middle_ZmY:
            return this.mapGroupPlane_GroupNodeIdHandelId.get(GroupHandelId.Translate_Z);
        case GroupHandelId.Conner_mZXY:
        case GroupHandelId.Conner_mZXmY:
        case GroupHandelId.Conner_mZmXY:
        case GroupHandelId.Conner_mZmXmY:
        case GroupHandelId.Middle_mZX:
        case GroupHandelId.Middle_mZmX:
        case GroupHandelId.Middle_mZY:
        case GroupHandelId.Middle_mZmY:
            return this.mapGroupPlane_GroupNodeIdHandelId.get(GroupHandelId.Translate_mZ);
        default:
            return this.mapGroupPlane_GroupNodeIdHandelId.get(handleGroup);
        }
    }

    getNodeIdMoveWithGroupId(handleGroup : number): number[]{
        //const nodeIds : number[] = [];
        //get handle group id
        const handleGroupIds = this.getHandleGroupIdMOveWithGroup(handleGroup);
        //get handle node id
        const handleNodeIds = this.getHandleNodeIds(handleGroupIds);
        
        //get plan nodeid
        return handleNodeIds;
    }

    getHandleNodeIds(handleGroupIds: number[]): number[]{
        const handleNodeIds : number[] = [];
        handleGroupIds.forEach(handleGroupId => {
            const nodeid = this.mapGroupHandelId_NodeId.get(handleGroupId);
            if(nodeid) handleNodeIds.push(nodeid);
        });
        return handleNodeIds
    }

    getHandleGroupIdMOveWithGroup(handleGroup: number): number[]{
        const handleGroupIds : number[] = [];
        handleGroupIds.push(handleGroup)

        switch (handleGroup) {
        case GroupHandelId.Translate_X:
            handleGroupIds.push(GroupHandelId.Conner_ZXY);
            handleGroupIds.push(GroupHandelId.Conner_ZXmY);
            handleGroupIds.push(GroupHandelId.Conner_mZXY);
            handleGroupIds.push(GroupHandelId.Conner_mZXmY);
            break;
        case GroupHandelId.Translate_mX:
            handleGroupIds.push(GroupHandelId.Conner_ZmXY);
            handleGroupIds.push(GroupHandelId.Conner_ZmXmY);
            handleGroupIds.push(GroupHandelId.Conner_mZmXY);
            handleGroupIds.push(GroupHandelId.Conner_mZmXmY);
            break;
        case GroupHandelId.Translate_Y:
            handleGroupIds.push(GroupHandelId.Conner_ZXY);
            handleGroupIds.push(GroupHandelId.Conner_ZmXY);
            handleGroupIds.push(GroupHandelId.Conner_mZXY);
            handleGroupIds.push(GroupHandelId.Conner_mZmXY);
            
            break;
        case GroupHandelId.Translate_mY:
            handleGroupIds.push(GroupHandelId.Conner_ZXmY);
            handleGroupIds.push(GroupHandelId.Conner_ZmXmY);
            handleGroupIds.push(GroupHandelId.Conner_mZXmY);
            handleGroupIds.push(GroupHandelId.Conner_mZmXmY);
            break;
        case GroupHandelId.Translate_Z:
            handleGroupIds.push(GroupHandelId.Conner_ZXY);
            handleGroupIds.push(GroupHandelId.Conner_ZXmY);
            handleGroupIds.push(GroupHandelId.Conner_ZmXmY);
            handleGroupIds.push(GroupHandelId.Conner_ZmXY);
            break;
        case GroupHandelId.Middle_ZmX:
            handleGroupIds.push(GroupHandelId.Conner_ZXY);
            handleGroupIds.push(GroupHandelId.Conner_ZXmY);
            handleGroupIds.push(GroupHandelId.Conner_ZmXmY);
            handleGroupIds.push(GroupHandelId.Conner_ZmXY);
            handleGroupIds.push(GroupHandelId.Middle_ZY);
            handleGroupIds.push(GroupHandelId.Middle_ZX);
            handleGroupIds.push(GroupHandelId.Middle_ZmY);
            handleGroupIds.push(GroupHandelId.Translate_Z);
            break;
        case GroupHandelId.Middle_ZX:
            handleGroupIds.push(GroupHandelId.Conner_ZXY);
            handleGroupIds.push(GroupHandelId.Conner_ZXmY);
            handleGroupIds.push(GroupHandelId.Conner_ZmXmY);
            handleGroupIds.push(GroupHandelId.Conner_ZmXY);
            handleGroupIds.push(GroupHandelId.Middle_ZY);
            handleGroupIds.push(GroupHandelId.Middle_ZmX);
            handleGroupIds.push(GroupHandelId.Middle_ZmY);
            handleGroupIds.push(GroupHandelId.Translate_Z);
            break;
        case GroupHandelId.Middle_ZY:
            handleGroupIds.push(GroupHandelId.Conner_ZXY);
            handleGroupIds.push(GroupHandelId.Conner_ZXmY);
            handleGroupIds.push(GroupHandelId.Conner_ZmXmY);
            handleGroupIds.push(GroupHandelId.Conner_ZmXY);
            handleGroupIds.push(GroupHandelId.Middle_ZmX);
            handleGroupIds.push(GroupHandelId.Middle_ZmY);
            handleGroupIds.push(GroupHandelId.Translate_Z);
            handleGroupIds.push(GroupHandelId.Middle_ZX);
            break;
        case GroupHandelId.Middle_ZmY:
            handleGroupIds.push(GroupHandelId.Conner_ZXY);
            handleGroupIds.push(GroupHandelId.Conner_ZXmY);
            handleGroupIds.push(GroupHandelId.Conner_ZmXmY);
            handleGroupIds.push(GroupHandelId.Conner_ZmXY);
            handleGroupIds.push(GroupHandelId.Middle_ZmX);
            handleGroupIds.push(GroupHandelId.Middle_ZmY);
            handleGroupIds.push(GroupHandelId.Translate_Z);
            handleGroupIds.push(GroupHandelId.Middle_ZY);
            handleGroupIds.push(GroupHandelId.Middle_ZX);
            break;
        case GroupHandelId.Middle_mZmY:
            handleGroupIds.push(GroupHandelId.Conner_mZXY);
            handleGroupIds.push(GroupHandelId.Conner_mZXmY);
            handleGroupIds.push(GroupHandelId.Conner_mZmXmY);
            handleGroupIds.push(GroupHandelId.Conner_mZmXY);
            // handleGroupIds.push(GroupHandelId.Middle_mZmX);
            handleGroupIds.push(GroupHandelId.Middle_mZmY);
            handleGroupIds.push(GroupHandelId.Translate_mZ);
            handleGroupIds.push(GroupHandelId.Middle_mZY);
            handleGroupIds.push(GroupHandelId.Middle_mZX);
            break;
        case GroupHandelId.Middle_mZY:
            handleGroupIds.push(GroupHandelId.Conner_mZXY);
            handleGroupIds.push(GroupHandelId.Conner_mZXmY);
            handleGroupIds.push(GroupHandelId.Conner_mZmXmY);
            handleGroupIds.push(GroupHandelId.Conner_mZmXY);
            handleGroupIds.push(GroupHandelId.Middle_mZmX);
            handleGroupIds.push(GroupHandelId.Middle_mZmY);
            handleGroupIds.push(GroupHandelId.Translate_mZ);
            handleGroupIds.push(GroupHandelId.Middle_mZX);
            // handleGroupIds.push(GroupHandelId.Middle_mZY);
            break;
        case GroupHandelId.Middle_mZX:
            handleGroupIds.push(GroupHandelId.Conner_mZXY);
            handleGroupIds.push(GroupHandelId.Conner_mZXmY);
            handleGroupIds.push(GroupHandelId.Conner_mZmXmY);
            handleGroupIds.push(GroupHandelId.Conner_mZmXY);
            handleGroupIds.push(GroupHandelId.Middle_mZmX);
            handleGroupIds.push(GroupHandelId.Middle_mZmY);
            handleGroupIds.push(GroupHandelId.Translate_mZ);
            // handleGroupIds.push(GroupHandelId.Middle_mZX);
            handleGroupIds.push(GroupHandelId.Middle_mZY);
            break;
        case GroupHandelId.Middle_mZmX:
            handleGroupIds.push(GroupHandelId.Conner_mZXY);
            handleGroupIds.push(GroupHandelId.Conner_mZXmY);
            handleGroupIds.push(GroupHandelId.Conner_mZmXmY);
            handleGroupIds.push(GroupHandelId.Conner_mZmXY);
            // handleGroupIds.push(GroupHandelId.Middle_mZmX);
            handleGroupIds.push(GroupHandelId.Middle_mZmY);
            handleGroupIds.push(GroupHandelId.Translate_mZ);
            handleGroupIds.push(GroupHandelId.Middle_mZX);
            handleGroupIds.push(GroupHandelId.Middle_mZY);
            break;    
        case GroupHandelId.Translate_mZ:
            handleGroupIds.push(GroupHandelId.Conner_mZXY);
            handleGroupIds.push(GroupHandelId.Conner_mZXmY);
            handleGroupIds.push(GroupHandelId.Conner_mZmXmY);
            handleGroupIds.push(GroupHandelId.Conner_mZmXY);
            break;

        case GroupHandelId.Conner_ZXY:
            handleGroupIds.push(GroupHandelId.Conner_mZXY);
            break;
        case GroupHandelId.Conner_ZmXY:
            handleGroupIds.push(GroupHandelId.Conner_mZmXY);
            break;
        case GroupHandelId.Conner_ZmXmY:
            handleGroupIds.push(GroupHandelId.Conner_mZmXmY);
            break;
        
        case GroupHandelId.Conner_mZXY:
            handleGroupIds.push(GroupHandelId.Conner_ZXY);
            break;
        case GroupHandelId.Conner_mZmXY:
            handleGroupIds.push(GroupHandelId.Conner_ZmXY);
            break;
        case GroupHandelId.Conner_mZXmY:
            handleGroupIds.push(GroupHandelId.Conner_ZXmY);
            break;
        case GroupHandelId.Conner_mZmXmY:
            handleGroupIds.push(GroupHandelId.Conner_ZmXmY);
            break;
        default:
            break;
        }
        return handleGroupIds;
    }

    addAxisTranslationHandle(position: Communicator.Point3, axis: Communicator.Point3, color: Communicator.Color, positionNormal?: Communicator.Point3 | null, groupId?: number | null): Promise<NodeId>{
        const promise : Promise<number> = super.addAxisTranslationHandle(position, axis, color, positionNormal, groupId);
        if(groupId)
            promise.then((id) => {
                this.mapGroupHandelId_NodeId.set(groupId, id);
                this.mapNodeId_GroupNodeIdHandelId.set(id, groupId);
            });
        return promise;
    }

    addPlaneTranslationHandle(position: Communicator.Point3, normal: Communicator.Point3, faceColor: Communicator.Color, lineColor: Communicator.Color, positionNormal?: Communicator.Point3 | null, groupId?: number | null): Promise<NodeId>{
        const promise : Promise<number> = super.addPlaneTranslationHandle(position, normal, faceColor, lineColor, positionNormal, groupId);
        if(groupId)
            promise.then((id) => {
                this.mapGroupHandelId_NodeId.set(groupId, id);
                this.mapNodeId_GroupNodeIdHandelId.set(id, groupId);
            });
        return promise;
    }
    private GetHandleGroupIdByNodeId(nodeId : number): number | undefined {
        return this.mapNodeId_GroupNodeIdHandelId.get(nodeId);
    }
}