import { MarkupBaseOperator } from './markup.base.operator';
import { MarkupArrowItem } from '../markup-items/markup.arrow.item';
export class MarkupArrowOperator extends MarkupBaseOperator {
    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.cursorSprite = true;
    }
    public createMarkupItem(position: Communicator.Point2): void {
        const view = this._viewer.view;
        this._markupItem = new MarkupArrowItem(this._viewer);
        this._markupItem.setLineFormat(this._lineColor, this._lineWeight, this._lineOpacity, this._fillColor, this._fillColorOption, this._lineStyle);
        const itemId = this._viewer.markupManager.registerMarkup(this._markupItem);
        this._markupItem.setMarkupItemId(itemId);
        const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(position, view);
        if (cameraPoint !== null) {
            (this._markupItem as MarkupArrowItem).setFirstPoint(cameraPoint);
            (this._markupItem as MarkupArrowItem).setSecondPoint(cameraPoint);
        }
    }

    public updateMarkupItem(position: Communicator.Point2): void {
        if (this._markupItem) {
            const view = this._viewer.view;
            const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(position, view);
            if (cameraPoint !== null) {
                (this._markupItem as MarkupArrowItem).setSecondPoint(cameraPoint);
                this._viewer.markupManager.refreshMarkup();
            }
        }
    }
}

