/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable indent */

import { CuttingType, PlaneBoxChild } from "common/type-viewer";
import Utils, { Lodash } from "utils/utils";
import { ActiveButtonControlService } from "./activeButtonControl.service";
import { GroupHandelId } from "./cutting-plane.helper";
import cuttingHandleOperator from "./cutting.handleOperator";
export default class CuttingPlaneOperator extends Communicator.Operator.OperatorBase {
    private _handleEventPromise = Promise.resolve();
    NodeId: Communicator.NodeId = 0;
    Color = Communicator.Color;
    Operator = Communicator.Operator;
    OperatorId = Communicator.OperatorId;
    private currentId: NodeId | undefined;
    public handleOperatorActive: cuttingHandleOperator;
    private _activeCuttingPlane: Communicator.Plane | null = null;
    private _boxCorners: Communicator.Point3[] = [];
    private mapHandleIdPoint = new Map<number, Communicator.Point3>();
    viewer: Communicator.WebViewer;
    idMiddlePlane = 0

    oldMiliSeccond: number | undefined;

    currentMiliSeccond: number | undefined;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    activeRedlineItem: any = null;

    currentOperatorId = null;

    bRemoteHandle?: boolean = false;

    cuttingManager: Communicator.CuttingManager | null = null;

    sectionActive: Communicator.CuttingSection | null = null;
    modeMultipleSides = false;
    isVisibility = false;
    referenceGeometryX: Communicator.Point3[] | null = null;
    referenceGeometryY: Communicator.Point3[] | null = null;
    referenceGeometryZ: Communicator.Point3[] | null = null;
    section1: Communicator.CuttingSection | null = null;

    section2: Communicator.CuttingSection | null = null;

    section3: Communicator.CuttingSection | null = null;

    section4: Communicator.CuttingSection | null = null;

    section5: Communicator.CuttingSection | null = null;

    section6: Communicator.CuttingSection | null = null;
    translationMatrix: Communicator.Matrix | undefined;
    meshInstanceData: Communicator.MeshInstanceData | undefined;
    meshInst: any;
    myMeshId: any;
    flatCutting1 = false;
    flatCutting2 = false;
    flatCutting3 = false;
    flatCutting4 = false;
    flatCutting5 = false;
    flatCutting6 = false;
    typeCutting = ''
    saveHandleType = 1;
    canMove: any = []
    planePosNew: any;
    isMoveHandle = false;
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    private _handleSelected: boolean = false;
    private _handleEventPromiseTransle = Promise.resolve();
    activeButtonControlService: ActiveButtonControlService
    public handleCustome: Communicator.Operator.HandleOperator | undefined
    public meshDataAxis: any = []
    //public handleCustome : Communicator.Operator.HandleOperator | undefined
    private idHandleCustome: number;
    public listHandlePlaneRemove: PlaneBoxChild[] = [];
    public checkUpdate = false;
    public mapGroupHandelId_section: Map<number, Communicator.CuttingSection> = new Map<number, Communicator.CuttingSection>();
    constructor(viewer: Communicator.WebViewer) {
        // eslint-disable-next-line no-underscore-dangle
        super(viewer);
        this.viewer = viewer;
        this.cuttingManager = this.viewer.cuttingManager
        this.unSetCallbacks();
        this.setCallbacks();
        this.activeButtonControlService = new ActiveButtonControlService()
        this.activeButtonControlService.setViewer(viewer);
        this.handleOperatorActive = new cuttingHandleOperator(this.viewer)
        this.idHandleCustome = this.viewer.operatorManager.registerCustomOperator(this.handleOperatorActive);
        this.viewer.operatorManager.push(this.idHandleCustome);
    }
    CreateNewHandleOperator(): void {
        const trackedPoints = this.handleOperatorActive.getTrackedPoints();
        //create New cutting handle and replace default HanleId
        this.viewer.operatorManager.remove(this.idHandleCustome);
        this.viewer.operatorManager.unregisterCustomOperator(this.idHandleCustome);
        this.handleOperatorActive = new cuttingHandleOperator(this.viewer);
        this.idHandleCustome = this.viewer.operatorManager.registerCustomOperator(this.handleOperatorActive);
        this.viewer.operatorManager.push(this.idHandleCustome);
        //Repush operator handle default
        this.handleOperatorActive.typeCutting = this.typeCutting;
        trackedPoints.forEach(p => this.handleOperatorActive.addTrackedPoint(p));
    }

    unregisterCustomHandleOperator(): void {
        const { operatorManager } = this.viewer;
        this.typeCutting = '';
        this.handleOperatorActive.typeCutting = '';
        this.handleOperatorActive.deleteHandles();
        operatorManager.remove(this.idHandleCustome);
        operatorManager.unregisterCustomOperator(this.idHandleCustome);
        operatorManager.push(Communicator.OperatorId.Handle);
    }

    setCallbacks(): void {
        this.viewer.setCallbacks({
            handleEventStart: this.handleEventStartFunction,
            handleEvent: this.handleEventFunction,
            handleEventEnd: this.handleEventEndFunction,
            removeCuttingSection: this.removeCuttingPlane,
            cuttingPlaneDrag: this.cuttingPlaneDrag,
        });
    }
    unSetCallbacks(): void {
        this.viewer.unsetCallbacks({
            handleEventStart: this.handleEventStartFunction,
            handleEvent: this.handleEventFunction,
            handleEventEnd: this.handleEventEndFunction,
            removeCuttingSection: this.removeCuttingPlane,
            cuttingPlaneDrag: this.cuttingPlaneDrag,
        });
    }
    setVisibility(visi: boolean): void {
        this.isVisibility = visi
    }
    setMuitiSelect(select?: boolean): boolean {
        if (select) {
            this.modeMultipleSides = select;
            return select
        }
        else {
            return this.modeMultipleSides
        }
    }
    setreferenceGeometry(x: Communicator.Point3[], y: Communicator.Point3[], z: Communicator.Point3[]): void {
        this.referenceGeometryX = x;
        this.referenceGeometryY = y;
        this.referenceGeometryZ = z
    }
    setSection(s1: Communicator.CuttingSection | null, s2: Communicator.CuttingSection | null, s3: Communicator.CuttingSection | null, s4: Communicator.CuttingSection | null, s5: Communicator.CuttingSection | null, s6: Communicator.CuttingSection | null): void {
        this.section1 = s1;
        this.section2 = s2;
        this.section3 = s3;
        this.section4 = s4;
        this.section5 = s5;
        this.section6 = s6;
    }

    private removeCuttingPlane = () => {
        this._activeCuttingPlane = null;
        Promise.all([
            this.handleOperatorActive?.removeHandles(),
        ]);
    };

    private cuttingPlaneDrag = (cuttingSection: Communicator.CuttingSection, planeIndex: number) => {
        //this.updateHandleOperatorActive();
        if (this.handleOperatorActive)
            this.resetHandle(this.handleOperatorActive)
    }
    private UpdateActiveCuttingSection() {
        if (this.handleOperatorActive.activeGroupId) {
            const activeSection = this.handleOperatorActive.mapGroupPlane_GroupNodeIdHandelId.get(this.handleOperatorActive.activeGroupId)
            if (activeSection) {
                this.sectionActive = activeSection
                const newPlane = activeSection.getPlane(0)?.copy()
                if (newPlane)
                    this._activeCuttingPlane = newPlane
            }
        }
    }

    private handleEventStartFunction = () => {
        // plane-box: call new function to cutting - Hieu Pham
        this._moveCount = 0;
        this._handleSelected = true;
        if (this.typeCutting === 'plane-box') {
            return;
        }
        // end
        this.UpdateActiveCuttingSection();
    };

    iSTranslateHandle(): boolean {
        if (this.handleOperatorActive.activeGroupId
            && this.handleOperatorActive.activeGroupId >= 1
            && this.handleOperatorActive.activeGroupId <= 6)
            return true;
        return false;
    }
    private handleEventEndFunction = async (
        eventType: Communicator.HandleEventType,
        nodeIds: Communicator.NodeId[],
        initialMatrices: Communicator.Matrix[],
        newMatrices: Communicator.Matrix[],
    ) => {
        this._viewer.cuttingManager.delayCapping();
        // plane-box: call new function to cutting - Hieu Pham
        if (this._moveCount === 0) return;
        if (this.typeCutting === 'plane-box') {
            this._handleEventPromise = this._handleEventPromise.then(() => {
                return this._onCuttingHandleEvent(eventType, nodeIds, initialMatrices, newMatrices, true).then(() => {
                    this.setPlaneListOfBox();
                    this._setTrackedCorners(this._getNewCorners());
                    !this.rotateBox && this._addCutingHandles(this._boundingBox!);
                    this._moveCount = 0;
                    this._handleSelected = false;
                });
            });
            return;
        }
        // end
        this.flatCutting1 = false;
        this.flatCutting2 = false;
        this.flatCutting3 = false;
        this.flatCutting4 = false;
        this.flatCutting5 = false;
        this.flatCutting6 = false;
        this._handleEventPromise = this._handleEventPromise.then(() => {
            this._onHandleEvent(eventType,
                nodeIds,
                initialMatrices,
                newMatrices, true).then(() => {
                    if (this.handleOperatorActive !== null) {
                        const newPlane = this.getactiveCuttingPlane(this.handleOperatorActive);
                        if (newPlane !== null) {
                            this._activeCuttingPlane = newPlane.copy();
                        } else {
                            this._activeCuttingPlane = null;
                        }
                        this._handleSelected = false;
                    }
                });
        });
    };
    private handleEventFunction = async (
        eventType: Communicator.HandleEventType,
        nodeIds: Communicator.NodeId[],
        initialMatrices: Communicator.Matrix[],
        newMatrices: Communicator.Matrix[],
    ) => {
        this._viewer.cuttingManager.delayCapping();
        // plane-box: call new function to cutting - Hieu Pham
        ++this._moveCount;
        if (this.typeCutting === 'plane-box') {
            this._handleEventPromise = this._handleEventPromise.then(() => {
                return this._onCuttingHandleEvent(eventType, nodeIds, initialMatrices, newMatrices, false);
            });
            return;
        }
        // end
        this.isMoveHandle = true
        this._handleEventPromise = this._handleEventPromise.then(() => {
            this._onHandleEvent(eventType,
                nodeIds,
                initialMatrices,
                newMatrices, true).then(() => {
                    if (this.handleOperatorActive !== null) {
                        const newPlane = this.getactiveCuttingPlane(this.handleOperatorActive);
                        if (newPlane !== null) {
                            this._activeCuttingPlane = newPlane.copy();
                        } else {
                            this._activeCuttingPlane = null;
                        }
                        this._handleSelected = false;
                    }
                });
        });
    }

    async handleEventFunctionForTranslate(
        eventType: Communicator.HandleEventType,
        nodeIds: Communicator.NodeId[],
        initialMatrices: Communicator.Matrix[],
        newMatrices: Communicator.Matrix[],
    ): Promise<void> {
        this._viewer.cuttingManager.delayCapping();
        this._handleEventPromiseTransle = this._handleEventPromiseTransle.then(() => {
            this._onHandleEvent(eventType,
                nodeIds,
                initialMatrices,
                newMatrices, true).then(() => {
                    if (this.handleOperatorActive !== null) {
                        this._handleSelected = false;
                    }
                });
        });
    };

    async clearAllSection(bUpdate: boolean): Promise<void> {
        const promiseList = [];
        const { cuttingManager } = this.viewer;
        const count = cuttingManager.getCuttingSectionCount();
        for (let i = 0; i < count; i++) {
            const section = cuttingManager.getCuttingSection(i);
            if (!section?.isActive()) continue;
            promiseList.push(new Promise((resolve, reject) => {
                section.clear().then(() => {
                    resolve(true);
                });
            }));
        }
        await Promise.all([...promiseList]).then(() => {
            if (bUpdate === true)
                this.initStatusButton();
        });
    }

    initStatusButton(isOnchange = false): void {
        this.activeButtonControlService.setViewer(this.viewer);
    }

    async updateReferenceGeometr(): Promise<void> {
        if (this.isVisibility) {
            this.referenceGeometryX = null;
            this.referenceGeometryY = null;
            this.referenceGeometryZ = null;
        }
        else {
            const box: Communicator.Box = await this.getReferenGeometrySection();
            const axisX = Communicator.Axis.X;
            this.referenceGeometryX = this.viewer.cuttingManager.createReferenceGeometryFromAxis(
                axisX,
                box,
            );
            const axisY = Communicator.Axis.Y;
            this.referenceGeometryY = this.viewer.cuttingManager.createReferenceGeometryFromAxis(
                axisY,
                box,
            );
            const axisZ = Communicator.Axis.Z;
            this.referenceGeometryZ = this.viewer.cuttingManager.createReferenceGeometryFromAxis(
                axisZ,
                box,
            );
        }
    }

    getReferenGeometrySection(): Communicator.Box {
        const box: Communicator.Box = new Communicator.Box();

        if (this.sectionActive === null)
            this.sectionActive = this.typeCutting === 'plane-box' ? this.sectionActive : this.viewer.cuttingManager.getCuttingSection(0);
        const plane: Communicator.Plane | null | undefined = this.sectionActive?.getPlane(0);
        const pt3d: Communicator.Point3[] | null | undefined = this.sectionActive?.getReferenceGeometry(0);
        if (plane && pt3d) {
            const distanceToPoint = (plane.distanceToPoint(pt3d[0]));
            box.min = this.getPointExtent(pt3d[0], distanceToPoint, plane.normal);
            box.max = this.getPointExtent(pt3d[2], distanceToPoint, plane.normal);
            box.addPoint(this.getPointExtent(pt3d[0], distanceToPoint, plane.normal));
            box.addPoint(this.getPointExtent(pt3d[2], distanceToPoint, plane.normal));
            const nodeId = this.sectionActive?.getNodeId(0);
            switch (nodeId) {
                case this.section1?.getNodeId(0):
                    // eslint-disable-next-line no-case-declarations
                    const plane1: Communicator.Plane | null | undefined = this.section4?.getPlane(0);
                    // eslint-disable-next-line no-case-declarations
                    const pt3d1: Communicator.Point3[] | null | undefined = this.section4?.getReferenceGeometry(0);
                    // eslint-disable-next-line no-case-declarations
                    if (pt3d1) {
                        const distanceToPoint1 = (plane1?.distanceToPoint(pt3d1[0]));
                        if (distanceToPoint1 && plane1) {
                            box.addPoint(this.getPointExtent(pt3d1[0], distanceToPoint1, plane1.normal));
                            box.addPoint(this.getPointExtent(pt3d1[2], distanceToPoint1, plane1.normal));
                        }
                    }
                    break;
                case this.section2?.getNodeId(0):
                    // eslint-disable-next-line no-case-declarations
                    const plane2: Communicator.Plane | null | undefined = this.section5?.getPlane(0);
                    // eslint-disable-next-line no-case-declarations
                    const pt3d2: Communicator.Point3[] | null | undefined = this.section5?.getReferenceGeometry(0);
                    if (pt3d2) {
                        const distanceToPoint2 = (plane2?.distanceToPoint(pt3d2[0]));
                        if (distanceToPoint2 && plane2) {
                            box.addPoint(this.getPointExtent(pt3d2[0], distanceToPoint2, plane2.normal));
                            box.addPoint(this.getPointExtent(pt3d2[2], distanceToPoint2, plane2.normal));
                        }
                    }
                    break;
                case this.section3?.getNodeId(0):
                    // eslint-disable-next-line no-case-declarations
                    const plane3: Communicator.Plane | null | undefined = this.section6?.getPlane(0);
                    // eslint-disable-next-line no-case-declarations
                    const pt3d3: Communicator.Point3[] | null | undefined = this.section6?.getReferenceGeometry(0);
                    if (pt3d3) {
                        const distanceToPoint3 = (plane3?.distanceToPoint(pt3d3[0]));
                        if (distanceToPoint3 && plane3) {
                            box.addPoint(this.getPointExtent(pt3d3[0], distanceToPoint3, plane3.normal));
                            box.addPoint(this.getPointExtent(pt3d3[2], distanceToPoint3, plane3.normal));
                        }
                    }
                    break;
                case this.section4?.getNodeId(0):
                    // eslint-disable-next-line no-case-declarations
                    const plane4: Communicator.Plane | null | undefined = this.section1?.getPlane(0);
                    // eslint-disable-next-line no-case-declarations
                    const pt3d4: Communicator.Point3[] | null | undefined = this.section1?.getReferenceGeometry(0);
                    if (pt3d4) {
                        const distanceToPoint4 = (plane4?.distanceToPoint(pt3d4[0]));
                        if (distanceToPoint4 && plane4) {
                            box.addPoint(this.getPointExtent(pt3d4[0], distanceToPoint4, plane4.normal));
                            box.addPoint(this.getPointExtent(pt3d4[2], distanceToPoint4, plane4.normal));
                        }
                    }
                    break;
                case this.section5?.getNodeId(0):
                    // eslint-disable-next-line no-case-declarations
                    const plane5: Communicator.Plane | null | undefined = this.section2?.getPlane(0);
                    // eslint-disable-next-line no-case-declarations
                    const pt3d5: Communicator.Point3[] | null | undefined = this.section2?.getReferenceGeometry(0);
                    if (pt3d5) {
                        const distanceToPoint5 = (plane5?.distanceToPoint(pt3d5[0]));
                        if (distanceToPoint5 && plane5) {
                            box.addPoint(this.getPointExtent(pt3d5[0], distanceToPoint5, plane5.normal));
                            box.addPoint(this.getPointExtent(pt3d5[2], distanceToPoint5, plane5.normal));
                        }
                    }
                    break;
                case this.section6?.getNodeId(0):
                    // eslint-disable-next-line no-case-declarations
                    const plane6: Communicator.Plane | null | undefined = this.section3?.getPlane(0);
                    // eslint-disable-next-line no-case-declarations
                    const pt3d6: Communicator.Point3[] | null | undefined = this.section3?.getReferenceGeometry(0);
                    if (pt3d6) {
                        const distanceToPoint6 = (plane6?.distanceToPoint(pt3d6[0]));
                        if (distanceToPoint6 && plane6) {
                            box.addPoint(this.getPointExtent(pt3d6[0], distanceToPoint6, plane6.normal));
                            box.addPoint(this.getPointExtent(pt3d6[2], distanceToPoint6, plane6.normal));
                        }
                    }
                    break;
            }
        }
        return box;
        // return _activeCuttingPlane;
    }
    onDoubleClick(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            resolve();
        });
    }

    setTypeCutting(type: CuttingType): CuttingType {
        this.typeCutting = type;
        this.handleOperatorActive.typeCutting = type
        return type
    }

    private _getHandleGroupIds(): number[] {
        const groupIds = [];
        for (const groupId in GroupHandelId) {
            if (!isNaN(Number(groupId))) groupIds.push(Number(groupId));
        }
        return groupIds;
    }

    private _getHandleIds(): number[] {
        const groupIds = this._getHandleGroupIds();
        const handleIds: number[] = [];
        groupIds.forEach(groupId => {
            const id = this.handleOperatorActive.mapGroupHandelId_NodeId.get(groupId);
            if (id !== undefined) handleIds.push(id);
        });
        return handleIds;
    }

    private _visibilityHandles(visibility: boolean) {
        const { model } = this._viewer;
        const handleIds: number[] = this._getHandleIds();
        if (!visibility) model.setNodesOpacity(handleIds, 0);
        else model.resetNodesOpacity(handleIds);
    }

    private async _hideHandlesBehindGeometry() {
        const { model, cuttingManager } = this._viewer;
        let displayHandleIds: number[] = [];
        let hideHandlesIds: number[] = [];
        const numOfSections = cuttingManager.getCuttingSectionCount();
        for (let i = 0; i < numOfSections; i++) {
            if (this.faceIsBehind(i, this._boxCorners, this._viewer)) 
                hideHandlesIds = Lodash.union(hideHandlesIds, this._getHandleIdsFromIndexSection(i));
            else displayHandleIds = Lodash.union(displayHandleIds, this._getHandleIdsFromIndexSection(i));
        }
        hideHandlesIds.length > 0 && model.setNodesOpacity(hideHandlesIds, 0);
        displayHandleIds.length > 0 && model.resetNodesOpacity(displayHandleIds);
    }

    private _getHandleIdsFromIndexSection(indexSection: number): number[] {
        let groupIds: number[] = [];
        switch (indexSection) {
            case 0:
                groupIds = [
                    GroupHandelId.Translate_X,
                    GroupHandelId.Conner_mZXmY, // 1
                    GroupHandelId.Conner_mZXY, // 3
                    GroupHandelId.Conner_ZXY, // 7
                    GroupHandelId.Conner_ZXmY, // 5
                    GroupHandelId.Middle_ZX,
                    GroupHandelId.Middle_mZX
                ];
                break;
            case 1:
                groupIds = [
                    GroupHandelId.Translate_Y,
                    GroupHandelId.Conner_mZmXY, // 2
                    GroupHandelId.Conner_ZmXY, // 6
                    GroupHandelId.Conner_ZXY, // 7
                    GroupHandelId.Conner_mZXY, // 3
                    GroupHandelId.Middle_ZY,
                    GroupHandelId.Middle_mZY
                ];
                break;
            case 2:
                groupIds = [
                    GroupHandelId.Translate_Z,
                    GroupHandelId.Conner_ZmXmY, // 4
                    GroupHandelId.Conner_ZmXY, // 6
                    GroupHandelId.Conner_ZXY, // 7
                    GroupHandelId.Conner_ZXmY, // 5
                    GroupHandelId.Middle_ZX,
                    GroupHandelId.Middle_ZY,
                    GroupHandelId.Middle_ZmX,
                    GroupHandelId.Middle_ZmY
                ];
                break;
            case 3:
                groupIds = [
                    GroupHandelId.Translate_mX,
                    GroupHandelId.Conner_mZmXmY, // 0
                    GroupHandelId.Conner_mZmXY, // 2
                    GroupHandelId.Conner_ZmXY, // 6
                    GroupHandelId.Conner_ZmXmY, // 4
                    GroupHandelId.Middle_ZmX,
                    GroupHandelId.Middle_mZmX
                ];
                break;
            case 4:
                groupIds = [
                    GroupHandelId.Translate_mY,
                    GroupHandelId.Conner_mZmXmY, // 0
                    GroupHandelId.Conner_ZmXmY, // 4
                    GroupHandelId.Conner_ZXmY, // 5
                    GroupHandelId.Conner_mZXmY, // 1
                    GroupHandelId.Middle_ZmY,
                    GroupHandelId.Middle_mZmY
                ];
                break;
            case 5:
                groupIds = [
                    GroupHandelId.Translate_mZ,
                    GroupHandelId.Conner_mZmXmY, // 0
                    GroupHandelId.Conner_mZmXY, // 2
                    GroupHandelId.Conner_mZXY, // 3
                    GroupHandelId.Conner_mZXmY, // 1
                    GroupHandelId.Middle_mZX,
                    GroupHandelId.Middle_mZY,
                    GroupHandelId.Middle_mZmX,
                    GroupHandelId.Middle_mZmY
                ];
                break;
            default:
                break;
        }
        return this.handleOperatorActive.getHandleNodeIds(groupIds);
    }

    async onMouseMove(event: Communicator.Event.MouseInputEvent): Promise<void> {
        if (this.typeCutting === 'plane-box') {
            if (!this._handleSelected) {
                const { view } = this._viewer;
                const config = new Communicator.PickConfig(Communicator.SelectionMask.Face | Communicator.SelectionMask.Line);
                const selections = await view.pickAllFromPoint(event.getPosition(), config);
                const visibility = selections.length > 0 ? true : false;
                this._visibilityHandles(visibility);
                visibility && this._hideHandlesBehindGeometry();
            }
            return super.onMouseMove(event);
        }
        //
        this.addHandle(event);
        if (this.activeRedlineItem) event.setHandled(true);
        super.onMouseMove(event);
        this.markupOperatorMove();
    }

    private markupOperatorMove() {
        if (!this.activeRedlineItem) return;
        if (this._dragging) {
            this.activeRedlineItem.onDragMove(this._ptCurrent);
            this._viewer.markupManager.refreshMarkup();
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getactiveCuttingPlane(handleOperator: Communicator.Operator.HandleOperator) {
        let _activeCuttingPlane: Communicator.Plane | null = null;
        const nodeIds = handleOperator.getNodeIds()[0];

        if (nodeIds) {
            const section = this.cuttingManager?.getCuttingSectionFromNodeId(nodeIds);
            if (section) {
                const index = section.getPlaneIndexByNodeId(nodeIds);
                if (index !== null) {
                    _activeCuttingPlane = section.getPlane(index);
                }
            }
        }
        return _activeCuttingPlane;
    }
    private async _onHandleEvent(
        eventType: Communicator.HandleEventType,
        _nodeIds: Communicator.NodeId[],
        initialMatrices: Communicator.Matrix[],
        newMatrices: Communicator.Matrix[],
        handleEventEnd: boolean,
    )
        : Promise<void> {
        //this.updateHandleOperatorActive();
        const handlePosition = this.handleOperatorActive?.getPosition();

        if (this._activeCuttingPlane === null || handlePosition === null) {
            return Promise.resolve();
        }
        if (eventType === Communicator.HandleEventType.Translate && newMatrices.length > 0 && handlePosition) {
            this._onHandleTranslate(this._activeCuttingPlane.copy(),
                handlePosition.copy(),
                initialMatrices[0].copy(),
                newMatrices[0].copy(),
                handleEventEnd)
        }

    }

    updateSectionActive(): void {
        //this.updateHandleOperatorActive();
        const nodeIds = this.handleOperatorActive?.getNodeIds()[0];
        if (nodeIds && this.cuttingManager?.getCuttingSectionFromNodeId(nodeIds) && this.typeCutting !== 'plane-box') {
            this.sectionActive = this.cuttingManager?.getCuttingSectionFromNodeId(nodeIds);
        }
    }
    private async _onHandleTranslate(
        plane: Communicator.Plane,
        handlePosition: Communicator.Point3,
        initialMatrix: Communicator.Matrix,
        newMatrix: Communicator.Matrix,
        handleEventEnd: boolean,
    )
        : Promise<void> {
        const newPlaneMatrix = newMatrix.copy();
        const newPos = new Communicator.Point3(
            newMatrix.m[12],
            newMatrix.m[13],
            newMatrix.m[14],
        );
        initialMatrix.setTranslationComponent(0, 0, 0);
        // eslint-disable-next-line no-param-reassign
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        initialMatrix = initialMatrix.inverseAndDeterminant()[0]!;
        newMatrix.setTranslationComponent(0, 0, 0);
        // const newPlane = new Communicator.Plane().setFromPointAndNormal(handlePosition, axisNormal);
        this.updateSectionActive();
        const cuttingSection = this.sectionActive;
        if (cuttingSection === null) {
            return;
        }
        //this.updateHandleOperatorActive();
        let indexPlan = 0;
        if (this.handleOperatorActive) {
            const ids = this.handleOperatorActive.getNodeIds()[0]
            const indexPlanTmp = cuttingSection.getPlaneIndexByNodeId(ids);
            indexPlan = indexPlanTmp ? indexPlanTmp : 0;
        }
        const normal = cuttingSection?.getPlane(0)?.normal?.copy()
        if (normal)
            cuttingSection.updatePlane(indexPlan, Communicator.Plane.createFromPointAndNormal(newPos, normal), newPlaneMatrix, handleEventEnd, true);
    }

    public async setDataCubeMesh(position: Communicator.Point3, normal: Communicator.Point3, groupId: number): Promise<void> {
        const axisMeshData = Utils.createCubeMeshData();
        await this.handleOperatorActive.setPlaneMeshData(axisMeshData);
        const nodeMeshId = await this.handleOperatorActive.addPlaneTranslationHandle(position, normal, Communicator.Color.blue(), Communicator.Color.blue(), null, groupId)
        this.viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotLight, [nodeMeshId], true)
        //this.handleOperatorActive!.updatePosition(position, new Communicator.Matrix(), true, groupId);
    }

    async addHandle(event: Communicator.Event.MouseInputEvent): Promise<void> {
        const pickConfig = new Communicator.PickConfig(Communicator.SelectionMask.Face);
        // this.handleOperatorActive = this.viewer.operatorManager.getOperator(this.OperatorId.Handle);
        try {
            const selectionItem = await this.viewer.view.pickFromPoint(event.getPosition(), pickConfig)
            const nodeId = selectionItem.getNodeId();
            if (nodeId != null && nodeId < 0 && this.typeCutting !== 'plane-box') {
                if (this.currentId !== nodeId) {
                    this.currentId = nodeId;
                    const cuttingSection = this.viewer.cuttingManager.getCuttingSectionFromNodeId(this.currentId);
                    if (cuttingSection) {
                        const index = cuttingSection.getPlaneIndexByNodeId(this.currentId)
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        if (index !== null) {

                            this._activeCuttingPlane = cuttingSection.getPlane(index);
                            if (this.handleOperatorActive && this.handleOperatorActive.getNodeIds().length <= 0) {

                                const pt3d: Communicator.Point3[] | null = cuttingSection.getReferenceGeometry(index);
                                if (pt3d !== null) {
                                    const newPt: Communicator.Point3 = this.getMidPoint(pt3d[0], pt3d?.[2]);
                                    const distanceToPoint = this._activeCuttingPlane?.distanceToPoint(newPt);
                                    if (distanceToPoint !== undefined && this._activeCuttingPlane) {
                                        const pointExtent = this.getPointExtent(newPt, distanceToPoint, this._activeCuttingPlane?.normal);
                                        this._addHandles(this.handleOperatorActive, pointExtent,
                                            this._activeCuttingPlane.normal, this.currentId);
                                        this.updateHandlePosition(cuttingSection, true);
                                    }
                                    //this.addMeshHanle(pt3d, cuttingSection)
                                }
                            }
                        }
                    }
                } else {
                    this.currentId = undefined;
                }
            } else {
                this.currentId = undefined;
                if (event.getButtons() !== 1 && this.bRemoteHandle === false) {
                    // this.handleOperatorActive.removeHandles();
                }
            }
        }
        catch (e) {
            //
        }
    }
    getMidPoint(pt1: Communicator.Point3, pt2: Communicator.Point3): Communicator.Point3 {
        const point: Communicator.Point3 = new Communicator.Point3((pt1.x + pt2.x) / 2, (pt1.y + pt2.y) / 2, (pt1.z + pt2.z) / 2);
        return point;
    }
    getPointExtent(pt1: Communicator.Point3, distanceToPoint: number, vNormal: Communicator.Point3): Communicator.Point3 {
        if (this._activeCuttingPlane === null) {
            if (this.sectionActive) {
                this._activeCuttingPlane = this.sectionActive.getPlane(0);
            }
        }
        let x = pt1.x;
        let y = pt1.y;
        let z = pt1.z;
        const distanceToPt = Math.abs(distanceToPoint);
        if (vNormal.x > 0 && distanceToPoint < 0) {
            x = pt1.x + vNormal.x * distanceToPt;
        }
        else if (vNormal.x > 0 && distanceToPoint > 0) {
            x = pt1.x - vNormal.x * distanceToPt;
        }
        else if (vNormal.x < 0 && distanceToPoint < 0) {
            x = pt1.x + vNormal.x * distanceToPt;
        }
        else if (vNormal.x < 0 && distanceToPoint > 0) {
            x = pt1.x - vNormal.x * distanceToPt;
        }

        if (vNormal.y > 0 && distanceToPoint < 0) {
            y = pt1.y + vNormal.y * distanceToPt;
        }
        else if (vNormal.y > 0 && distanceToPoint > 0) {
            y = pt1.y - vNormal.y * distanceToPt;
        }
        else if (vNormal.y < 0 && distanceToPoint < 0) {
            y = pt1.y + vNormal.y * distanceToPt;
        }
        else if (vNormal.y < 0 && distanceToPoint > 0) {
            y = pt1.y - vNormal.y * distanceToPt;
        }

        if (vNormal.z > 0 && distanceToPoint < 0) {
            z = pt1.z + vNormal.z * distanceToPt;
        }
        else if (vNormal.z > 0 && distanceToPoint > 0) {
            z = pt1.z - vNormal.z * distanceToPt;
        }
        else if (vNormal.z < 0 && distanceToPoint < 0) {
            z = pt1.z + vNormal.z * distanceToPt;
        }
        else if (vNormal.z < 0 && distanceToPoint > 0) {
            z = pt1.z - vNormal.z * distanceToPt;
        }

        const point: Communicator.Point3 = new Communicator.Point3(x, y, z);

        return point;
    }

    public async addAxisMiddle(position: Communicator.Point3, normal: Communicator.Point3, groupId: number): Promise<void> {
        const axisMeshData = Utils.createCubeMeshData();
        const id = await this.handleOperatorActive.addAxisTranslationHandle(position, normal, Communicator.Color.blue(), null, groupId);
        const mesId = await this.viewer.model.getMeshIds([id])
        this.viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotLight, [id], true)
        await this.viewer.model.replaceMesh(mesId[0], axisMeshData)
        this.meshDataAxis.push(mesId)
    }

    public async addAxisHandle2(pt3d: Communicator.Point3[], cuttingSection: Communicator.CuttingSection,
        cuttingPlan: Communicator.Plane, groupId: number): Promise<NodeId | undefined> {
        if (this.handleOperatorActive && this.handleOperatorActive.getNodeIds().length <= 0) {
            if (pt3d !== null) {
                const newPt: Communicator.Point3 = this.getMidPoint(pt3d[0], pt3d?.[2]);
                const distanceToPoint = cuttingPlan?.distanceToPoint(newPt);
                if (distanceToPoint !== undefined && cuttingPlan) {
                    const pointExtent = this.getPointExtent(newPt, distanceToPoint, cuttingPlan.normal);
                    const a = await this._addHandles2(this.handleOperatorActive, pointExtent, cuttingPlan.normal, groupId);
                    // const mesId = await this.viewer.model.getMeshIds([a])
                    return a
                }
            }
        }
        return undefined;
    }

    private async _addHandles2(_handleOperator: Communicator.Operator.HandleOperator,
        position: Communicator.Point3, normal: Communicator.Point3, groupId: number): Promise<NodeId> {
        const color = this.Color.red();
        return _handleOperator.addAxisTranslationHandle(position, normal, color, null, groupId);
    }

    private async _addHandles(_handleOperator: Communicator.Operator.HandleOperator,
        position: Communicator.Point3, normal: Communicator.Point3, nodeId: NodeId): Promise<void> {
        this.resetHandle(_handleOperator);
        const color = this.Color.red();
        this.idMiddlePlane = await _handleOperator.addAxisTranslationHandle(position, normal, color);
        _handleOperator.setNodeIds([nodeId]);
        _handleOperator.showHandles();

    }
    resetHandle(_handleOperator: Communicator.Operator.HandleOperator): void {
        _handleOperator.removeHandles();
    }
    private updateHandlePosition = (cuttingSection: Communicator.CuttingSection, cuttingEventEnd: boolean) => {
        const newPlane = cuttingSection.getPlane(0);
        if (newPlane === null) {
            return;
        }
        this._updateHandlePosition(this._activeCuttingPlane!.copy(), newPlane.copy(), cuttingEventEnd);

        if (cuttingEventEnd) {
            this._activeCuttingPlane = newPlane.copy();
        }
    };

    private async _updateHandlePosition(previousPlane: Communicator.Plane,
        newPlane: Communicator.Plane,
        resetInitialPosition: boolean)
        : Promise<void> {
        const newDistance = previousPlane.d - newPlane.d;
        const c = newPlane.getCoefficients();
        const newTranslation = new Communicator.Point3(c[0], c[1], c[2]).scale(newDistance);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.handleOperatorActive!.updatePosition(newTranslation, new Communicator.Matrix(), resetInitialPosition);
    }

    // The methods for cutting box - Hieu Pham
    public _boundingBox: Communicator.Box | null = null;
    private _moveCount = 0;

    private _getActivePlane(nodeId: number): Communicator.Plane | null {
        const plane = this.mapPlaneList.get(nodeId);
        return plane ? plane : null;
    }

    // eslint-disable-next-line
    public _getReferenceGeometry(box: Communicator.Box) {
        const cuttingManager = this._viewer.cuttingManager;
        const xRefGeo = cuttingManager.createReferenceGeometryFromAxis(Communicator.Axis.X, box);
        const yRefGeo = cuttingManager.createReferenceGeometryFromAxis(Communicator.Axis.Y, box);
        const zRefGeo = cuttingManager.createReferenceGeometryFromAxis(Communicator.Axis.Z, box);
        return { X: xRefGeo, Y: yRefGeo, Z: zRefGeo };
    }

    private _getMiddlePoint3(a: Communicator.Point3, b: Communicator.Point3): Communicator.Point3 {
        return new Communicator.Point3((a.x + b.x) / 2, (a.y + b.y) / 2, (a.z + b.z) / 2);
    }

    private _getHandlePositions(box: Communicator.Box, corners?: Communicator.Point3[]) {
        const boxCorners = corners?.length === 8 ? Lodash.clone(corners) : box.getCorners();
        // middle points of the planes
        const invXMidPlane = this._getMiddlePoint3(boxCorners[0], boxCorners[6]);
        const xMidPlane = this._getMiddlePoint3(boxCorners[1], boxCorners[7]);
        const invYMidPlane = this._getMiddlePoint3(boxCorners[0], boxCorners[5]);
        const yMidPlane = this._getMiddlePoint3(boxCorners[2], boxCorners[7]);
        const invZMidPlane = this._getMiddlePoint3(boxCorners[0], boxCorners[3]);
        const zMidPlane = this._getMiddlePoint3(boxCorners[4], boxCorners[7]);
        // points of the box corners
        const invCornerXYZ = boxCorners[0];
        const invCornerX = boxCorners[1];
        const invCornerY = boxCorners[2];
        const invCornerZ = boxCorners[3];
        const cornerXYZ = boxCorners[4];
        const cornerX = boxCorners[5];
        const cornerY = boxCorners[6];
        const cornerZ = boxCorners[7];
        // middle points of the edges
        const invZEdgeMidX = this._getMiddlePoint3(boxCorners[0], boxCorners[1]);
        const invZEdgeMidY = this._getMiddlePoint3(boxCorners[0], boxCorners[2]);
        const invZEdgeMidXnZ = this._getMiddlePoint3(boxCorners[1], boxCorners[3]);
        const invZEdgeMidYnZ = this._getMiddlePoint3(boxCorners[2], boxCorners[3]);
        //
        const zEdgeMidX = this._getMiddlePoint3(boxCorners[4], boxCorners[5]);
        const zEdgeMidY = this._getMiddlePoint3(boxCorners[4], boxCorners[6]);
        const zEdgeMidXnZ = this._getMiddlePoint3(boxCorners[5], boxCorners[7]);
        const zEdgeMidYnZ = this._getMiddlePoint3(boxCorners[6], boxCorners[7]);
        //
        return {
            invCornerXYZ,
            invCornerX,
            invCornerY,
            invCornerZ,
            cornerXYZ,
            cornerX,
            cornerY,
            cornerZ,
            invXMidPlane,
            xMidPlane,
            invYMidPlane,
            yMidPlane,
            invZMidPlane,
            zMidPlane,
            invZEdgeMidX,
            invZEdgeMidY,
            invZEdgeMidXnZ,
            invZEdgeMidYnZ,
            zEdgeMidX,
            zEdgeMidY,
            zEdgeMidXnZ,
            zEdgeMidYnZ
        }
    }

    private referenceGeometryIsNull(indexSection: number): boolean {
        let referenceGeometry = false;
        switch (indexSection) {
            case 0:
                if (this.listHandlePlaneRemove.includes('plane-x-plus')) referenceGeometry = true;
                break;
            case 1:
                if (this.listHandlePlaneRemove.includes('plane-y-plus')) referenceGeometry = true;
                break;
            case 2:
                if (this.listHandlePlaneRemove.includes('plane-z-plus')) referenceGeometry = true;
                break;
            case 3:
                if (this.listHandlePlaneRemove.includes('plane-x-minus')) referenceGeometry = true;
                break;
            case 4:
                if (this.listHandlePlaneRemove.includes('plane-y-minus')) referenceGeometry = true;
                break;
            case 5:
                if (this.listHandlePlaneRemove.includes('plane-z-minus')) referenceGeometry = true;
                break;
            default:
                break;
        }
        return referenceGeometry;
    }

    /** 4 coordinates that define the reference geometry should be specified in a clockwise or counterclockwise fashion */
    private async _setPlaneWithSpecifiedPoints(
        indexSection: number,
        p1: Communicator.Point3,
        p2: Communicator.Point3,
        p3: Communicator.Point3,
        p4: Communicator.Point3
    ): Promise<void> {
        const cuttingSection = this._viewer.cuttingManager.getCuttingSection(indexSection);
        if (cuttingSection === null) return;

        const newPlane = Communicator.Plane.createFromPoints(p1, p2, p3);
        const normal = newPlane.normal.copy();
        if ([2, 3, 4].includes(indexSection)) {
            normal.set(-1 * normal.x, -1 * normal.y, -1 * normal.z);
            newPlane.setFromPointAndNormal(p1, normal);
        }

        const referenceGeometry: Communicator.Point3[] | null = this.referenceGeometryIsNull(indexSection) ? null :
            [p1, p2, p3, p4].map(p => p.copy().add(normal.copy().scale(newPlane.d)));

        await cuttingSection.setPlane(0, newPlane, referenceGeometry);
    }

    public async setReferenceGeometryForSection(
        indexSection: number 
    ): Promise<void> {
        const corners = this._getNewCorners();
        // let specifiedPoints = [];
        switch (indexSection) {
            case 0:
                await this._setPlaneWithSpecifiedPoints(indexSection, corners[1], corners[3], corners[7], corners[5]);
                break;
            case 1: 
                await this._setPlaneWithSpecifiedPoints(indexSection, corners[2], corners[6], corners[7], corners[3]);
                break;
            case 2:
                await this._setPlaneWithSpecifiedPoints(indexSection, corners[4], corners[6], corners[7], corners[5]);
                break;
            case 3:
                await this._setPlaneWithSpecifiedPoints(indexSection, corners[0], corners[2], corners[6], corners[4]);
                break;
            case 4:
                await this._setPlaneWithSpecifiedPoints(indexSection, corners[0], corners[4], corners[5], corners[1]);
                break;
            case 5:
                await this._setPlaneWithSpecifiedPoints(indexSection, corners[0], corners[2], corners[3], corners[1]);
                break;
            default:
                break;
        }
    }

    /** 4 coordinates that define the reference geometry should be specified in a clockwise or counterclockwise fashion */
    private async _addPlaneWithSpecifiedPoints(
        indexSection: number,
        p1: Communicator.Point3,
        p2: Communicator.Point3,
        p3: Communicator.Point3,
        p4: Communicator.Point3
    ): Promise<void> {
        const cuttingSection = this._viewer.cuttingManager.getCuttingSection(indexSection);
        if (cuttingSection === null) return;

        await cuttingSection.clear();

        const newPlane = Communicator.Plane.createFromPoints(p1, p2, p3);
        const normal = newPlane.normal.copy();
        if ([2, 3, 4].includes(indexSection)) {
            normal.set(-1 * normal.x, -1 * normal.y, -1 * normal.z);
            newPlane.setFromPointAndNormal(p1, normal);
        }

        const referenceGeometry: Communicator.Point3[] | null = this.referenceGeometryIsNull(indexSection) ? null :
            [p1, p2, p3, p4].map(p => p.copy().add(normal.copy().scale(newPlane.d)));

        await cuttingSection.addPlane(newPlane, referenceGeometry);
        await cuttingSection.activate();
    }

    private async _updatePlanes() {
        const corners = this._getNewCorners();
        await Promise.all([
            this._setPlaneWithSpecifiedPoints(0, corners[1], corners[3], corners[7], corners[5]),
            this._setPlaneWithSpecifiedPoints(1, corners[2], corners[6], corners[7], corners[3]),
            this._setPlaneWithSpecifiedPoints(2, corners[4], corners[6], corners[7], corners[5]),
            this._setPlaneWithSpecifiedPoints(3, corners[0], corners[2], corners[6], corners[4]),
            this._setPlaneWithSpecifiedPoints(4, corners[0], corners[4], corners[5], corners[1]),
            this._setPlaneWithSpecifiedPoints(5, corners[0], corners[2], corners[3], corners[1])
        ]);
        this.setPlaneListOfBox();
    }

    private _updateHandles(box: Communicator.Box) {
        const positions = this._getHandlePositions(box, this._getNewCorners());
        const groupIds = this._getHandleGroupIds();
        for (let i = 0; i < groupIds.length; i++) {
            const nodeId = this.handleOperatorActive.mapGroupHandelId_NodeId.get(groupIds[i]);
            if (!nodeId) continue;
            const initialMatix = this._viewer.model.getNodeMatrix(nodeId);
            const newMatrix = initialMatix.copy();
            switch (groupIds[i]) {
                case GroupHandelId.Conner_mZmXmY: { // corner 0
                    newMatrix.setTranslationComponent(positions.invCornerXYZ.x, positions.invCornerXYZ.y, positions.invCornerXYZ.z);
                    this.mapHandleIdPoint.set(nodeId, positions.invCornerXYZ);
                    break;
                }
                case GroupHandelId.Conner_mZXmY: { // corner 1
                    newMatrix.setTranslationComponent(positions.invCornerX.x, positions.invCornerX.y, positions.invCornerX.z);
                    this.mapHandleIdPoint.set(nodeId, positions.invCornerX);
                    break;
                }
                case GroupHandelId.Conner_mZmXY: { // corner 2
                    newMatrix.setTranslationComponent(positions.invCornerY.x, positions.invCornerY.y, positions.invCornerY.z);
                    this.mapHandleIdPoint.set(nodeId, positions.invCornerY);
                    break;
                }
                case GroupHandelId.Conner_mZXY: { // corner 3
                    newMatrix.setTranslationComponent(positions.invCornerZ.x, positions.invCornerZ.y, positions.invCornerZ.z);
                    this.mapHandleIdPoint.set(nodeId, positions.invCornerZ);
                    break;
                }
                case GroupHandelId.Conner_ZmXmY: { // corner 4
                    newMatrix.setTranslationComponent(positions.cornerXYZ.x, positions.cornerXYZ.y, positions.cornerXYZ.z)
                    this.mapHandleIdPoint.set(nodeId, positions.cornerXYZ);
                    break;
                }
                case GroupHandelId.Conner_ZXmY: { // corner 5
                    newMatrix.setTranslationComponent(positions.cornerX.x, positions.cornerX.y, positions.cornerX.z);
                    this.mapHandleIdPoint.set(nodeId, positions.cornerX);
                    break;
                }
                case GroupHandelId.Conner_ZmXY: { // corner 6
                    newMatrix.setTranslationComponent(positions.cornerY.x, positions.cornerY.y, positions.cornerY.z);
                    this.mapHandleIdPoint.set(nodeId, positions.cornerY);
                    break;
                }
                case GroupHandelId.Conner_ZXY: { // corner 7
                    newMatrix.setTranslationComponent(positions.cornerZ.x, positions.cornerZ.y, positions.cornerZ.z);
                    this.mapHandleIdPoint.set(nodeId, positions.cornerZ);
                    break;
                }
                case GroupHandelId.Translate_X: {
                    newMatrix.setTranslationComponent(positions.xMidPlane.x, positions.xMidPlane.y, positions.xMidPlane.z);
                    this.mapHandleIdPoint.set(nodeId, positions.xMidPlane);
                    break;
                }
                case GroupHandelId.Translate_Y: {
                    newMatrix.setTranslationComponent(positions.yMidPlane.x, positions.yMidPlane.y, positions.yMidPlane.z);
                    this.mapHandleIdPoint.set(nodeId, positions.yMidPlane);
                    break;
                }
                case GroupHandelId.Translate_Z: {
                    newMatrix.setTranslationComponent(positions.zMidPlane.x, positions.zMidPlane.y, positions.zMidPlane.z);
                    this.mapHandleIdPoint.set(nodeId, positions.zMidPlane);
                    break;
                }
                case GroupHandelId.Translate_mX: {
                    newMatrix.setTranslationComponent(positions.invXMidPlane.x, positions.invXMidPlane.y, positions.invXMidPlane.z);
                    this.mapHandleIdPoint.set(nodeId, positions.invXMidPlane);
                    break;
                }
                case GroupHandelId.Translate_mY: {
                    newMatrix.setTranslationComponent(positions.invYMidPlane.x, positions.invYMidPlane.y, positions.invYMidPlane.z);
                    this.mapHandleIdPoint.set(nodeId, positions.invYMidPlane);
                    break;
                }
                case GroupHandelId.Translate_mZ: {
                    newMatrix.setTranslationComponent(positions.invZMidPlane.x, positions.invZMidPlane.y, positions.invZMidPlane.z);
                    this.mapHandleIdPoint.set(nodeId, positions.invZMidPlane);
                    break;
                }
                case GroupHandelId.Middle_mZmY: {
                    newMatrix.setTranslationComponent(positions.invZEdgeMidX.x, positions.invZEdgeMidX.y, positions.invZEdgeMidX.z);
                    this.mapHandleIdPoint.set(nodeId, positions.invZEdgeMidX);
                    break;
                }
                case GroupHandelId.Middle_mZmX: {
                    newMatrix.setTranslationComponent(positions.invZEdgeMidY.x, positions.invZEdgeMidY.y, positions.invZEdgeMidY.z);
                    this.mapHandleIdPoint.set(nodeId, positions.invZEdgeMidY);
                    break;
                }
                case GroupHandelId.Middle_mZX: {
                    newMatrix.setTranslationComponent(positions.invZEdgeMidXnZ.x, positions.invZEdgeMidXnZ.y, positions.invZEdgeMidXnZ.z);
                    this.mapHandleIdPoint.set(nodeId, positions.invZEdgeMidXnZ);
                    break;
                }
                case GroupHandelId.Middle_mZY: {
                    newMatrix.setTranslationComponent(positions.invZEdgeMidYnZ.x, positions.invZEdgeMidYnZ.y, positions.invZEdgeMidYnZ.z);
                    this.mapHandleIdPoint.set(nodeId, positions.invZEdgeMidYnZ);
                    break;
                }

                case GroupHandelId.Middle_ZmY: {
                    newMatrix.setTranslationComponent(positions.zEdgeMidX.x, positions.zEdgeMidX.y, positions.zEdgeMidX.z);
                    this.mapHandleIdPoint.set(nodeId, positions.zEdgeMidX);
                    break;
                }
                case GroupHandelId.Middle_ZmX: {
                    newMatrix.setTranslationComponent(positions.zEdgeMidY.x, positions.zEdgeMidY.y, positions.zEdgeMidY.z);
                    this.mapHandleIdPoint.set(nodeId, positions.zEdgeMidY);
                    break;
                }
                case GroupHandelId.Middle_ZX: {
                    newMatrix.setTranslationComponent(positions.zEdgeMidXnZ.x, positions.zEdgeMidXnZ.y, positions.zEdgeMidXnZ.z);
                    this.mapHandleIdPoint.set(nodeId, positions.zEdgeMidXnZ);
                    break;
                }
                case GroupHandelId.Middle_ZY: {
                    newMatrix.setTranslationComponent(positions.zEdgeMidYnZ.x, positions.zEdgeMidYnZ.y, positions.zEdgeMidYnZ.z);
                    this.mapHandleIdPoint.set(nodeId, positions.zEdgeMidYnZ);
                    break;
                }
                default:
                    break;
            }
            this._viewer.model.setNodeMatrix(nodeId, newMatrix, true);
        }
    }

    private async _addAxisHandles(position: Communicator.Point3, plane: Communicator.Plane, section: Communicator.CuttingSection, groupId: GroupHandelId, axisCube?: boolean): Promise<number | undefined> {
        const nodeId = section.getNodeId(0);
        if (nodeId !== null) {
            const { blue, red } = Communicator.Color;
            if (axisCube) {
                const axisId = await this.handleOperatorActive.addAxisTranslationHandle(position, plane.normal, blue(), null, groupId);
                const meshIds = await this._viewer.model.getMeshIds([axisId]);
                await this._viewer.model.replaceMesh(meshIds[0], Utils.createCubeMeshData());
                this._viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotLight, [axisId], true);
                this.handleOperatorActive.setNodeIds([nodeId], groupId);
                this.mapHandleIdPoint.set(axisId, position);
                return axisId;
            }
            const axisId = await this.handleOperatorActive.addAxisTranslationHandle(position, plane.normal, red(), null, groupId);
            this.handleOperatorActive.setNodeIds([nodeId], groupId);
            this.mapHandleIdPoint.set(axisId, position);
            return axisId;
        }
    }

    private async _addCubeHandles(position: Communicator.Point3, plane: Communicator.Plane, section: Communicator.CuttingSection, groupId: GroupHandelId, positionNormal?: Communicator.Point3): Promise<number | undefined> {
        const nodeId = section.getNodeId(0);
        if (nodeId !== null) {
            const { blue } = Communicator.Color;
            const axisId = await this.handleOperatorActive.addPlaneTranslationHandle(position, plane.normal, blue(), blue(), positionNormal, groupId);
            this._viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotLight, [axisId], true);
            const meshIds = await this._viewer.model.getMeshIds([axisId]);
            await this._viewer.model.replaceMesh(meshIds[0], Utils.createCubeMeshData());
            this.handleOperatorActive.setNodeIds([nodeId], groupId);
            this.mapHandleIdPoint.set(axisId, position);
            return axisId;
        }
    }

    public async _addCuttingBox(box: Communicator.Box | null): Promise<void> {
        if (this._activeCuttingPlane !== null) return;

        if (!box) {
            box = await this._viewer.model.getModelBounding(true, true);
        }

        this._boundingBox = box.copy();
        let corners = this._getNewCorners();
        if (corners.length !== 8) corners = box.getCorners();

        await Promise.all([
            this._addPlaneWithSpecifiedPoints(0, corners[1], corners[3], corners[7], corners[5]),
            this._addPlaneWithSpecifiedPoints(1, corners[2], corners[6], corners[7], corners[3]),
            this._addPlaneWithSpecifiedPoints(2, corners[4], corners[6], corners[7], corners[5]),
            this._addPlaneWithSpecifiedPoints(3, corners[0], corners[2], corners[6], corners[4]),
            this._addPlaneWithSpecifiedPoints(4, corners[0], corners[4], corners[5], corners[1]),
            this._addPlaneWithSpecifiedPoints(5, corners[0], corners[2], corners[3], corners[1])
        ]);

        if (this.rotateBox) {
            await this.addRotateHandles(box);
        } else {
            await this._addCutingHandles(box);
        }

        this._setTrackedCorners(corners);
        this.setPlaneListOfBox();
    }

    private rotateBox = false;
    private mapPlaneList = new Map<number, Communicator.Plane | null | undefined>();
    public async addRotateHandles(box: Communicator.Box): Promise<void> {
        const nodeIds = [];
        for (let i = 0; i < 6; i++) {
            const section = this._viewer.cuttingManager.getCuttingSection(i);
            const plane = section?.getPlane(0);
            const nodeId = section?.getNodeId(0);
            if (nodeId && plane) {
                nodeIds.push(nodeId);
                this.mapPlaneList.set(nodeId, plane.copy());
            }
        }
        await this.handleOperatorActive.deleteHandles();
        this.CreateNewHandleOperator();
        const hdPosition = this._boxCorners.length === 8 ? this._getMiddlePoint3(this._boxCorners[0], this._boxCorners[7]) : box.center();
        await this.handleOperatorActive.addHandles(nodeIds, hdPosition, GroupHandelId.Center_handles);
    }

    public setRotateBoxState(rotate: boolean): void {
        this.rotateBox = rotate;
    }

    public getClippingIsHide(): PlaneBoxChild[] {
        return this.listHandlePlaneRemove;
    }

    public async addCuttingBoxHandles(box: Communicator.Box): Promise<void> {
        this._boundingBox = box.copy();
        if (this.rotateBox) {
            this.addRotateHandles(box);
        } else {
            this._addCutingHandles(box);
        }
    }

    private async _addCutingHandles(box: Communicator.Box) {
        await this.handleOperatorActive.deleteHandles();
        await this.CreateNewHandleOperator();

        const positions = this._getHandlePositions(box, this._getNewCorners());

        const ps: Promise<number | undefined>[] = [];

        if (!this.listHandlePlaneRemove.includes('plane-x-plus')) {
            const xSection = this._viewer.cuttingManager.getCuttingSection(0)!;
            const xPlane = xSection.getPlane(0)!;
            ps.push(this._addAxisHandles(positions.xMidPlane, xPlane, xSection, GroupHandelId.Translate_X));
        }
        if (!this.listHandlePlaneRemove.includes('plane-y-plus')) {
            const ySection = this._viewer.cuttingManager.getCuttingSection(1)!;
            const yPlane = ySection.getPlane(0)!;
            ps.push(this._addAxisHandles(positions.yMidPlane, yPlane, ySection, GroupHandelId.Translate_Y));
        }
        if (!this.listHandlePlaneRemove.includes('plane-z-plus')) {
            const zSection = this._viewer.cuttingManager.getCuttingSection(2)!;
            const zPlane = zSection.getPlane(0)!;
            ps.push(this._addAxisHandles(positions.zMidPlane, zPlane, zSection, GroupHandelId.Translate_Z));

            ps.push(this._addAxisHandles(positions.zEdgeMidX, zPlane, zSection, GroupHandelId.Middle_ZmY, true));
            ps.push(this._addAxisHandles(positions.zEdgeMidY, zPlane, zSection, GroupHandelId.Middle_ZmX, true));
            ps.push(this._addAxisHandles(positions.zEdgeMidXnZ, zPlane, zSection, GroupHandelId.Middle_ZX, true));
            ps.push(this._addAxisHandles(positions.zEdgeMidYnZ, zPlane, zSection, GroupHandelId.Middle_ZY, true));


            ps.push(this._addCubeHandles(positions.cornerXYZ, zPlane, zSection, GroupHandelId.Conner_ZmXmY)); // corner 4
            ps.push(this._addCubeHandles(positions.cornerX, zPlane, zSection, GroupHandelId.Conner_ZXmY)); // corner 5
            ps.push(this._addCubeHandles(positions.cornerY, zPlane, zSection, GroupHandelId.Conner_ZmXY)); // corner 6
            ps.push(this._addCubeHandles(positions.cornerZ, zPlane, zSection, GroupHandelId.Conner_ZXY)); // corner 7
        }

        if (!this.listHandlePlaneRemove.includes('plane-x-minus')) {
            const invXSection = this._viewer.cuttingManager.getCuttingSection(3)!;
            const invXPlane = invXSection.getPlane(0)!;
            ps.push(this._addAxisHandles(positions.invXMidPlane, invXPlane, invXSection, GroupHandelId.Translate_mX));
        }

        if (!this.listHandlePlaneRemove.includes('plane-y-minus')) {
            const invYSection = this._viewer.cuttingManager.getCuttingSection(4)!;
            const invYPlane = invYSection.getPlane(0)!;
            ps.push(this._addAxisHandles(positions.invYMidPlane, invYPlane, invYSection, GroupHandelId.Translate_mY));
        }
        if (!this.listHandlePlaneRemove.includes('plane-z-minus')) {
            const invZSection = this._viewer.cuttingManager.getCuttingSection(5)!;
            const invZPlane = invZSection.getPlane(0)!;
            ps.push(this._addAxisHandles(positions.invZMidPlane, invZPlane, invZSection, GroupHandelId.Translate_mZ));

            ps.push(this._addAxisHandles(positions.invZEdgeMidX, invZPlane, invZSection, GroupHandelId.Middle_mZmY, true));
            ps.push(this._addAxisHandles(positions.invZEdgeMidY, invZPlane, invZSection, GroupHandelId.Middle_mZmX, true));
            ps.push(this._addAxisHandles(positions.invZEdgeMidXnZ, invZPlane, invZSection, GroupHandelId.Middle_mZX, true));
            ps.push(this._addAxisHandles(positions.invZEdgeMidYnZ, invZPlane, invZSection, GroupHandelId.Middle_mZY, true));

            ps.push(this._addCubeHandles(positions.invCornerXYZ, invZPlane, invZSection, GroupHandelId.Conner_mZmXmY)); // corner 0
            ps.push(this._addCubeHandles(positions.invCornerX, invZPlane, invZSection, GroupHandelId.Conner_mZXmY)); // corner 1
            ps.push(this._addCubeHandles(positions.invCornerY, invZPlane, invZSection, GroupHandelId.Conner_mZmXY)); // corner 2
            ps.push(this._addCubeHandles(positions.invCornerZ, invZPlane, invZSection, GroupHandelId.Conner_mZXY)); // corner 3

        }

        await Promise.all(ps);

        this.handleOperatorActive.showHandles();
        this._visibilityHandles(false);
    }

    private _setTrackedCorners(corners: Communicator.Point3[]): void {
        if (corners.length < 8) return;
        this._boxCorners = [];
        this.handleOperatorActive.clearTrackedPoints();
        corners.forEach(p => {
            this._boxCorners.push(p.copy());
            this.handleOperatorActive.addTrackedPoint(p.copy());
        });
    }

    public clearTrackedCorners(): void {
        this._boxCorners = [];
        this.handleOperatorActive.clearTrackedPoints();
    }

    private async _onHandleRotateOfBox(
        cuttingSection: Communicator.CuttingSection | null,
        plane: Communicator.Plane | null,
        initialMatrix: Communicator.Matrix,
        newMatrix: Communicator.Matrix,
        handleEventEnd: boolean)
        : Promise<void> {
        if (cuttingSection === null || plane === null) return;

        const handlePosition = new Communicator.Point3(newMatrix.m[12], newMatrix.m[13], newMatrix.m[14]);
        const newPlaneMatrix = newMatrix.copy();
        const axisNormal = plane.normal.copy();

        initialMatrix.setTranslationComponent(0, 0, 0);
        initialMatrix = initialMatrix.inverseAndDeterminant()[0]!;

        newMatrix.setTranslationComponent(0, 0, 0);

        const rotationMatrix = Communicator.Matrix.multiply(initialMatrix, newMatrix);

        const newNormal = rotationMatrix.transform(axisNormal);
        const newPlane = new Communicator.Plane().setFromPointAndNormal(handlePosition, newNormal);

        await cuttingSection.updatePlane(0, newPlane, newPlaneMatrix, handleEventEnd, true);
    }

    private async _rotatePlanesWithoutReferenceGemometry(): Promise<void> {
        const corners = this.handleOperatorActive.getTrackedPoints();
        const ps: Promise<void>[] = [];
        this.listHandlePlaneRemove.forEach(planeLabel => {
            switch (planeLabel) {
                case 'plane-x-plus':
                    ps.push(this._setPlaneWithSpecifiedPoints(0, corners[1], corners[3], corners[7], corners[5]));
                    break;
                case 'plane-y-plus':
                    ps.push(this._setPlaneWithSpecifiedPoints(1, corners[2], corners[6], corners[7], corners[3]));
                    break;
                case 'plane-z-plus':
                    ps.push(this._setPlaneWithSpecifiedPoints(2, corners[4], corners[6], corners[7], corners[5]));
                    break;
                case 'plane-x-minus':
                    ps.push(this._setPlaneWithSpecifiedPoints(3, corners[0], corners[2], corners[6], corners[4]));
                    break;
                case 'plane-y-minus':
                    ps.push(this._setPlaneWithSpecifiedPoints(4, corners[0], corners[4], corners[5], corners[1]));
                    break;
                case 'plane-z-minus':
                    ps.push(this._setPlaneWithSpecifiedPoints(5, corners[0], corners[2], corners[3], corners[1]));
                    break;
                default:
                    break;
            }
        });
        await Promise.all(ps);
    }

    private setPlaneListOfBox() {
        for (let i = 0; i < 6; i++) {
            const section = this._viewer.cuttingManager.getCuttingSection(i);
            const plane = section?.getPlane(0);
            const nodeId = section?.getNodeId(0);
            if (nodeId && plane) {
                this.mapPlaneList.set(nodeId, plane.copy());
            }
        }
    }

    private async _onCuttingHandleEvent(
        eventType: Communicator.HandleEventType,
        nodeIds: Communicator.NodeId[],
        initialMatrices: Communicator.Matrix[],
        newMatrices: Communicator.Matrix[],
        handleEventEnd: boolean
    ): Promise<void> {
        if (
            this.rotateBox &&
            eventType === Communicator.HandleEventType.Translate &&
            newMatrices.length > 0
        ) {
            const ps: Promise<void>[] = [];
            nodeIds.forEach((nId, i) => {
                const section = this._viewer.cuttingManager.getCuttingSectionFromNodeId(nId);
                const plane = this._getActivePlane(nId);
                ps.push(this._onCuttingHandleTranslate(section, plane, newMatrices[i], handleEventEnd));
            });
            await Promise.all(ps);
            return Promise.resolve();
        }

        if (
            this.rotateBox &&
            eventType === Communicator.HandleEventType.Rotate &&
            newMatrices.length > 0
        ) {
            const ps: Promise<void>[] = [];
            nodeIds.forEach((nId, i) => {
                const section = this._viewer.cuttingManager.getCuttingSectionFromNodeId(nId);
                const plane = this._getActivePlane(nId);
                ps.push(this._onHandleRotateOfBox(section, plane, initialMatrices[i].copy(), newMatrices[i].copy(), handleEventEnd));
            });
            await Promise.all(ps);
            await this._rotatePlanesWithoutReferenceGemometry();
            return Promise.resolve();
        }

        await this._updatePlanes();
        await this._updateHandles(this._boundingBox!);
    }

    private async _onCuttingHandleTranslate(
        cuttingSection: Communicator.CuttingSection | null,
        plane: Communicator.Plane | null,
        newMatrix: Communicator.Matrix,
        handleEventEnd: boolean
    ): Promise<void> {
        if (cuttingSection === null || plane === null) return;

        const newPlaneMatrix = newMatrix.copy();
        const axisNormal = plane.normal.copy();
        const handlePosition = new Communicator.Point3(newMatrix.m[12], newMatrix.m[13], newMatrix.m[14]);
        const newPlane = new Communicator.Plane().setFromPointAndNormal(handlePosition, axisNormal);
        await cuttingSection.updatePlane(0, newPlane, newPlaneMatrix, handleEventEnd, true);
    }

    /** check a face is behind other face */
    public faceIsBehind(
        indexSection: number,
        corners: Communicator.Point3[],
        viewer: Communicator.WebViewer
    ): boolean {
        const listFace = [
            { face: [7, 3, 1, 5], n: Utils.getNormal(corners[3], corners[1], corners[5]) }, // x : index section 0
            { face: [7, 6, 2, 3], n: Utils.getNormal(corners[6], corners[2], corners[3]) }, // y : 1
            { face: [7, 5, 4, 6], n: Utils.getNormal(corners[5], corners[4], corners[6]) }, // z : 2
            { face: [0, 2, 6, 4], n: Utils.getNormal(corners[2], corners[6], corners[4]) }, // -x : 3
            { face: [0, 4, 5, 1], n: Utils.getNormal(corners[4], corners[5], corners[1]) }, // -y : 4
            { face: [0, 1, 3, 2], n: Utils.getNormal(corners[1], corners[3], corners[2]) }, // -z : 5
        ];
        if (indexSection >= listFace.length || indexSection < 0) return true;
        const n = listFace[indexSection].n;
        const cam = viewer.view.getCamera();
        const camPos = cam.getPosition();
        const camAt = cam.getTarget();
        const dir = camPos.copy().subtract(camAt).normalize();

        // const angle = Math.acos(dir.x * n.x + dir.y * n.y + dir.z * n.z) * 180 / Math.PI;
        // return angle <=0 || angle > 90;
        const cosa = dir.x * n.x + dir.y * n.y + dir.z * n.z;
        return cosa < 0 && cosa !== 1;
    }

    /**
     * Check a point is behind other geometry
     * @param corners Eight corner points of the box
     * @param point The point is position need to check
     */
     public pointIsBehindOtherGeometry(corners: Communicator.Point3[],
        point: Communicator.Point3, viewer: Communicator.WebViewer): boolean
    {
        const cam = viewer.view.getCamera();
        
        const camPos = cam.getPosition();
        const camAt = cam.getTarget();
        const dir = camPos.copy().subtract(camAt).normalize();
        const r = new Communicator.Ray(point, dir);
        const listFace = [
            { face: [7, 3, 1, 5], n: Utils.getNormal(corners[3], corners[1], corners[5]) }, // x : index section 0
            { face: [7, 6, 2, 3], n: Utils.getNormal(corners[6], corners[2], corners[3]) }, // y : 1
            { face: [7, 5, 4, 6], n: Utils.getNormal(corners[5], corners[4], corners[6]) }, // z : 2
            { face: [0, 2, 6, 4], n: Utils.getNormal(corners[2], corners[6], corners[4]) }, // -x : 3
            { face: [0, 4, 5, 1], n: Utils.getNormal(corners[4], corners[5], corners[1]) }, // -y : 4
            { face: [0, 1, 3, 2], n: Utils.getNormal(corners[1], corners[3], corners[2]) }, // -z : 5
        ];
        const prCorners = corners.map((v) => viewer.view.projectPoint(v));
        let bret = false;
        listFace.forEach(f => {
            const plane = new Communicator.Plane();
            plane.setFromPointAndNormal(corners[f.face[0]], f.n);
            const pInsect = plane.rayIntersection(r!);
            if (pInsect) {
                const pr = viewer.view.projectPoint(pInsect);
                const polygon = [];
                for (let i = 0; i < f.face.length; i += 1) {
                    polygon.push(prCorners[f.face[i]]);
                    if (i === f.face.length - 1 ) {
                        polygon.push(polygon[0]);
                    }
                }
                let minDis = 100000;
                
                for (let i = 0; i < f.face.length - 1; i += 1) {
                    const d = Utils.distancePointAndSegment(polygon[i], polygon[i + 1], pr)
                    if (d < minDis) minDis = d;
                }

                if (minDis > 5 && Utils.isPointInPolygon(pr, polygon)) bret = true;
            }
            
        });
        return bret;
    }

    /** 
     * @param corners: eight corner points of the box
     * @param index: the move corner
     * @param point: new point of the move corner
     */
    private _getNewCornersByIndex(
        corners: Communicator.Point3[],
        index: number,
        point: Communicator.Point3
    ) {
        if (!corners || corners.length !== 8 || index < 0 || index >= 8 || !point) return corners;
        /**
         *
         *  6           7
         *      4           5   ^ Z   
         *                      |
         *  2           3       |
         *      0           1   ----> X
         */
        const len = corners[0].copy().subtract(corners[7]).length();
        const newCorners = corners.slice();
        const listFace = [
            { face: [7, 3, 1, 5], n: Utils.getNormal(corners[3], corners[1], corners[5]) }, // x : index section 0
            { face: [7, 6, 2, 3], n: Utils.getNormal(corners[6], corners[2], corners[3]) }, // y : 1
            { face: [7, 5, 4, 6], n: Utils.getNormal(corners[5], corners[4], corners[6]) }, // z : 2
            { face: [0, 2, 6, 4], n: Utils.getNormal(corners[2], corners[6], corners[4]) }, // -x : 3
            { face: [0, 4, 5, 1], n: Utils.getNormal(corners[4], corners[5], corners[1]) }, // -y : 4
            { face: [0, 1, 3, 2], n: Utils.getNormal(corners[1], corners[3], corners[2]) }, // -z : 5
        ];
        listFace.forEach(f => {
            if (f.face.includes(index)) {
                //
                const plane = new Communicator.Plane();
                plane.setFromPointAndNormal(point, f.n);
                for (let i = 0; i < 4; i++) {
                    const pfix = f.n.copy().scale(len);
                    const j = f.face[i];
                    if (j === index) newCorners[index] = point.copy();
                    else {
                        const r = new Communicator.Ray(newCorners[j].copy().subtract(pfix), f.n);
                        const pInsect = plane.rayIntersection(r);
                        if (pInsect) newCorners[j] = pInsect;
                    }
                }
            }
        });
        return newCorners;
    }

    public getEightCorners(): Communicator.Point3[] {
        return this._getNewCorners();
    }

    public setEightCorners(corners: Communicator.Point3[]): void {
        this._setTrackedCorners(corners);
    }

    /** get all eight corner points of the clipping box */
    private _getNewCorners(): Communicator.Point3[] {
        const trackedPoints = this.handleOperatorActive.getTrackedPoints();
        let corners: Communicator.Point3[] = Lodash.clone(trackedPoints);
        switch (this.handleOperatorActive.activeGroupId) {
            case GroupHandelId.Translate_X:
                corners = [
                    this._boxCorners[0],
                    trackedPoints[1],
                    this._boxCorners[2],
                    trackedPoints[3],
                    this._boxCorners[4],
                    trackedPoints[5],
                    this._boxCorners[6],
                    trackedPoints[7]
                ]
                break;
            case GroupHandelId.Translate_mX:
                corners = [
                    trackedPoints[0],
                    this._boxCorners[1],
                    trackedPoints[2],
                    this._boxCorners[3],
                    trackedPoints[4],
                    this._boxCorners[5],
                    trackedPoints[6],
                    this._boxCorners[7]
                ]
                break;
            case GroupHandelId.Translate_Y:
                corners = [
                    this._boxCorners[0],
                    this._boxCorners[1],
                    trackedPoints[2],
                    trackedPoints[3],
                    this._boxCorners[4],
                    this._boxCorners[5],
                    trackedPoints[6],
                    trackedPoints[7]
                ]
                break;
            case GroupHandelId.Translate_mY:
                corners = [
                    trackedPoints[0],
                    trackedPoints[1],
                    this._boxCorners[2],
                    this._boxCorners[3],
                    trackedPoints[4],
                    trackedPoints[5],
                    this._boxCorners[6],
                    this._boxCorners[7]
                ]
                break;
            case GroupHandelId.Middle_ZX:
            case GroupHandelId.Middle_ZY:
            case GroupHandelId.Middle_ZmX:
            case GroupHandelId.Middle_ZmY:
            case GroupHandelId.Translate_Z:
                corners = [
                    this._boxCorners[0],
                    this._boxCorners[1],
                    this._boxCorners[2],
                    this._boxCorners[3],
                    trackedPoints[4],
                    trackedPoints[5],
                    trackedPoints[6],
                    trackedPoints[7]
                ]
                break;
            case GroupHandelId.Middle_mZX:
            case GroupHandelId.Middle_mZY:
            case GroupHandelId.Middle_mZmX:
            case GroupHandelId.Middle_mZmY:
            case GroupHandelId.Translate_mZ:
                corners = [
                    trackedPoints[0],
                    trackedPoints[1],
                    trackedPoints[2],
                    trackedPoints[3],
                    this._boxCorners[4],
                    this._boxCorners[5],
                    this._boxCorners[6],
                    this._boxCorners[7]
                ]
                break;
            case GroupHandelId.Conner_ZmXmY:
                corners = this._getNewCornersByIndex(this._boxCorners, 4, trackedPoints[4]);
                break;
            case GroupHandelId.Conner_mZmXmY:
                corners = this._getNewCornersByIndex(this._boxCorners, 0, trackedPoints[0]);
                break;
            case GroupHandelId.Conner_mZXmY:
                corners = this._getNewCornersByIndex(this._boxCorners, 1, trackedPoints[1]);
                break;
            case GroupHandelId.Conner_ZXmY:
                corners = this._getNewCornersByIndex(this._boxCorners, 5, trackedPoints[5]);
                break;
            case GroupHandelId.Conner_mZmXY:
                corners = this._getNewCornersByIndex(this._boxCorners, 2, trackedPoints[2]);
                break;
            case GroupHandelId.Conner_ZmXY:
                corners = this._getNewCornersByIndex(this._boxCorners, 6, trackedPoints[6]);
                break;
            case GroupHandelId.Conner_mZXY:
                corners = this._getNewCornersByIndex(this._boxCorners, 3, trackedPoints[3]);
                break;
            case GroupHandelId.Conner_ZXY:
                corners = this._getNewCornersByIndex(this._boxCorners, 7, trackedPoints[7]);
                break;
            default:
                break;
        }
        return corners;
    }
}