/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTheme } from "react-jss";
import { ThemeDefine } from "theme/ThemeProvider";

export default function useStyleTheme<T extends string, K extends string>(
    useStyles: (data?: any) => Record<T, string>, 
    props?: Record<K, any>
): Record<T, string> {
    const theme = useTheme<ThemeDefine>();
    return useStyles({...props, theme});
}
