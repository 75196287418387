import { createSelector } from "reselect";
import { selectSelfMarkupPanel } from "./self.selector";

export const getMarkupPanelState = createSelector(
    selectSelfMarkupPanel,
    (state) => state
)
export const getMarkupPanelTypeGroup = createSelector(
    selectSelfMarkupPanel,
    (state) => state.typeGroup
)
export const getMarkupPanelModeLayer = createSelector(
    selectSelfMarkupPanel,
    (state) => state.modeLayer
)