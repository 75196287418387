import React from "react";
import clsx from "clsx";
import useStyleTheme from "hooks/useStyleTheme";
import { createStyles, ThemeDefine } from "theme/ThemeProvider";

const rootStyle = createStyles((theme: ThemeDefine) => ({
    rootScrollView: {
        backgroundColor: theme.palette.background,
        // overflow: 'hidden !important',
        // '&:hover': {
        //     overflow: 'auto !important',
        // }
    }
}));

const CScrollViewComponent = ({children, className, style}: Props): JSX.Element | null => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const classes = useStyleTheme(rootStyle);

    if (!children) {
        return null;
    }

    return (
        <div className={clsx(
            'custom-scrollbar overflow-auto',
            className,
        )} style={style}>
            {children}
        </div>
    )
}

const CScrollView = React.memo(CScrollViewComponent);
export default CScrollView;