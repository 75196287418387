/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LineCapStyle, MarkupBaseJson, MarkupFillType } from 'common/type-markup';
import MathHelper from 'container/pdf-viewer/helper/math.helper';
import { MarkupBaseBounding, GripPoint } from '../markup-canvas-elements/markup.bounding.element';
import { CustomGrippointElement } from '../markup-canvas-elements/markup.custom-grippoint.element';
import { LineElementCanvas } from '../markup-canvas-elements/markup.line-canvas.element';
import { TextboxElementCanvas } from '../markup-canvas-elements/markup.textbox-canvas.element';
import { MarkupBaseItem } from './markup.base.item';


export class MarkupCalloutItem extends MarkupBaseItem {
    public _firstPoint: Communicator.Point3 = Communicator.Point3.zero();
    public _secondPoint: Communicator.Point3 = Communicator.Point3.zero();
    private _uniqueId: string;
    private lineArrowHTML: LineElementCanvas = new LineElementCanvas();
    private textboxHTML: TextboxElementCanvas;
    private startGrippoint: CustomGrippointElement;
    private endGrippoint: CustomGrippointElement;
    private _textBoxSize: Communicator.Point2;
    private _textboxElementId: string | null = null;
    public constructor(
        viewer: Communicator.WebViewer,
        initSize: Communicator.Point2) {
        super(viewer);
        this.iconName = 'callOut';
        this.shapeName = 'Callout';
        this._uniqueId = this.uniqueId;
        this.textboxHTML = new TextboxElementCanvas(
            () => this.blurTextArea(),
            () => this.triggerOnMarkupUpdated(),
            () => this.onAutoSizeCallback(),
        );
        this._textBoxSize = new Communicator.Point2(initSize.x, initSize.y);
        this.startGrippoint = new CustomGrippointElement(
            viewer,
            (point: Communicator.Point2) => this.customGripPointDragStartCallback(point),
            (point: Communicator.Point2, isForcing) => { this.startGripPointDragMoveCallback(point, false) },
            (point: Communicator.Point2) => this.customGrippointDragEndCallback(point),
        );

        this.endGrippoint = new CustomGrippointElement(
            viewer,
            (point: Communicator.Point2) => this.customGripPointDragStartCallback(point),
            (point: Communicator.Point2, isForcing) => this.endGripPointDragMoveCallback(point, false),
            (point: Communicator.Point2) => this.customGrippointDragEndCallback(point),
        );
        this.createParentBounding(viewer);
        this.createChildBounding(viewer);
    }
    createParentBounding(viewer: Communicator.WebViewer) {
        this.redlineBounding = new MarkupBaseBounding(viewer);
        this.redlineBounding.setCanRotate(false);
        this.redlineBounding.setGripDirection([]);
        this.redlineBounding.setBoundingboxDoubleClickCallback(() => {
            if (!this._isCanEdit) return;
            this.focusTextArea();
        });
        this.redlineBounding.setBoundingBoxClickCallback(
            (point: Communicator.Point2, event: MouseEvent) => this.onClickBoundingCallBack(event),
        );
        this.redlineBounding.createBoundingBox();
    }

    createChildBounding(viewer: Communicator.WebViewer) {
        this.childBounding = new MarkupBaseBounding(viewer);
        this.childBounding.setCanRotate(false);
        this.childBounding.setGripDirection([GripPoint.right, GripPoint.left]);
        this.childBounding.setOutlineVisible(false);
        this.childBounding.padding = 0;
        this.childBounding.setBoudingboxCallback(
            (point: Communicator.Point2) => this.onDragStart(point),
            (point: Communicator.Point2) => this.onDragMove(point, false),
            (point: Communicator.Point2) => this.onBoundingBoxDragEndCallback(point),
        );
        this.childBounding.setGripPointCallback(
            (point: Communicator.Point2, type: GripPoint) => {
                this.gripPointDragStartCallback(point, type);
                this.blurTextArea();
            },
            (point: Communicator.Point2) => this.gripPointDragMoveCallback(point),
            (point: Communicator.Point2) => {
                if (this.redlineBounding) this.redlineBounding.setTextboxBoundingPointerEvent(false);
                this.gripPointDragEndCallback(point);
            },
        );
        this.childBounding.setBoundingboxDoubleClickCallback(() => {
            if (!this._isCanEdit) return;
            this.focusTextArea();
        });
        this.childBounding.createBoundingBox();
    }
    updateLineItem() {
        this.lineArrowHTML.setStrokeWidth(this._lineWeight);
        this.lineArrowHTML.setStrokeColor(new Communicator.Color(this._lineColor.r, this._lineColor.g, this._lineColor.b));
        this.lineArrowHTML.setLineStyle(this._lineStyle);
        this.lineArrowHTML.setStartLineCapStyle(LineCapStyle.OpenArrow);
        this.lineArrowHTML.lineCap = 'butt';
    }
    updateTextboxItem() {
        this.textboxHTML.setStrokeWidth(this._lineWeight);
        this.textboxHTML.setStrokeColor(new Communicator.Color(this._lineColor.r, this._lineColor.g, this._lineColor.b));
        this.textboxHTML.setFillType(this._fillColorOption ? MarkupFillType.Opaque : MarkupFillType.None);
        this.textboxHTML.setFillColor(new Communicator.Color(this._fillColor.r, this._fillColor.g, this._fillColor.b));
        this.textboxHTML.setFillOpacity(this._lineOpacity);

        this.textboxHTML.setTextColor(MathHelper.RGBToHexA(this._textColor));
        this.textboxHTML.setTextBold(this._textIsBold);
        this.textboxHTML.setTextItalic(this._textIsItalic);
        this.textboxHTML.setTextUnderline(this._textIsUnderline);
        this.textboxHTML.setTextFontFamily(this._textFontFamily);
        this.textboxHTML.setTextFontSize(this._textFontSize);
    }
    private _update(): void {
        this.updateLineItem();
        this.updateTextboxItem();
        this.endGrippoint.setVisible(false);
        const { view } = this._viewer;
        const firstPoint = Communicator.Point2.fromPoint3(view.projectPoint(this._firstPoint.copy()));
        const secondPoint = Communicator.Point2.fromPoint3(view.projectPoint(this._secondPoint.copy()));
        const tbP1 = new Communicator.Point2(secondPoint.x - this._textBoxSize.x / 2, secondPoint.y - this._textBoxSize.y / 2);
        const tbP2 = new Communicator.Point2(secondPoint.x + this._textBoxSize.x / 2, secondPoint.y + this._textBoxSize.y / 2);
        this._point1 = view.getCamera().getCameraPlaneIntersectionPoint(tbP1, view);
        this._point2 = view.getCamera().getCameraPlaneIntersectionPoint(tbP2, view);
        this.textboxHTML.setFirstPoint(tbP1);
        const changeDetect = this._textBoxSize.equals(this.textboxHTML.size);
        this.textboxHTML.setSize(this._textBoxSize);
        if (!changeDetect) this.handleAutoResize();
        const pos1 = new Communicator.Point2(Math.min(tbP1.x, tbP2.x, firstPoint.x), Math.min(tbP1.y, tbP2.y, firstPoint.y));
        const pos2 = new Communicator.Point2(Math.max(tbP1.x, tbP2.x, firstPoint.x), Math.max(tbP1.y, tbP2.y, firstPoint.y));
        this.vertices = [tbP2, new Communicator.Point2(tbP1.x, tbP2.y),
            tbP1, new Communicator.Point2(tbP2.x, tbP1.y)];
        const bdCenter = new Communicator.Point2((pos1.x + pos2.x) / 2, (pos1.y + pos2.y) / 2);
        const attachPoint = MathHelper.getAttachPoint(tbP1, firstPoint, this._textBoxSize, bdCenter);

        this.lineArrowHTML.setFirstPoint(firstPoint);
        this.lineArrowHTML.setSecondPoint(attachPoint);
        this.lineArrowHTML.createLine();
        this._isReady = true;

        this.updateBoundingBoxTextbox(pos1, pos2);
        this.updateChildBoundingBox(tbP1, tbP2);
        this.updateCustomGrippoint(firstPoint, secondPoint);
    }

    public draw(): void {
        this._update();
        if (!this.isHiding) {
            if (this._isReady) {
                this._redlineElementId = this._viewer.markupManager.addMarkupElement(
                    this.lineArrowHTML.getLine(),
                );
                this._textboxElementId = this._viewer.markupManager.addMarkupElement(
                    this.textboxHTML.getTextboxCanvas(),
                );
            }
        } else {
            if (this._redlineElementId) {
                this._viewer.markupManager.removeMarkupElement(this._redlineElementId);
                this._redlineElementId = null;
            }
            if (this._textboxElementId) {
                this._viewer.markupManager.removeMarkupElement(this._textboxElementId);
                this._textboxElementId = null;
            }
        }
        this.handleBoundingRectInteraction(() => {
            if (!this.boundingIds) return;
            const startEnt = this.startGrippoint.getGrippointElement();
            this.boundingIds.push(this._viewer.markupManager.addMarkupElement(startEnt));

            const endEnt = this.endGrippoint.getGrippointElement();
            this.boundingIds.push(this._viewer.markupManager.addMarkupElement(endEnt));
        });

    }
    updateChildBoundingBox(pos1: Communicator.Point2, pos2: Communicator.Point2) {
        const size = new Communicator.Point2(Math.abs(pos1.x - pos2.x), Math.abs(pos1.y - pos2.y))
        const stroke = this._lineWeight;
        const boxPos = new Communicator.Point2(pos1.x - stroke / 2, pos1.y - stroke / 2);
        if (!this.childBounding) return;
        this.childBounding.setCenter(pos1, pos2);
        this.childBounding.setPosition(boxPos);
        this.childBounding.setSize(new Communicator.Point2(size.x + stroke, size.y + stroke));
    }
    updateBoundingBoxTextbox(firstPoint: Communicator.Point2, secondPoint: Communicator.Point2) {
        if (!firstPoint || !secondPoint) return;
        const pos = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y));
        const size = new Communicator.Point2(Math.abs(firstPoint.x - secondPoint.x), Math.abs(firstPoint.y - secondPoint.y))
        const stroke = this.textboxHTML.getStrokeWidth();
        const boxPos = new Communicator.Point2(pos.x - stroke / 2, pos.y - stroke / 2);
        if (this.redlineBounding) {
            this.redlineBounding.setCenter(firstPoint, secondPoint);
            this.redlineBounding.setPosition(boxPos);
            this.redlineBounding.setSize(new Communicator.Point2(size.x + stroke, size.y + stroke));
        }
    }

    updateCustomGrippoint(firstPoint: Communicator.Point2, secondPoint: Communicator.Point2) {
        const temp = 5;
        const p1 = new Communicator.Point2(firstPoint.x - temp, firstPoint.y - temp);
        const p2 = new Communicator.Point2(secondPoint.x - temp, secondPoint.y - temp);
        this.startGrippoint.setPosition(p1);
        this.endGrippoint.setPosition(p2);
    }
    public getClassName(): string {
        return "Communicator.Markup.MarkupCalloutItem";
    }
    remove(): void {
        if (this._textboxElementId) {
            this._viewer.markupManager.removeMarkupElement(this._textboxElementId);
            this._textboxElementId = null;
        }
        super.remove();
    }
    toJson(): MarkupBaseJson {
        const circleObj = {
            className: this.getClassName(),
            lineColor: this._lineColor,
            lineStyle: this._lineStyle,
            lineWeight: this._lineWeight,
            lineOpacity: this._lineOpacity,
            fillColor: this._fillColor,
            fillColorOption: this._fillColorOption,
            textFontSize: this._textFontSize,
            textColor: this._textColor,
            textFontFamily: this._textFontFamily,
            textIsBold: this._textIsBold,
            textIsItalic: this._textIsItalic,
            textIsUnderline: this._textIsUnderline,
            iconName: this.iconName,
            shapeName: this.shapeName,
            uniqueIdGroup: this.uniqueIdGroup,
            point1: this._firstPoint.copy(),
            point2: this._secondPoint.copy(),
            textContent: this.textboxHTML.getTextContent(),
            uniqueId: this.uniqueId,
            textBoxSize: this._textBoxSize.copy(),
            arrowPoint2: this.lineArrowHTML.getSecondPoint(),
            modifiedDate: this._modifiedDate,
            lastModifiedBy: this._lastModifiedBy,
        };
        return circleObj;
    }
    fromJson(data: any): void {
        this._lineColor = data.lineColor;
        this._lineWeight = data.lineWeight;
        this._lineOpacity = data.lineOpacity;
        this._lineStyle = data.lineStyle;
        this._fillColor = data.fillColor;
        this._fillColorOption = data.fillColorOption;
        this._textFontSize = data.textFontSize;
        this._textColor = data.textColor;
        this._textFontFamily = data.textFontFamily;
        this._textIsBold = data.textIsBold;
        this._textIsItalic = data.textIsItalic;
        this._textIsUnderline = data.textIsUnderline;
        this._firstPoint = data.point1;
        this._secondPoint = data.point2;
        this.uniqueIdGroup = data.uniqueIdGroup;
        this._uniqueId = data.uniqueId;
        this._textBoxSize = data.textBoxSize;
        this.setTextContent(data.textContent);
        this._modifiedDate = data.modifiedDate;
        this._lastModifiedBy = data.lastModifiedBy;
    }
    setTextContent(content: string): void {
        this.textboxHTML.setTextContent(content);
    }

    onDragStart(point: Communicator.Point2) {
        if (!this._isCanEdit) return false;
        this.isUpdate = false;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        b !== null && this._previousDragPlanePosition.assign(b);
        return !1;
    }

    onDragMove(point: Communicator.Point2, ischildBounding = true) {
        if (!this._isCanEdit) return false;
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        let c = null;
        if (b !== null) {
            c = Communicator.Point3.subtract(b, this._previousDragPlanePosition);
            ischildBounding && this._firstPoint.add(c);
            this._secondPoint.add(c);
            this._previousDragPlanePosition.assign(b);
        }
        this._viewer.markupManager.refreshMarkup();
        return !0;
    }
    gripPointDragStartCallback(point: Communicator.Point2, type: GripPoint) {
        this.isUpdate = false;
        if (!this._isCanEdit) return;
        this.gripPointDragType = type;
        this.previousGripDragPoint = point;
    }

    gripPointDragMoveCallback(point: Communicator.Point2) {
        if (this.gripPointDragType === null) return;
        this.redlineBounding && this.redlineBounding.setTextboxBoundingPointerEvent(true);
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        if (b) {
            const secondPoint = Communicator.Point2.fromPoint3(a.projectPoint(this._secondPoint.copy()));
            const pad = this.childBounding!.padding;
            const dist = point.x - this.previousGripDragPoint!.x;
            const minOffset = this._lineWeight * 2 + this.textboxHTML.innerPad.x * 2 + 30;
            switch (this.gripPointDragType) {
                case GripPoint.right:
                    {
                        this._textBoxSize.assign(new Communicator.Point2(
                            Math.abs(this._textBoxSize.copy().x + dist),
                            this._textBoxSize.copy().y
                        ));
                        this.textboxHTML.setSize(this._textBoxSize);
                        if (this._textBoxSize.x <= minOffset) {
                            this.gripPointDragType = GripPoint.left;
                            break;
                        }

                        const newPos = a.getCamera().getCameraPlaneIntersectionPoint(new Communicator.Point2(
                            point.x - (this._textBoxSize.x / 2 + pad * 2),
                            secondPoint.y), a);
                        this._secondPoint.assign(newPos!);
                        this.previousGripDragPoint = point

                        this.textboxHTML.handleAutoResize();
                        break;
                    }
                case GripPoint.left:
                    {
                        this._textBoxSize.assign(new Communicator.Point2(
                            Math.abs(this._textBoxSize.copy().x - dist),
                            this._textBoxSize.copy().y
                        ));
                        this.textboxHTML.setSize(this._textBoxSize);
                        if (this._textBoxSize.x <= minOffset) {
                            this.gripPointDragType = GripPoint.right;
                            break;
                        }
                        const newPos = a.getCamera().getCameraPlaneIntersectionPoint(new Communicator.Point2(
                            point.x + (this._textBoxSize.x / 2 + pad * 2),
                            secondPoint.y), a);
                        this._secondPoint.assign(newPos!);
                        this.previousGripDragPoint = point

                        this.textboxHTML.handleAutoResize();
                        break;
                    }
                default:
                    break;
            }
        }
        this._viewer.markupManager.refreshMarkup();
    }
    customGripPointDragStartCallback(point: Communicator.Point2) {
        this.isUpdate = false;
    }

    startGripPointDragMoveCallback(point: Communicator.Point2, isForcing: boolean) {
        if (!this._isCanEdit) return;
        this.redlineBounding!.setTextboxBoundingPointerEvent(true);
        this.isUpdate = true;
        const { view } = this._viewer;
        const pos = point;
        if (isForcing) {
            const secondPoint = Communicator.Point2.fromPoint3(
                view.projectPoint(this._secondPoint.copy()),
            );
            const tempPos = this.updatePos(point, secondPoint);
            pos.assign(tempPos);
        }
        this._firstPoint.assign(view.getCamera().getCameraPlaneIntersectionPoint(pos, view)!);
        this._viewer.markupManager.refreshMarkup();
    }

    endGripPointDragMoveCallback(point: Communicator.Point2, isForcing: boolean) {
        if (!this._isCanEdit) return;
        this.isUpdate = true;
        const { view } = this._viewer;
        const pos = point;
        if (isForcing) {
            const firstPoint = Communicator.Point2.fromPoint3(
                view.projectPoint(this._firstPoint.copy()),
            );
            const tempPos = this.updatePos(point, firstPoint);
            pos.assign(tempPos);
        }
        this._secondPoint.assign(view.getCamera().getCameraPlaneIntersectionPoint(pos, view)!);
        this._viewer.markupManager.refreshMarkup();
    }

    public updatePos(position: Communicator.Point2, handlePoint: Communicator.Point2): Communicator.Point2 {
        // Rectangle force to Square when holding shift
        const pos = position.copy();
        if (handlePoint) {
            const deltaX = Math.abs(position.x - handlePoint.x);
            const deltaY = Math.abs(position.y - handlePoint.y);
            const range = 10;
            if (deltaX < range || deltaY < range) {
                const p = position.copy();
                if (deltaX < range) {
                    p.x = handlePoint.x;
                }
                if (deltaY < range) {
                    p.y = handlePoint.y;
                }
                pos.assign(p);
            } else {
                const delta = deltaX > deltaY ? deltaX : deltaY;
                pos.x = position.x - handlePoint.x > 0 ? handlePoint.x + delta : handlePoint.x - delta;
                pos.y = position.y - handlePoint.y > 0 ? handlePoint.y + delta : handlePoint.y - delta;
            }
        }

        return pos;
    }
    blurTextArea(): void {
        if (this.redlineBounding) {
            this.redlineBounding.setBoundingBoxPointerEvent(true);
            this.redlineBounding.setEditingTextbox(false);
        }
        if (this.childBounding) {
            this.childBounding.setBoundingBoxPointerEvent(true);
            this.childBounding.setEditingTextbox(false);
        }
        this.textboxHTML.blurTextArea();
    }
    focusTextArea(): void {
        if (this.redlineBounding) {
            this.redlineBounding.setBoundingBoxPointerEvent(false);
            this.redlineBounding.setEditingTextbox(true);
        }
        if (this.childBounding) {
            this.childBounding.setBoundingBoxPointerEvent(false);
            this.childBounding.setEditingTextbox(true);
        }
        this.textboxHTML.focusTextArea();
    }
    customGrippointDragEndCallback(point: Communicator.Point2) {
        this.isUpdate = false;
        this.triggerOnMarkupUpdated();
    }
    hit(point: Communicator.Point2) {
        if (this.vertices.length < 1) return false;
        let ct = Communicator.Point2.zero();
        if (this.redlineBounding) ct = this.redlineBounding.center;
        const rotatedVerties = this.vertices.map(v => MathHelper.convertPointWithRotation(v, ct, this._rotation));
        return this.lineArrowHTML.hit(point) || MathHelper.polyPointCollide(rotatedVerties, point);
    }
    setMarkupVisible(visible: boolean): void {
        this.lineArrowHTML.baseCanvas.style.display = visible ? 'initial' : 'none';
        this.textboxHTML.baseCanvas.style.display = visible ? 'initial' : 'none';
        if (this.textboxHTML.textboxCanvas) this.textboxHTML.textboxCanvas.style.display = visible ? 'initial' : 'none';
        if (this.childBounding) this.childBounding.setVisibleBounding(visible);
        this.startGrippoint.setVisibleGripPoints(visible);
        this.endGrippoint.setVisibleGripPoints(visible);
        super.setMarkupVisible(visible);
    }
    getHTMLElement() {
        return this.lineArrowHTML.baseCanvas;
    }
    onAutoSizeCallback(): void {
        if (!this._point1 || !this._point2) return;
        const a = this._viewer.view;
        const fPoint = Communicator.Point2.fromPoint3(a.projectPoint(this._point1.copy()));
        const sPoint = Communicator.Point2.fromPoint3(a.projectPoint(this._point2.copy()));
        const size = new Communicator.Point2(Math.abs(fPoint.x - sPoint.x), Math.abs(fPoint.y - sPoint.y));
        const offset = -size.y + this.textboxHTML.getStrokeWidth() + this.textboxHTML.autoSize.y;
        const cVec = this.mPoint2.copy().subtract(this.fPoint2.copy());
        const nVec = cVec.scale(1 / (cVec.length()));
        const newMPoint2 = this.mPoint2.copy().add(nVec.scale(offset));
        this.mPoint2.assign(newMPoint2);
        this._textBoxSize = new Communicator.Point2(
            this.textboxHTML.size.x,
            this.textboxHTML.autoSize.y + this.textboxHTML.strokeWidth
        )
        this._update();
        this.textboxHTML.getTextboxCanvas();
    }
    setLineWeight(lineWeight: number): void {
        super.setLineWeight(lineWeight);
        this.handleAutoResize();
    }
    setTextFontSize(fontSize: number) {
        super.setTextFontSize(fontSize);
        this.textboxHTML.setTextFontSize(this._textFontSize);
        this.handleAutoResize();
    }
    handleAutoResize() {
        setTimeout(() => {
            this.textboxHTML.handleAutoResize();
        }, 50)
    }
    setBoundingGripPointVisible(vis: boolean): void {
        this.startGrippoint.setVisible(vis);
        this.endGrippoint.setVisible(vis);
        this.childBounding && this.childBounding.setVisibleBounding(vis)
    }
}
