/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export default class BaseRedline extends Communicator.Markup.Redline.RedlineItem {
    protected _handleId: Communicator.Uuid | undefined;
    constructor(private viewer: Communicator.WebViewer) {
        super(viewer);
    }
    registerMarkup() {
        this.unRegisterMarkup();
        this._handleId = this._viewer.markupManager.registerMarkup(this);
    }

    unRegisterMarkup() {
        if (this._handleId) {
            this._viewer.markupManager.unregisterMarkup(this._handleId);
            this._handleId = undefined;
        }
    }
}