import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootEpic } from "common/type-state"
import { concatMap, filter, withLatestFrom } from "rxjs/operators"
import { deleteStateByViewIdMultiView } from "./multiViewer.slice"
import { deleteFLoatingVisibleByViewId } from "./panelVisible.slice"
import { deleteStateByViewIdPdfViewer } from "./pdfViewer.slice"
import { deleteStateByViewIdSheet } from "./sheets.slice"
import { deleteStateByViewIdTree } from "./tree.slice"
import { deleteStateByViewIdViewer3D } from "./viewer3D.slice"

interface AppSliceState {
    loading: boolean
}
const initState: AppSliceState = {
    loading: false
}

const appSlice = createSlice({
    name: 'app',
    initialState: initState,
    reducers: {
        setAppLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        deleteStateByViewId(state, action: PayloadAction<ViewId>) { return },
        noProcess(state) {
            return
        }
    }
})

const deleteStateByViewId$: RootEpic = (action$, state$) => action$.pipe(
    filter(deleteStateByViewId.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const viewId = action.payload;
        const fileInfo = state.filesList.filesOrigin.find(f => f.viewId === viewId);
        if (fileInfo) {
            const { modelFileId } = fileInfo;
            const format = fileInfo.converter;
            if (format === 'Hoops') {
                return [
                    deleteFLoatingVisibleByViewId(viewId),
                    deleteStateByViewIdViewer3D(viewId),
                    deleteStateByViewIdMultiView(viewId),
                    deleteStateByViewIdSheet(viewId),
                    deleteStateByViewIdTree(modelFileId),
                    setAppLoading(false),
                ]
            } else if (format === 'Foxit') {
                return [
                    deleteStateByViewIdPdfViewer(viewId),
                    deleteStateByViewIdSheet(viewId),
                    setAppLoading(false),
                ]
            }
        }
        return [setAppLoading(false)]
    })
)

export const AppEpics = [
    deleteStateByViewId$
]
export const { setAppLoading, deleteStateByViewId, noProcess } = appSlice.actions;
export default appSlice.reducer;