import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BimApi } from "api/bim.api";
import { BookmarkCustom } from "common/define";
import { RootEpic } from "common/type-state";
import { catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { BookmarkHelper } from "./helper";

interface BookmarkState {
    loading: boolean;
    currentBookmark: BookmarkCustom[];
    bookmarkSelected: string
}

const initState: BookmarkState = {
    currentBookmark: [],
    loading: false,
    bookmarkSelected: ''
}
const bookmarkSlice = createSlice({
    name: 'bookmark',
    initialState: initState,
    reducers: {
        fetchBookmark(state, action: PayloadAction<ViewId>) {
            state.loading = true;
        },
        updateBookmarkComplete(state, action: PayloadAction<BookmarkCustom[]>) {
            state.currentBookmark = action.payload;
            state.loading = false
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        selectedBookmark(state, action: PayloadAction<string>) {
            state.bookmarkSelected = action.payload
        },
        cudBookmark(state, action: PayloadAction<BookmarkHelper.PayloadCUDBookmark>) {
            state.loading = true
        }
    }
})

const fetchBookmark$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchBookmark.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const viewId = action.payload;
        const preResult = BookmarkHelper.getBookmarks(viewId);
        if (preResult !== undefined) {
            return [bookmarkSlice.actions.updateBookmarkComplete(preResult)]
        }
        const fileInfo = state.filesList.filesOrigin.find(f => f.viewId === viewId);
        if (fileInfo) {
            return BimApi.getBookmarks(fileInfo.modelFileId).pipe(
                map(re => {
                    BookmarkHelper.setBookmark(viewId, re);
                    return bookmarkSlice.actions.updateBookmarkComplete(re)
                }),
                catchError(err => {
                    BookmarkHelper.setBookmark(viewId, []);
                    return [bookmarkSlice.actions.updateBookmarkComplete([])]
                })
            )
        }
        BookmarkHelper.setBookmark(viewId, []);
        return [bookmarkSlice.actions.updateBookmarkComplete([])]
    })
)
const cudBookmark$: RootEpic = (action$, state$) => action$.pipe(
    filter(cudBookmark.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const {viewId, key} = action.payload;
        const fileInfo = state.filesList.filesOrigin.find(f => f.viewId === viewId);
        if (fileInfo) {
            return BookmarkHelper.cudBookmarkHelper(action.payload, state.bookmark.currentBookmark).pipe(
                mergeMap(newBookmarks => BimApi.cudBookmark(fileInfo.modelFileId, newBookmarks.data).pipe(
                    map(_ => newBookmarks)
                )),
                mergeMap(result => {
                    BookmarkHelper.setBookmark(viewId, result.data);
                    if (key === 'create') {
                        return [
                            bookmarkSlice.actions.updateBookmarkComplete(result.data),
                            selectedBookmark(result.bmId)
                        ]
                    } else {
                        return [bookmarkSlice.actions.updateBookmarkComplete(result.data)]
                    }
                }),
                catchError(err => [bookmarkSlice.actions.setLoading(false)])
            )
        }
        return [bookmarkSlice.actions.setLoading(false)]
    })
)
export const BookmarkEpics = [
    fetchBookmark$,
    cudBookmark$
]
export const {
    fetchBookmark,
    selectedBookmark,
    cudBookmark
} = bookmarkSlice.actions
export default bookmarkSlice.reducer
