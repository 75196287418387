/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NotepinCategory } from "common/define";
import { MarkupBaseOperator } from "../markup-operators/markup.base.operator";
import { MarkupNotepinItem } from "./markup.notepin.item";


export class MarkupNotePinOperator extends MarkupBaseOperator {
    private _insertNoteButton = Communicator.Button.Left;

    private _callbackFlag = false;
    private _noteTextManager: Communicator.Markup.Note.NoteTextManager;

    private _notepinCategory: NotepinCategory;
    public noteText: MarkupNotepinItem | null = null;
    /** @hidden */
    public constructor(
        viewer: Communicator.WebViewer,
        noteTextManager: Communicator.Markup.Note.NoteTextManager,
        notepinCategory: NotepinCategory,
    ) {
        super(viewer);
        this._noteTextManager = noteTextManager;
        this._viewer.getViewElement().style.cursor = 'crosshair';
        this._notepinCategory = notepinCategory;
    }

    /** @hidden */
    public onMouseDown(event: Communicator.Event.MouseInputEvent): void {
        super.onMouseDown(event);

        if (this.isActive()) {
            if (!this._callbackFlag) {
                this._viewer.setCallbacks({
                    explode: async (magnitude: number) => {
                        await this._noteTextManager.explode(magnitude);
                    },
                });
                this._callbackFlag = true;
            }

            this._dragging = false;
        }
    }

    public async getInfoNoteText(): Promise<void> {
        if (this.noteText) {
            await this.noteText.getInfo() as Communicator.Internal.UnusedPromise;
            this._markupItem = this.noteText;
            this._viewer.getViewElement().style.cursor = 'default';
            if (this._operatorEndCallback != null) {
                this._operatorEndCallback();
            }
        }
    }

    getCameraPlaneIntersectionPoint(
        camera: Communicator.Camera,
        point: Communicator.Point2,
        view: Communicator.View,
    ): Communicator.Point3 | null {
        const target = camera.getTarget();

        const normal = Communicator.Point3.subtract(
            camera.getPosition(),
            target,
        ).normalize();
        const plane = Communicator.Plane.createFromPointAndNormal(target, normal);

        const ray = view.raycastFromPoint(point);
        if (ray === null) {
            return null;
        }

        const intersectionPoint = Communicator.Point3.zero();

        if (plane.intersectsRay(ray, intersectionPoint)) { return intersectionPoint; }
        return null;
    }

    getRayCastPoint(
        selection: Communicator.Selection.FaceSelectionItem,
        point: Communicator.Point2,
    ): Communicator.Point3 | null {
        const { min, max } = selection.getFaceEntity().getBounding();
        const pos = selection.getPosition();

        const checkPos = (pos.x >= min.x && pos.x <= max.x) &&
            (pos.y >= min.y && pos.y <= max.y) &&
            (pos.z >= min.z && pos.z <= max.z);
        if (checkPos) return pos;

        const plane = Communicator.Plane.createFromPointAndNormal(min, selection.getFaceEntity().getNormal());
        const ray = this._viewer.view.raycastFromPoint(point);
        if (!ray) return null;
        const intersectionPoint = pos.copy();
        if (plane.intersectsRay(ray, intersectionPoint)) return intersectionPoint;
        return null;
    }

    /** @hidden */
    public async onMouseUp(event: Communicator.Event.MouseInputEvent): Promise<void> {
        if (this.isActive()) {
            const cameraPoint = this._viewer.view.getCamera().getCameraPlaneIntersectionPoint(event.getPosition(), this._viewer.view);
            if (this._viewer.sheetManager.getActiveSheetId() && cameraPoint) {
                this.noteText = new MarkupNotepinItem(
                    this._viewer,
                    this._noteTextManager,
                    cameraPoint,
                    new Communicator.Point3(0, 0, 1),
                    0,
                    this._notepinCategory
                );
                super.onMouseUp(event);
                this.getInfoNoteText();
                event.setHandled(true);
            }
            else {
                const pickConfig = new Communicator.PickConfig(Communicator.SelectionMask.Face);
                if (this._ptFirst.equals(this._ptCurrent)
                    && event.getButton() === this._insertNoteButton
                    || this._primaryTouchId !== null
                ) {
                    this._viewer.view.pickFromPoint(event.getPosition(), pickConfig).then((selection) => {
                        if (!this._noteTextManager.selectPin(selection)
                            && !this._noteTextManager.getExplodeActive()
                            && !this._noteTextManager.getIsolateActive()
                            && selection.overlayIndex() === 0
                        ) {
                            if (selection.isFaceSelection()) {
                                const newSelectionPos = this.getRayCastPoint(selection, event.getPosition());
                                if (!newSelectionPos) return;
                                this.noteText = new MarkupNotepinItem(
                                    this._viewer,
                                    this._noteTextManager,
                                    newSelectionPos,
                                    selection.getFaceEntity().getNormal(),
                                    selection.getNodeId(),
                                    this._notepinCategory
                                );

                                super.onMouseUp(event);
                                this.getInfoNoteText();
                            }
                            event.setHandled(true);
                        }
                        else {
                            this._operatorEndCallback();
                        }
                    });
                }
            }
        }
    }
    finishCommand(): void {
        this._startOperator = false;
        if (this._operatorEndCallback != null) {
            this._operatorEndCallback();
        }
        this._markupItem = null;
    }

    public Escape(): boolean {
        return true;
    }
}
