import { createSelector } from "reselect";
import { selectSelfPanelApp } from "./self.selector";

export const getPanelApp = createSelector(
    selectSelfPanelApp,
    (state) => state
)
export const getPanelAppActive = createSelector(
    selectSelfPanelApp,
    (state) => state.filter(app => app.active)
)