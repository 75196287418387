// import BaseOperator, { ModeOperator } from './BaseOperator';
// import ADCameraOrbitOperator from '../custorm-operator/ADCameraOrbitOperator';
// import MarkupOperator from './MarkupOperator';
// import ADMeasureFaceFaceAngleOperator from '../custorm-operator/ad-measure-face-face-angle-operator';
// import ADMeasureFaceFaceDistanceOperator from '../custorm-operator/ad-measure-face-face-distance-operator';
// import ADMeasureEdgeLengthOperator from '../custorm-operator/ad-measure-edge-length-operator';
// import ADMeasurePointPointDistanceOperator from '../custorm-operator/ad-measure-point-point-distance-operator';
// import MeasureBaseOperator from '../operator/MeasureBaseOperator';
// import { MeasureOperatorService } from 'src/app/viewer3d/services/measure-operator.service';
// import ADGeneralOperator from '../custorm-operator/ad-general-operator';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import BaseOperator, { ModeOperator } from "./base.operator";
import MarkupOperator from "./markup.operator";
import ADMeasurePointPointDistanceOperator from "../custom-operator/measure-point-point-distance.operator";
// import MeasureBaseOperator from './MeasureBaseOperator';
export default class MeasureOperator extends BaseOperator {
    public static OPERATOR = "MEARSURE";

    operatorBase: ADMeasurePointPointDistanceOperator | undefined;

    handle: any;
    constructor(
        viewer: Communicator.WebViewer,
        operatorID: Communicator.OperatorId,
    ) {
        // Add custom
        super(viewer);
        this.Name = MeasureOperator.OPERATOR as any;
        this.Mode = ModeOperator.Normal;
        this.Id = operatorID;
        if (
            operatorID >= Communicator.OperatorId.None &&
            operatorID <= Communicator.OperatorId.AxisTriad
        )
            this.Name = Communicator.OperatorId[operatorID] as any;
        else this.Name = MarkupOperator.OPERATOR as any;

        // custorm lai cac operator dfault
        switch (operatorID) {
            // case Communicator.OperatorId.MeasureFaceFaceAngle:
            //     this.operatorBase = new ADMeasureFaceFaceAngleOperator(viewer, viewer.measureManager);
            //     this.handle = viewer.operatorManager.registerCustomOperator(
            //         this.operatorBase,
            //     );
            //     this.Id = this.handle;
            //     break;
            // case Communicator.OperatorId.MeasureFaceFaceDistance:
            //     this.operatorBase = new ADMeasureFaceFaceDistanceOperator(viewer, viewer.measureManager);
            //     this.handle = viewer.operatorManager.registerCustomOperator(
            //         this.operatorBase,
            //     );
            //     this.Id = this.handle;
            //     break;
            // case Communicator.OperatorId.MeasureEdgeLength:
            //     this.operatorBase = new ADMeasureEdgeLengthOperator(viewer, viewer.measureManager);
            //     this.handle = viewer.operatorManager.registerCustomOperator(
            //         this.operatorBase,
            //     );
            //     this.Id = this.handle;
            //     break;
            case Communicator.OperatorId.MeasurePointPointDistance:
                this.operatorBase = new ADMeasurePointPointDistanceOperator(
                    viewer,
                    viewer.measureManager,
                ); // Add custom
                // this.operatorBase.measureSetting = setting;
                this.handle = viewer.operatorManager.registerCustomOperator(
                    this.operatorBase
                );
                this.Id = this.handle;
                break;
            default:
                break;
        }
    }

    /**
     * Active
     *
     */
    public Active(): void {
        if (this.Status === false) {
            //
            this.Viewer.operatorManager
                .getOperator(Communicator.OperatorId.Pan)
                .setMapping(Communicator.Button.Right);
            this.Viewer.operatorManager
                .getOperator(Communicator.OperatorId.Pan)
                .addMapping(Communicator.Button.Middle);
            this.Viewer.operatorManager.set(this.Id, 1);

            this.Status = true;
        }
    }

    public Escape(): boolean {
        if (this.Status === true) {
            if (this.handle) {
                this.Viewer.operatorManager.remove(this.handle);
                this.handle = null;
            }
            this.ResetDefautOperators();
            this.Status = false;
        }
        return !this.operatorBase?.Escape();

        //return true;
    }

    /**
     * InActive
     *
     */
    public InActive(): void {
        if (this.Status === true) {
            if (this.handle) {
                this.Viewer.operatorManager.remove(this.handle);
                this.Viewer.operatorManager.unregisterCustomOperator(
                    this.handle
                );
                this.handle = null;
            }
            this.operatorBase = null as any;
            this.ResetDefautOperators();
            this.Status = false;
        }
    }
}
