import { IconName } from "common/define-name-icon";

export type ModeLeftPanelNew = 'files' | 'markups' | 'issues' | 'modelTree' | 'properties' | 'settings'
interface LeftNavButton {
    iconName: IconName;
    leftMode: ModeLeftPanelNew;
    title: string;
    active: boolean

}

export const initLeftNavPanel: LeftNavButton[] =[
    { iconName: 'folder-light', leftMode: 'files', title: 'Files & Views', active: false},
    { iconName: 'documentEdit', leftMode: 'markups', title: 'Markups', active: false},
    { iconName: 'Issue', leftMode: 'issues', title: 'Issues', active: false},
    { iconName: 'tree-light', leftMode: 'modelTree', title: 'Model Tree', active: false},
    { iconName: 'properties', leftMode: 'properties', title: 'Object Properties', active: false},
    { iconName: 'setting-light', leftMode: 'settings', title: 'Settings', active: false},
] 
export default class LeftNavBarHelper {

}
