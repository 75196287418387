import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PrintingState } from "common/define";
import { RootEpic } from "common/type-state";
import { filter, switchMap, withLatestFrom } from "rxjs/operators";
import Utils from "utils/utils";
import { defaultPrinting } from "./helper/printing.helper";

const initState = defaultPrinting;
const printingSlice = createSlice({
    name: 'printing',
    initialState: initState,
    reducers: {
        setPrintings: (state, action: PayloadAction<PrintingState>) => {
            state.pageArea = action.payload.pageArea;
            state.layout = action.payload.layout;
            state.scaling = action.payload.scaling;
            state.scalingValue = action.payload.scalingValue;
            state.pageSize = action.payload.pageSize;
            state.color = action.payload.color;
        },
        saveAllPrinting: (state, action: PayloadAction<PrintingState>) => {
            const setting = action.payload;
            state.pageArea = setting.pageArea;
            state.layout = setting.layout;
            state.scaling = setting.scaling;
            state.scalingValue = setting.scalingValue;
            state.pageSize = setting.pageSize;
            state.color = setting.color;
            Utils.setLocalStorage('print-setting', setting)
            return
        },
        fetchAllPrinting: (state, action: PayloadAction<undefined>) => { return },
        applyPrinting: (state, action: PayloadAction<PrintingState>) => { return },
        cancelPrintingChanges: (state, action: PayloadAction<undefined>) => { return },
        restoreDefaultPrinting: (state, action: PayloadAction<undefined>) => { return },
    }
})

const fetchPrinting$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchAllPrinting.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const oldSetting: PrintingState | null = Utils.getValueLocalStorage('print-setting');
        const finalSetting = oldSetting ?? defaultPrinting;
        Utils.setLocalStorage('print-setting', finalSetting);
        return [setPrintings(finalSetting)]
    })
);
const applyPrinting$: RootEpic = (action$, state$) => action$.pipe(
    filter(applyPrinting.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        return []
    })
)
const cancelPrintingChanges$: RootEpic = (action$, state$) => action$.pipe(
    filter(cancelPrintingChanges.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        return [];
    })
)
const restoreDefaultPrinting$: RootEpic = (action$, state$) => action$.pipe(
    filter(restoreDefaultPrinting.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        return []
    })
)
export const PrintingActionEpics = [
    fetchPrinting$,
    applyPrinting$,
    cancelPrintingChanges$,
    restoreDefaultPrinting$,
]
export const {
    setPrintings,
    saveAllPrinting,
    fetchAllPrinting,
    applyPrinting,
    cancelPrintingChanges,
    restoreDefaultPrinting,

} = printingSlice.actions;

export default printingSlice.reducer
