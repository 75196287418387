import Utils from "utils/utils";


export default class GeneralOperator extends Communicator.Operator.SelectionOperator {
    viewer: Communicator.WebViewer;

    oldMiliSeccond = 0;

    currentMiliSeccond = 0;

    posStart: Communicator.Point2 | undefined;

    posCurrent: Communicator.Point2 | undefined;

    isMouseMove = false;

    constructor(viewer: Communicator.WebViewer) {
        super(viewer, viewer.noteTextManager);
        this.viewer = viewer;
    }


    onDoubleClick() : Promise<void> {
        return new Promise<void>((resolve) => {
            resolve();
        });
    }

    onMouseDown(event: Communicator.Event.MouseInputEvent) : void {
        this.posStart = event.getPosition().copy();
        this.isMouseMove = false;
    }

    onMouseMove(event: Communicator.Event.MouseInputEvent) : void {
        if (this.posStart) {
            this.posCurrent = event.getPosition().copy();
            if (Utils.isRectangleEnoughLarge(this.posStart.x, this.posStart.y, this.posCurrent.x, this.posCurrent.y)) {
                this.isMouseMove = true;
            }
        }
    }

    onMouseUp(event: Communicator.Event.MouseInputEvent): void {
        if (!event.getHandled()) {
            if (!this.isMouseMove && event.getButton() === Communicator.Button.Middle) {
                return
            }
        }
    }
}
