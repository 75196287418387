/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { GlobalState } from "common/global";
import ModelHelper from "../model/model.helper";

export default class CPanOperator extends Communicator.Operator.CameraPanOperator {
    viewer: Communicator.WebViewer;
    private inPanMode = false;
    private panMouseMove = false;
    private inWalkMode = false
    constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.viewer = viewer;
    }

    onMouseDown(event: Communicator.Event.MouseInputEvent) {
        if (GlobalState.markupMode === 'viewMode') {
            if (this.inPanMode || event.getButtons() === Communicator.Buttons.Right && !(event.shiftDown())) {
                ModelHelper.setCursorViewer(this.viewer, 'grab');
            }
            super.onMouseDown(event)
        }
    }
    onMouseMove(event: Communicator.Event.MouseInputEvent) {
        if (GlobalState.markupMode === 'viewMode') {
            if (event.shiftDown() && event.getButtons() === Communicator.Buttons.Right) {
                return
            }
            if (this.inPanMode) {
                ModelHelper.setCursorViewer(this.viewer, 'grabbing');
                super.onMouseMove(event)
            }
            if (event.getButtons() === Communicator.Buttons.Right || (event.getButtons() === Communicator.Buttons.Middle && this.inWalkMode === true)) {
                ModelHelper.setCursorViewer(this.viewer, 'grabbing');
                super.onMouseMove(event)
            }
            else {
                if (event.shiftDown() && event.getButtons() === Communicator.Buttons.Left) {
                    ModelHelper.setCursorViewer(this.viewer, 'grabbing');
                    super.onMouseMove(event)
                }
            }
        }
    }
    onMouseUp(event: Communicator.Event.MouseInputEvent) {
        if (GlobalState.markupMode === 'viewMode') {
            const currPrimaryOperator = GlobalState.primaryOperator.get(this.viewer);
            const cursor = GlobalState.mapOperatorCursor.get(currPrimaryOperator ?? 'select');
            ModelHelper.setCursorViewer(this.viewer, cursor ?? '')
            super.onMouseUp(event)
        }
    }
    setInPanMode(val: boolean) {
        this.inPanMode = val
    }
    setInWalkMode(val: boolean) {
        this.inWalkMode = val
    }
    getInPanMode(): boolean {
        return this.inPanMode;
    }
    setPanMouseMove(val: boolean) {
        this.panMouseMove = val;
    }
    getPanMouseMove(): boolean {
        return this.panMouseMove;
    }
}