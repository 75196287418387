import { ColorSetting, DisplayUnits, EffectSetting, GeneralSettingState, NavigationSetting, PointCloudSetting, SettingState } from "common/define";
import { GlobalState } from "common/global";
import { SystemConstants } from "common/system-constants";
import { JsonSetting } from "container/viewer3d/json-setting/jsonSetting";
import ModelHelper from "container/viewer3d/model/model.helper";
import { RGBColor } from "react-color";

export const defaultNavigationSetting: NavigationSetting = {
    rotateSpeed: 40,
    walkSpeed: 100,
    elevationSpeed: 100,
    zoomSpeed: 100,
    mouseSpeed: 100
}
export const defaultGeneralSetting: GeneralSettingState = {
    axis: true,
    cube: true,
    projectionModelDefault: 0,
    xRayOpacity: 15,
    ghostingOpacity: 5,
    backGroundColor: { r: 255, g: 255, b: 255, a: 100 },
    sheetBackGroundColor: { r: 155, g: 155, b: 155, a: 100 },
    sheetColor: { r: 0, g: 0, b: 0, a: 100 },
    sheetShadow: { r: 74, g: 74, b: 74, a: 100 },
    gradient: false,
    gradientColor: { r: 255, g: 255, b: 255, a: 100 },
    showLineWeight: false,
    displayUnit: DisplayUnits.Milimeter,
    precision: 2
}
export const defaultEffectSetting: EffectSetting = {
    ambientOcclusion: false,
    ambientOcclusionRadius: 0.03,
    antiAliasing: true,
    bloom: false,
    bloomIntensityScale: 0.01,
    threshold: 40,
    silhoutteEdges: false,
    reflectionPlane: false,
    shadows: false,
    interaction: false,
    blurSample: 40
}
export const defaultColorSetting: ColorSetting = {
    capingGeometryFace: { r: 100, g: 100, b: 100, a: 100 },
    capingGeometryLine: { r: 150, g: 150, b: 150, a: 100 },
    capingGeometryPlane: { r: 200, g: 200, b: 200, a: 100 },
    selectionMeasurement: { r: 250, g: 250, b: 250, a: 100 },
    enablePMIOverride: false,
    selectionPMI: { r: 50, g: 50, b: 50, a: 100 }
}
export const defaultPointCloudSetting: PointCloudSetting = {
    splatSize: 0.03,
    enableSplat: true,
    enableEyeLighting: 0.03,
    cloudSplatMode: 1
}

export const defaultSetting: SettingState = {
    navigation: defaultNavigationSetting,
    general: defaultGeneralSetting,
    effect: defaultEffectSetting,
    color: defaultColorSetting,
    fetchSettingFail: false,
    customSetting: false
}

export default class SettingHelper {
    static getDefaultSheetColor = (extension: string): GeneralSettingState => {
        let defaultSheetColor: RGBColor = { r: 255, g: 255, b: 255, a: 100 };
        switch (extension) {
        case 'dwg':
        case 'dgn':
            defaultSheetColor = { r: 0, g: 0, b: 0, a: 100 };
            break;
        default:
            break;
        }
        const cloneGeneralSetting: GeneralSettingState = {
            ...defaultGeneralSetting,
            sheetColor: defaultSheetColor
        }
        return cloneGeneralSetting
    };
    static setBackgroundColor(webViewer: Communicator.WebViewer, settingsGradient: boolean, bg: RGBColor, gradient: RGBColor, viewId?: string, show?: boolean): void {
        const bgColor = new Communicator.Color(bg.r, bg.g, bg.b);
        const gradientColor = new Communicator.Color(gradient.r, gradient.g, gradient.b);
        let bottomColor: Communicator.Color = bgColor;
        settingsGradient && (bottomColor = gradientColor);
        webViewer.view.setBackgroundColor(bgColor, bottomColor);
        if (webViewer.view.getDrawMode() === Communicator.DrawMode.HiddenLine) {
            ModelHelper.configHiddenLineMode(webViewer, bgColor, bottomColor)
        }
        if (webViewer && viewId && show) {
            const viewer = GlobalState.mapActiveView.get(viewId)
            if (viewer) {
                const jsonData = GlobalState.mapJsonSetting.get(viewId)
                JsonSetting.prototype.changeColorBlackWhite(viewer, jsonData)
            }
        }
    };
    static setGeneralSetting = (webViewer: Communicator.WebViewer, settings: GeneralSettingState, viewId?: string): void => {
        const axis = webViewer.view.getAxisTriad();
        const cube = webViewer.view.getNavCube();
        const { xRayOpacity, ghostingOpacity, gradient } = settings;
        const isDrawing = webViewer.sheetManager.isDrawingSheetActive();
        if (!isDrawing && webViewer.overlayManager) {
            try {
                if (settings.axis) {
                    axis.enable();
                } else {
                    axis.disable();
                };
                if (settings.cube) {
                    cube.enable();
                } else {
                    cube.disable();
                };
                // webViewer.view.setProjectionMode(projectionModelDefault);
            } catch (e) {
                console.warn(e)
            }
        } else {
            const defaultBG = webViewer.sheetManager.getSheetBackgroundColor();
            const defaultShadow = webViewer.sheetManager.getSheetShadowColor();
            const defaultColor = webViewer.sheetManager.getSheetColor();
            const sheetShadowColor = settings.sheetShadow ?
                new Communicator.Color(settings.sheetShadow.r, settings.sheetShadow.g, settings.sheetShadow.b)
                : defaultShadow
            const sheetColor = settings.sheetColor ?
                new Communicator.Color(settings.sheetColor.r, settings.sheetColor.g, settings.sheetColor.b)
                : defaultColor
            const sheetBackGroundColor = settings.sheetBackGroundColor ?
                new Communicator.Color(settings.sheetBackGroundColor.r, settings.sheetBackGroundColor.g, settings.sheetBackGroundColor.b)
                : defaultBG
            webViewer.sheetManager.setSheetColors(sheetBackGroundColor, sheetColor, sheetShadowColor);
            // webViewer.view.setProjectionMode(Communicator.Projection.Orthographic);
        }
        SettingHelper.setBackgroundColor(webViewer, gradient, settings.backGroundColor, settings.gradientColor, viewId, settings.showLineWeight);
        ModelHelper.applyXrayOpacity(webViewer, xRayOpacity, ghostingOpacity);
        if (viewId && settings.showLineWeight) {
            const viewer = GlobalState.mapActiveView.get(viewId)
            if (viewer) {
                GlobalState.mapLineWeight.set(viewId, settings.showLineWeight)
                SettingHelper.setLineWeight(viewer, settings.showLineWeight, viewId)
            }
        }
    };
    static setLineWeight = (webViewer: Communicator.WebViewer, show: boolean, viewId?: string): void => {
        if (webViewer && viewId) {
            const jsonData = GlobalState.mapJsonSetting.get(viewId)
            if (jsonData)
                JsonSetting.prototype.callApplyLineWeight(webViewer, jsonData, show)
        }
    };

    static setEffectSetting = (webViewer: Communicator.WebViewer, settings: EffectSetting): void => {
        const isDrawing = webViewer.sheetManager.isDrawingSheetActive();
        if (isDrawing) {
            webViewer.view.setAmbientOcclusionEnabled(false);
        }
        else {
            webViewer.view.setAmbientOcclusionEnabled(settings.ambientOcclusion);
            webViewer.view.setAmbientOcclusionRadius(settings.ambientOcclusionRadius);
        }
        webViewer.view.setAntiAliasingMode(settings.antiAliasing ? 0 : 1);
        webViewer.view.setBloomEnabled(settings.bloom);
        webViewer.view.setBloomIntensityScale(settings.bloomIntensityScale * 100);
        webViewer.view.setBloomThreshold(settings.threshold / 100.0);
        webViewer.view.setSilhouetteEnabled(settings.silhoutteEdges);
        webViewer.view.setSimpleReflectionEnabled(settings.reflectionPlane);
        webViewer.view.setSimpleShadowEnabled(settings.shadows);
        webViewer.view.setInteractiveDrawLimitIncreaseEnabled(settings.interaction);
        webViewer.view.setSimpleShadowBlurSamples(settings.blurSample);
    };

    static setColorSetting = (webViewer: Communicator.WebViewer, settings: ColorSetting): void => {
        const cappingFaceColor = new Communicator.Color(settings.capingGeometryFace.r, settings.capingGeometryFace.g, settings.capingGeometryFace.b);
        const cappingLineColor = new Communicator.Color(settings.capingGeometryLine.r, settings.capingGeometryLine.g, settings.capingGeometryLine.b);
        const cappingPlaneColor = new Communicator.Color(settings.capingGeometryPlane.r, settings.capingGeometryPlane.g, settings.capingGeometryPlane.b);
        const measureColor = new Communicator.Color(settings.selectionMeasurement.r, settings.selectionMeasurement.g, settings.selectionMeasurement.b);
        const pmiColor = new Communicator.Color(settings.selectionPMI.r, settings.selectionPMI.g, settings.selectionPMI.b);
        webViewer.model && webViewer.model.setPmiColorOverride(settings.enablePMIOverride);
        webViewer.model && webViewer.model.setPmiColor(pmiColor);
        webViewer.cuttingManager.setCappingFaceColor(cappingFaceColor);
        webViewer.cuttingManager.setCappingLineColor(cappingLineColor);
        webViewer.cuttingManager.setCuttingPlaneColor(cappingPlaneColor);
        webViewer.measureManager.setMeasurementColor(measureColor);
    };

    static setPointCloudSetting = (webViewer: Communicator.WebViewer, settings: PointCloudSetting): void => {
        const isDrawing = webViewer.sheetManager.isDrawingSheetActive();
        if (settings.enableEyeLighting > 0) {
            webViewer.view.setEyeDomeLightingEnabled(true);
            webViewer.view.setEyeDomeLightingOpacity(settings.enableEyeLighting);
        } else {
            webViewer.view.setEyeDomeLightingEnabled(false);
        };
        const size = SettingHelper.getPointSize(settings.splatSize, settings.cloudSplatMode, webViewer);
        if (isDrawing) {
            webViewer.view.setPointSize(SystemConstants.POINT_SIZE_DEFAULT, Communicator.PointSizeUnit.ScreenPixels);
        } else if (settings.enableSplat) {
            webViewer.view.setPointSize(size, settings.cloudSplatMode);
        } else {
            webViewer.view.setPointSize(SystemConstants.POINT_SIZE_DEFAULT, Communicator.PointSizeUnit.ScreenPixels);
        }
    };
    static getPointSize = (splatSize: number, splatMode: Communicator.PointSizeUnit, webViewer: Communicator.WebViewer): number => {
        let pixelSize = 1.0;
        const canvas = webViewer.view.getCanvasSize();
        let canvasSize = Math.min(canvas.x, canvas.y);
        if (canvasSize < 1) canvasSize = 1;

        switch (splatMode) {
        case Communicator.PointSizeUnit.CSSPixels:
        case Communicator.PointSizeUnit.ScreenPixels:
            pixelSize = splatSize * SystemConstants.POINT_SIZE_MAX;
            break;
        case Communicator.PointSizeUnit.ProportionOfBoundingDiagonal:
        case Communicator.PointSizeUnit.ProportionOfScreenWidth:
        case Communicator.PointSizeUnit.ProportionOfScreenHeight:
            pixelSize = (splatSize * SystemConstants.POINT_SIZE_MAX) / canvasSize;
            break;
        case Communicator.PointSizeUnit.World:
            pixelSize = (splatSize * SystemConstants.POINT_SIZE_MAX * 5) / canvasSize;
            break;
        default:
            pixelSize = (splatSize * SystemConstants.POINT_SIZE_MAX) / canvasSize;
        }
        return pixelSize;
    };
    static setAxis = (webViewer: Communicator.WebViewer, isAxis: boolean): void => {
        const axis = webViewer.view.getAxisTriad();
        const isDrawing = webViewer.sheetManager.isDrawingSheetActive();
        if (!isDrawing && webViewer.overlayManager) {
            if (isAxis)
                axis.enable();
            else
                axis.disable();
        };
    };
    static setCube = (webViewer: Communicator.WebViewer, isCube: boolean): void => {
        const cube = webViewer.view.getNavCube();
        const isDrawing = webViewer.sheetManager.isDrawingSheetActive();
        if (!isDrawing && webViewer.overlayManager) {
            if (isCube)
                cube.enable();
            else
                cube.disable();
        };
    };
    static setProjection = (webViewer: Communicator.WebViewer, projection: Communicator.Projection): void => {
        webViewer.view.setProjectionMode(projection);
        const currentCamera = webViewer.view.getCamera();
        webViewer.view.setCamera(currentCamera);
    }
    static toggleLineWeight = (webViewer: Communicator.WebViewer, lineWeight: boolean, viewId: string): void => {
        GlobalState.mapLineWeight.set(viewId, lineWeight)
        const jsonData = GlobalState.mapJsonSetting.get(viewId);
        if (jsonData)
            JsonSetting.prototype.callApplyLineWeight(webViewer, jsonData, lineWeight);
    }
    static setSheetColor = (webViewer: Communicator.WebViewer, color: RGBColor): void => {
        const isDrawing = webViewer.sheetManager.isDrawingSheetActive();
        if (isDrawing) {
            const currBG = webViewer.sheetManager.getSheetBackgroundColor();
            const currShadow = webViewer.sheetManager.getSheetShadowColor();
            const newColor = new Communicator.Color(color.r, color.g, color.b)
            webViewer.sheetManager.setSheetColors(currBG, newColor, currShadow);
        }
    }
    static setSheetBG = (webViewer: Communicator.WebViewer, color: RGBColor): void => {
        const isDrawing = webViewer.sheetManager.isDrawingSheetActive();
        if (isDrawing) {
            const currColor = webViewer.sheetManager.getSheetColor();
            const currShadow = webViewer.sheetManager.getSheetShadowColor();
            const newBG = new Communicator.Color(color.r, color.g, color.b)
            webViewer.sheetManager.setSheetColors(newBG, currColor, currShadow);
        }
    }
    static setSheetShadow = (webViewer: Communicator.WebViewer, color: RGBColor): void => {
        const isDrawing = webViewer.sheetManager.isDrawingSheetActive();
        if (isDrawing) {
            const currColor = webViewer.sheetManager.getSheetColor();
            const currBG = webViewer.sheetManager.getSheetBackgroundColor();
            const newShadow = new Communicator.Color(color.r, color.g, color.b)
            webViewer.sheetManager.setSheetColors(currBG, currColor, newShadow);
        }
    }
    static setAmbient = (webViewer: Communicator.WebViewer, ambient: boolean): void => {
        webViewer.view.setAmbientOcclusionEnabled(ambient);
    }
    static setAmbientRad = (webViewer: Communicator.WebViewer, ambientRad: number): void => {
        webViewer.view.setAmbientOcclusionRadius(ambientRad);
    }
    static setAntiAlias = (webViewer: Communicator.WebViewer, antiAlias: boolean): void => {
        webViewer.view.setAntiAliasingMode(antiAlias ? 0 : 1);
    }
    static setBloom = (webViewer: Communicator.WebViewer, bloom: boolean): void => {
        webViewer.view.setBloomEnabled(bloom);
    }
    static setBloomScale = (webViewer: Communicator.WebViewer, bloomScale: number): void => {
        webViewer.view.setBloomIntensityScale(bloomScale * 100);
    }
    static setBloomThreshold = (webViewer: Communicator.WebViewer, bloomThreshold: number): void => {
        webViewer.view.setBloomThreshold(bloomThreshold / 100.0);
    }
    static setSilhouette = (webViewer: Communicator.WebViewer, silhouette: boolean): void => {
        webViewer.view.setSilhouetteEnabled(silhouette);
    }
    static setReflection = (webViewer: Communicator.WebViewer, reflection: boolean): void => {
        webViewer.view.setSimpleReflectionEnabled(reflection);
    }
    static setShadows = (webViewer: Communicator.WebViewer, reflection: boolean): void => {
        webViewer.view.setSimpleShadowEnabled(reflection);
    }
    static setCappingFaceColor = (webViewer: Communicator.WebViewer, color: RGBColor): void => {
        if (!webViewer.cuttingManager.hasActiveCuttingSection()) return
        const cappingFaceColor = new Communicator.Color(color.r, color.g, color.b);
        webViewer.cuttingManager.setCappingFaceColor(cappingFaceColor);
    }
    static setCappingLineColor = (webViewer: Communicator.WebViewer, color: RGBColor): void => {
        if (!webViewer.cuttingManager.hasActiveCuttingSection()) return
        const cappingLineColor = new Communicator.Color(color.r, color.g, color.b);
        webViewer.cuttingManager.setCappingLineColor(cappingLineColor);
    }
    static setCappingPlaneColor = (webViewer: Communicator.WebViewer, color: RGBColor): void => {
        if (!webViewer.cuttingManager.hasActiveCuttingSection()) return
        const cappingPlaneColor = new Communicator.Color(color.r, color.g, color.b);
        webViewer.cuttingManager.setCuttingPlaneColor(cappingPlaneColor);
    }
    static setSelectionMeasurement = (webViewer: Communicator.WebViewer, color: RGBColor): void => {
        const measureColor = new Communicator.Color(color.r, color.g, color.b);
        webViewer.measureManager.setMeasurementColor(measureColor);
    }
    static setPMI = (webViewer: Communicator.WebViewer, pmi: boolean): void => {
        webViewer.model && webViewer.model.setPmiColorOverride(pmi);
    }
    static setPMIColor = (webViewer: Communicator.WebViewer, color: RGBColor): void => {
        const pmiColor = new Communicator.Color(color.r, color.g, color.b);
        webViewer.model && webViewer.model.setPmiColor(pmiColor);
    }
    static setEyeDome = (webViewer: Communicator.WebViewer, eyeDome: number): void => {
        if (eyeDome > 0) {
            webViewer.view.setEyeDomeLightingEnabled(true);
            webViewer.view.setEyeDomeLightingOpacity(eyeDome);
        } else {
            webViewer.view.setEyeDomeLightingEnabled(false);
        };
    }
    static setSplats = (webViewer: Communicator.WebViewer, splats: boolean, splatsSize: number, splatsMode: number): void => {
        const isDrawing = webViewer.sheetManager.isDrawingSheetActive();
        const size = SettingHelper.getPointSize(splatsSize, splatsMode, webViewer);
        if (isDrawing) {
            webViewer.view.setPointSize(SystemConstants.POINT_SIZE_DEFAULT, Communicator.PointSizeUnit.ScreenPixels);
        } else if (splats) {
            webViewer.view.setPointSize(size, splatsMode);
        } else {
            webViewer.view.setPointSize(SystemConstants.POINT_SIZE_DEFAULT, Communicator.PointSizeUnit.ScreenPixels);
        }
    }
    static setNavigationSetting = (viewId: string, settings: NavigationSetting): void => {
        const setOperatorClass = GlobalState.mapSetOperator.get(viewId);
        setOperatorClass && setOperatorClass.updateSettingWalk(settings);
    };
}