import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { createStyles, ThemeDefine } from 'theme/ThemeProvider';
import useStyleTheme from 'hooks/useStyleTheme';
import { useContextBoostrap } from 'context/boostrap.context';
import CIconSvg from './CIconSvg';
import clsx from 'clsx';

const rootStyles = createStyles((theme: ThemeDefine) => ({
    headerIcon: {
        height: '24px',
    },
    boldTitle: {
        color: '#000000',
        fontFamily: 'Avenir',
        fontStyle: 'nomal',
        fontWeight: '800',
        fontSize: '16px',
        margin: '0 0 16px',
    },
    divider: {
        display: 'block',
        margin: '0',
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'rgba(0,0,0,0.12)',
    },
    card: {
        margin: '20px 0px',
        background: '#f2fafd',
        boxShadow: '0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)',
        transition: 'box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1)',
        position: 'relative',
        padding: '16px',
        borderRadius: '4px',
        color: 'rgba(0,0,0,.87)',
    },
    content: {
        flex: '1 auto',
    },
    colName: {
        flex: 1,
        marginRight: '30px',
    },
    titleToggle: {
        color: '#0D1C36',
    },
    "& p": {
        fontFamily: 'Avenir',
        fontStyle: 'nomal',
        fontWeight: 'nomal',
        fontSize: '16px',
        height: '22px',
    },
}));

const CDialogInfo = (): JSX.Element => {
    const {state: {visibleInfo}, dispatch} = useContextBoostrap();
    const classes = useStyleTheme(rootStyles);

    const handleClose = useCallback(() => {
        dispatch({type: 'VISIBLE_CONTENT_INFO', payload: false})
    }, [dispatch])

    return (
        <Modal width='350px' visible={visibleInfo} footer={null} onCancel={handleClose} closable={false} centered={true}
        >
            <div>
                <div className={clsx('flex justify-end', classes.headerIcon)}>
                    <CIconSvg name='close' onClick={handleClose}/>
                </div>
                <div className='flex justify-between'>
                    <h4 className={classes.boldTitle}>Document Information</h4>
                </div>
                <div className={classes.divider}></div>
                <div className={clsx('flex flex-row', classes.card)}>
                    <div className={clsx('flex flex-row justify-start', classes.content)}>
                        <div className={clsx('flex flex-col justify-start align-start', classes.colName)}>
                            <p className={classes.titleToggle}>
                                Native file size
                            </p>
                            <p className={classes.titleToggle}>
                                Cache file size
                            </p>
                            <p className={classes.titleToggle}>
                                Total cache time
                            </p>
                            <p className={classes.titleToggle}>
                                Cache Object tab time
                            </p>
                            <p className={classes.titleToggle}>
                                Load time
                            </p>
                        </div>
                        <div className='flex flex-col justify-start align-start'>
                            <p>27.2 KB</p>
                            <p>27.2 KB</p>
                            <p>27.2 KB</p>
                            <p>27.2 KB</p>
                            <p>27.2 KB</p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CDialogInfo;
