/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from "@reduxjs/toolkit";
import { FileExtension } from "common/define";
import { GlobalState } from "common/global";
import Utils from "utils/utils";
import { selectSelf, selectSelfMultiViewer } from "./self.selector";

export const getMultiViewerState = createSelector(
    selectSelfMultiViewer,
    (state) => state
)

export const getSynchronizedState = createSelector(
    selectSelfMultiViewer,
    (state) => state.synchronized
)

export const getNameViewActive = createSelector(
    selectSelfMultiViewer,
    state => {
        const name = state.viewActive.name;
        if (!name) {
            return 'Model name'
        }
        return Utils.getFileNameWithoutExtension(name)
    }
)
export const getCurrentViewActive = createSelector(
    selectSelfMultiViewer,
    state => state.viewActive
)
export const getCurrentViewIdActive = createSelector(
    selectSelfMultiViewer,
    state => state.viewActive.viewId
)
export const getCurrentViewIdActiveFinal = createSelector(
    selectSelfMultiViewer,
    state => {
        const viewId = state.viewActive.viewId
        return GlobalState.getViewId(viewId)
    }
)
export const getCurrentViewActiveExtension = createSelector(
    selectSelfMultiViewer,
    state => state.viewActive.extension
)
export const getViewActive = createSelector(
    selectSelf,
    state => {
        let name = getNameViewActive(state);
        const viewActive = state.multiViewer.viewActive;
        if (viewActive.parentIdMerge) {
            const fileInfoOrigin = state.filesList.filesOrigin.find(f => f.viewId === viewActive.viewId);
            if (fileInfoOrigin) {
                name = fileInfoOrigin.filename ?? fileInfoOrigin.originalFile ?? 'Model name';
                if (fileInfoOrigin.multiStream && fileInfoOrigin.isRootModel === 0 && fileInfoOrigin.cacheFilename) {
                    name = fileInfoOrigin.cacheFilename
                }
            }
        }
        return { ...viewActive, name }
    }
)
export const getInfoByViewId = createSelector(
    [selectSelf, (_: any, viewId: ViewId | undefined) => viewId],
    (state, viewId) => {
        let name = '';
        let extenstion = ''
        if (!viewId) {
            const { name: nameExt, extension: ext, viewId: viewIdActive } = getViewActive(state);
            name = nameExt ?? 'Model name';
            extenstion = ext;
            if (ext === FileExtension.Revit) {
                const viewIdFinal = GlobalState.getViewId(viewIdActive);
                if (viewIdActive !== viewIdFinal) {
                    const fileInfoOfViewIdFinal = state.filesList.filesOrigin.find(f => f.viewId === viewIdFinal);
                    if (fileInfoOfViewIdFinal) {
                        name = fileInfoOfViewIdFinal.filename ?? fileInfoOfViewIdFinal.originalFile ?? 'Model name';
                    }
                }
            }
        } else {
            const fileInfoOrigin = state.filesList.filesOrigin.find(f => f.viewId === viewId);
            if (fileInfoOrigin) {
                name = fileInfoOrigin.filename ?? fileInfoOrigin.originalFile ?? 'Model name';
                extenstion = Utils.getFileExtension(name);
                if (fileInfoOrigin.multiStream && fileInfoOrigin.isRootModel === 0 && fileInfoOrigin.cacheFilename) {
                    name = fileInfoOrigin.cacheFilename
                }
            }
        }
        return {
            name,
            extenstion
        }
    }
)
export const getFileInfoByViewId = createSelector(
    [selectSelf, (_: any, viewId: ViewId | undefined) => viewId],
    (state, viewId) => {
        if (!viewId) {
            return getFileInfoViewActive(state)
        } else {
            return state.filesList.filesOrigin.find(f => f.viewId === viewId);
        }
    }
)
export const getFileInfoActiveFinal = createSelector(
    selectSelf,
    (state) => {
        const viewIdActive = state.multiViewer.viewActive.viewId;
        const viewIdFinal = GlobalState.getViewId(viewIdActive);
        const fileInfo = state.filesList.filesOrigin.find(f => f.viewId === viewIdFinal);
        if (fileInfo) return fileInfo
        else {
            const listViewerDisplay = state.multiViewer.listViewerDisplay;
            return listViewerDisplay.find(f => f.viewId === viewIdFinal)!
        }
    }
)

export const getFileInfoViewActive = createSelector(
    selectSelfMultiViewer,
    state => {
        const { viewActive, listViewerDisplay } = state;
        const viewIdFinal = GlobalState.getViewId(viewActive.viewId);
        const result = listViewerDisplay.find(f => f.viewId === viewIdFinal);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return result!;
    }
)

export const getFormatViewActive = createSelector(
    selectSelfMultiViewer,
    state => {
        const viewActive = state.viewActive;
        return viewActive.formatViewer ?? 'Blank'
    }
)
export const getLayout = createSelector(
    selectSelfMultiViewer,
    state => state.layout
)
export const getListViewOnly = createSelector(
    selectSelfMultiViewer,
    state => state.listViewOnly
)
export const getListViewBlank = createSelector(
    selectSelfMultiViewer,
    state => state.listViewerBlank
)
export const getListViewVisible = createSelector(
    selectSelfMultiViewer,
    state => state.listViewerVisible
)
export const getListViewDisplayIDs = createSelector(
    selectSelfMultiViewer,
    state => state.listViewerDisplay.map(view => view.viewId)
)
export const getListViewDisplay = createSelector(
    selectSelfMultiViewer,
    state => state.listViewerDisplay
)

export const getFinalModelFileId = createSelector(
    selectSelfMultiViewer,
    state => {
        const {
            viewActive,
            // listViewerDisplay 
        } = state;
        // const viewIdFinal = GlobalState.getViewId(viewActive.viewId);
        // const isSheet = viewIdFinal !== viewActive.viewId;
        // if (isSheet && viewActive.extension === FileExtension.Revit) {
        //     const match = listViewerDisplay.find(v => v.isRootModel === 1 && v.viewId === viewIdFinal);
        //     return match?.modelFileId || '';
        // }
        return viewActive.modelFileId;
    }
)
export const getViewsDisplayLayout = createSelector(
    selectSelfMultiViewer,
    state => ({
        listViewerVisible: state.listViewerVisible,
        layout :state.layout
    })
)