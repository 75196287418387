/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-classes-per-file */
/* eslint-disable */
import { ADMeasurePointPointDistanceMarkup } from '../markup/markup-custom/markup.measure-point-point-distance.item';
// import OperatorBase = Communicator.Operator.OperatorBase;
// import Selection = Communicator.Selection;
// import Internal = Communicator.Internal;
// import MeasureManager = Communicator.MeasureManager;
// import Markup = Communicator.Markup;
// import Util = Communicator.Util;
// import WebViewer = Communicator.WebViewer;
// import Point2 = Communicator.Point2;
// import Point3 = Communicator.Point3;
// import Point4 = Communicator.Point4;
// import PickConfig = Communicator.PickConfig;
// import Event = Communicator.Event;
// import SelectionMask = Communicator.SelectionMask;
// import KeyCode = Communicator.KeyCode;
import MeasureBaseOperator from '../operator/measure-base.operator';
// import { MeasureOperatorService } from 'src/app/viewer3d/services/measure-operator.service';
import { CursorMarkup } from '../markup/markup-3d/ad-cursor-markup-item';
import { MarkupAttachType } from 'common/type-markup';
import { GlobalState } from 'common/global';

enum Stage {
    NoPointsSelected,
    OnePointSelected,
    TwoPointsSelected,
}

// TODO: This seems useful in a more generic place
function worldPointToScreenPoint(
    viewer: Communicator.WebViewer,
    worldPosition: Communicator.Point3,
)
    : Communicator.Point2 {
    const wp4 = new Communicator.Point4(worldPosition.x, worldPosition.y, worldPosition.z, 1);
    const sp4 = new Communicator.Point4(0, 0, 0, 0);

    viewer.view.getFullCameraMatrix().transform4(wp4, sp4);

    const invW = 1 / sp4.w;
    const screenPosition = new Communicator.Point2(sp4.x * invW, sp4.y * invW);

    const dims = viewer.model.getClientDimensions();
    const w = dims[0];
    const h = dims[1];

    screenPosition.x = 0.5 * w * (screenPosition.x + 1);
    screenPosition.y = 0.5 * h * (screenPosition.y + 1);

    screenPosition.x = Math.max(0, Math.min(screenPosition.x, w));
    screenPosition.y = h - Math.max(0, Math.min(screenPosition.y, h));

    return screenPosition;
}

class SelectionPoints {
    public readonly worldPosition: Communicator.Point3 | null;

    public readonly screenPosition: Communicator.Point2;

    public readonly selectionItem: Communicator.Selection.SelectionItem;

    public constructor(worldPosition: Communicator.Point3 | null, screenPosition: Communicator.Point2, selectionItem: Communicator.Selection.SelectionItem) {
        this.worldPosition = worldPosition;
        this.screenPosition = screenPosition;
        this.selectionItem = selectionItem;
    }
}

// TODO: Don't see anyway to influence this for the operator
interface SnappingConfig {
    enabled: boolean;
    preferVertices: boolean;
}

export default class ADMeasurePointPointDistanceOperator extends MeasureBaseOperator {
    private readonly _measureManager: Communicator.MeasureManager;

    private readonly _snappingConfig: SnappingConfig;

    private readonly _updateCursorSpriteAction = new Communicator.Util.CurrentAction(true);    private _measureMarkup: ADMeasurePointPointDistanceMarkup | null = null;

    private _cursorMarkup: CursorMarkup | null = null;

    private _cameraInteractionActive = false;

    _attachMode:MarkupAttachType | undefined;
    _selectionItem:any = null;
    bMidPoint:boolean = false;
    bEndpoint :boolean = false;

    /** @hidden */
    public constructor(
        viewer: Communicator.WebViewer,
        measureManager: Communicator.MeasureManager,
    ) {
        super(viewer);

        this._viewer = viewer;
        this._measureManager = measureManager;
        this._snappingConfig = {
            enabled: true,
            preferVertices: true,
        };

        this._viewer.setCallbacks({
            beginInteraction: () => { this._onBeginInteraction(); },
            endInteraction: () => { this._onEndInteraction(); },
        });
        // TODO: was planning to have the "modelSwitched" callback here handle the switch from 3D to 2D and
        //  enabling the background selection, but upon receipt of a modelSwitched callback, our operator
        //  reports it is no longer active. But of course it is sort-of active in that you can still make
        //  measurements. This probably a deeper bug. This should get looked at with COM-2021
    }

    private _onBeginInteraction() {
        this._cameraInteractionActive = true;
        this._activateCursorSprite(false);
    }

    private _onEndInteraction() {
        this._cameraInteractionActive = false;
    }

    private _getStage(): Stage {
        return this._measureMarkup === null
            ? Stage.NoPointsSelected
            : this._measureMarkup._getStage();
    }

    private _draw(): void {
        let refresh = false;

        const stage = this._getStage();
        if (stage < Stage.TwoPointsSelected && this._cursorMarkup !== null) {
            this._cursorMarkup.draw();
            refresh = true;
        }

        if (this._measureMarkup !== null) {
            this._measureMarkup.draw();
            refresh = true;
        }

        if (refresh) {
            this._viewer.markupManager.refreshMarkup();
        }

    }

    private _activateCursorSprite(enable: boolean): void {
        if (this._cursorMarkup !== null) {
            this._cursorMarkup.enable(enable);
        }
    }

    private _createConfig(useSnapping: boolean): Communicator.PickConfig {
        return new Communicator.PickConfig(useSnapping ? Communicator.SelectionMask.All : Communicator.SelectionMask.Face);
    }

    private async _finalizeMeasurement(
        mousePosition: Communicator.Point2,
        useSnapping: boolean,
    )
        : Promise<void> {
        const measureMarkup = this._measureMarkup;
        if (measureMarkup === null) {
            console.assert(false);
            return;
        }

        const config = this._createConfig(useSnapping);

        const selectionItem = await this._viewer.view.pickFromPoint(mousePosition, config);
        if (selectionItem.overlayIndex()) {
            return;
        }

        // tslint:disable-next-line: no-non-null-assertion
        this._measureMarkup!.finalize();
        // tslint:disable-next-line: no-non-null-assertion
        this._measureManager.finalizeMeasurement(this._measureMarkup!); // Note: This triggers a "measurementCreated" callback.
        this._measureMarkup = null;

    }

    /**
     * Finds the best point to use for the given lineEntity given the snapping behavior and settings.
     */
    private _getLineSnapPoint(
        lineEntity: Communicator.Selection.LineEntity,
        useSnapping: boolean,
    )
        : Communicator.Point3 | null {
        console.assert(this._getStage() !== Stage.TwoPointsSelected);

        // Always favor vertex snapping if it's viable
        const bestVertexPosition = this._snappingConfig.preferVertices ? lineEntity.getBestVertex() : null;
        if (bestVertexPosition !== null) {
            return bestVertexPosition;
        }

        // The currently selected position from the line entity will be correct unless we want to find
        // the snap-based center of the second-point line
        const selectedPosition = lineEntity.getPosition();
        if (!useSnapping || this._getStage() === Stage.NoPointsSelected) {
            return selectedPosition;
        }

        // Getting here means we're snapping and selecting the second point. We need to support either
        //   1) Snapping to the closest point on the line from our original point if we're *near* that closest point OR
        //   2) Just snapping to the line if we're not near the closest point.
        // Thus, we need to figure out the closest point to make that decision. But first we have to figure out
        // which line segment from the set in the selection contains the current selection point. (It would be
        // nice if the selection information indicated that segment, but that's a deeper change into
        // the selection code and really needs it's own JIRA card)
        console.assert(this._measureMarkup !== null);
        const points = lineEntity.getPoints();
        const onLineEpsilon = 1.0e-10;
        const firstSegmentVertexIndex = (() => {
            for (let i = 0; i < points.length - 1; i++) {
                if (Communicator.Util.isPointOnLineSegment(points[i], points[i + 1], selectedPosition, onLineEpsilon)) {
                    return i;
                }
            }

            // Punt if we didn't find a match and just use the first segment. Should never happen
            // unless maybe we have a bad epsilon
            return 0;
        })();

        // Find closest point on that selected line segment from our first selection point
        const p0 = points[firstSegmentVertexIndex];
        const p1 = points[firstSegmentVertexIndex + 1];
        // tslint:disable-next-line: no-non-null-assertion
        const firstSelectedPoint = this._measureMarkup!.getFirstPointPosition();
        const closestLinePoint = Communicator.Util.closestPointFromPointToSegment(p0, p1, firstSelectedPoint);

        // Determine if we are within the acceptable tolerance of the closest point on the second line
        const closestScreenPoint = worldPointToScreenPoint(this._viewer, closestLinePoint);
        const selectedScreenPoint = worldPointToScreenPoint(this._viewer, selectedPosition);
        const pixelDistanceSq = Communicator.Point2.subtract(closestScreenPoint, selectedScreenPoint).squaredLength();
        const pickTolerance = this._viewer.selectionManager.getPickTolerance();
        const toleranceSq = pickTolerance * pickTolerance;
        return pixelDistanceSq <= toleranceSq ? closestLinePoint : selectedPosition;
    }

    private async _getSelectionCursorPoints(
        mousePosition: Communicator.Point2,
        useSnapping: boolean,
    )
        : Promise<SelectionPoints | null> {
        const stage = this._getStage();
        if (stage >= Stage.TwoPointsSelected) {
            return null;
        }

        const config = this._createConfig(useSnapping);

        const selectionItem = await this._viewer.view.pickFromPoint(mousePosition, config);
        if (selectionItem.overlayIndex() !== 0) {
            return null;
        }

        let worldPosition = selectionItem.getPosition();
        let screenPosition = mousePosition;

        if (this._snappingConfig.enabled) {
            const lineEntity = selectionItem.getLineEntity();
            const pointEntity = selectionItem.getPointEntity();
            if (lineEntity || pointEntity) {
                let worldSnapPosition: Communicator.Point3 | null = null;
                if (lineEntity !== null) {
                    worldSnapPosition = this._getLineSnapPoint(lineEntity, useSnapping);
                } else if (pointEntity !== null) {
                    worldSnapPosition = pointEntity.getPosition();
                }

                if (worldSnapPosition !== null) {
                    worldPosition = worldSnapPosition;
                    screenPosition = worldPointToScreenPoint(this._viewer, worldPosition);
                }
            }
        }

        return new SelectionPoints(worldPosition, screenPosition, selectionItem);
    }

    static async getSelectionCursorPointsEx(
        mousePosition: Communicator.Point2,
        viewer: Communicator.WebViewer,
        selectionItem:any,
        worldSnapPosition: Communicator.Point3
      )
            : Promise<SelectionPoints | null> {
    
        if (selectionItem === null || selectionItem.overlayIndex() !== 0) {
          return null;
        }
    
        let worldPosition = selectionItem.getPosition();
        let screenPosition = mousePosition as any;
        // let worldSnapPosition: Communicator.Point3 | null = null;
    
    
        if (worldSnapPosition !== null) {
          worldPosition = worldSnapPosition;
          screenPosition = this.worldPointToScreenPoint(viewer, worldPosition);
        } else {
          screenPosition = null;
          worldPosition = null;
        }
    
        return new SelectionPoints(worldPosition, screenPosition, selectionItem);
    }
    static worldPointToScreenPoint(
        viewer: Communicator.WebViewer,
        worldPosition: Communicator.Point3,
    ) : Communicator.Point2 {
        if (!worldPosition) return null as any;
        const wp4 = new Communicator.Point4(worldPosition.x, worldPosition.y, worldPosition.z, 1);
        const sp4 = new Communicator.Point4(0, 0, 0, 0);

        viewer.view.getFullCameraMatrix().transform4(wp4, sp4);

        const invW = 1 / sp4.w;
        const screenPosition = new Communicator.Point2(sp4.x * invW, sp4.y * invW);

        const dims = viewer.model.getClientDimensions();
        const w = dims[0];
        const h = dims[1];

        screenPosition.x = 0.5 * w * (screenPosition.x + 1);
        screenPosition.y = 0.5 * h * (screenPosition.y + 1);

        screenPosition.x = Math.max(0, Math.min(screenPosition.x, w));
        screenPosition.y = h - Math.max(0, Math.min(screenPosition.y, h));

        return screenPosition;
    }
    
    private async _updateMeasurementPoints(
        mousePosition: Communicator.Point2,
        useSnapping: boolean,
    )
        : Promise<void> {

        const stage = this._getStage();
        console.assert(stage < Stage.TwoPointsSelected);

        this._viewer.trigger('measurementBegin');

        const selection = await this._getSelectionCursorPoints(mousePosition, useSnapping);
        if (selection === null || selection.worldPosition === null) {
            this._activateCursorSprite(false);
            return;
        }

        this._activateCursorSprite(true);

        if (this._measureMarkup === null) {
            this._measureMarkup = new ADMeasurePointPointDistanceMarkup(this._viewer);
            this._measureManager.addMeasurement(this._measureMarkup);
        }

        if (stage === Stage.NoPointsSelected) {
            this._measureMarkup.setFirstPointPosition(selection.worldPosition);
            this._measureMarkup.setUnitMultiplier(
                selection.selectionItem.isNodeSelection()
                    ? this._viewer.model.getNodeUnitMultiplier(selection.selectionItem.getNodeId())
                    : 1,
            );
        } else if (stage === Stage.OnePointSelected) {
            const firstPosition = this._measureMarkup.getFirstPointPosition();
            if (!selection.worldPosition.equalsWithTolerance(firstPosition, 0.0000001)) {
                this._measureMarkup.setSecondPointPosition(selection.worldPosition);
                this._measureMarkup.adjust(selection.screenPosition);
                this._finalizeMeasurement(mousePosition, useSnapping) as Communicator.Internal.UnusedPromise;
            }
        }
        this.updateUnitType();

    }
    updateUnitType() {
        for (const item of this._measureManager.getAllMeasurements()) {
            GlobalState.measureSetting$.subscribe(v => {
                if (v) {
                    (item as ADMeasurePointPointDistanceMarkup).updateSettingUnit(v.unitType);  
                    (item as ADMeasurePointPointDistanceMarkup).updateSettingPrecision(v.precision);
                }
            })
        }
    }

    async getMarkupAttachType(mousePosition: Communicator.Point2,
        viewer: Communicator.WebViewer
        ){
    
        let result:boolean = true;
        let worldSnapPosition: Communicator.Point3 | null = null;
    
        let attachModes =[MarkupAttachType.Edge, MarkupAttachType.Vertex, MarkupAttachType.Face]
        for(let attachMode of attachModes) {
          const config = ADMeasurePointPointDistanceOperator.createConfig(attachMode);
    
          const selectionItem = await viewer.view.pickFromPoint(mousePosition, config);
          if (selectionItem.overlayIndex() !== 0) {
            result = false;
            continue;
          }
          this.bMidPoint = false;
          this.bEndpoint = false;
          switch (attachMode) {
            case MarkupAttachType.Edge:
            {
              const lineEnt = selectionItem.getLineEntity();
              if (lineEnt){
                worldSnapPosition = lineEnt.getPosition();
                // console.log(worldSnapPosition = lineEnt.getPosition());
                // console.log(selectionItem.getLineEntity().getBounding().center());
    
                this.bMidPoint = this.checkMidPoint(worldSnapPosition, lineEnt.getBounding().center(), lineEnt)
                let result = this.checkEndPoint(worldSnapPosition, lineEnt);
                if(result === 1){
                    this.bEndpoint = true;
                    worldSnapPosition = lineEnt.getBounding().max;
                }
                if(result === 2){
                    this.bEndpoint = true;
                    worldSnapPosition = lineEnt.getBounding().min;
                }
                if(this.bMidPoint === true){
                  worldSnapPosition = lineEnt.getBounding().center();
                }
              }
              
              break;
            }
            case MarkupAttachType.Vertex:
            {
              const lineEnt = selectionItem.getLineEntity();
              if (lineEnt) {
                const points = lineEnt.getPoints();
                const bestVertex = lineEnt.getBestVertex();
                if (bestVertex && (bestVertex.equals(points[0]) || bestVertex.equals(points[points.length - 1]))) { // Add custom
                  worldSnapPosition = bestVertex;
                }
              }
              break;
            }
            case MarkupAttachType.Face:
            {
              const faceEnt = selectionItem.getFaceEntity();
              if (faceEnt) worldSnapPosition = faceEnt.getPosition();
              break;
            }
            default:
              break;
          }
          if (worldSnapPosition !== null) {
            this._cursorMarkup?.setMarkupAttachType(attachMode, this.bMidPoint, this.bEndpoint);
            this._attachMode = attachMode;
            this._selectionItem = selectionItem;
            result = true;
            break;
          } else {
          }
       }
       if(result === false){
        this._attachMode = null as any;
        this._selectionItem = null;
       }
    
       return worldSnapPosition;
    }
    
    checkMidPoint(pSnapPoint: Communicator.Point3, pCenter:Communicator.Point3, lineEnt :Communicator.Selection.LineEntity){

        let pStartPoint: Communicator.Point3 = lineEnt.getBounding().max;
        let pEndPoint: Communicator.Point3 = lineEnt.getBounding().min;

        if( (Math.abs(pSnapPoint.x - pCenter.x) - Math.abs((pEndPoint.x - pStartPoint.x)/60)) <= 0.01 &&
            (Math.abs(pSnapPoint.y - pCenter.y) - Math.abs((pEndPoint.y - pStartPoint.y)/60)) <= 0.01 &&
            (Math.abs(pSnapPoint.z - pCenter.z) - Math.abs((pEndPoint.z - pStartPoint.z)/60)) <= 0.01 )
        return true;
    
        return false;
    }

    checkEndPoint(pSnapPoint: Communicator.Point3, lineEnt :Communicator.Selection.LineEntity){

        let pStartPoint: Communicator.Point3 = lineEnt.getBounding().max;
        let pEndPoint: Communicator.Point3 = lineEnt.getBounding().min;
        if( (Math.abs(pSnapPoint.x - pStartPoint.x) - Math.abs((pEndPoint.x - pStartPoint.x)/90)) <= 0.01 &&
            (Math.abs(pSnapPoint.y - pStartPoint.y) - Math.abs((pEndPoint.y - pStartPoint.y)/90)) <= 0.01 &&
            (Math.abs(pSnapPoint.z - pStartPoint.z) - Math.abs((pEndPoint.z - pStartPoint.z)/90)) <= 0.01 ){
                return 1;
        }
        if( (Math.abs(pSnapPoint.x - pEndPoint.x) - Math.abs((pEndPoint.x - pStartPoint.x)/90)) <= 0.01 &&
            (Math.abs(pSnapPoint.y - pEndPoint.y) - Math.abs((pEndPoint.y - pStartPoint.y)/90)) <= 0.01 &&
            (Math.abs(pSnapPoint.z - pEndPoint.z) - Math.abs((pEndPoint.z - pStartPoint.z)/90)) <= 0.01){
                return 2;
        }
    
        return 0;
    }
    
    private async _updateCursorSpriteImpl(
        mousePosition: Communicator.Point2,
        useSnapping: boolean,
    )
        : Promise<void> {
        if (this._cursorMarkup !== null) {

            if (useSnapping) {
                let worldSnapPosition: Communicator.Point3 = await this.getMarkupAttachType(mousePosition, this._viewer) as any;
                const selection = await ADMeasurePointPointDistanceOperator.getSelectionCursorPointsEx(mousePosition, this._viewer, this._selectionItem, worldSnapPosition);
                if (selection !== null) {
                    this._cursorMarkup.setPosition(selection.screenPosition);
                    this._activateCursorSprite(true);
                } else {
                    this._activateCursorSprite(false);
                }
            } else {
                this._cursorMarkup.setPosition(mousePosition);
            }
        }

        this._draw();
    }

    public static createConfig(attachMode: MarkupAttachType): Communicator.PickConfig {
        let ret: Communicator.PickConfig = new Communicator.PickConfig(Communicator.SelectionMask.None);
    
        switch (attachMode) {
          case MarkupAttachType.Vertex:
            ret = new Communicator.PickConfig(Communicator.SelectionMask.Line);
            break;
          case MarkupAttachType.Face:
            ret = new Communicator.PickConfig(Communicator.SelectionMask.Face);
            break;
          case MarkupAttachType.Edge:
            ret = new Communicator.PickConfig(Communicator.SelectionMask.Line);
            break;
          default:
            break;
        }
    
        // if (this.attachMode === MarkupAttachType.Face) return new Communicator.PickConfig(Communicator.SelectionMask.Face);
    
        // return new Communicator.PickConfig(Communicator.SelectionMask.All);
        return ret;
      }
    
    private _updateCursorSprite(
        mousePosition: Communicator.Point2,
        useSnapping: boolean,
    )
        : void {
        this._updateCursorSpriteAction.set(() => this._updateCursorSpriteImpl(mousePosition, useSnapping));
    }

    /**
     * Determine if the given mouse event should cause snapping. This is influenced by
     * the snap configuration enabled value.
     */
    private _useSnapping(event: Communicator.Event.MouseInputEvent): boolean {
        // COM-1683 Changed behavior to default to snapping unless the alt-key is down
        return this._snappingConfig.enabled && !event.altDown();
    }

    /** @hidden */
    public onMouseMove(event: Communicator.Event.MouseInputEvent): void {
        super.onMouseMove(event);

        const stage = this._getStage();
        if (stage < Stage.TwoPointsSelected) {
            if (!this._cameraInteractionActive) {
                const mousePosition = event.getPosition();
                this._updateCursorSprite(mousePosition, this._useSnapping(event));
            }
        } else if (stage === Stage.TwoPointsSelected) {
            // tslint:disable-next-line: no-non-null-assertion
            this._measureMarkup!.adjust(event.getPosition()); //
            event.setHandled(true);
            // end
            const useSnapping = this._useSnapping(event);
            const mousePosition = event.getPosition();
            // tslint:disable-next-line: no-unused-expression
            this._finalizeMeasurement(mousePosition, useSnapping) as Communicator.Internal.UnusedPromise;
        }
        this._draw(); // XXX: This is probably redundant.

    }

    /** @hidden */
    public onMouseUp(event: Communicator.Event.MouseInputEvent): void {
        if (this.isActive()) {
            const stage = this._getStage();
            const touch = this._primaryTouchId !== null
                && this._measureMarkup !== null
                && stage > Stage.OnePointSelected;
            if (this._dragCount < 3 || touch) {
                const useSnapping = this._useSnapping(event);
                const mousePosition = event.getPosition();
                if (stage <= Stage.OnePointSelected) {
                    // tslint:disable-next-line: no-unused-expression
                    this._updateMeasurementPoints(mousePosition, useSnapping) as Communicator.Internal.UnusedPromise;

                } else {
                    // tslint:disable-next-line: no-unused-expression
                    this._finalizeMeasurement(mousePosition, useSnapping) as Communicator.Internal.UnusedPromise;

                }
            }
        }
        // `onMouseUp` resets `_dragCount`, and must come after we check for it
        super.onMouseUp(event);
    }

    /** @hidden */
    public onKeyUp(_event: Communicator.Event.KeyInputEvent): void {
        // do nothing
    }

    private _clearMeasurement(): void {
        if (this._measureMarkup !== null) {
            this._measureManager.removeMeasurement(this._measureMarkup);
            this._measureMarkup = null;
        } else {
            this._measureManager.removeAllMeasurements();
        }

    }

    public Escape(): boolean {
        this._clearMeasurement();
        return true;

    }

    /** @hidden */
    public onKeyDown(event: Communicator.Event.KeyInputEvent): void {
        const keyCode = event.getKeyCode();

        if (keyCode === Communicator.KeyCode.Escape) {
            this._clearMeasurement();
        }

    }

    /** @hidden */
    public setHandled(): boolean {
        return this._getStage() > Stage.OnePointSelected;
    }
      
    /** @hidden */
    public onActivate(): void {
        this._cursorMarkup = new CursorMarkup(this._viewer);

        // For 2D drawings, make the background sheet selectable while we're active so
        // the user can point-to-point measure anything on the drawing
        //
        // TODO: Known issue here if hwv.switchToModel() is called while we're active, and the
        // switch is from a 3D to 2D, the background won't be selectable. But there are other issues
        // with the measurement operator already when switching models... fix them then. COM-2021
        if (this._viewer.sheetManager.isDrawingSheetActive()) {
            // tslint:disable-next-line: no-unused-expression
            this._viewer.sheetManager.setBackgroundSelectionEnabled(true) as Communicator.Internal.UnusedPromise;
        }
    }

    /** @hidden */
    public onDeactivate(): void {
        if (this._cursorMarkup !== null) {
            this._cursorMarkup.destroy();
            this._cursorMarkup = null;
        }

        if (this._measureMarkup !== null) {
            this._measureManager.removeMeasurement(this._measureMarkup);
            this._measureMarkup = null;
        }

        // Restore the no-selection behavior for 2D drawings when we're done. Note that if a model-switch to 3D
        // happened while we're active, the background selection will be disabled by the sheet manager so it's
        // fine that it won't get called here.
        if (this._viewer.sheetManager.isDrawingSheetActive()) {
            // tslint:disable-next-line: no-unused-expression
            this._viewer.sheetManager.setBackgroundSelectionEnabled(false) as Communicator.Internal.UnusedPromise;
        }
    }
}
