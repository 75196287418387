

export class MarkupSettingToolbar {
    private settingToolbarBox: HTMLElement | null = null;

    constructor() {
        this.createMarkupSettingToolbar();
    }

    createMarkupSettingToolbar(): void {
        this.settingToolbarBox = document.getElementById('setting-toolbar');
        if (!this.settingToolbarBox) return;
        this.settingToolbarBox.style.position = 'absolute';
        this.settingToolbarBox.style.zIndex = '3';
        this.settingToolbarBox.style.display = 'flex';
        this.settingToolbarBox.style.justifyContent = 'center'
    }

    getSettingToolbarBox(): HTMLElement | null {
        return this.settingToolbarBox;
    }
}