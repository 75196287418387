
import CLoading from "components/CLoading";
import React, {Suspense} from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "router/PrivateRouter";
import RouterArr from "./router";
import {useSelector} from "react-redux";
import {RootState} from "../redux/rootReducers";

const MainRouter = (): JSX.Element => {
    const basename = useSelector<RootState, string>(state=> state.bootstrap.systemConfig.baseName);
    return (
        <Router basename={basename}>
            <Suspense fallback={<CLoading visible={true} fullScreen={true} />}>
                <Switch>
                    {RouterArr.map(({path, component, isNotPrivate, ...rest}) => {
                        const results = isNotPrivate ?
                            <Route path={path} component={component} key={path} {...rest} /> :
                            <PrivateRoute path={path} key={path} com={component} {...rest} />
                        return results
                    })}
                </Switch>
            </Suspense>
        </Router>
    )
};

export default MainRouter;
