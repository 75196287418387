/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable */
import { UnitMeasure } from 'common/define';
import { runInThisContext } from 'vm';

// import Util = Communicator.Util;
// import WebViewer = Communicator.WebViewer;
// import Point2 = Communicator.Point2;
// import Point3 = Communicator.Point3;
// import Color = Communicator.Color;
// import Shape = Communicator.Markup.Shape;
// import MeasureMarkup = Communicator.Markup.Measure.MeasureMarkup;
/** @hidden */
export class ADMeasurePointPointDistanceMarkup extends Communicator.Markup.Measure.MeasureMarkup {
    private _firstPointShape = new Communicator.Markup.Shape.Circle();

    private _secondPointShape = new Communicator.Markup.Shape.Circle();
    private _arrowsInvert = false;
    private defaultPrecision: number | undefined;
    textShape: Communicator.Markup.Shape.TextBox[] = [];
    private _drawFontSize = 12;
    private _drawFontFamily = 'Open Sans';
    private _primaryColor = new Communicator.Color(33, 150, 243);
    private initCircle(circle: Communicator.Markup.Shape.Circle) {
        circle.setRadius(2.5);
        circle.setFillColor(this._viewer.measureManager.getMeasurementColor());
    }

    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);

        this._name = 'MeasurePointPointDistance';
        this._lineShapes = [];

        for (let i = 0; i < 6; i++) {
            this._lineShapes.push(new Communicator.Markup.Shape.Line());
            this._lineShapes[i].setStrokeColor(this._primaryColor);
            this._lineShapes[i].setStrokeWidth(2);
            this._lineShapes[i].setEndEndcapColor(this._primaryColor);
            this._lineShapes[i].setStartEndcapColor(this._primaryColor);
        }

        // const colorList = [Color.red(), Color.green(), Color.blue()]; 126 114 27
        const colorList = [new Communicator.Color(241, 44, 44), Communicator.Color.green(), new Communicator.Color(255, 244, 79)];
        for (let i = 0; i < 3; i++) {
            const shapeLine = new Communicator.Markup.Shape.Line();
            shapeLine.setStrokeColor(colorList[i]);
            shapeLine.setStrokeWidth(2);
            shapeLine.setEndEndcapColor(colorList[i]);
            shapeLine.setStartEndcapColor(colorList[i]);
            this._lineShapes.push(shapeLine);
            const shapeText = new Communicator.Markup.Shape.TextBox();
            shapeText.getBoxPortion().setFillOpacity(1);
            shapeText.getBoxPortion().setFillColor(colorList[i]);
            this.textShape.push(shapeText);
        }

        this._viewer = viewer;

        this.initCircle(this._firstPointShape);
        this.initCircle(this._secondPointShape);

        this._textShape = new Communicator.Markup.Shape.TextBox();
        this._textShape.getBoxPortion().setFillOpacity(1);
        this._textShape.getBoxPortion().setFillColor(new Communicator.Color(255, 255, 255));
    }

    public setUnitMultiplier(value: number) {
        this._unitMultiplier = value;
    }

    public setFirstPointPosition(position: Communicator.Point3): void {
        this._stage = 1;
        this._positions[0] = position.copy();
    }

    public setSecondPointPosition(position: Communicator.Point3): void {
        this._stage = 2;
        this._positions[1] = position.copy();
        this._positions[2] = position.copy();

        this._setMeasurementValue(
            Communicator.Point3.subtract(this._positions[1], this._positions[0]).length(),
        );
    }

    public _getStage(): number {
        return this._stage;
    }

    public finalize(): void {
        this._stage++;
    }

    public getFirstPointPosition(): Communicator.Point3 {
        return this._positions[0];
    }

    public adjust(position: Communicator.Point2): void {
        super.adjust(position);
        const viewRay = this._viewer.view.raycastFromPoint(position);
        if (viewRay === null) {
            return;
        }

        const position1 = this._positions[0];
        const position2 = this._positions[1];

        let positionDiff = new Communicator.Point3(1, 0, 0);
        if (!position2.equals(position1)) positionDiff = Communicator.Point3.subtract(position2, position1);

        const camera = this._viewer.view.getCamera();
        const cameraUp = camera.getUp();

        const viewRayLeft = Communicator.Point3.cross(viewRay.direction, cameraUp).normalize();

        const center = new Communicator.Point3(
            (position1.x + position2.x) / 2,
            (position1.y + position2.y) / 2,
            (position1.z + position2.z) / 2,
        );

        const upPoint = new Communicator.Point3(center.x + cameraUp.x, center.y + cameraUp.y, center.z + cameraUp.z);
        const leftPoint = new Communicator.Point3(center.x + viewRayLeft.x, center.y + viewRayLeft.y, center.z + viewRayLeft.z);

        const distantViewRayPoint = new Communicator.Point3(
            viewRay.origin.x + viewRay.direction.x * 1000000,
            viewRay.origin.y + viewRay.direction.y * 1000000,
            viewRay.origin.z + viewRay.direction.z * 1000000,
        );

        let planeIntersectionPoint = new Communicator.Point3(0, 0, 0);

        Communicator.Util.intersectionPlaneLine(viewRay.origin, distantViewRayPoint, center, upPoint, leftPoint, planeIntersectionPoint);

        this._positions[2].assign(planeIntersectionPoint);

        let axisPoint1 = new Communicator.Point3(0, 0, 0);
        if (Math.abs(positionDiff.x) <= Math.abs(positionDiff.y)
            && Math.abs(positionDiff.x) <= Math.abs(positionDiff.z)) axisPoint1 = new Communicator.Point3(1, 0, 0);
        else if (Math.abs(positionDiff.y) <= Math.abs(positionDiff.x)
            && Math.abs(positionDiff.y) <= Math.abs(positionDiff.z)) axisPoint1 = new Communicator.Point3(0, 1, 0);
        else axisPoint1 = new Communicator.Point3(0, 0, 1);

        const axisPoint2 = Communicator.Point3.cross(axisPoint1, positionDiff);
        const axisPoint3 = Communicator.Point3.cross(axisPoint2, positionDiff);

        axisPoint2.set(position1.x + axisPoint2.x, position1.y + axisPoint2.y, position1.z + axisPoint2.z);
        axisPoint3.set(position1.x + axisPoint3.x, position1.y + axisPoint3.y, position1.z + axisPoint3.z);

        const lineBegin = new Communicator.Point3(
            planeIntersectionPoint.x + positionDiff.x * 10000,
            planeIntersectionPoint.y + positionDiff.y * 10000,
            planeIntersectionPoint.z + positionDiff.z * 10000,
        );

        const lineEnd = new Communicator.Point3(
            planeIntersectionPoint.x - positionDiff.x * 10000,
            planeIntersectionPoint.y - positionDiff.y * 10000,
            planeIntersectionPoint.z - positionDiff.z * 10000,
        );

        const doesIntersect = Communicator.Util.intersectionPlaneLine(
            lineBegin,
            lineEnd,
            position1,
            axisPoint2,
            axisPoint3,
            planeIntersectionPoint,
        );
        // eslint-disable-next-line no-restricted-globals
        const validIntersection = !isNaN(planeIntersectionPoint.x) && !isNaN(planeIntersectionPoint.y) && !isNaN(planeIntersectionPoint.z);
        if (!doesIntersect || !validIntersection) {
            planeIntersectionPoint = position2.copy();
        }

        const delta = Communicator.Point3.subtract(planeIntersectionPoint, position1);

        this._positions[3] = new Communicator.Point3(position1.x + delta.x, position1.y + delta.y, position1.z + delta.z);
        this._positions[4] = new Communicator.Point3(position2.x + delta.x, position2.y + delta.y, position2.z + delta.z);

        this._updateArrowsInverted();

        this._viewer.markupManager.refreshMarkup();
    }

    private _updateArrowsInverted(): void {
        const d1 = new Communicator.Point3(
            (this._positions[3].x + this._positions[4].x) / 2.0,
            (this._positions[3].y + this._positions[4].y) / 2.0,
            (this._positions[3].z + this._positions[4].z) / 2.0,
        );

        const d2 = Communicator.Point3.subtract(this._positions[4], this._positions[3]);

        const d3 = Communicator.Point3.subtract(this._positions[2], d1);

        if (d3.length() * 2.0 > d2.length()) this._arrowsInvert = true;
        else this._arrowsInvert = false;
    }

    getMidPoint(p1: Communicator.Point2, p2: Communicator.Point2) {
        return p1.copy().add(p2).scale(0.5);
    }

    updateSettingUnit(value: any) {
        switch (value) {
            case 0: // mm
                this.setUnitMultiplier(UnitMeasure.milimet);
                break;
            case 1: // decimet
                this.setUnitMultiplier(UnitMeasure.centimet);
                break;
            case 2: // decimet
                this.setUnitMultiplier(UnitMeasure.decimet);
                break;
            case 3: // met
                this.setUnitMultiplier(UnitMeasure.met);
                break;
            case 4: // inch
                this.setUnitMultiplier(UnitMeasure.inch);
                break;
            case 5: // feet
                this.setUnitMultiplier(UnitMeasure.feet);
                break;
            default:
                break;
        }
    }
    updateSettingPrecision(value: any) { // value precision range form 0->5
        this.defaultPrecision = value;
        return this.defaultPrecision;
    }
    formatUnitPrecision(value: number, multiplier: number): string {
        const precisionValue = value.toFixed(this.defaultPrecision);
        let displayFormatString;
        switch (multiplier) {
            case UnitMeasure.milimet:
                displayFormatString = precisionValue + ' mm';
                break;
            case UnitMeasure.centimet:
                displayFormatString = precisionValue + ' cm';
                break;
            case UnitMeasure.decimet:
                displayFormatString = precisionValue + ' dm';
                break;
            case UnitMeasure.met:
                displayFormatString = precisionValue + ' m';
                break;
            case UnitMeasure.inch:
                displayFormatString = precisionValue + ' \"';
                break;
            case UnitMeasure.feet:
                displayFormatString = precisionValue + ' ft';
                break;
            default:
                break;
        }
        return displayFormatString as any;
    }

    getLenAndUnit(p1: Communicator.Point3, p2: Communicator.Point3) {
        const milimet = p1.copy().subtract(p2).length();
        const unitMul = this.getUnitMultiplier();
        const measureValue = milimet / unitMul;
        // return Util.formatWithUnit(measureValuePrecision, unitMul);
        return this.formatUnitPrecision(measureValue, unitMul);
    }

    public update(): void {
        super.update();
        const { view } = this._viewer;
        const projectedPosition = new Array<Communicator.Point2>(6);

        const listMeasurement = this._viewer.measureManager.getAllMeasurements();
        const lastMeasurement = (listMeasurement[listMeasurement.length - 1] as ADMeasurePointPointDistanceMarkup);
        const mul = lastMeasurement.getUnitMultiplier();
        this.setUnitMultiplier(mul);

        const val = this.getMeasurementValue();
        this.setMeasurementText(this.formatUnitPrecision(val / mul, mul));

        if (this._stage > 0) {
            this._behindView = false;
            for (let i = 0; i < this._positions.length; i++) {
                const pt3: Point3 = view.projectPoint(this._positions[i]);
                if (pt3.z <= 0.0) {
                    this._behindView = true;
                }
                projectedPosition[i] = Communicator.Point2.fromPoint3(view.projectPoint(this._positions[i]));
            }

            this._firstPointShape.setCenter(projectedPosition[0]);
        }

        if (this._stage > 1) {
            if (this._textShape) this._textShape.setPosition(this.getMidPoint(projectedPosition[0], projectedPosition[1]));

            this._secondPointShape.setCenter(projectedPosition[1]);
            this._lineShapes[0].set(projectedPosition[0], projectedPosition[1]); // root
            this._lineShapes[5].set(projectedPosition[0], projectedPosition[1]);
            this._lineShapes[5].setEndcapType(Communicator.Markup.Shape.EndcapType.Arrowhead);
            this._lineShapes[5].setStartEndcapType(Communicator.Markup.Shape.EndcapType.Arrowhead);

            const listPoint = [];
            const p1 = this._positions[0].copy();
            const p2 = this._positions[1].copy();
            const p = p1.copy();
            listPoint.push(p1.copy());
            p.x = p2.x;
            listPoint.push(p.copy());
            p.y = p2.y;
            listPoint.push(p.copy());
            listPoint.push(p2.copy());
            const projectedPositionEx = new Array<Communicator.Point2>(listPoint.length);
            const len = new Array<string>(listPoint.length - 1);
            projectedPositionEx[0] = Communicator.Point2.fromPoint3(view.projectPoint(listPoint[0]));
            for (let i = 1; i < listPoint.length; i++) {
                projectedPositionEx[i] = Communicator.Point2.fromPoint3(view.projectPoint(listPoint[i]));
                const j = i - 1;
                let unitStr = this.getLenAndUnit(listPoint[j], listPoint[j + 1]);
                this._lineShapes[6 + j].set(projectedPositionEx[j], projectedPositionEx[j + 1]);
                this.textShape[j].setPosition(this.getMidPoint(projectedPositionEx[j], projectedPositionEx[j + 1]));

                switch (j) {
                    case 0:
                        unitStr = 'X: ' + unitStr;
                        break;
                    case 1:
                        unitStr = 'Y: ' + unitStr;
                        break;
                    case 2:
                        unitStr = 'Z: ' + unitStr;
                        break;
                    default:
                        break;
                }
                this.textShape[j].setTextString(unitStr);
            }
        }

    }
    checkAnyShapeCollision(): boolean { // aassume box is 100px*30px
        let shapePosition: Communicator.Markup.Shape.TextBox[] = [];
        for (const item of this.textShape) {
            shapePosition.push(item)
        }
        shapePosition.push(this._textShape)
        // generate pos of 4 angles of xyz


        let isCollide = true;

        if (this.check2ShapeCollide(shapePosition[0], shapePosition[1]) &&
            this.check2ShapeCollide(shapePosition[0], shapePosition[2]) &&
            this.check2ShapeCollide(shapePosition[0], shapePosition[3]) &&
            this.check2ShapeCollide(shapePosition[1], shapePosition[2]) &&
            this.check2ShapeCollide(shapePosition[1], shapePosition[3]) &&
            this.check2ShapeCollide(shapePosition[2], shapePosition[3])) {
            isCollide = false;
        }
        return isCollide;
    }
    getLengthString(shape: Communicator.Markup.Shape.TextBox) {
        const context = document.createElement('canvas').getContext('2d') as any;
        context.font = String(this._drawFontSize) + 'px ' + this._drawFontFamily;
        const width = context.measureText(shape.getTextString()).width + shape.getPadding() * 2;
        return width;
    }
    check2ShapeCollide(shape1: Communicator.Markup.Shape.TextBox, shape2: Communicator.Markup.Shape.TextBox): boolean {
        const shape1Pos = shape1.getPosition();
        const shape2Pos = shape2.getPosition();
        const shape1Width = this.getLengthString(shape1)
        const shape2Width = this.getLengthString(shape2)
        const rect1 = { x: shape1Pos.x, y: shape1Pos.y, width: shape1Width, height: 30 };
        const rect2 = { x: shape2Pos.x, y: shape2Pos.y, width: shape2Width, height: 30 };

        if (rect1.x < rect2.x + rect2.width &&
            rect1.x + rect1.width > rect2.x &&
            rect1.y < rect2.y + rect2.height &&
            rect1.y + rect1.height > rect2.y) {
            // collision detected!
            return false;
        }
        else {
            return true;
        }
    }

    public draw(): void {

        // do not draw if the markup is hidden or the model is exploded
        if (this._visibility && this._viewer.explodeManager.getMagnitude() === 0) {
            this.update();

            // only draw when in view
            if (!this._behindView) {
                const renderer = this._viewer.markupManager.getRenderer();
                switch (this._stage) {
                    case 1:
                        renderer.drawCircle(this._firstPointShape);
                        break;
                    case 2:
                    case 3:
                        renderer.drawCircle(this._firstPointShape);
                        renderer.drawCircle(this._secondPointShape);
                        const strokeColor = new Communicator.Color(105, 105, 105);
                        const strokeWidth = 0.7;
                        const borderRad = 2;
                        this._textShape.getBoxPortion().setBorderRadius(borderRad);
                        this._textShape.getBoxPortion().setStrokeWidth(strokeWidth);
                        this._textShape.getBoxPortion().setStrokeColor(strokeColor);
                        for (const item of this.textShape) {
                            item.getBoxPortion().setBorderRadius(borderRad);
                            item.getBoxPortion().setStrokeWidth(strokeWidth);
                            item.getBoxPortion().setStrokeColor(strokeColor);
                        }
                        for (const shape of this._lineShapes) renderer.drawLine(shape);
                        if (!this.checkAnyShapeCollision()) {
                            renderer.drawTextBox(this._textShape);
                            this.drawExcludeValueZero(renderer);
                        }
                        else {
                            // this.drawWhenScrollAlignLeft(renderer);
                            // this.drawExcludeValueZero(renderer);

                            this.drawWhenScrollAlignCenter(renderer);
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    }
    drawExcludeValueZero(renderer: Communicator.Markup.MarkupRenderer) {
        for (const textShape of this.textShape) {
            const splitStr = textShape.getTextString().split(' ');
            let textNumberValue: number = 0; // Add custom
            for (const num of splitStr) {
                if (parseFloat(num)) {
                    textNumberValue = Number(num);
                }
            }
            if (textNumberValue !== 0 && textNumberValue !== undefined) {
                renderer.drawTextBox(textShape);
            }
        }
    }
    checkShapeValueZero(textShape: any) {
        const splitStr = textShape.getTextString().split(' ');
        let textNumberValue: number = 0; // Add custom
        for (const num of splitStr) {
            if (parseFloat(num)) {
                textNumberValue = Number(num);
            }
        }
        if (textNumberValue !== 0 && textNumberValue !== undefined) {
            return true;
        }
        else {
            return false;
        }
    }
    drawWhenScrollAlignCenter(renderer: Communicator.Markup.MarkupRenderer) {
        this._textShape.getTextPortion().setFontSize(this._drawFontSize);
        this._textShape.getTextPortion().setFontFamily(this._drawFontFamily);
        const boxHeight = this._drawFontSize + this._textShape.getPadding() * 2 + 4;
        const mainShapeWidth = this.getLengthString(this._textShape);
        renderer.drawTextBox(this._textShape);
        const pos = this._textShape.getPosition();
        let yLevel = 0;
        for (let i = 0; i < this.textShape.length; i++) {
            this.textShape[i].getTextPortion().setFontSize(this._drawFontSize);
            this.textShape[i].getTextPortion().setFontFamily(this._drawFontFamily);
            const shapeWidth = this.getLengthString(this.textShape[i]);
            this.textShape[i].setPosition({
                x: pos.x - (shapeWidth - mainShapeWidth) / 2,
                y: pos.y + boxHeight * (yLevel + 1) + 2.5
            } as Communicator.Point2);
            if (this.checkShapeValueZero(this.textShape[i])) {
                renderer.drawTextBox(this.textShape[i]); yLevel++;
            }
        }
    }
    drawWhenScrollAlignLeft(renderer: Communicator.Markup.MarkupRenderer) {
        this._textShape.getTextPortion().setFontSize(this._drawFontSize);
        this._textShape.getTextPortion().setFontFamily(this._drawFontFamily);
        const boxHeight = this._drawFontSize + this._textShape.getPadding() * 2 + 4;
        const context = document.createElement('canvas').getContext('2d') as any;
        context.font = String(this._drawFontSize) + 'px ' + this._drawFontFamily;

        let maxWidth = context.measureText(this._textShape.getTextString()).width;
        for (const i of this.textShape) {
            const shapeWidth = context.measureText(i.getTextString()).width;
            if (maxWidth < shapeWidth) {
                maxWidth = shapeWidth;
            }
        }
        this._textShape.setPadding(5 + (maxWidth - context.measureText(this._textShape.getTextString()).width) / 2);
        renderer.drawTextBox(this._textShape);
        const pos = this._textShape.getPosition();
        for (let i = 0; i < this.textShape.length; i++) {
            if (i === 0) {
                this.textShape[i].setPosition({ x: pos.x, y: pos.y + boxHeight * (i + 1) + 2 } as Communicator.Point2);
            }
            else {
                this.textShape[i].setPosition({
                    x: pos.x,
                    y: this.textShape[i - 1].getPosition().y + this._drawFontSize + (this.textShape[i - 1].getPadding() * 2 + 4)
                } as Communicator.Point2);
            }
            this.textShape[i].getTextPortion().setFontSize(this._drawFontSize);
            this.textShape[i].getTextPortion().setFontFamily(this._drawFontFamily);

            const shapeText = this.textShape[i].getTextString();
            const shapeWidth = context.measureText(shapeText).width;
            const gapPixel = (maxWidth - shapeWidth);
            this.textShape[i].setPadding(5 + gapPixel * 3 / 1.7);
        }
        this.drawExcludeValueZero(renderer);
    }
    // serialization methods

    /**
     * Creates an object ready for JSON serialization.
     * @returns The prepared object.
     */
    // tslint:disable-next-line: ban-types
    public toJson(): Object {
        return this._toJson();
    }

    private _toJson() {
        return {
            name: this._name,
            measurePoint1: this._positions[0].copy(),
            measurePoint2: this._positions[1].copy(),
            leaderPoint1: this._positions[3].copy(),
            leaderPoint2: this._positions[4].copy(),
            textPoint: this._positions[2].copy(),
            text: this._textShape.getTextString(),
            measurementValue: this._measurementValue,
            unitMultiplier: this._unitMultiplier,
            className: this.getClassName(),
        };
    }

    /** @deprecated Use [[toJson]] instead. */
    // tslint:disable-next-line: ban-types
    public forJson(): Object {
        return this.toJson();
    }

    /**
     * Creates a new [[MeasurePointPointDistanceMarkup]] from an object given by [[toJson]].
     * @param objData An object given by [[toJson]].
     * @returns The prepared object.
     */
    public static fromJson(objData: any, viewer: Communicator.WebViewer): ADMeasurePointPointDistanceMarkup {
        const obj = objData as ReturnType<ADMeasurePointPointDistanceMarkup['_toJson']>;

        const measurement = new ADMeasurePointPointDistanceMarkup(viewer);

        measurement._name = obj.name;

        measurement._positions[0] = Communicator.Point3.fromJson(obj.measurePoint1);
        measurement._positions[1] = Communicator.Point3.fromJson(obj.measurePoint2);
        measurement._positions[2] = Communicator.Point3.fromJson(obj.textPoint);
        measurement._textShape.setTextString(obj.text);
        measurement._positions[3] = Communicator.Point3.fromJson(obj.leaderPoint1);
        measurement._positions[4] = Communicator.Point3.fromJson(obj.leaderPoint2);

        measurement._measurementValue = obj.measurementValue;
        measurement._unitMultiplier = obj.unitMultiplier || 1;

        measurement._updateArrowsInverted();
        measurement._stage = 2;
        return measurement;
    }

    /** @deprecated Use [[fromJson]] instead. */
    public static construct(obj: any, viewer: Communicator.WebViewer): ADMeasurePointPointDistanceMarkup {
        return ADMeasurePointPointDistanceMarkup.fromJson(obj, viewer);
    }

    public getClassName(): string {
        return 'ADMeasurePointPointDistanceMarkup';
    }
}
