import { createSelector } from "@reduxjs/toolkit";
import { selectSelfSheet } from "./self.selector";

export const getSheetState = createSelector(
    selectSelfSheet,
    (state) => state
)
export const selectSheetDataActive = createSelector(
    selectSelfSheet,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => {
        const sheetActiveId = state.active[viewId];
        if (state.data[viewId]?.length > 0) {
            return state.data[viewId].find(s => s.id === sheetActiveId || s.viewId === sheetActiveId)
        }   
    }
)
export const selectSheetActive = createSelector(
    selectSelfSheet,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => {
        return state.active[viewId];
    }
)
export const getSheetData = createSelector(
    selectSelfSheet,
    (state) => state.data
)
