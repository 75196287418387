/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FileInfo, SaveView, Views, ViewType } from "common/define";
import { GlobalState } from "common/global";
import { CuttingPlaneState, SheetData } from "common/type-state";
import { from, Observable } from "rxjs";
import { Lodash } from "utils/utils";

type CuttingDataInfo = {
    data: unknown,
    state: CuttingPlaneState,
    cutted: boolean | undefined
}
/** functions */
async function initSyns(
    viewer: Communicator.WebViewer,
    view: SaveView,
): Promise<SaveView> {
    const id = Communicator.UUID.create();
    const htmlImage = await createSnapShot(viewer);
    view.thumnail = htmlImage.src;
    view.Id = id;
    view.uniqueId = id;
    const visibilityState = await viewer.model.getVisibilityState(viewer.model.getAbsoluteRootNode());
    view.visibilityState = visibilityState;
    return view;
}
function createSnapShot(viewer: Communicator.WebViewer): Promise<HTMLImageElement> {
    const { x: width, y: height } = viewer.view.getCanvasSize();
    const snapConfig = new Communicator.SnapshotConfig(
        width,
        height,
        Communicator.SnapshotLayer.Model,
    );
    return viewer.takeSnapshot(snapConfig);
}
async function activeCutting(viewer: Communicator.WebViewer, viewId: ViewId, cuttingCurrentState: CuttingPlaneState, cuttingPlane: CuttingDataInfo): Promise<CuttingPlaneState | undefined> {
    if (cuttingPlane && viewId) {
        const isCuttingCurrent = cuttingCurrentState.active && cuttingCurrentState.active?.length > 0;
        if (!isCuttingCurrent && !cuttingPlane.cutted) {
            return undefined;
        }
        const cuttingPlaneHelper = GlobalState.getCuttingPlaneHelper(viewId);
        if (!cuttingPlaneHelper) {
            viewer.cuttingManager.fromJson(cuttingPlane.data);
            return cuttingPlane.state;
        }
        
        await cuttingPlaneHelper.fromJson(cuttingPlane.data, cuttingPlane.state);
        return cuttingPlaneHelper.state;
    }
}
async function activeSaveView(viewer: Communicator.WebViewer, viewId: ViewId, saveView: SaveView, cuttingCurrentState: CuttingPlaneState): Promise<CuttingPlaneState | undefined> {
    try {
        const { drawMode, Camera, explodeMagnitude, CuttingPlane, } = saveView;
        drawMode >= 0 && viewer.view.setDrawMode(drawMode);
        const camera = Communicator.Camera.fromJson(Camera);
        viewer.view.setCamera(camera, Communicator.DefaultTransitionDuration);
        explodeMagnitude !== undefined && viewer.explodeManager.setMagnitude(explodeMagnitude);
        const newCuttingState = await activeCutting(viewer, viewId, cuttingCurrentState, CuttingPlane);
        return newCuttingState
    } catch (error) {
        return undefined
    }
}
function getCuttingData(viewer: Communicator.WebViewer, cuttingState: CuttingPlaneState): CuttingDataInfo {
    const data = viewer.cuttingManager.toJson();
    const state = { ...cuttingState };
    return {
        data,
        state,
        cutted: state.active && state.active.length > 0
    }
}

export default class ViewsHelper {
    static getViews(viewId: ViewId): Views | undefined {
        const viewIdFinal = GlobalState.getViewId(viewId);
        return GlobalState.mapViews.get(viewIdFinal)
    }
    static setViews(viewId: ViewId, views: Views): void {
        const viewIdFinal = GlobalState.getViewId(viewId);
        GlobalState.mapViews.set(viewIdFinal, views)
    }
    static getViewSelected(viewId: ViewId): string | undefined {
        const viewIdFinal = GlobalState.getViewId(viewId);
        return GlobalState.mapViewsSelected.get(viewIdFinal)
    }
    static setViewSelected(viewId: ViewId, viewSelected: string): void {
        const viewIdFinal = GlobalState.getViewId(viewId);
        GlobalState.mapViewsSelected.set(viewIdFinal, viewSelected)
    }
    static createSaveView(viewId: string, saveView: SaveView): Views {
        const viewIdFinal = GlobalState.getViewId(viewId);
        const preViews = GlobalState.mapViews.get(viewIdFinal);
        let newViews: Views;
        if (!preViews) {
            newViews = {
                cadViews: [],
                savedViews: [saveView]
            };
            GlobalState.mapViews.set(viewIdFinal, newViews);
        } else {
            newViews = Lodash.cloneDeep(preViews);
            newViews.savedViews = [...newViews.savedViews, saveView];
            GlobalState.mapViews.set(viewIdFinal, newViews);
        }
        GlobalState.subjectCreateSaveView.next(saveView.uniqueId)
        return newViews
    }
    static getSaveViewById(idSaveView: string, viewId: ViewId): SaveView | undefined {
        const views = ViewsHelper.getViews(viewId);
        if (views) {
            const saveViews = views.savedViews;
            if (saveViews.length > 0) {
                return saveViews.find(s => s.Id === idSaveView);
            }
        }
    }
    static updateSaveView(viewId: string, newView: SaveView): Views | undefined {
        const views = ViewsHelper.getViews(viewId);
        if (views) {
            const saveViews = views.savedViews;
            if (saveViews.length > 0) {
                const cloneViews = Lodash.cloneDeep(views);
                const indexOldView = cloneViews.savedViews.findIndex(s => s.Id === newView.Id);
                if (indexOldView !== -1) {
                    cloneViews.savedViews.splice(indexOldView, 1, newView);
                    ViewsHelper.setViews(viewId, cloneViews);
                    return cloneViews;
                }
            }
        }
        return undefined
    }
    static deleteView(viewId: string, idSaveView: string): Views | undefined {
        const views = ViewsHelper.getViews(viewId);
        if (views) {
            const saveViews = views.savedViews;
            if (saveViews.length > 0) {
                const cloneViews = Lodash.cloneDeep(views);
                const indexOldView = cloneViews.savedViews.findIndex(s => s.Id === idSaveView);
                if (indexOldView !== -1) {
                    cloneViews.savedViews.splice(indexOldView, 1);
                    ViewsHelper.setViews(viewId, cloneViews);
                    return cloneViews;
                }
            }
        }
    }
    static createCustomViewSaved$(
        viewer: Communicator.WebViewer,
        cuttingState: CuttingPlaneState,
        inputName: string,
        isolateMode: number,
        modelFileId: string,
        sheetId: number | string,
        userName?: string): Observable<SaveView> {
        const currentCamera = viewer.view.getCamera();
        const cameraJson = currentCamera.toJson();
        const name = inputName;
        const explodeMagnitude = viewer.explodeManager.getMagnitude();
        const createdDate = Date.now();
        const drawMode = viewer.view.getDrawMode();
        const createdBy = userName !== undefined ? userName : 'no user';
        const selectionManager = viewer.selectionManager;
        const arrSelected = selectionManager.getResultsExtends();
        const CuttingPlane = getCuttingData(viewer, cuttingState);
        const customView: SaveView = {
            Name: name,
            SheetId: sheetId,
            NodesShow: [],
            NodesHide: [],
            NodeSetting: '',
            Camera: cameraJson,
            CuttingPlane,
            explodeMagnitude,
            viewType: ViewType.SaveView,
            isolateMode,
            createdDate,
            modifiedDate: createdDate,
            drawMode,
            createdBy,
            ModelFileId: modelFileId,
            arrSelected,
            visibilityState: new Communicator.VisibilityState(true, new Set()),
        };
        return from(initSyns(viewer, customView));
    }
    static activeSaveView$(saveViewId: string, viewId: ViewId, cuttingCurrentState: CuttingPlaneState): Observable<CuttingPlaneState | undefined> {
        const webViewer = GlobalState.getViewer3D(viewId);
        const saveView = ViewsHelper.getSaveViewById(saveViewId, viewId);
        if (webViewer && saveView) {
            return from(activeSaveView(webViewer, viewId, saveView, cuttingCurrentState))
        }
        return from([undefined])
    }

    static checkView3DWhenSelectedCadView$(
        viewId: string,
        dataSheet: { [viewId: string]: SheetData[] },
        filesOrigin: FileInfo[]
    ): Observable<{ sheetData: SheetData | undefined, viewId: ViewId } | undefined> {
        return new Observable<{ sheetData: SheetData | undefined, viewId: ViewId }>(subscriber => {
            const ViewIdModelTree = filesOrigin.find(f => f.isRootModel !== 0)?.viewId;
            if (ViewIdModelTree && ViewIdModelTree !== viewId) {
                const dataSheetByViewId = dataSheet[viewId];
                const sheetData = dataSheetByViewId.find(s => s.viewId === ViewIdModelTree);
                const data = {
                    viewId: ViewIdModelTree,
                    sheetData
                };
                subscriber.next(data);
            } else {
                subscriber.next(undefined);
            }
            subscriber.complete();
        });
    }

    static checkOpenFileMergeWhenSelectedSaveView$(
        saveViewId: string,
        viewId: string,
        dataSheet: { [viewId: string]: SheetData[] },
        filesOrigin: FileInfo[]
    ): Observable<{ sheetData: SheetData | undefined, viewId: ViewId } | undefined> {
        return new Observable<{ sheetData: SheetData | undefined, viewId: ViewId }>(subscriber => {
            const saveView = ViewsHelper.getSaveViewById(saveViewId, viewId);
            if (saveView) {
                const viewIdFinal = GlobalState.getViewId(viewId);
                const dataSheetByViewId = dataSheet[viewIdFinal];
                const sheetData = dataSheetByViewId.find(s => s.id === saveView.SheetId);
                // if (!sheetData) {
                //     const ViewIdModelTree = filesOrigin.find(f => f.isRootModel !== 0)?.viewId;
                //     if (ViewIdModelTree && ViewIdModelTree !== viewId) {
                //         sheetData = dataSheetByViewId.find(s => s.viewId === ViewIdModelTree);
                //     }
                // }
                const fileInfoSheetMerge = filesOrigin.find(f => f.modelFileId === saveView.ModelFileId);
                const data = {
                    viewId: fileInfoSheetMerge ? fileInfoSheetMerge.viewId : '',
                    sheetData
                };
                subscriber.next(data);
            } else {
                subscriber.next(undefined);
            }
            subscriber.complete()
        })
    }
    static filterDataByModelFileId(modelFileId: ModelFileId, data: Views): Views {
        const { cadViews = [], savedViews = [] } = data;
        const newViews: Views = {
            cadViews: cadViews.filter(cad => cad.ModelFileId === modelFileId),
            savedViews: savedViews.filter(save => save.ModelFileId === modelFileId)
        }
        return newViews
    }
}
