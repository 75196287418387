/* eslint-disable prefer-const */
/* eslint-disable indent */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BimApi } from "api/bim.api";
import { AnnotActiveResult } from "common/define-foxit";
import { GlobalState } from "common/global";
import { AnnotInfoExtra, AnnotJson, FillerMarkupViewEntity, MarkupEntity, MarkupViewEntities, NoteEntity, ResponseMarkups, ViewMode3D } from "common/type-markup";
import { RootEpic } from "common/type-state";
import PdfHelper from "container/pdf-viewer/helper/pdf.helper";
import { cloneDeep } from "lodash";
import { merge } from "rxjs";
import { catchError, filter, switchMap, withLatestFrom } from "rxjs/operators";
import { setEnableSaveBtn } from "./markupToolbar.slice";
interface MarkupActionState {
    markupMode: ViewMode3D;
    isFetching: boolean;
    listEntities: MarkupEntity[];
    savedMarkup: ResponseMarkups;
    savedEntities: MarkupEntity[];
    unsavedEntities: MarkupEntity[];
    rightPointClick: Point2,
    isCopy: boolean,
    listMarkupsSelected: string[];
    listMarkupViews: MarkupViewEntities[];
    filterMarkupView: FillerMarkupViewEntity;
    markupViewSelected: string | null;
    viewActiveRect: DOMRect | null;
    triggerUndoRedoUpdate: boolean;
}
const initState: MarkupActionState = {
    markupMode: 'viewMode',
    isFetching: false,
    listEntities: [],
    savedMarkup: {
        listGroups: [],
        markupEntities: [],
        markupViews: []
    },
    savedEntities: [],
    unsavedEntities: [],
    rightPointClick: { x: 0, y: 0 },
    isCopy: false,
    listMarkupsSelected: [],
    listMarkupViews: [],
    filterMarkupView: {createdBy: 'All', startDate: undefined, endDate: undefined, isFilter: false},
    markupViewSelected: null,
    viewActiveRect: null,
    triggerUndoRedoUpdate: false,
}
const markupActionSlice = createSlice({
    name: 'markupAction',
    initialState: initState,
    reducers: {
        setMarkupMode(state, action: PayloadAction<ViewMode3D>) { return },
        applyMarkupMode(state, action: PayloadAction<ViewMode3D>) {
            state.markupMode = action.payload;
            GlobalState.markupMode = action.payload;
        },
        saveMarkups(state, action: PayloadAction<MarkupViewEntities | undefined>) { return },
        fetchMarkups(state, action: PayloadAction<undefined>) { return },
        selectPDFMarkups(state, action: PayloadAction<AnnotActiveResult>) { return },
        updatePDFMarkups(state, action: PayloadAction<AnnotJson>) { return },
        deletePDFMarkups(state, action: PayloadAction<undefined>) { return },
        saveNoteMarkup(state, action: PayloadAction<NoteEntity>) { return },
        setMarkupFetching(state, action: PayloadAction<boolean>) {
            state.isFetching = action.payload;
        },
        setListEntities(state, action: PayloadAction<MarkupEntity[]>) {
            state.listEntities = action.payload;
        },
        setSavedMarkup(state, action: PayloadAction<ResponseMarkups>) {
            state.savedMarkup = action.payload;
            state.isFetching = false;
            state.savedEntities = action.payload.markupEntities;
        },
        setSavedEntities(state, action: PayloadAction<MarkupEntity[]>) {
            state.savedEntities = action.payload;
        },
        setUnsavedEntities(state, action: PayloadAction<MarkupEntity[]>) {
            state.unsavedEntities = action.payload;
        },
        setRightPointClick: (state, action: PayloadAction<Point2>) => {
            state.rightPointClick = { ...action.payload };
        },
        setIsCopy: (state, action: PayloadAction<boolean>) => {
            state.isCopy = action.payload;
        },
        setListMarkupsSelected(state, action: PayloadAction<string[]>) {
            state.listMarkupsSelected = action.payload;
        },
        setListMarkupViews(state, action: PayloadAction<MarkupViewEntities[]>) {
            state.listMarkupViews = action.payload;
            state.isFetching = false;
        },
        setFilterMarkupView(state, action: PayloadAction<FillerMarkupViewEntity>) {
            state.filterMarkupView = action.payload;
            state.isFetching = false;
        },
        deleteMarkupView(state, action: PayloadAction<string>) { return },
        updateMarkupView(state, action: PayloadAction<MarkupViewEntities>) {
            const listMarkupViews = state.listMarkupViews? [...state.listMarkupViews] : [];
            // eslint-disable-next-line array-callback-return
            listMarkupViews.find(v => {
                if (v.uniqueId === action.payload.uniqueId) {
                    v = action.payload;
                }
            });
            state.listMarkupViews = listMarkupViews;
            return
        },
        setMarkupViewSelected(state, action: PayloadAction<string | null>) { return },
        applyMarkupViewSelected(state, action: PayloadAction<string | null>) {
            state.markupViewSelected = action.payload;
        },
        setViewActiveRect(state, action: PayloadAction<DOMRect | null>) {
            state.viewActiveRect = action.payload;
        },
        setTriggerUndoRedoUpdate(state, action: PayloadAction<boolean>) {
            state.triggerUndoRedoUpdate = action.payload;
        },
    }
})

const fetchMarkups$: RootEpic = (action$, state$) => action$.pipe(
    filter(fetchMarkups.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        const viewer = GlobalState.getViewer3D(viewActive.viewId);
        const { username } = state.bootstrap.systemConfig;
        switch (viewActive.formatViewer) {
            case 'Hoops':
                return merge(
                    [setMarkupFetching(true)],
                    BimApi.getAllMarkupView(viewActive.modelFileId).pipe(
                        switchMap(value => {
                            if (value) {
                                let data: MarkupViewEntities[] = JSON.parse(value);
                                data = data.filter((item) => item?.lastModifiedBy?.userName === username || item?.viewStatus)
                                if (viewer) {
                                    const markupData = {
                                        views: data,
                                        notes: [],
                                        measurement: [],
                                        lines: [],
                                    };
                                    viewer.markupManager.loadMarkupData(markupData);
                                }
                                return [setListMarkupViews(data)]
                            }
                            return [setMarkupFetching(false)];
                        }),
                        catchError(err => [setMarkupFetching(false)])
                    ),

                );
            default:
                return merge(
                    [setMarkupFetching(true)],
                    BimApi.getMarkups(viewActive.modelFileId).pipe(
                        switchMap(value => {
                            if (value) {
                                const data: ResponseMarkups = JSON.parse(value);
                                return [setSavedMarkup(data)]
                            }
                            return [];
                        }),
                        catchError(err => [setMarkupFetching(false)])
                    )
                )
        }

    })
)

const saveMarkups$: RootEpic = (action$, state$) => action$.pipe(
    filter(saveMarkups.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        setEnableSaveBtn(false);
        if (viewActive.formatViewer === 'Hoops') {
            const data = action.payload;
            if (!data) return [setEnableSaveBtn(false)];
            return merge(
                BimApi.updateMarkupView(data).pipe(
                    switchMap(val => {
                        return [
                            setEnableSaveBtn(false),
                            fetchMarkups(),
                        ]
                    }),
                    catchError(err => [setEnableSaveBtn(false)])
                )
            );
        } else {
            const currPage = state.pdfViewer.currentPageIndex[viewActive.viewId];
            const pdfViewer = GlobalState.getPdfViewer(viewActive.viewId);
            const allMarkupEntities: MarkupEntity[] = [...state.markup.unsavedEntities, ...state.markup.savedEntities].reduce((unique: MarkupEntity[], o: MarkupEntity) => {
                if (!unique.some(obj => obj.uniqueId === o.uniqueId)) {
                    unique.push(o);
                }
                return unique;
            }, []);
            let allMarkupClone: MarkupEntity[] = cloneDeep(allMarkupEntities);
            if (pdfViewer) {
                const { annotsRender } = pdfViewer.getPDFPageRender(currPage);
                annotsRender.unActiveAnnot();
            }
            const data: ResponseMarkups = {
                listGroups: [],
                markupEntities: allMarkupClone,
                markupViews: [],
            };
            return merge(
                BimApi.saveMarkups(viewActive.modelFileId, data).pipe(
                    switchMap(val => {
                        return [
                            setSavedEntities([]),
                            setUnsavedEntities([]),
                            setEnableSaveBtn(false),
                            fetchMarkups(),
                        ]
                    }),
                    catchError(err => [setEnableSaveBtn(false)])
                ),
                [setEnableSaveBtn(false)]
            );

        }
    })
)
// Foxit PDF

const selectPDFMarkups$: RootEpic = (action$, state$) => action$.pipe(
    filter(selectPDFMarkups.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { id, active }: AnnotActiveResult = action.payload;
        const { listMarkupsSelected } = state.markup;
        let newlistMarkupsSelected: string[] = listMarkupsSelected;
        if (active) {
            newlistMarkupsSelected = [...newlistMarkupsSelected, ...[id]];
        } else {
            newlistMarkupsSelected = newlistMarkupsSelected.filter(v => v !== id);
        }
        return [
            setListMarkupsSelected(newlistMarkupsSelected)
        ]
    })

)

const updatePDFMarkups$: RootEpic = (action$, state$) => action$.pipe(
    filter(updatePDFMarkups.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const annotJson = action.payload;
        const matchSaved = state.markup.savedEntities.find(v => v.uniqueId === annotJson.name);
        // eslint-disable-next-line prefer-const
        let savedClone = cloneDeep(state.markup.savedEntities);
        if (matchSaved) {
            savedClone.forEach(v => {
                if (v.uniqueId === matchSaved.uniqueId) {
                    const extraAnnotInfo: AnnotInfoExtra = {
                        iconName: matchSaved.originData.iconName,
                        shapeName: matchSaved.originData.shapeName,
                        markupMode: matchSaved.originData.markupMode,
                    }
                    v.originData = Object.assign(annotJson, extraAnnotInfo);
                    v.modifiedDate = new Date().toISOString();
                }
            })
        }
        const matchUnsaved = state.markup.unsavedEntities.find(v => v.uniqueId === annotJson.name);
        // eslint-disable-next-line prefer-const
        let unsavedClone = cloneDeep(state.markup.unsavedEntities);
        if (matchUnsaved) {
            unsavedClone.forEach(v => {
                if (v.uniqueId === matchUnsaved.uniqueId) {
                    const extraAnnotInfo: AnnotInfoExtra = {
                        iconName: matchUnsaved.originData.iconName,
                        shapeName: matchUnsaved.originData.shapeName,
                        markupMode: matchUnsaved.originData.markupMode,
                    }
                    v.originData = Object.assign(annotJson, extraAnnotInfo);
                    v.modifiedDate = new Date().toISOString();
                }
            })
        }
        return merge(
            [setUnsavedEntities(unsavedClone)],
            [setSavedEntities(savedClone)],
            [setEnableSaveBtn(true)]
        );
    })
)
const deletePDFMarkups$: RootEpic = (action$, state$) => action$.pipe(
    filter(deletePDFMarkups.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        const pdfViewer = GlobalState.getPdfViewer(viewActive.viewId);
        const { savedEntities, unsavedEntities, listMarkupsSelected } = state.markup;
        const allPdfMarkups = [...savedEntities, ...unsavedEntities];
        const markupsFiltered = allPdfMarkups.filter(v => {
            const isSelected = listMarkupsSelected.includes(v.uniqueId);
            if (isSelected) {
                pdfViewer && PdfHelper.removeAnnotByID(pdfViewer, v.originData.page, v.uniqueId)
            }
            return !isSelected;
        });
        const data: ResponseMarkups = {
            listGroups: [],
            markupEntities: markupsFiltered,
            markupViews: [],
        };
        return merge(
            BimApi.saveMarkups(viewActive.modelFileId, data).pipe(
                switchMap(val => {
                    return merge(
                        [setUnsavedEntities([])],
                        [setListMarkupsSelected([])],
                        [fetchMarkups()]
                    )
                })),
        )
    })
)

const saveNoteMarkup$: RootEpic = (action$, state$) => action$.pipe(
    filter(saveNoteMarkup.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const noteSaving = action.payload;
        let savedClone = cloneDeep(state.markup.savedEntities);
        savedClone.forEach(v => {
            if (v.uniqueId === noteSaving.annotId) {
                v.originData.content = noteSaving.content;
            }
        })
        let unsavedClone = cloneDeep(state.markup.unsavedEntities);
        unsavedClone.forEach(v => {
            if (v.uniqueId === noteSaving.annotId) {
                v.originData.content = noteSaving.content;
            }
        })
        return merge(
            [setUnsavedEntities(unsavedClone)],
            [setSavedEntities(savedClone)],
            [setEnableSaveBtn(true)],
        );
    })
)

const deleteMarkupView$: RootEpic = (action$, state$) => action$.pipe(
    filter(deleteMarkupView.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const markupViewId = action.payload;
        const modelFileId = state.multiViewer.viewActive.modelFileId;
        return merge(
            [setMarkupFetching(true)],
            BimApi.deleteView(markupViewId, modelFileId).pipe(
                switchMap(val => {
                    return [
                        fetchMarkups(),
                        setMarkupFetching(false)
                    ]
                }),
                catchError(e => [setMarkupFetching(false)])
            )
        )
    })
)

const updateMarkupView$: RootEpic = (action$, state$) => action$.pipe(
    filter(updateMarkupView.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const data = action.payload;
        return merge(
            [setMarkupFetching(true)],
            BimApi.updateMarkupView(data).pipe(
                switchMap(val => {
                    return [
                        fetchMarkups(),
                        setMarkupFetching(false)
                    ]
                }),
                catchError(e => [setMarkupFetching(false)])
            )
        )
    })
)

const setMarkupMode$: RootEpic = (action$, state$) => action$.pipe(
    filter(setMarkupMode.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        const newMode = action.payload;
        GlobalState.selectionEvent = true;
        GlobalState.mapMarkupMode.set(viewActive.viewId, newMode);
        return [applyMarkupMode(newMode)];
    }))

const setMarkupViewSelected$: RootEpic = (action$, state$) => action$.pipe(
    filter(setMarkupViewSelected.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        const markupViewSelected = action.payload;
        GlobalState.mapMarkupViewSelected.set(viewActive.viewId, markupViewSelected);
        return [applyMarkupViewSelected(markupViewSelected)];
    }))
export const MarkupActionEpics = [
    saveMarkups$,
    fetchMarkups$,
    selectPDFMarkups$,
    updatePDFMarkups$,
    deletePDFMarkups$,
    saveNoteMarkup$,
    deleteMarkupView$,
    updateMarkupView$,
    setMarkupMode$,
    setMarkupViewSelected$
]
export const {
    saveMarkups,
    fetchMarkups,
    setMarkupFetching,
    setListEntities,
    setSavedMarkup,
    setSavedEntities,
    setUnsavedEntities,
    setRightPointClick,
    setIsCopy,
    setMarkupMode,
    selectPDFMarkups,
    updatePDFMarkups,
    deletePDFMarkups,
    saveNoteMarkup,
    setListMarkupsSelected,
    setListMarkupViews,
    setFilterMarkupView,
    deleteMarkupView,
    updateMarkupView,
    setMarkupViewSelected,
    applyMarkupMode,
    setViewActiveRect,
    setTriggerUndoRedoUpdate,
    applyMarkupViewSelected,
} = markupActionSlice.actions;

export default markupActionSlice.reducer;
