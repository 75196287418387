import { CommandType, CommandValue, KeysGroup, Priority } from "./type";

export default class KeyCommand implements CommandValue {
    /**
     * Is type string
     */
    cmdType: CommandType
    /**
     * Key group => Is a object key and modified of KeyCode
     */
    keyFinal: KeysGroup | KeysGroup[] = {}
    /**
     * Key group => Is a object key and modified of KeyCode
     */
    get keyLast(): KeysGroup | KeysGroup[] | undefined { return this.keyLastPrivate }
    /**
     * boolean || function return boolean
     */
    when: boolean | (() => boolean) = true
    /**
     * As a number, the larger number takes precedence
     */
    priority: Priority = 1
    description = ''
    preventAndStopPropagation?: boolean = true
    constructor(partial: Partial<CommandValue>) {
        this.cmdType = 'none';
        this.update(partial)
    }

    cmdExc: (() => void | boolean) | (() => Promise<void | boolean>) = () => { return }
    update(val: Partial<CommandValue>): void {
        val.cmdType && (this.cmdType = val.cmdType);
        if (val.keyFinal) {
            this.keyFinal = val.keyFinal;
            this.keyLastPrivate === undefined && this.setKeyLast(val.keyFinal)
        }
        val.keyFinal && (this.keyFinal = val.keyFinal);
        val.when !== undefined && (this.when = val.when);
        val.priority && (this.priority = val.priority);
        val.description && (this.description = val.description);
        val.preventAndStopPropagation !== undefined && (this.preventAndStopPropagation = val.preventAndStopPropagation);
        val.cmdExc && (this.cmdExc = val.cmdExc);
    }

    setKeyLast(keys: KeysGroup | KeysGroup[]): void {
        this.keyLastPrivate = keys
    }
    restoreDefault(): void {
        this.keyLastPrivate = undefined;
    }

    // private
    private keyLastPrivate: KeysGroup | KeysGroup[] | undefined = undefined
}