import { innerPad } from 'utils/utils';
import { MarkupCalloutItem } from '../markup-items/markup.callout.item';
import { MarkupBaseOperator } from './markup.base.operator';
export class MarkupCalloutOperator extends MarkupBaseOperator {
    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.cursorSprite = true;
    }
    public createMarkupItem(position: Communicator.Point2): void {
        const view = this._viewer.view;
        const initSize = new Communicator.Point2(
            this._textFontSize * 10 + innerPad.x * 2,
            this._textFontSize * 2 + innerPad.y * 2);
        this._markupItem = new MarkupCalloutItem(this._viewer, initSize);
        this._markupItem.setLineFormat(this._lineColor, this._lineWeight, this._lineOpacity, this._fillColor, this._fillColorOption, this._lineStyle);
        this._markupItem.setTextFormat(this._textFontSize, this._textColor, this._textFontFamily, this._textIsBold, this._textIsItalic, this._textIsUnderline);
        const itemId = this._viewer.markupManager.registerMarkup(this._markupItem);
        this._markupItem.setMarkupItemId(itemId);
        const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(position, view);
        if (cameraPoint !== null) {
            (this._markupItem as MarkupCalloutItem).setFirstPoint(cameraPoint);
            (this._markupItem as MarkupCalloutItem).setSecondPoint(cameraPoint);
        }
    }

    public updateMarkupItem(position: Communicator.Point2): void {
        if (this._markupItem) {
            const view = this._viewer.view;
            const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(position, view);
            if (cameraPoint !== null) {
                (this._markupItem as MarkupCalloutItem).setSecondPoint(cameraPoint);
                this._viewer.markupManager.refreshMarkup();
            }
        }
    }
}

