/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    IPinMarkerData,
    MarkupGroupItem,
    MarkupToolbarAction,
    NameMarkupItem,
    NotepinCategory
} from 'common/define';
import { GlobalState } from 'common/global';
import { MarkupEntity, MarkupPinMarkerJson, Point3D, ViewMode3D } from 'common/type-markup';
import MathHelper from 'container/pdf-viewer/helper/math.helper';
import { BehaviorSubject } from 'rxjs';
import ModelHelper from '../model/model.helper';
import { MarkupPinMarkerItem } from './markup-3d/markup.pinmarker.item';
import { MarkupArrowItem } from './markup-items/markup.arrow.item';
import { MarkupBaseItem } from './markup-items/markup.base.item';
import { MarkupCalloutItem } from './markup-items/markup.callout.item';
import { MarkupCircleItem } from './markup-items/markup.circle.item';
import { MarkupCloudItem } from './markup-items/markup.cloud.item';
import { MarkupEllipseItem } from './markup-items/markup.ellipse.item';
import { MarkupLineItem } from './markup-items/markup.line.item';
import { MarkupNoteItem } from './markup-items/markup.note.item';
import { MarkupPolygonItem } from './markup-items/markup.polygon.item';
import { MarkupPolylineItem } from './markup-items/markup.polyline.item';
import { MarkupRectangleItem } from './markup-items/markup.rectangle.item';
import { MarkupSignatureItem } from './markup-items/markup.signature.item';
import { MarkupTextBoxItem } from './markup-items/markup.textbox.item';
import { MarkupBaseOperator } from './markup-operators/markup.base.operator';
import { MarkupSelectionOperator } from './markup-operators/markup.selection.operator';
import { MarkupCopyPaste } from './markup.copy-paste';
import { MarkupData } from './markup.data';
import { MarkupEdit } from './markup.edit';
import { MarkupInsert } from './markup.insert';
import { MarkupSelecion } from './markup.selection';
import { MarkupUndoRedo } from './markup.undo.redo';

type LoadElementFunc = (element: MarkupEntity) => MarkupBaseItem | undefined;

export class MarkupAction {
    private viewer: Communicator.WebViewer | null;
    public markupData: MarkupData | null;
    public markupInsert: MarkupInsert | null;
    public markupEdit: MarkupEdit | null;
    public markupUndoRedo: MarkupUndoRedo | null;
    public markupSelection: MarkupSelecion | null;
    public currentOperators: Communicator.OperatorId[] = [];
    public markupCopyPaste: MarkupCopyPaste | null;
    public markupMode: ViewMode3D = 'viewMode';
    public modelFileId = "";
    public markupItems$: BehaviorSubject<MarkupBaseItem[]>;
    public markupNotepins$: BehaviorSubject<MarkupBaseItem[]>;
    public markupPinMarker$: BehaviorSubject<MarkupBaseItem[]>;
    public markupGroups$: BehaviorSubject<MarkupGroupItem[]>;
    public markupUnsaved$: BehaviorSubject<MarkupEntity[]>;
    public currentActiveNotpin$: BehaviorSubject<MarkupBaseItem | undefined>;
    public currentActivePinMarker$: BehaviorSubject<MarkupBaseItem | undefined>;
    public loadElementMap: Record<NameMarkupItem, LoadElementFunc> = {
        MarkupBaseItem: (element: MarkupEntity) => undefined,
        Arrow: (element: MarkupEntity) => this.loadArrow(element),
        Circle: (element: MarkupEntity) => this.loadCircle(element),
        Ellipse: (element: MarkupEntity) => this.loadEllipse(element),
        Line: (element: MarkupEntity) => this.loadLine(element),
        Note: (element: MarkupEntity) => this.loadNote(element),
        Polygon: (element: MarkupEntity) => this.loadPolygon(element),
        Polyline: (element: MarkupEntity) => this.loadPolyline(element),
        Rectangle: (element: MarkupEntity) => this.loadRectangle(element),
        Freehand: (element: MarkupEntity) => this.loadSignature(element),
        'Text Box': (element: MarkupEntity) => this.loadTextBox(element),
        Callout: (element: MarkupEntity) => this.loadCallout(element),
        Cloud: (element: MarkupEntity) => this.loadCloud(element),
        "Note Pin": (element: MarkupEntity) => { return undefined },
        "Pin Marker": (element: MarkupEntity) => this.loadPinMarker(element),
    }
    private _activeDraw = false;
    public constructor(viewer: Communicator.WebViewer | null) {
        this.viewer = viewer;
        this.markupItems$ = new BehaviorSubject<MarkupBaseItem[]>([]);
        this.markupGroups$ = new BehaviorSubject<MarkupGroupItem[]>([]);
        this.markupUnsaved$ = new BehaviorSubject<MarkupEntity[]>([]);
        this.markupItems$ = new BehaviorSubject<MarkupBaseItem[]>([]);
        this.markupNotepins$ = new BehaviorSubject<MarkupBaseItem[]>([]);
        this.currentActiveNotpin$ = new BehaviorSubject<MarkupBaseItem | undefined>(undefined);
        this.markupPinMarker$ = new BehaviorSubject<MarkupBaseItem[]>([]);
        this.currentActivePinMarker$ = new BehaviorSubject<MarkupBaseItem | undefined>(undefined);

        this.markupData = new MarkupData(
            this.markupItems$,
            this.markupGroups$,
            this.markupUnsaved$,
            this.markupNotepins$,
            this.currentActiveNotpin$,
            this.markupPinMarker$,
            this.currentActivePinMarker$);
        this.markupSelection = new MarkupSelecion(viewer, this);
        this.markupUndoRedo = new MarkupUndoRedo(viewer, this.markupData);
        this.markupInsert = new MarkupInsert(viewer, this);
        this.markupEdit = new MarkupEdit(viewer, this.markupData, this.markupSelection, this.markupUndoRedo);
        this.markupCopyPaste = new MarkupCopyPaste(viewer, this.markupData, this);
    }
    setActiveDrawingState(active: boolean): void {
        this._activeDraw = active;
    }
    getActiveDrawingState(): boolean {
        return this._activeDraw;
    }
    setMarkupMode(mode: ViewMode3D): void {
        if (this.viewer) {
            this.markupMode = mode;
            const operatorStack: Communicator.OperatorId[] = [];
            while (this.viewer.operatorManager.size() > 0) {
                const id = this.viewer.operatorManager.peek();
                operatorStack.push(id);
                this.viewer.operatorManager.pop();
            };
            if (operatorStack.length > 0) this.currentOperators = operatorStack; // store the currently operators
            if (mode !== 'viewMode') {
                this.viewer.operatorManager.push(Communicator.OperatorId.None);
                this.viewer.operatorManager.push(Communicator.OperatorId.Select);
                if (this.markupSelection && mode === 'editMode') { // only allow to select markup in edit mode
                    const isDrawing = GlobalState.mapActiveDraw.get(this.viewer)
                    if (isDrawing) {
                        this.currentOperators.reverse().forEach(v => {
                            const ope = this.viewer?.operatorManager.getOperator(v);
                            if (!(ope instanceof MarkupBaseOperator || ope instanceof MarkupSelectionOperator)) return;
                            return this.viewer?.operatorManager.push(v);
                        });
                        ModelHelper.setCursorViewer(this.viewer, 'crosshair')
                    }
                    this.markupSelection.addSelectOperator();
                }
                this.setMarkupVisible(true);
            }
            else {
                this.setMarkupVisible(false);
                this.currentOperators.reverse().forEach(v => {
                    const ope = this.viewer?.operatorManager.getOperator(v);
                    if (ope instanceof MarkupSelectionOperator) return;
                    return this.viewer?.operatorManager.push(v);

                });
                this.viewer.operatorManager.push(Communicator.OperatorId.None);
                this.viewer.operatorManager.push(Communicator.OperatorId.Select);
                this.viewer.operatorManager.push(Communicator.OperatorId.Handle);
                this.viewer.operatorManager.push(Communicator.OperatorId.NavCube);
                this.viewer.operatorManager.push(Communicator.OperatorId.AxisTriad);
            }
        }
    }

    onClickToolbarAction(action: MarkupToolbarAction, secondaryParam?: NotepinCategory | IPinMarkerData | string | number): void {
        if (this.markupInsert && this.viewer) {
            const isEditAction = !(action === MarkupToolbarAction.Cancel || action === MarkupToolbarAction.Save);
            this.setActiveDrawingState(isEditAction);
            GlobalState.mapActiveDraw.set(this.viewer, !isEditAction);
            this.markupInsert.InsertShapes(action, secondaryParam);

        }
        GlobalState.activeOperator$.next(action)
    }

    setMarkupVisible(vis: boolean): void {
        if (this.viewer && this.markupData) {
            const markup = this.viewer.markupManager;
            if (this.markupData.markupItems && this.markupData.markupItems.length > 0) {
                for (let i = 0; i < this.markupData.markupItems.length; i++) {
                    if (this.markupData.markupItems[i] instanceof MarkupBaseItem) {
                        const item = this.markupData.markupItems[i] as MarkupBaseItem;
                        item.setVisible(vis);
                    }
                }
                markup.refreshMarkup();
            }
        }
    }

    cancelOperator(): void {
        this.setActiveDrawingState(false);
        GlobalState.activeOperator$.next(null);
        if (this.viewer) {
            const currPrimaryOperator = GlobalState.primaryOperator.get(this.viewer);
            const cursor = GlobalState.mapOperatorCursor.get(currPrimaryOperator ?? 'select');
            ModelHelper.setCursorViewer(this.viewer, cursor ?? 'default')
            const currentOperatorId = this.viewer.operatorManager.peek();
            if (currentOperatorId) {
                const currentOperator = this.viewer.operatorManager.getOperator(currentOperatorId);
                if (currentOperator instanceof MarkupBaseOperator) {
                    currentOperator.cancelOperator();
                    this.viewer.operatorManager.remove(currentOperator.getOperatorId());
                }
            }
        }
    }

    appendMarkupGroup(item: MarkupGroupItem): void {
        if (item && this.markupData) {
            const idx = this.markupData.markupGroups.findIndex(t => t === item);
            if (idx === -1) {
                this.markupData.markupGroups.push(item);
                this.markupData.updateMarkupGroup();
            }
        }
    }

    addSelectedItemToGroup(groupId: string): void {
        if (this.viewer && this.markupData) {
            if (this.markupData.markupItems && this.markupData.markupItems.length > 0) {
                for (let i = 0; i < this.markupData.markupItems.length; i++) {
                    if (this.markupData.markupItems[i].isSelected) {
                        this.markupData.markupItems[i].uniqueIdGroup = groupId;
                    }
                }
                this.markupData.updateMarkupItems();
            }
        }
    }
    addItemToGroup(uniqueID: string, groupId: string): void {
        if (this.viewer && this.markupData) {
            if (this.markupData.markupItems && this.markupData.markupItems.length > 0) {
                for (let i = 0; i < this.markupData.markupItems.length; i++) {
                    if (this.markupData.markupItems[i].uniqueId === uniqueID) {
                        this.markupData.markupItems[i].uniqueIdGroup = groupId;
                    }
                }
                this.markupData.updateMarkupItems();
            }
        }
    }
    loadArrow(entity: MarkupEntity): MarkupBaseItem | undefined {
        const element = entity.originData;
        if (this.viewer && this.markupData) {
            const markupItem = new MarkupArrowItem(this.viewer);
            this.loadCommonAttr(markupItem, element);
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            markupItem.setFirstPoint(MathHelper.parseDataToPoint3(element.startPoint));
            markupItem.setSecondPoint(MathHelper.parseDataToPoint3(element.endPoint));
            markupItem.uniqueIdGroup = element.uniqueIdGroup;
            markupItem.uniqueId = entity.uniqueId;
            this.markupData.markupItems.push(markupItem);
            this.markupData.updateMarkupItems();
            return markupItem;
        }
    }
    loadCircle(entity: MarkupEntity): MarkupBaseItem | undefined {
        const element = entity.originData;
        if (this.viewer && this.markupData) {
            const markupItem = new MarkupCircleItem(this.viewer);
            this.loadCommonAttr(markupItem, element);
            markupItem.setRotation(element.rotation);
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            markupItem.setPosition(MathHelper.parseDataToPoint3(element.point1));
            markupItem.setRadiusPoint(MathHelper.parseDataToPoint3(element.point2));
            markupItem.uniqueIdGroup = element.uniqueIdGroup;
            markupItem.uniqueId = entity.uniqueId;
            this.markupData.markupItems.push(markupItem);
            this.markupData.updateMarkupItems();
            return markupItem;
        }

    }
    loadEllipse(entity: MarkupEntity): MarkupBaseItem | undefined {
        const element = entity.originData;
        if (this.viewer && this.markupData) {
            const markupItem = new MarkupEllipseItem(this.viewer);
            this.loadCommonAttr(markupItem, element);
            markupItem.setRotation(element.rotation);
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            markupItem.setPosition(MathHelper.parseDataToPoint3(element.point1));
            markupItem.setRadiusPoint(MathHelper.parseDataToPoint3(element.point2));
            markupItem.uniqueIdGroup = element.uniqueIdGroup;
            markupItem.uniqueId = entity.uniqueId;
            this.markupData.markupItems.push(markupItem);
            this.markupData.updateMarkupItems();
            return markupItem;
        }
    }
    loadLine(entity: MarkupEntity): MarkupBaseItem | undefined {
        const element = entity.originData;
        if (this.viewer && this.markupData) {
            const markupItem = new MarkupLineItem(this.viewer);
            this.loadCommonAttr(markupItem, element);
            markupItem.setStartLineShape(element.startLineShapeType);
            markupItem.setEndLineShape(element.endLineShapeType);
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            markupItem.setFirstPoint(MathHelper.parseDataToPoint3(element.startPoint));
            markupItem.setSecondPoint(MathHelper.parseDataToPoint3(element.endPoint));
            markupItem.uniqueIdGroup = element.uniqueIdGroup;
            markupItem.uniqueId = entity.uniqueId;
            this.markupData.markupItems.push(markupItem);
            this.markupData.updateMarkupItems();
            return markupItem;
        }
    }
    loadNote(entity: MarkupEntity): MarkupBaseItem | undefined {
        const element = entity.originData;
        if (this.viewer && this.markupData) {
            const markupItem = new MarkupNoteItem(this.viewer);
            this.loadCommonAttr(markupItem, element);
            markupItem.setTextValue(element.textValue);
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            markupItem.setFirstPoint(MathHelper.parseDataToPoint3(element.startPoint));
            markupItem.setSecondPoint(MathHelper.parseDataToPoint3(element.endPoint));
            markupItem.uniqueIdGroup = element.uniqueIdGroup;
            markupItem.uniqueId = entity.uniqueId;
            this.markupData.markupItems.push(markupItem);
            this.markupData.updateMarkupItems();
            return markupItem;
        }
    }
    loadPolygon(entity: MarkupEntity): MarkupBaseItem | undefined {
        const element = entity.originData;
        if (this.viewer && this.markupData) {
            const view = this.viewer.view;
            const markupItem = new MarkupPolygonItem(this.viewer);
            this.loadCommonAttr(markupItem, element);
            markupItem.setRotation(element.rotation);
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            const points = element.points as Point3D[];
            points.forEach(val => {
                const point3 = new Communicator.Point3(val.x, val.y, val.z);
                const point2 = Communicator.Point2.fromPoint3(point3);
                const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(point2, view);
                cameraPoint && markupItem.addPoint(point3);
            })
            markupItem.uniqueIdGroup = element.uniqueIdGroup;
            markupItem.uniqueId = entity.uniqueId;
            this.markupData.markupItems.push(markupItem);
            this.markupData.updateMarkupItems();
            return markupItem;
        }
    }
    loadPolyline(entity: MarkupEntity): MarkupBaseItem | undefined {
        const element = entity.originData;
        if (this.viewer && this.markupData) {
            const view = this.viewer.view;
            const markupItem = new MarkupPolylineItem(this.viewer);
            this.loadCommonAttr(markupItem, element);
            markupItem.setRotation(element.rotation);
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            const points = element.points as Point3D[];
            points.forEach(val => {
                const point3 = new Communicator.Point3(val.x, val.y, val.z);
                const point2 = Communicator.Point2.fromPoint3(point3);
                const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(point2, view);
                cameraPoint && markupItem.addPoint(point3);
            })
            markupItem.uniqueIdGroup = element.uniqueIdGroup;
            markupItem.uniqueId = entity.uniqueId;
            this.markupData.markupItems.push(markupItem);
            this.markupData.updateMarkupItems();
            return markupItem;
        }
    }
    loadRectangle(entity: MarkupEntity): MarkupBaseItem | undefined {
        const element = entity.originData;
        if (this.viewer && this.markupData) {
            const markupItem = new MarkupRectangleItem(this.viewer);
            this.loadCommonAttr(markupItem, element);
            markupItem.setRotation(element.rotation);
            markupItem.setPoint1(MathHelper.parseDataToPoint3(element.point1));
            markupItem.setPoint2(MathHelper.parseDataToPoint3(element.point2));
            markupItem.uniqueIdGroup = element.uniqueIdGroup;
            markupItem.uniqueId = entity.uniqueId;
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            this.markupData.markupItems.push(markupItem);
            this.markupData.updateMarkupItems();
            return markupItem;
        }
    }
    loadSignature(entity: MarkupEntity): MarkupBaseItem | undefined {
        const element = entity.originData;
        if (this.viewer && this.markupData) {
            const view = this.viewer.view;
            const markupItem = new MarkupSignatureItem(this.viewer);
            this.loadCommonAttr(markupItem, element);
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            const points = element.points as Point3D[];
            points.forEach(val => {
                const point3 = new Communicator.Point3(val.x, val.y, val.z);
                const point2 = Communicator.Point2.fromPoint3(point3);
                const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(point2, view);
                cameraPoint && markupItem.addPoint(point3);
            })
            markupItem.uniqueIdGroup = element.uniqueIdGroup;
            markupItem.uniqueId = entity.uniqueId;
            this.markupData.markupItems.push(markupItem);
            this.markupData.updateMarkupItems();
            return markupItem;
        }
    }
    loadTextBox(entity: MarkupEntity): MarkupBaseItem | undefined {
        const element = entity.originData;
        if (this.viewer && this.markupData) {
            const markupItem = new MarkupTextBoxItem(this.viewer);
            this.loadCommonAttr(markupItem, element);
            markupItem.setTextContent(element.textContent);
            markupItem.setRotation(element.rotation);
            markupItem.setPoint1(MathHelper.parseDataToPoint3(element.point1));
            markupItem.setPoint2(MathHelper.parseDataToPoint3(element.point2));
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            markupItem.uniqueIdGroup = element.uniqueIdGroup;
            markupItem.uniqueId = entity.uniqueId;
            this.markupData.markupItems.push(markupItem);
            this.markupData.updateMarkupItems();
            this.viewer.markupManager.refreshMarkup();
            return markupItem;
        }
    }
    loadCallout(entity: MarkupEntity): MarkupBaseItem | undefined {
        const element = entity.originData;
        if (this.viewer && this.markupData) {
            const markupItem = new MarkupCalloutItem(this.viewer, element.textBoxSize);
            this.loadCommonAttr(markupItem, element);
            markupItem.setTextContent(element.textContent);
            markupItem.setFirstPoint(MathHelper.parseDataToPoint3(element.point1));
            markupItem.setSecondPoint(MathHelper.parseDataToPoint3(element.point2));
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            markupItem.uniqueIdGroup = element.uniqueIdGroup;
            markupItem.uniqueId = entity.uniqueId;
            this.markupData.markupItems.push(markupItem);
            this.markupData.updateMarkupItems();
            this.viewer.markupManager.refreshMarkup();
            return markupItem;
        }
    }

    loadCloud(entity: MarkupEntity): MarkupBaseItem | undefined {
        const element = entity.originData;
        if (this.viewer && this.markupData) {
            const markupItem = new MarkupCloudItem(this.viewer);
            this.loadCommonAttr(markupItem, element);
            markupItem.setRotation(element.rotation);
            markupItem.setPoint1(MathHelper.parseDataToPoint3(element.point1));
            markupItem.setPoint2(MathHelper.parseDataToPoint3(element.point2));
            markupItem.uniqueIdGroup = element.uniqueIdGroup;
            markupItem.uniqueId = entity.uniqueId;
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            this.markupData.markupItems.push(markupItem);
            this.markupData.updateMarkupItems();
            return markupItem;
        }
    }

    loadPinMarker(entity: MarkupEntity): MarkupBaseItem | undefined {
        const element = entity.originData as MarkupPinMarkerJson;
        if (this.viewer && this.markupData) {
            const markupItem = new MarkupPinMarkerItem(this.viewer, element.pinMarkerData);
            markupItem.setFirstPoint(MathHelper.parseDataToPoint3(element.startPoint));
            markupItem.uniqueId = entity.uniqueId;
            markupItem.markupView = entity.originData.markupView;
            const itemId = this.viewer.markupManager.registerMarkup(markupItem);
            markupItem.setMarkupItemId(itemId);
            this.markupData.markupPinMarker.push(markupItem);
            this.markupData.updateMarkupPinMarker();
            return markupItem;
        }
    }

    loadCommonAttr(markupItem: MarkupBaseItem, element: any) {
        const lineAttr = ['lineColor', 'lineWeight', 'lineOpacity', 'fillColor', 'fillColorOption', 'lineStyle'];
        const textAttr = ['textFontSize', 'textColor', 'textFontFamily', 'textIsBold', 'textIsItalic', 'textIsUnderline'];
        if (lineAttr.some(attr => element[attr]) || markupItem instanceof MarkupTextBoxItem) {
            markupItem.setLineFormat(
                element.lineColor,
                element.lineWeight,
                element.lineOpacity,
                element.fillColor,
                element.fillColorOption,
                element.lineStyle);
        }
        if (textAttr.some(attr => element[attr])) {
            markupItem.setTextFormat(
                element.textFontSize,
                element.textColor,
                element.textFontFamily,
                element.textIsBold,
                element.textIsItalic,
                element.textIsUnderline);
        }
        markupItem.modifiedDate = element.modifiedDate;
        markupItem.lastModifiedBy = element.lastModifiedBy;
    }
    loadElement(element: MarkupEntity, setVisible = false, isSelected = false) {
        const markupName: NameMarkupItem = element.originData.shapeName;
        if (!markupName) return;
        const markupLoaded = this.loadElementMap[markupName](element);
        if (!markupLoaded) return;
        markupLoaded.setSelected(isSelected);
        if (markupLoaded.isMarkup3D) return;
        markupLoaded.setBoundingGripPointVisible(setVisible);
        markupLoaded.setBoundingPointerEvent(setVisible);
    }
}