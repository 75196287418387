/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown, Menu } from 'antd';
import { CTooltip } from 'components/CTooltip'
import { StreamFile } from 'common/define';
import { useContextListOpenFile } from 'context/listopenfile.context';
import React, { useLayoutEffect, useState } from 'react';
import './uploadStatus.less';
import { useEffect } from 'react';
import CIconSvg from 'components/CIconSvg';
import { RightOutlined } from '@ant-design/icons';

const FONTSIZE = 25;
const PERCENTOFOPENPAGE = 0.62;

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function FolderPath(): JSX.Element {
    const {state: {folders}, dispatchContext} = useContextListOpenFile();
    const [showFolders, updateShowFolders] = useState<StreamFile[]>([]);
    const [hiddenFolders, updateHiddenFolders] = useState<StreamFile[]>([]);
    const [width, height] = useWindowSize();

    useEffect(() => {
        const numOfFolders = folders.length;
        updateHiddenFolders([]);
        for(let k = 0; k < numOfFolders; k++){
            const handleFolders = folders.slice(k, numOfFolders);
            const len = handleFolders.length;
            let i = len;
            let folderPath = "";
            while(i-- && i >= 0){
                folderPath += handleFolders[i].filename;
                const size = measureText(folderPath, FONTSIZE);
                if(size.width > (window.innerWidth*PERCENTOFOPENPAGE)){
                    break;
                }
            }
            if(i === -1){
                updateShowFolders([...handleFolders]);
                break;
            }
            else{
                let j = len;
                while(j-- && j >= 0){
                    folderPath += truncateFolderFileName(handleFolders[i].filename, 39);
                    const size = measureText(folderPath, FONTSIZE);
                    if(size.width > (window.innerWidth*PERCENTOFOPENPAGE)){
                        break;
                    }
                }
                if(j === -1){
                    updateShowFolders([...handleFolders]);
                    break;
                }
                else{
                    updateShowFolders([...handleFolders]);
                    const tmp = folders.slice(0, k+1);
                    updateHiddenFolders([...tmp]);
                }
            }
        }
        
    }, [folders, width, height])
    
    const click = (folder: StreamFile) => {
        dispatchContext({type: 'SELECT_FOLDER', payload: folder})
    }

    const truncateFolderFileName = (filename: string, maxLen: number, separator = ' ') => {
        if (filename.length <= maxLen) return filename;
        return filename.substr(0, filename.lastIndexOf(separator, maxLen)) + '...';
    }

    function measureText(pText: string, pFontSize: number) {
        const lDiv = document.createElement('div');
        document.body.appendChild(lDiv);
        lDiv.style.fontSize = "" + pFontSize + "px";
        lDiv.style.position = "absolute";
        lDiv.style.left = '-1000';
        lDiv.style.top = '-1000';
    
        lDiv.innerHTML = pText;
    
        const lResult = {
            width: lDiv.clientWidth,
            height: lDiv.clientHeight
        };
    
        document.body.removeChild(lDiv);
        return lResult;
    }

    const menu = (
        <Menu>
            {hiddenFolders.map((folder, index) => {
                return (<Menu.Item onClick={() => {click(folder)}} key={index}>
                    <span>{folder.filename}</span>
                </Menu.Item>)
            })}
        </Menu>
    );

    const RenderFilePath = () => {
        return (<div style={{width: 'fit-content', display: 'flex'}}>
            {hiddenFolders.length > 0 && <Dropdown overlay={menu} trigger={['click']}>
                <a className="ant-dropdown-link mt-3" onClick={e => e.preventDefault()}>
                    <CIconSvg colorSvg={'default'} name="double_left_arrow" svgSize="medium" className="cursor-pointer">
                    </CIconSvg>
                </a>
            </Dropdown>}
            {showFolders.map((folder, index) => {
                return (
                    <div key={index} className="flex">
                        <CTooltip key={index} title={folder.filename} >
                            <span key={index} style={{ fontWeight: 'bold', fontSize: FONTSIZE + 'px', maxWidth: '98%'}} 
                                className="cursor-pointer text-gray-primary hover:underline hover:text-black truncate inline-block"
                                onClick={() => click(folder)}>
                                {truncateFolderFileName(folder.filename, 50)}
                            </span>
                        </CTooltip>
                        <div className="mt-1.5 mx-1">
                            <RightOutlined />
                        </div>
                    </div>
                )
            })
            }
        </div>)
    }

    return (
        <div id="folder-path" className="w-full h-10">
            {
                RenderFilePath()
            }
        </div>
    );
}