/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GlobalState } from "common/global";
import { CuttingPlaneState } from "common/type-state";
import { CuttingType, PlaneBoxChild } from "common/type-viewer";
import { BehaviorSubject } from "rxjs";
import { Lodash } from "utils/utils";
import { ActiveButtonControlService } from "./activeButtonControl.service";
import BaseOperator from "./base.operator";
import CuttingPlaneOperator from "./cuttingPlane.operator";
import { SetOperator } from "./operator3D.helper";
import OrbitOperator from "./orbit.operator";
import PanOperator from "./pan.operator";
import SelectOperator from "./select.operator";
import TurntableOperator from "./turntable.operator";
import ZoomWindowOperator from "./zoom-window.operator";
export enum SectionStatus {
    NoFaceCutOff,
    PresentCutOff,
    ReversesTheCutOfTheCutters,
}
export enum SectionName {
    SectionX,
    SectionY,
    SectionZ,
    SectionBox,
    SectionToggle,
}
export class Normal {
    public static NormalX = new Communicator.Point3(1, 0, 0);

    public static NormalY = new Communicator.Point3(0, 1, 0);

    public static NormalZ = new Communicator.Point3(0, 0, 1);

    public static NormalMinusX = new Communicator.Point3(-1, 0, 0);

    public static NormalMinusY = new Communicator.Point3(0, -1, 0);

    public static NormalMinusZ = new Communicator.Point3(0, 0, -1);
}


export enum GroupHandelId {
    Translate_X = 1,
    Translate_mX = 2,

    Translate_Y = 3,
    Translate_mY = 4,

    Translate_Z = 5,
    Translate_mZ = 6,

    Conner_ZmXY = 7,
    Conner_ZXY = 8,
    Conner_ZXmY = 9,
    Conner_ZmXmY = 10,

    Conner_mZmXY = 11,
    Conner_mZXY = 12,
    Conner_mZXmY = 13,
    Conner_mZmXmY = 14,
    Middle_ZY = 15,
    Middle_ZX = 16,
    Middle_ZmY = 17,
    Middle_ZmX = 18,
    Middle_mZY = 19,
    Middle_mZX = 20,
    Middle_mZmY = 21,
    Middle_mZmX = 22,

    Center_handles = 23
}

type PlaneType = Extract<CuttingType, 'plane-x' | 'plane-y' | 'plane-z'>;
export class CuttingPlaneHelper extends BaseOperator {
    listPlaneActive: Communicator.Plane[] = []
    public currentOperators: Communicator.OperatorId[] = [];
    state: CuttingPlaneState = this.initState();
    referenceGeometryX: Communicator.Point3[] | null = null;
    referenceGeometryY: Communicator.Point3[] | null = null;
    referenceGeometryZ: Communicator.Point3[] | null = null;
    nodeIds: number[] = [];
    modelBoundingBox: Communicator.Box | null = null;
    planeX: Communicator.Plane | undefined;
    planeY: Communicator.Plane | undefined;
    planeZ: Communicator.Plane | undefined;
    statusX = SectionStatus.NoFaceCutOff;
    statusY = SectionStatus.NoFaceCutOff;
    statusZ = SectionStatus.NoFaceCutOff;
    section1: Communicator.CuttingSection | null = null;

    section2: Communicator.CuttingSection | null = null;

    section3: Communicator.CuttingSection | null = null;

    section4: Communicator.CuttingSection | null = null;

    section5: Communicator.CuttingSection | null = null;

    section6: Communicator.CuttingSection | null = null;
    isVisibility = false;
    cuttingPlaneOperator: CuttingPlaneOperator
    modeMultipleSides = false;
    activeButtonControlService: ActiveButtonControlService;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cuttingHandle: any;
    private onChange = new BehaviorSubject<boolean>(false);
    onChange$ = this.onChange.asObservable();
    public _handleSelected = false;
    private _handleEventPromise = Promise.resolve();
    listHandlePlaneRemove: PlaneBoxChild[] = [];
    groupHandleConnerId = 1;
    groupHandleTranslate = 2;
    public removeable = false;
    public isOpenBox = false;
    
    // eslint-disable-next-line
    setOnChange(value: boolean) {
        this.onChange.next(value);
    }

    constructor(
        private viewer: Communicator.WebViewer,
        private setOperator: SetOperator
    ) {
        super(viewer)
        this.initCuttingSection();
        this.updateRefGeo();
        this.initCallback();
        this.initPlane();
        this.cuttingPlaneOperator = new CuttingPlaneOperator(viewer)
        this.cuttingHandle = viewer.operatorManager.registerCustomOperator(
            this.cuttingPlaneOperator,
        );
        this.viewer.operatorManager.replaceOperator(Communicator.OperatorId.Cutting, this.cuttingHandle);
        this.activeButtonControlService = new ActiveButtonControlService()
    }

    initCallback(): void {
        let operator = -1;
        const cuttingPlaneDragStartFunction = () => {
            const { currentOperator } = this.setOperator;
            if (
                currentOperator instanceof OrbitOperator
                || currentOperator instanceof TurntableOperator
                || currentOperator instanceof ZoomWindowOperator
                || currentOperator instanceof SelectOperator
                || currentOperator instanceof PanOperator
            ) {
                if (operator === -1) {
                    const id = currentOperator.GetIdOperator();
                    if (id !== -1) {
                        operator = id;
                        currentOperator.ClearTemp();
                        this.viewer.operatorManager.remove(id);
                    }
                }
            }
        };

        const cuttingPlaneDragEndFunction = () => {
            if (operator) {
                this.viewer.operatorManager.push(operator);
                operator = -1;
            }
        };
        this.viewer.setCallbacks({
            cuttingPlaneDragStart: cuttingPlaneDragStartFunction,
            cuttingPlaneDragEnd: cuttingPlaneDragEndFunction,
            // cuttingPlaneDrag: this.cuttingPlaneRunning,
        });
    }

    updateState(state: CuttingPlaneState): void {
        this.state = state;
    }

    updateNodeIds(nodeIds: number[] | undefined): void {
        this.nodeIds = !nodeIds || nodeIds.length === 0 ? [this.viewer.model.getAbsoluteRootNode()] : nodeIds;
    }

    // eslint-disable-next-line
    public async fromJson(json: any, state: CuttingPlaneState): Promise<void> {
        await this.cuttingPlaneOperator.handleOperatorActive.deleteHandles();
        await this.cuttingPlaneOperator.CreateNewHandleOperator();
        const stateActive = state.active ? state.active.filter(x => x !== 'toggle-plane' && x) : [];
        stateActive.push('toggle-plane');
        if (stateActive.length > 0) {
            this.cuttingPlaneOperator.setTypeCutting(stateActive[0]);
            // this.updateCuttingPlaneState(stateActive[0]);
            this.updateState({
                active: [...stateActive],
                disable: state.disable
            });
            if (stateActive.includes('plane-x')) this.statusX = SectionStatus.PresentCutOff;
            if (stateActive.includes('plane-y')) this.statusY = SectionStatus.PresentCutOff;
            if (stateActive.includes('plane-z')) this.statusZ = SectionStatus.PresentCutOff;
        } else {
            this.updateState(state);
        }
        if (!stateActive.includes('plane-box'))
            await this._viewer.cuttingManager.fromJson(json);
        this.listHandlePlaneRemove = [];
        this.cuttingPlaneOperator.listHandlePlaneRemove = [];
        if (state.planeBoxChildState && state.planeBoxChildState.isClipping) {
            const planeList: PlaneBoxChild[] = [];
            for (let i = 0; i < state.planeBoxChildState.planeBoxChild.length; i++) {
                const plane = state.planeBoxChildState.planeBoxChild[i];
                planeList.push(plane);
                this.cuttingPlaneOperator._boundingBox = null;
                await this.unVisibleClipping(planeList);
            }
        }
        if (stateActive.includes('plane-box')) {
            let box : Communicator.Box | null = null;
            if (state.planeBoxChildState && state.planeBoxChildState.boundingBox) {
                box = new Communicator.Box(
                    this.parsePoint3(state.planeBoxChildState.boundingBox.min),
                    this.parsePoint3(state.planeBoxChildState.boundingBox.max)
                );
            } else {
                box = this._getBoxFromSection();
            }
            if (state.planeBoxChildState?.eightCorners) {
                this.cuttingPlaneOperator.setEightCorners(
                    state.planeBoxChildState.eightCorners.map(p => this.parsePoint3(p))
                );
            } else 
                this.cuttingPlaneOperator.setEightCorners(box.getCorners());
            this.cuttingPlaneOperator.setRotateBoxState(
                stateActive.includes('rotate-box') ? true : false
            );
            await this.cuttingPlaneOperator._addCuttingBox(box);
        }
        if (stateActive.includes('toggle-plane')) {
            await this.visibility(true);
        }
    }

    private parsePoint3(point: any) {
        return new Communicator.Point3(point.x, point.y, point.z);
    }

    async addPlane(section: SectionName, cuttingType: CuttingType): Promise<CuttingPlaneState | undefined> {
        this.cuttingPlaneOperator.setTypeCutting(cuttingType)
        await this.cuttingPlaneOperator.handleOperatorActive?.removeHandles();
        this.viewer.operatorManager.getOperator(Communicator.OperatorId.Handle).removeHandles();
        this.viewer.operatorManager.remove(Communicator.OperatorId.Handle);

        if (this.viewer) {
            await this.updateRefGeo();
            if (this.state.active?.includes("multi")) {
                this.viewer.operatorManager.push(Communicator.OperatorId.Cutting);
                this.cuttingPlaneOperator.CreateNewHandleOperator();
                await this.addPlaneModeMultipleSides(section);
            } else {
                if (cuttingType === 'plane-box') {
                    this.listHandlePlaneRemove = [];
                    this.cuttingPlaneOperator.listHandlePlaneRemove = [];
                    if (this.modelBoundingBox)
                        this.cuttingPlaneOperator._boundingBox = this.modelBoundingBox?.copy();
                    this.removeable = true;
                    this.isOpenBox = this.state.active?.includes('plane-box') ? true : false;
                    this.cuttingPlaneOperator.setRotateBoxState(false);
                    this.cuttingPlaneOperator.clearTrackedCorners();
                    await this.addCuttingBox();
                    this.cuttingPlaneOperator.setMuitiSelect(true);
                    this.cuttingPlaneOperator.setSection(this.section1, this.section2, this.section3, this.section4, this.section5, this.section6)
                }
                else {

                    if (this.state.active?.includes('plane-box')) {
                        await this.turnOffBoxMode();
                        this.modeMultipleSides = !this.modeMultipleSides
                    }
                    this.viewer.operatorManager.push(Communicator.OperatorId.Cutting);
                    this.cuttingPlaneOperator.CreateNewHandleOperator();
                    await this.addPlaneNormal(section, cuttingType);
                    this.cuttingPlaneOperator.setMuitiSelect(false)

                }

            }

            return this.updateCuttingPlaneState(cuttingType);
        }
    }

    async reversePlane(section: SectionName, cuttingType: CuttingType): Promise<CuttingPlaneState | undefined> {
        if (this.viewer) {
            await this.cuttingPlaneOperator.handleOperatorActive.deleteHandles();
            await this.reversePlaneExtend(section);
            return undefined
        }
    }

    async rotateBox(): Promise<CuttingPlaneState> {
        if (!this.cuttingPlaneOperator._boundingBox) return this.state;
        await this.cuttingPlaneOperator.handleOperatorActive.deleteHandles();
        const activeState = Lodash.clone(this.state.active);
        if (activeState?.includes('rotate-box')) {
            activeState.splice(activeState.indexOf('rotate-box'), 1);
            this.cuttingPlaneOperator.setRotateBoxState(false);
            this.cuttingPlaneOperator.addCuttingBoxHandles(this.cuttingPlaneOperator._boundingBox);
        } else {
            activeState?.push('rotate-box');
            this.cuttingPlaneOperator.setRotateBoxState(true);
            if (!this.state.active?.includes('toggle-plane'))
                this.cuttingPlaneOperator.addRotateHandles(this.cuttingPlaneOperator._boundingBox);
        }
        this.updateState({
            active: activeState,
            disable: Lodash.clone(this.state.disable)
        });
        return this.state;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async addCuttingBox() {
        await this.clearAllSection();
        if (this.isOpenBox) {
            if (this.state.active?.includes('toggle-plane'))
                await this.reset()
            else
                this.turnOffBoxMode();
        } else {
            await this.updateRefGeo().then(async () => {
                this.turnOnBoxMode();
            });
        }
        this.isOpenBox = !this.isOpenBox
        // chưa tìm ra lý do
        this.activeButtonControlService.setMultipleSidesMode(this.modeMultipleSides);
        this.initStatusButton();

    }

    async turnOffBoxMode(): Promise<void> {
        const promiseList = [];
        promiseList.push(
            new Promise((resolve, reject) => {
                this.section1?.clear().then(() => {
                    resolve(true);
                });
            }),
            new Promise((resolve, reject) => {
                this.section2?.clear().then(() => {
                    resolve(true);
                });
            }),
            new Promise((resolve, reject) => {
                this.section3?.clear().then(() => {
                    resolve(true);
                });
            }),
            new Promise((resolve, reject) => {
                this.section4?.clear().then(() => {
                    resolve(true);
                });
            }), new Promise((resolve, reject) => {
                this.section5?.clear().then(() => {
                    resolve(true);
                });
            }), new Promise((resolve, reject) => {
                this.section6?.clear().then(() => {
                    resolve(true);
                });
            }),
        );
        await Promise.all([...promiseList]).then(async () => {
            this.viewer.cuttingManager.deactivateCuttingSections(true);
            this.deactiveSection();
            this.initStatusButton(true);
            this.cuttingPlaneOperator.setTypeCutting('close')
            await this.cuttingPlaneOperator.handleOperatorActive?.removeHandles()
            this.statusX = 0;
            this.statusY = 0;
            this.statusZ = 0
        });
    }

    async turnOnBoxMode(): Promise<void> {
        if (!this.cuttingPlaneOperator._boundingBox) {
            this.cuttingPlaneOperator._boundingBox = this.modelBoundingBox ? this.modelBoundingBox.copy() :
                await this._viewer.model.getModelBounding(true, true);
        }
        this.cuttingPlaneOperator.handleOperatorActive.deleteHandles();
        this.cuttingPlaneOperator._addCuttingBox(this.cuttingPlaneOperator._boundingBox);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    initStatusButton(isOnchange = false) {
        this.activeButtonControlService.setViewer(this.viewer);
        if (isOnchange) {
            this.setOnChange(true);
        }
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async deactiveSection() {
        const sectionCount = this.viewer.cuttingManager.getCuttingSectionCount();
        for (let i = 0; i < sectionCount; i += 1) {
            const section = this.viewer.cuttingManager.getCuttingSection(i);
            await section?.deactivate();
        }
    }

    private _getBoxFromSection() {
        const xSection = this._viewer.cuttingManager.getCuttingSection(0)!;
        const zSection = this._viewer.cuttingManager.getCuttingSection(2)!;
        const xRefGeo = xSection.getReferenceGeometry(0);
        const zRefGeo = zSection.getReferenceGeometry(0);
        if (!zRefGeo || !xRefGeo) return this.modelBoundingBox || new Communicator.Box();
        const min = new Communicator.Point3(zRefGeo[3].x, xRefGeo[3].y, xRefGeo[3].z);
        const max = new Communicator.Point3(zRefGeo[1].x, xRefGeo[1].y, xRefGeo[1].z)
        const box = new Communicator.Box(min, max);
        return box;
    }

    private async setReferenceGeometryForSection(cuttingSection: Communicator.CuttingSection): Promise<void> {
        const planeList = this.copyPlaneOfSection(cuttingSection);
        cuttingSection.clear().then(() => {
            this.applyRefGeo(cuttingSection, planeList).then(() => {
                cuttingSection.activate();
            });
        });
    }

    private async setCuttingBoxVisibility(): Promise<void> {
        this.listHandlePlaneRemove = [];
        this.cuttingPlaneOperator.listHandlePlaneRemove = [];
        if (this.isVisibility) {
            this.referenceGeometryX = null;
            this.referenceGeometryY = null;
            this.referenceGeometryZ = null;
            const ps: Promise<void>[] = [];
            for (let i = 0; i < 6; i++) {
                const section = this._viewer.cuttingManager.getCuttingSection(i);
                const plane = section?.getPlane(0);
                const newPlane = plane?.copy();
                if (section && newPlane) ps.push(section.setPlane(0, newPlane, null));
            }
            await Promise.all(ps);
            await this.cuttingPlaneOperator.handleOperatorActive.deleteHandles();
            this.cuttingPlaneOperator.handleOperatorActive.mapGroupHandelId_NodeId.clear();
        } else {
            await this.cuttingPlaneOperator._addCuttingBox(this.cuttingPlaneOperator._boundingBox);
        }
    }

    async visibility(visibility?: boolean): Promise<CuttingPlaneState | undefined> {
        this.isVisibility = visibility !== undefined ? visibility : !this.isVisibility;
        this.cuttingPlaneOperator.setVisibility(this.isVisibility);
        if (this.state.active?.includes('plane-box')) {
            await this.setCuttingBoxVisibility();
        } else if (this.state.active?.includes('toggle-section')) {
            await this.updateRefGeo();
            await this.visiableCuttingSection(this.section4!);
            await this.section4!.activate();
        } else {
            await this.updateRefGeo();
            await this.updateRefGeoForSection();
            await this.isCutting();
        }
        return this.updateCuttingPlaneState('toggle-plane');
    }

    async changeMode(): Promise<CuttingPlaneState | undefined> {
        await this.changeModeMulti();
        return this.updateCuttingPlaneState('multi');
    }
    //eslint-disable-next-line
    async handleUnVisibilityByTypeChild(indexSection: number, groudId: number, show: boolean) {
        const section = this._viewer.cuttingManager.getCuttingSection(indexSection);
        if (section === null) return;
        if (show) {
            this.setReferenceGeometryForSection(section);
            this.cuttingPlaneOperator.handleOperatorActive.mapGroupHandelId_NodeId.clear();
            if (this.cuttingPlaneOperator._boundingBox) {
                this.cuttingPlaneOperator.addCuttingBoxHandles(this.cuttingPlaneOperator._boundingBox);
            }
            return;
        } else {
            await this.cuttingPlaneOperator.setReferenceGeometryForSection(indexSection);
            await this.cuttingPlaneOperator.addCuttingBoxHandles(this.cuttingPlaneOperator._boundingBox!);
            return;
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async handleVisbileHandles() {
        this.cuttingPlaneOperator.handleOperatorActive.showHandles();
        const newPlane1 = this.section1?.getPlane(0);
        const newPlane2 = this.section2?.getPlane(0);
        const newPlane3 = this.section3?.getPlane(0);
        const newPlane4 = this.section4?.getPlane(0);
        const newPlane5 = this.section5?.getPlane(0);
        const newPlane6 = this.section6?.getPlane(0);
        const promiseList = [];
        promiseList.push(
            // eslint-disable-next-line no-async-promise-executor
            new Promise(async (resolve, reject) => {
                if (newPlane4) {
                    //Draw handles of planeX minus
                    if (this.cuttingPlaneOperator.referenceGeometryX && !this.listHandlePlaneRemove.includes("plane-x-minus")) {
                        this.cuttingPlaneOperator.addAxisHandle2(this.cuttingPlaneOperator.referenceGeometryX!, this.section4!, newPlane4, GroupHandelId.Translate_mX).then((id) => {
                            if (this.section4)
                                this.cuttingPlaneOperator.handleOperatorActive?.mapGroupPlane_GroupNodeIdHandelId.set(
                                    GroupHandelId.Translate_mX,
                                    this.section4
                                );
                        })
                    }
                }
            }),
            // eslint-disable-next-line
            new Promise(async (resolve, reject) => {
                if (newPlane1) {
                    //Draw handles of plane-x-plus
                    if (this.cuttingPlaneOperator.referenceGeometryX && !this.listHandlePlaneRemove.includes("plane-x-plus")) {
                        this.cuttingPlaneOperator.addAxisHandle2(this.cuttingPlaneOperator.referenceGeometryX!, this.section1!, newPlane1, GroupHandelId.Translate_X).then((id) => {
                            if (this.section1)
                                this.cuttingPlaneOperator.handleOperatorActive?.mapGroupPlane_GroupNodeIdHandelId.set(
                                    GroupHandelId.Translate_X,
                                    this.section1
                                );
                        })
                    }
                }
            }),
            // eslint-disable-next-line
            new Promise(async (resolve, reject) => {
                if (newPlane5) {
                    if (this.cuttingPlaneOperator.referenceGeometryY && !this.listHandlePlaneRemove.includes("plane-y-minus")) {
                        this.cuttingPlaneOperator.addAxisHandle2(this.cuttingPlaneOperator.referenceGeometryY, this.section5!, newPlane5, GroupHandelId.Translate_mY).then((id) => {
                            this.cuttingPlaneOperator.handleOperatorActive?.mapGroupPlane_GroupNodeIdHandelId.set(
                                GroupHandelId.Translate_mY,
                                this.section5!)
                        })
                    }
                }
            }),
            // eslint-disable-next-line
            new Promise(async (resolve, reject) => {
                if (newPlane2) {
                    if (this.cuttingPlaneOperator.referenceGeometryY && !this.listHandlePlaneRemove.includes("plane-y-plus")) {
                        this.cuttingPlaneOperator.addAxisHandle2(this.cuttingPlaneOperator.referenceGeometryY!, this.section2!, newPlane2, GroupHandelId.Translate_Y).then((id) => {
                            if (this.section2)
                                this.cuttingPlaneOperator.handleOperatorActive?.mapGroupPlane_GroupNodeIdHandelId.set(
                                    GroupHandelId.Translate_Y,
                                    this.section2
                                );
                        })
                    }
                }
            }),
            // eslint-disable-next-line
            new Promise(async (resolve, reject) => {
                if (newPlane6) {
                    if (this.cuttingPlaneOperator.referenceGeometryZ && !this.listHandlePlaneRemove.includes("plane-z-minus")) {
                        this.cuttingPlaneOperator.addAxisHandle2(this.cuttingPlaneOperator.referenceGeometryZ, this.section6!, newPlane6, GroupHandelId.Translate_mZ).then((id) => {
                            if (this.section6)
                                this.cuttingPlaneOperator.handleOperatorActive?.mapGroupPlane_GroupNodeIdHandelId.set(
                                    GroupHandelId.Translate_mZ,
                                    this.section6
                                );
                            const z = this.modelBoundingBox?.min.z
                            let start = GroupHandelId.Conner_mZmXY - 1;
                            for (const item of this.cuttingPlaneOperator.referenceGeometryZ!) {
                                if (z) {
                                    this.cuttingPlaneOperator.setDataCubeMesh(new Communicator.Point3(item.x, item.y, z), Normal.NormalMinusZ, ++start)
                                    this.cuttingPlaneOperator.handleOperatorActive?.showHandles()
                                }
                            }
                            const newPt: Communicator.Point3 = this.cuttingPlaneOperator.getMidPoint(this.cuttingPlaneOperator.referenceGeometryZ![0], this.cuttingPlaneOperator.referenceGeometryZ![1]);
                            const newPt2: Communicator.Point3 = this.cuttingPlaneOperator.getMidPoint(this.cuttingPlaneOperator.referenceGeometryZ![2], this.cuttingPlaneOperator.referenceGeometryZ![3]);
                            const newPt3: Communicator.Point3 = this.cuttingPlaneOperator.getMidPoint(this.cuttingPlaneOperator.referenceGeometryZ![1], this.cuttingPlaneOperator.referenceGeometryZ![2]);
                            const newPt4: Communicator.Point3 = this.cuttingPlaneOperator.getMidPoint(this.cuttingPlaneOperator.referenceGeometryZ![0], this.cuttingPlaneOperator.referenceGeometryZ![3]);
                            const arrayMiddle = [newPt, newPt3, newPt2, newPt4]
                            if (z) {
                                let count = 18
                                for (const p of arrayMiddle) {
                                    p.z = z;
                                    this.cuttingPlaneOperator.addAxisMiddle(p, Normal.NormalZ, ++count);
                                }
                            }
                        })

                    }
                }
            }),
            // eslint-disable-next-line
            new Promise(async (resolve, reject) => {
                if (newPlane3) {
                    if (this.cuttingPlaneOperator.referenceGeometryZ && !this.listHandlePlaneRemove.includes("plane-z-plus")) {
                        this.cuttingPlaneOperator.addAxisHandle2(this.cuttingPlaneOperator.referenceGeometryZ, this.section3!, newPlane3, GroupHandelId.Translate_Z).then((id) => {
                            if (this.section3)
                                this.cuttingPlaneOperator.handleOperatorActive?.mapGroupPlane_GroupNodeIdHandelId.set(
                                    GroupHandelId.Translate_Z,
                                    this.section3
                                );
                            const z = this.modelBoundingBox?.max.z
                            let start = GroupHandelId.Conner_ZmXY - 1;
                            for (const item of this.cuttingPlaneOperator.referenceGeometryZ!) {
                                if (z) {
                                    this.cuttingPlaneOperator.setDataCubeMesh(new Communicator.Point3(item.x, item.y, z), Normal.NormalZ, ++start);
                                    this.cuttingPlaneOperator.handleOperatorActive?.showHandles()
                                }
                            }
                            const newPt: Communicator.Point3 = this.cuttingPlaneOperator.getMidPoint(this.cuttingPlaneOperator.referenceGeometryZ![0], this.cuttingPlaneOperator.referenceGeometryZ![1]);
                            const newPt2: Communicator.Point3 = this.cuttingPlaneOperator.getMidPoint(this.cuttingPlaneOperator.referenceGeometryZ![2], this.cuttingPlaneOperator.referenceGeometryZ![3]);
                            const newPt3: Communicator.Point3 = this.cuttingPlaneOperator.getMidPoint(this.cuttingPlaneOperator.referenceGeometryZ![1], this.cuttingPlaneOperator.referenceGeometryZ![2]);
                            const newPt4: Communicator.Point3 = this.cuttingPlaneOperator.getMidPoint(this.cuttingPlaneOperator.referenceGeometryZ![0], this.cuttingPlaneOperator.referenceGeometryZ![3]);
                            const arrayMiddle = [newPt, newPt3, newPt2, newPt4]
                            if (z) {
                                let count = 14
                                for (const p of arrayMiddle) {
                                    p.z = z;
                                    this.cuttingPlaneOperator.addAxisMiddle(p, Normal.NormalZ, ++count);
                                }
                            }
                        })
                    }
                }
            }),
        );

        await Promise.all([...promiseList]).then(() => {
            return
        });

    }

    turnOffClipping(): void {
        this.handleUnVisibilityByTypeChild(3, GroupHandelId.Translate_mX, false);

        this.handleUnVisibilityByTypeChild(0, GroupHandelId.Translate_X, false);

        this.handleUnVisibilityByTypeChild(4, GroupHandelId.Translate_mY, false);

        this.handleUnVisibilityByTypeChild(1, GroupHandelId.Translate_Y, false);

        this.handleUnVisibilityByTypeChild(5, GroupHandelId.Translate_mZ, false);

        this.handleUnVisibilityByTypeChild(2, GroupHandelId.Translate_Z, false);
        this.listHandlePlaneRemove = []
    }

    async visibleClipping(typeChild: PlaneBoxChild[]): Promise<any> {
        let visiblePlane: any
        // eslint-disable-next-line array-callback-return
        this.listHandlePlaneRemove.forEach((item) => {
            if (!typeChild.includes(item))
                visiblePlane = item
        })
        this.listHandlePlaneRemove = typeChild
        this.cuttingPlaneOperator.listHandlePlaneRemove = this.listHandlePlaneRemove;

        this.cuttingPlaneOperator.setVisibility(this.isVisibility)
        switch (visiblePlane) {
            case 'plane-x-minus': {
                this.handleUnVisibilityByTypeChild(3, GroupHandelId.Translate_mX, false);
                break;
            }
            case 'plane-x-plus': {
                this.handleUnVisibilityByTypeChild(0, GroupHandelId.Translate_X, false);
                break;
            }
            case 'plane-y-minus': {
                this.handleUnVisibilityByTypeChild(4, GroupHandelId.Translate_mY, false);
                break;
            }
            case 'plane-y-plus': {
                this.handleUnVisibilityByTypeChild(1, GroupHandelId.Translate_Y, false);
                break;
            }
            case 'plane-z-minus': {
                this.handleUnVisibilityByTypeChild(5, GroupHandelId.Translate_mZ, false);
                break;
            }
            case 'plane-z-plus': {
                this.handleUnVisibilityByTypeChild(2, GroupHandelId.Translate_Z, false);
                break;
            }
        }
    }
    turnOnClipping(): void {
        this.handleUnVisibilityByTypeChild(3, GroupHandelId.Translate_mX, true);
        this.handleUnVisibilityByTypeChild(0, GroupHandelId.Translate_X, true);
        this.handleUnVisibilityByTypeChild(4, GroupHandelId.Translate_mY, true);
        this.handleUnVisibilityByTypeChild(1, GroupHandelId.Translate_Y, true);
        this.handleUnVisibilityByTypeChild(5, GroupHandelId.Translate_mZ, true);
        this.handleUnVisibilityByTypeChild(2, GroupHandelId.Translate_Z, true);
        this.listHandlePlaneRemove = ['plane-z-plus', 'plane-z-minus', 'plane-y-plus', 'plane-y-minus', 'plane-x-plus', 'plane-x-minus']
    }

    async unVisibleClipping(typeChild: PlaneBoxChild[]): Promise<any> {
        let unvisiblePlane;
        if (this.listHandlePlaneRemove.length === 0) {
            this.listHandlePlaneRemove = typeChild
            this.cuttingPlaneOperator.listHandlePlaneRemove = this.listHandlePlaneRemove
            unvisiblePlane = typeChild[0];
        }
        else {
            // eslint-disable-next-line array-callback-return
            typeChild.forEach((item) => {
                if (!this.listHandlePlaneRemove.includes(item)) {
                    unvisiblePlane = item;
                }
            });
            if (!unvisiblePlane) unvisiblePlane = typeChild[typeChild.length - 1];
        }
        this.listHandlePlaneRemove = typeChild;
        this.cuttingPlaneOperator.listHandlePlaneRemove = this.listHandlePlaneRemove
        this.isVisibility = true;
        this.cuttingPlaneOperator.setVisibility(this.isVisibility)
        await this.updateRefGeo();
        switch (unvisiblePlane) {
            case 'plane-x-minus': {
                this.handleUnVisibilityByTypeChild(3, GroupHandelId.Translate_mX, true);
                break;
            }
            case 'plane-x-plus': {
                this.handleUnVisibilityByTypeChild(0, GroupHandelId.Translate_X, true);
                break;
            }
            case 'plane-y-minus': {
                this.handleUnVisibilityByTypeChild(4, GroupHandelId.Translate_mY, true);
                break;
            }
            case 'plane-y-plus': {
                this.handleUnVisibilityByTypeChild(1, GroupHandelId.Translate_Y, true);
                break;
            }
            case 'plane-z-minus': {
                this.handleUnVisibilityByTypeChild(5, GroupHandelId.Translate_mZ, true);
                break;
            }
            case 'plane-z-plus': {
                this.handleUnVisibilityByTypeChild(2, GroupHandelId.Translate_Z, true);
                break;
            }
        }
        this.isVisibility = false;
        this.cuttingPlaneOperator.setVisibility(this.isVisibility)
    }

    async reset(home?: boolean): Promise<CuttingPlaneState | undefined> {
        if (home)
            this.cuttingPlaneOperator._boundingBox = await this._viewer.model.getModelBounding(true, true);
        if (this.state?.active?.includes('plane-box')) {
            if (this.state?.active?.includes('toggle-plane')) {
                this.visibility()
            }
            if (this.listHandlePlaneRemove.length > 0) {
                this.turnOffClipping()
            }

            await this.clearAllSection();
            await this.turnOffBoxMode();
            this.modeMultipleSides = !this.modeMultipleSides
            this.isOpenBox = !this.isOpenBox
            return this.initState();
        }
        else {
            await this.resetCuttingPlane();
            return this.initState();
        }
    }
    /** privates */
    private nodeType = [1, 3, 6, 9, 10, 11];
    private planeType: CuttingType[] = ['plane-x', 'plane-y', 'plane-z', 'plane-box'];
    private typePlaneX: CuttingType[] = ['plane-x', 'reverse-plane-x'];
    private typePlaneY: CuttingType[] = ['plane-y', 'reverse-plane-y'];
    private typePlaneZ: CuttingType[] = ['plane-z', 'reverse-plane-z'];
    private mapCuttingTypeReverse: Record<PlaneType, CuttingType> = {
        "plane-x": 'reverse-plane-x',
        "plane-y": 'reverse-plane-y',
        "plane-z": 'reverse-plane-z'
    }
    private initState(): CuttingPlaneState {
        const initState: CuttingPlaneState = {
            active: [],
            disable: [
                "reverse-plane-x",
                "reverse-plane-y",
                "reverse-plane-z",
                "toggle-plane",
                "toggle-section",
                "rotate-box"
            ],
        };
        return initState;
    }
    public resetCuttingState(): void {
        const initState = this.initState();
        this.updateState(initState);
    }

    private initCuttingSection() {
        if (this.viewer) {
            this.section1 = this.viewer.cuttingManager.getCuttingSection(0);
            this.section2 = this.viewer.cuttingManager.getCuttingSection(1);
            this.section3 = this.viewer.cuttingManager.getCuttingSection(2);
            this.section4 = this.viewer.cuttingManager.getCuttingSection(3);
            this.section5 = this.viewer.cuttingManager.getCuttingSection(4);
            this.section6 = this.viewer.cuttingManager.getCuttingSection(5);
        }
    }
    private async initPlane() {
        this.modelBoundingBox = await this.viewer.model.getModelBounding(
            true,
            true
        );
        if (this.modelBoundingBox) {
            const ration = (this.modelBoundingBox.copy().max.x - this.modelBoundingBox.copy().min.x) / 10000
            this.modelBoundingBox.min.x = this.modelBoundingBox.copy().min.x - ration
            this.modelBoundingBox.min.y = this.modelBoundingBox.copy().min.y - ration
            this.modelBoundingBox.min.z = this.modelBoundingBox.copy().min.z - ration
            this.modelBoundingBox.max.x = this.modelBoundingBox.copy().max.x - ration
            this.modelBoundingBox.max.y = this.modelBoundingBox.copy().max.y - ration
            this.modelBoundingBox.max.z = this.modelBoundingBox.copy().max.z - ration
        }
        const position = this.modelBoundingBox.center();
        this.planeX = Communicator.Plane.createFromPointAndNormal(
            position,
            Normal.NormalX
        );
        this.planeY = Communicator.Plane.createFromPointAndNormal(
            position,
            Normal.NormalY
        );
        this.planeZ = Communicator.Plane.createFromPointAndNormal(
            position,
            Normal.NormalZ
        );
    }
    private updatePlaneStatus(type: CuttingType, status: SectionStatus): void {
        if (this.typePlaneX.includes(type)) {
            this.statusX = status
        } else if (this.typePlaneY.includes(type)) {
            this.statusY = status
        } else if (this.typePlaneZ.includes(type)) {
            this.statusZ = status
        }
    }
    private updateCuttingPlaneState(cuttingType: CuttingType): CuttingPlaneState {
        const { active, disable } = this.state;
        const isInCurrentActiveState = this.state.active?.includes(cuttingType);
        let cloneStateActive = active ? [...active] : [];
        let cloneStateDisable = disable ? [...disable] : [];
        if (this.planeType.includes(cuttingType)) {
            const type = cuttingType as PlaneType;
            const reverseType = this.mapCuttingTypeReverse[type];
            if (cuttingType === 'plane-box' && !isInCurrentActiveState) {
                cloneStateActive = active?.includes('toggle-plane') ? ['plane-box', 'toggle-plane'] : ['plane-box']
                cloneStateDisable = Lodash.remove(cloneStateDisable, c => ![reverseType, 'toggle-plane'].includes(c));
                cloneStateDisable = [...cloneStateDisable, 'multi', 'reverse-plane-x', 'reverse-plane-y', 'reverse-plane-z', 'toggle-section'];
            }
            else if (cuttingType === 'plane-box' && isInCurrentActiveState) {
                cloneStateActive = []
                cloneStateDisable = [...cloneStateDisable, 'toggle-plane'];
                cloneStateDisable = Lodash.remove(cloneStateDisable, c => c !== 'multi');
            }
            else if (!isInCurrentActiveState) {
                cloneStateActive = [...cloneStateActive, cuttingType];
                cloneStateDisable = Lodash.remove(cloneStateDisable, c => ![reverseType, 'toggle-plane', 'multi'].includes(c));
                if (cloneStateActive.includes('plane-box'))
                    cloneStateActive = cloneStateActive.filter(c => c !== 'plane-box');
                if (cloneStateActive.includes('toggle-plane')) {
                    cloneStateDisable.push('plane-box');
                }
                this.updatePlaneStatus(cuttingType, SectionStatus.PresentCutOff);
            }
            else {
                cloneStateActive = Lodash.remove(cloneStateActive, c => c !== cuttingType);
                cloneStateDisable = [...cloneStateDisable, reverseType];
                if (!cloneStateActive.find(c => this.planeType.includes(c))) {
                    cloneStateDisable = [...cloneStateDisable, 'toggle-plane'];
                }
                this.updatePlaneStatus(cuttingType, SectionStatus.NoFaceCutOff)
            }
        } else if (cuttingType === 'toggle-plane') {
            if (this.isVisibility) {
                cloneStateActive = [...cloneStateActive, cuttingType]
                if (cloneStateActive.includes('plane-x') || cloneStateActive.includes('plane-y') || cloneStateActive.includes('plane-z'))
                    cloneStateDisable.push('plane-box')
            } else {
                cloneStateActive = Lodash.remove(cloneStateActive, c => c !== cuttingType)
                cloneStateDisable = cloneStateDisable.filter(c => c !== 'plane-box')
            }
        } else if (cuttingType === 'multi') {
            if (isInCurrentActiveState) {
                cloneStateActive = Lodash.remove(cloneStateActive, c => c !== cuttingType)
                cloneStateDisable = Lodash.remove(cloneStateDisable, c => c !== 'plane-box')

            } else {
                cloneStateActive = [...cloneStateActive, cuttingType]
                cloneStateDisable = [...cloneStateDisable, 'plane-box'];
            }
        }
        if (cloneStateActive.includes('plane-box')) {
            cloneStateDisable = Lodash.remove(cloneStateDisable, c => c !== 'rotate-box');
        } else {
            cloneStateActive = Lodash.remove(cloneStateActive, c => c !== 'rotate-box');
            cloneStateDisable.push('rotate-box');
        }
        this.cuttingPlaneOperator.setRotateBoxState(
            cloneStateActive.includes('rotate-box') ? true : false
        );
        if (cloneStateActive && cloneStateActive.length === 1) {
            if (cloneStateActive[0] === 'toggle-plane') {
                this.isVisibility = !this.isVisibility;
                cloneStateActive = []
                cloneStateDisable = cloneStateDisable.filter(c => c !== 'plane-box')
            }
        }
        if (cloneStateActive.length === 0) {
            this.cuttingPlaneOperator.unregisterCustomHandleOperator();
        }

        return {
            active: cloneStateActive,
            disable: cloneStateDisable
        }
    }
    private async updateRefGeo() {
        if (this.isVisibility) {
            this.referenceGeometryX = null;
            this.referenceGeometryY = null;
            this.referenceGeometryZ = null;
        } else if (this.nodeIds.length > 0) {
            const box = await this.getBoundingBox(this.nodeIds);
            const axisX = Communicator.Axis.X;
            this.referenceGeometryX = this.viewer.cuttingManager.createReferenceGeometryFromAxis(
                axisX,
                box
            );
            const axisY = Communicator.Axis.Y;
            this.referenceGeometryY = this.viewer.cuttingManager.createReferenceGeometryFromAxis(
                axisY,
                box
            );
            const axisZ = Communicator.Axis.Z;
            this.referenceGeometryZ = this.viewer.cuttingManager.createReferenceGeometryFromAxis(
                axisZ,
                box
            );
            this.cuttingPlaneOperator.setreferenceGeometry(this.referenceGeometryX, this.referenceGeometryY, this.referenceGeometryZ)
        }
    }

    public getClippingIsHide(): PlaneBoxChild[] {
        return this.cuttingPlaneOperator.getClippingIsHide();
    }

    private async getBoundingBox(nodeIds: number[]): Promise<Communicator.Box> {
        const sheetId = this.viewer.sheetManager.getSheetIds();
        let retBox: Communicator.Box;

        if (!this.modelBoundingBox) {
            const rootid = this.viewer.model.getAbsoluteRootNode();
            const isActive = this.viewer.explodeManager.getActive();
            if (!isActive) {
                this.modelBoundingBox = await this.viewer.model.getModelBounding(
                    false,
                    false
                );
                if (this.modelBoundingBox) {
                    const ration = (this.modelBoundingBox.copy().max.x - this.modelBoundingBox.copy().min.x) / 10000
                    this.modelBoundingBox.min.x = this.modelBoundingBox.copy().min.x - ration
                    this.modelBoundingBox.min.y = this.modelBoundingBox.copy().min.y - ration
                    this.modelBoundingBox.min.z = this.modelBoundingBox.copy().min.z - ration
                    this.modelBoundingBox.max.x = this.modelBoundingBox.copy().max.x - ration
                    this.modelBoundingBox.max.y = this.modelBoundingBox.copy().max.y - ration
                    this.modelBoundingBox.max.z = this.modelBoundingBox.copy().max.z - ration
                }

            } else {
                this.modelBoundingBox = await this.viewer.model.getNodeRealBounding(
                    rootid
                );
            }
        }
        if (sheetId.length !== 0) {
            const promiseList: Promise<Communicator.Box>[] = [];
            if (this.viewer.explodeManager.getMagnitude() > 0) {
                nodeIds.forEach((id) => {
                    const type = this.viewer.model.getNodeType(id);
                    if (this.nodeType.includes(type)) {
                        const pr = new Promise<Communicator.Box>(
                            (resolve, reject) => {
                                this.viewer.model
                                    .getNodeRealBounding(id)
                                    .then((box) => {
                                        resolve(box);
                                    });
                            }
                        );
                        promiseList.push(pr);
                    }
                });
            }
            retBox = this.modelBoundingBox.copy();
            await Promise.all([...promiseList]).then((arrBox) => {
                arrBox.forEach((box) => {
                    if (box) {
                        retBox.addBox(box);
                    }
                });
            });
            return retBox;
        }
        return this.modelBoundingBox.copy();
    }
    private getPlanePropertiesNormal(sectionName: SectionName) {
        let status = null;
        let section: Communicator.CuttingSection | null = null;
        let plane = null;
        let referenceGeometry = null;
        let normal = null;
        switch (sectionName) {
            case SectionName.SectionX:
                status = this.statusX;
                section = this.section1;
                plane = this.planeX?.copy();
                referenceGeometry = this.referenceGeometryX;
                if (status === SectionStatus.PresentCutOff) {
                    normal = Normal.NormalX;
                } else {
                    normal = Normal.NormalMinusX;
                }
                break;
            case SectionName.SectionY:
                status = this.statusY;
                section = this.section2;
                plane = this.planeY?.copy();
                referenceGeometry = this.referenceGeometryY;
                if (status === SectionStatus.PresentCutOff) {
                    normal = Normal.NormalY;
                } else {
                    normal = Normal.NormalMinusY;
                }
                break;
            case SectionName.SectionZ:
                status = this.statusZ;
                section = this.section3;
                plane = this.planeZ?.copy();
                referenceGeometry = this.referenceGeometryZ;
                if (status === SectionStatus.PresentCutOff) {
                    normal = Normal.NormalZ;
                } else {
                    normal = Normal.NormalMinusZ;
                }
                break;
            default:
        }
        return {
            status,
            section,
            plane,
            referenceGeometry,
            normal
        }
    }
    private async addPlaneExtend(
        section: Communicator.CuttingSection,
        plane: Communicator.Plane,
        refGeo: Communicator.Point3[],
        clear?: boolean
    ) {
        if (clear) {
            await section.clear();
        }
        await section.deactivate();
        await section.addPlane(plane, refGeo);
        if (!section.isActive()) {
            await section.activate();
        }
    }

    private async removePlaneSection(section: Communicator.CuttingSection, normal: Communicator.Point3) {
        let index = -1;
        for (let i = 0; i < section.getCount(); i += 1) {
            if (section.getPlane(i)?.normal.equals(normal)) {
                index = i;
                break;
            }
        }
        if (index >= 0) {
            await section.removePlane(index).then(() => {
                const count = section.getCount();
                if (count === 0) {
                    section.deactivate();
                }
            });
        }
    }
    private async addPlaneNormal(sectionName: SectionName, cuttingType: CuttingType) {
        this.initCallback();
        const { status, section, plane, referenceGeometry, normal } = this.getPlanePropertiesNormal(sectionName);
        if (status === SectionStatus.NoFaceCutOff) {
            if (this.state.active?.includes('toggle-section')) {
                await this.addPlaneExtend(this.section4!, plane!, referenceGeometry!);
            } else {
                await this.addPlaneExtend(section!, plane!, referenceGeometry!)
            }
        } else {
            if (this.state.active?.includes('toggle-section')) {
                await this.removePlaneSection(this.section4!, normal!);
            } else {
                await section?.clear();
            }
        }
    }
    private async isCutting() {
        await this.updateRefGeo();
        await this.initPlane();
    }

    /** reverse plane */
    private async reverseDistanceAndNormal(cuttingSection: Communicator.CuttingSection, referenceGeometry: Communicator.Point3[], index: number) {
        const pl: Communicator.Plane | null = cuttingSection.getPlane(index);
        if (pl) {
            const { normal } = pl;
            const { d } = pl;
            pl.normal = new Communicator.Point3(
                normal.x === 0 ? 0 : -normal.x,
                normal.y === 0 ? 0 : -normal.y,
                normal.z === 0 ? 0 : -normal.z,
            );
            pl.d = -d;
            await cuttingSection.removePlane(index).then(() => {
                cuttingSection.addPlane(pl, referenceGeometry);
            });
        }
    }
    private async reverseDistanceAndNormalForSectionXYZ(normal: Communicator.Point3) {
        const count = this.section4?.getCount() ?? 0;
        const promiseList = [];
        for (let i = 0; i < count; i += 1) {
            const temp = this.section4?.getPlane(i);
            const referenceGeometry = this.section4?.getReferenceGeometry(i);
            if (temp?.normal.equals(normal)) {
                promiseList.push(new Promise((resolve) => {
                    this.reverseDistanceAndNormal(this.section4!, referenceGeometry!, i).then(() => {
                        resolve(true);
                    });
                }));
                break;
            }
        }
        await Promise.all([...promiseList])
    }
    private async reversePlaneModeMultipleSides(sectionName: SectionName) {
        let section1: Communicator.CuttingSection | null = null;
        let section2: Communicator.CuttingSection | null = null;
        switch (sectionName) {
            case SectionName.SectionX:
                section1 = this.section1;
                section2 = this.section4;
                break;
            case SectionName.SectionY:
                section1 = this.section2;
                section2 = this.section5;
                break;
            case SectionName.SectionZ:
                section1 = this.section3;
                section2 = this.section6;
                break;
            default:
        }
        if (section1 !== null && section2 !== null) {
            const s1Count = section1.getCount();
            const s2Count = section2.getCount();
            if (s1Count === 1 && s2Count === 1) {
                const plane1 = section1.getPlane(0);
                const plane2 = section2.getPlane(0);
                let refGeo1: Communicator.Point3[] | null = null;
                const temp1 = section1.getReferenceGeometry(0);
                if (temp1) refGeo1 = [...temp1];
                let refGeo2: Communicator.Point3[] | null = null;
                const temp2 = section2.getReferenceGeometry(0);
                if (temp2) refGeo2 = [...temp2];
                const promiseList = [];
                promiseList.push(
                    new Promise((resolve) => {
                        section1!.clear().then(() => {
                            resolve(true);
                        });
                    }), new Promise((resolve) => {
                        section2!.clear().then(() => {
                            resolve(true);
                        });
                    }),
                );
                await Promise.all([...promiseList]).then(() => {
                    const tempPl1 = plane1!.copy();
                    const tempPl2 = plane2!.copy();
                    tempPl1.d = -plane2!.d;
                    tempPl2.d = -plane1!.d;
                    this.addPlaneExtend(section1!, tempPl1, refGeo1!);
                    this.addPlaneExtend(section1!, tempPl2, refGeo2!);
                });
            } else {
                let plane: Communicator.Plane | null = null;
                let index = -1;
                let refGeo: Communicator.Point3[] | null = null;
                for (let i = 0; i < s1Count; i += 1) {
                    const temp = section1.getPlane(i);
                    const tempGeo = section1.getReferenceGeometry(i);
                    if (temp) {
                        if (temp.normal.x < 0 || temp.normal.y < 0 || temp.normal.z < 0) {
                            index = i;
                            plane = temp.copy();
                            if (tempGeo) refGeo = [...tempGeo];
                            break;
                        }
                    }
                }
                const promiseList = [];
                promiseList.push(
                    new Promise((resolve) => {
                        section1!.removePlane(index).then(() => {
                            resolve(true);
                        });
                    }), new Promise((resolve) => {
                        section2!.addPlane(plane!, refGeo!).then(() => {
                            resolve(true);
                        });
                    }),
                );
                await Promise.all([...promiseList]).then(() => {
                    const plane1 = section1!.getPlane(0);
                    const plane2 = section2!.getPlane(0);
                    const tempRefGeo1 = section1!.getReferenceGeometry(0);
                    const tempRefGeo2 = section2!.getReferenceGeometry(0);
                    const tempPl1 = plane1!.copy();
                    const tempPl2 = plane2!.copy();
                    tempPl1.d = -plane2!.d;
                    tempPl2.d = -plane1!.d;
                    section1!.clear().then(() => {
                        this.addPlaneExtend(section1!, tempPl1, tempRefGeo1!);
                    });
                    section2!.clear().then(() => {
                        this.addPlaneExtend(section2!, tempPl2, tempRefGeo2!);
                    });
                });
            }
        }
    }
    private async reversePlaneExtend(sectionName: SectionName) {
        switch (sectionName) {
            case SectionName.SectionX:
                if (this.statusX !== SectionStatus.NoFaceCutOff) {
                    if (this.state.active?.includes('toggle-section')) {
                        if (this.statusX === SectionStatus.PresentCutOff) {
                            await this.reverseDistanceAndNormalForSectionXYZ(Normal.NormalX);
                        } else if (this.statusX === SectionStatus.ReversesTheCutOfTheCutters) {
                            await this.reverseDistanceAndNormalForSectionXYZ(Normal.NormalMinusX)
                        }
                    } else if (this.state.active?.includes('multi')) {
                        await this.reversePlaneModeMultipleSides(sectionName)
                    } else {
                        await this.reverseDistanceAndNormal(this.section1!, this.referenceGeometryX!, 0);
                    }

                    if (this.statusX === SectionStatus.PresentCutOff) {
                        this.statusX = SectionStatus.ReversesTheCutOfTheCutters
                    } else {
                        this.statusX = SectionStatus.PresentCutOff
                    }
                }
                break;
            case SectionName.SectionY:
                if (this.statusY !== SectionStatus.NoFaceCutOff) {
                    if (this.state.active?.includes('toggle-section')) {
                        if (this.statusY === SectionStatus.PresentCutOff) {
                            await this.reverseDistanceAndNormalForSectionXYZ(Normal.NormalY);
                        } else if (this.statusY === SectionStatus.ReversesTheCutOfTheCutters) {
                            await this.reverseDistanceAndNormalForSectionXYZ(Normal.NormalMinusY)
                        }
                    } else if (this.state.active?.includes('multi')) {
                        await this.reversePlaneModeMultipleSides(sectionName)
                    } else {
                        await this.reverseDistanceAndNormal(this.section2!, this.referenceGeometryY!, 0);
                    }

                    if (this.statusY === SectionStatus.PresentCutOff) {
                        this.statusY = SectionStatus.ReversesTheCutOfTheCutters
                    } else {
                        this.statusY = SectionStatus.PresentCutOff
                    }
                }
                break;
            case SectionName.SectionZ:
                if (this.statusZ !== SectionStatus.NoFaceCutOff) {
                    if (this.state.active?.includes('toggle-section')) {
                        if (this.statusZ === SectionStatus.PresentCutOff) {
                            await this.reverseDistanceAndNormalForSectionXYZ(Normal.NormalZ);
                        } else if (this.statusZ === SectionStatus.ReversesTheCutOfTheCutters) {
                            await this.reverseDistanceAndNormalForSectionXYZ(Normal.NormalMinusZ)
                        }
                    } else if (this.state.active?.includes('multi')) {
                        await this.reversePlaneModeMultipleSides(sectionName)
                    } else {
                        await this.reverseDistanceAndNormal(this.section3!, this.referenceGeometryZ!, 0);
                    }

                    if (this.statusZ === SectionStatus.PresentCutOff) {
                        this.statusZ = SectionStatus.ReversesTheCutOfTheCutters
                    } else {
                        this.statusZ = SectionStatus.PresentCutOff
                    }
                }
                break;
            default:
        }
        await this.isCutting();
    }

    /** visible */
    private copyPlaneOfSection(section: Communicator.CuttingSection): Communicator.Plane[] {
        const arrPlane = [];
        const count = section.getCount();
        if (count !== 0) {
            for (let i = 0; i < count; i += 1) {
                const temp = section.getPlane(i);
                if (temp) {
                    const plane = temp.copy();
                    arrPlane.push(plane);
                }
            }
        }
        return arrPlane;
    }
    private async visiableCuttingSection(cuttingSection: Communicator.CuttingSection) {
        if (!cuttingSection || !cuttingSection.isActive()) {
            return;
        }
        const arrayPlane = this.copyPlaneOfSection(cuttingSection);
        await cuttingSection.clear();
        while (arrayPlane.length !== 0) {
            const plane: Communicator.Plane | undefined = arrayPlane.pop();
            if (plane) {
                let refGeo: Communicator.Point3[] | null = null;
                if (
                    plane.normal.equals(Normal.NormalX)
                    || plane.normal.equals(Normal.NormalMinusX)
                ) {
                    refGeo = this.referenceGeometryX;
                } else if (
                    plane.normal.equals(Normal.NormalY)
                    || plane.normal.equals(Normal.NormalMinusY)
                ) {
                    refGeo = this.referenceGeometryY;
                } else if (
                    plane.normal.equals(Normal.NormalZ)
                    || plane.normal.equals(Normal.NormalMinusZ)
                ) {
                    refGeo = this.referenceGeometryZ;
                }
                cuttingSection.addPlane(plane, refGeo);
            }
        }
    }
    private async applyRefGeo(section: Communicator.CuttingSection, arrPlane: Communicator.Plane[]) {
        if (arrPlane.length !== 0) {
            arrPlane.forEach((p) => {
                const plane = p.copy();
                let refGeo = null;
                if (
                    plane.normal.equals(Normal.NormalX)
                    || plane.normal.equals(Normal.NormalMinusX)
                ) {
                    if (this.referenceGeometryX) refGeo = [...this.referenceGeometryX];
                } else if (
                    plane.normal.equals(Normal.NormalY)
                    || plane.normal.equals(Normal.NormalMinusY)
                ) {
                    if (this.referenceGeometryY) refGeo = [...this.referenceGeometryY];
                } else if (
                    plane.normal.equals(Normal.NormalZ)
                    || plane.normal.equals(Normal.NormalMinusZ)
                ) {
                    if (this.referenceGeometryZ) refGeo = [...this.referenceGeometryZ];
                }
                section.addPlane(plane, refGeo);
            });
        }
    }
    private async updateRefGeoForModeMultipleSides() {
        if (this.section4 && this.section4.getCount() !== 0) {
            const arrPlane: Communicator.Plane[] = this.copyPlaneOfSection(this.section4);
            await this.section4.clear().then(() => {
                this.applyRefGeo(this.section4!, arrPlane).then(() => {
                    this.section4!.activate();
                });
            });
        }
        if (this.section5 && this.section5.getCount() !== 0) {
            const arrPlane: Communicator.Plane[] = this.copyPlaneOfSection(this.section5);
            await this.section5.clear().then(() => {
                this.applyRefGeo(this.section5!, arrPlane).then(() => {
                    this.section5!.activate();
                });
            });
        }
        if (this.section6 && this.section6.getCount() !== 0) {
            const arrPlane: Communicator.Plane[] = this.copyPlaneOfSection(this.section6);
            await this.section6.clear().then(() => {
                this.applyRefGeo(this.section6!, arrPlane).then(() => {
                    this.section6!.activate();
                });
            });
        }
    }
    private async updateRefGeoForSection() {
        const promiseList = [];
        if (this.state.active?.includes('toggle-section')) {
            const arrPlane: Communicator.Plane[] = this.copyPlaneOfSection(this.section4!);
            if (arrPlane.length !== 0) {
                promiseList.push(new Promise((resolve) => {
                    this.section4!.clear().then(() => {
                        this.applyRefGeo(this.section4!, arrPlane).then(() => {
                            this.section4!.activate();
                        });
                    });
                }));
            }
        } else if (this.state.active?.includes('plane-box')) {
            const arrPlane1: Communicator.Plane[] = this.copyPlaneOfSection(this.section1!);
            if (arrPlane1.length !== 0) {
                promiseList.push(new Promise((resolve) => {
                    this.section1!.clear().then(() => {
                        this.applyRefGeo(this.section1!, arrPlane1).then(() => {
                            this.section1!.activate().then(() => {
                                resolve(true);
                            });
                        });
                    });
                }));
            }
            const arrPlane2: Communicator.Plane[] = this.copyPlaneOfSection(this.section2!);
            if (arrPlane2.length !== 0) {
                promiseList.push(new Promise((resolve) => {
                    this.section2!.clear().then(() => {
                        this.applyRefGeo(this.section2!, arrPlane2).then(() => {
                            this.section2!.activate().then(() => resolve(true));
                        });
                    });
                }));
            }
            const arrPlane3: Communicator.Plane[] = this.copyPlaneOfSection(this.section3!);
            if (arrPlane3.length !== 0) {
                promiseList.push(new Promise((resolve) => {
                    this.section3!.clear().then(() => {
                        this.applyRefGeo(this.section3!, arrPlane3).then(() => {
                            this.section3!.activate().then(() => resolve(true));
                        });
                    });
                }));
            }
            promiseList.push(new Promise((resolve) => {
                this.updateRefGeoForModeMultipleSides().then(() => resolve(true));
            }));
        }
        else {
            if (this.statusX !== SectionStatus.NoFaceCutOff) {
                const arrPlane: Communicator.Plane[] = this.copyPlaneOfSection(this.section1!);
                if (arrPlane.length !== 0) {
                    promiseList.push(new Promise((resolve) => {
                        this.section1!.clear().then(() => {
                            this.applyRefGeo(this.section1!, arrPlane).then(() => {
                                this.section1!.activate().then(() => {
                                    resolve(true);
                                });
                            });
                        });
                    }));
                }
            }
            if (this.statusY !== SectionStatus.NoFaceCutOff) {
                const arrPlane: Communicator.Plane[] = this.copyPlaneOfSection(this.section2!);
                if (arrPlane.length !== 0) {
                    promiseList.push(new Promise((resolve) => {
                        this.section2!.clear().then(() => {
                            this.applyRefGeo(this.section2!, arrPlane).then(() => {
                                this.section2!.activate().then(() => resolve(true));
                            });
                        });
                    }));
                }
            }
            if (this.statusZ !== SectionStatus.NoFaceCutOff) {
                const arrPlane: Communicator.Plane[] = this.copyPlaneOfSection(this.section3!);
                if (arrPlane.length !== 0) {
                    promiseList.push(new Promise((resolve) => {
                        this.section3!.clear().then(() => {
                            this.applyRefGeo(this.section3!, arrPlane).then(() => {
                                this.section3!.activate().then(() => resolve(true));
                            });
                        });
                    }));
                }
            }
            if (this.state.active?.includes('multi')) {
                promiseList.push(new Promise((resolve) => {
                    this.updateRefGeoForModeMultipleSides().then(() => resolve(true));
                }));
            }
        }
        await Promise.all([...promiseList])
    }

    /** mode multi */
    private async addPlaneModeMultipleSides(sectionName: SectionName) {
        let sectionFist: Communicator.CuttingSection | null = null;
        let sectionSeconth: Communicator.CuttingSection | null = null;
        let status: SectionStatus | null = null;
        let planeFist: Communicator.Plane | null = null;
        let planeSeconth: Communicator.Plane | null = null;
        let referenceGeometryFist: Communicator.Point3[] | null = null;
        let referenceGeometrySeconth: Communicator.Point3[] | null = null;

        switch (sectionName) {
            case SectionName.SectionX: {
                sectionFist = this.section1;
                sectionSeconth = this.section4;
                status = this.statusX;
                if (this.modelBoundingBox) {
                    planeFist = Communicator.Plane.createFromPointAndNormal(this.modelBoundingBox.max, Normal.NormalX);
                    planeSeconth = Communicator.Plane.createFromPointAndNormal(this.modelBoundingBox.min, Normal.NormalMinusX);
                }
                if (this.referenceGeometryX) {
                    referenceGeometryFist = [...this.referenceGeometryX];
                    referenceGeometrySeconth = [...this.referenceGeometryX];
                }
                break;
            }
            case SectionName.SectionY: {
                sectionFist = this.section2;
                sectionSeconth = this.section5;
                status = this.statusY;
                if (this.modelBoundingBox) {
                    planeFist = Communicator.Plane.createFromPointAndNormal(this.modelBoundingBox.max, Normal.NormalY);
                    planeSeconth = Communicator.Plane.createFromPointAndNormal(this.modelBoundingBox.min, Normal.NormalMinusY);
                }
                if (this.referenceGeometryY) {
                    referenceGeometryFist = [...this.referenceGeometryY];
                    referenceGeometrySeconth = [...this.referenceGeometryY];
                }
                break;
            }
            case SectionName.SectionZ: {
                sectionFist = this.section3;
                sectionSeconth = this.section6;
                status = this.statusZ;
                if (this.modelBoundingBox) {
                    planeFist = Communicator.Plane.createFromPointAndNormal(this.modelBoundingBox.max, Normal.NormalZ);
                    planeSeconth = Communicator.Plane.createFromPointAndNormal(this.modelBoundingBox.min, Normal.NormalMinusZ);
                }
                if (this.referenceGeometryZ) {
                    referenceGeometryFist = [...this.referenceGeometryZ];
                    referenceGeometrySeconth = [...this.referenceGeometryZ];
                }
                break;
            }
            default:
        }

        if (status !== null && status === SectionStatus.NoFaceCutOff) {
            const promiseList = [];
            promiseList.push(new Promise((resolve, reject) => {
                this.addPlaneExtend(sectionFist!, planeFist!, referenceGeometryFist!).then(() => {
                    resolve(true);
                });
            }), new Promise((resolve, reject) => {
                this.addPlaneExtend(sectionSeconth!, planeSeconth!, referenceGeometrySeconth!).then(() => {
                    resolve(true);
                });
            }));
            await Promise.all([...promiseList])
        } else {
            const promiseList = [];
            const pr1 = new Promise((resolve, reject) => {
                sectionFist!.clear().then(() => {
                    resolve(true);
                });
            });
            const pr2 = new Promise((resolve, reject) => {
                sectionSeconth!.clear().then(() => {
                    resolve(true);
                });
            });
            promiseList.push(pr1, pr2);
            await Promise.all([...promiseList])
        }
    }
    private async removePlaneByNormal(section: Communicator.CuttingSection, normal: Communicator.Point3) {
        let index = -1;
        const count = section.getCount();
        for (let i = 0; i < count; i += 1) {
            const plane = section.getPlane(i);
            if (plane) {
                if (plane.normal.equals(normal)) {
                    index = i;
                    break;
                }
            }
        }
        if (index >= 0) {
            await section.removePlane(index);
        }
    }

    private async turnOffMultipleSides() {
        const promiseList = [];
        promiseList.push(
            new Promise((resolve, reject) => {
                this.removePlaneByNormal(this.section1!, Normal.NormalMinusX).then(() => {
                    resolve(true);
                });
            }), new Promise((resolve, reject) => {
                this.removePlaneByNormal(this.section2!, Normal.NormalMinusY).then(() => {
                    resolve(true);
                });
            }), new Promise((resolve, reject) => {
                this.removePlaneByNormal(this.section3!, Normal.NormalMinusZ).then(() => {
                    resolve(true);
                });
            }),
            new Promise((resolve, reject) => {
                this.section4!.clear().then(() => {
                    resolve(true);
                });
            }), new Promise((resolve, reject) => {
                this.section5!.clear().then(() => {
                    resolve(true);
                });
            }), new Promise((resolve, reject) => {
                this.section6!.clear().then(() => {
                    resolve(true);
                });
            }),
        );
        await Promise.all([...promiseList])
    }
    private async turnOnMultipleSides() {
        const promiseList = [];
        if (this.statusX !== SectionStatus.NoFaceCutOff) {
            const sectionRefgeo = this.section1!.getReferenceGeometry(0);
            let refGeo: Communicator.Point3[] | null = null;
            if (sectionRefgeo !== null) { refGeo = [...sectionRefgeo]; }
            if (this.statusX === SectionStatus.ReversesTheCutOfTheCutters) {
                promiseList.push(new Promise((resolve, reject) => {
                    this.reverseDistanceAndNormal(this.section1!, refGeo!, 0).then(() => {
                        this.statusX = SectionStatus.PresentCutOff;
                        resolve(true);
                    });
                }));
            }
            if (this.statusX === SectionStatus.PresentCutOff && this.modelBoundingBox) {
                const plane = Communicator.Plane.createFromPointAndNormal(this.modelBoundingBox.min, Normal.NormalMinusX);
                promiseList.push(new Promise((resolve, reject) => {
                    this.addPlaneExtend(this.section4!, plane, refGeo!).then(() => {
                        resolve(true);
                    });
                }));
            }
        }
    }

    private async turnOnMultipleSides2() {
        if (this.statusX !== SectionStatus.NoFaceCutOff) {
            await this.AddMultiPlan(Normal.NormalMinusX, this.section1!, this.section4!, this.statusX, SectionName.SectionX);
        }
        if (this.statusY !== SectionStatus.NoFaceCutOff) {
            await this.AddMultiPlan(Normal.NormalMinusY, this.section2!, this.section5!, this.statusY, SectionName.SectionY);
        }
        if (this.statusZ !== SectionStatus.NoFaceCutOff) {
            await this.AddMultiPlan(Normal.NormalMinusZ, this.section3!, this.section6!, this.statusZ, SectionName.SectionZ);
        }
    }

    private async AddMultiPlan(normal: Communicator.Point3, section: Communicator.CuttingSection
        , sectionMulti: Communicator.CuttingSection, isReverses: SectionStatus, name: SectionName) {
        const promiseList = [];
        const sectionRefgeo = section.getReferenceGeometry(0);
        let refGeo: Communicator.Point3[] | null = null;
        if (sectionRefgeo !== null) { refGeo = [...sectionRefgeo]; }
        if (isReverses === SectionStatus.ReversesTheCutOfTheCutters) {
            promiseList.push(new Promise((resolve, reject) => {
                this.reverseDistanceAndNormal(section, refGeo!, 0).then(() => {
                    this.SetStatusPresenCutoff(name);
                    resolve(true);
                });
            }));
        }
        else if (this.modelBoundingBox) {
            const plane = Communicator.Plane.createFromPointAndNormal(this.modelBoundingBox.min, normal);
            promiseList.push(new Promise((resolve, reject) => {
                this.addPlaneExtend(sectionMulti, plane, refGeo!).then(() => {
                    resolve(true);
                });
            }));
        }
        isReverses = SectionStatus.ReversesTheCutOfTheCutters;
    }

    private SetStatusPresenCutoff(name: SectionName) {

        switch (name) {
            case SectionName.SectionX: {
                this.statusX = SectionStatus.PresentCutOff;
                break;
            }
            case SectionName.SectionY: {
                this.statusY = SectionStatus.PresentCutOff;
                break;
            }
            case SectionName.SectionZ: {
                this.statusZ = SectionStatus.PresentCutOff;
                break;
            }
        }
    }

    private async changeModeMulti() {
        if (this.state.active?.includes('multi')) {
            await this.turnOffMultipleSides()
        } else {
            await this.turnOnMultipleSides2();
        }
    }

    /** reset */
    private async clearAllSection() {
        const promiseList = [];
        const { cuttingManager } = this.viewer;
        const count = cuttingManager.getCuttingSectionCount();
        for (let i = 0; i < count; i += 1) {
            const section = cuttingManager.getCuttingSection(i);
            if (section) {
                promiseList.push(new Promise((resolve, reject) => {
                    section.clear().then(() => {
                        resolve(true);
                    });
                }));
            }
        }
        await Promise.all([...promiseList])
    }
    private async clearAllProps() {
        this.isVisibility = false;
        this.cuttingPlaneOperator.setVisibility(this.isVisibility)
        this.statusX = SectionStatus.NoFaceCutOff;
        this.statusY = SectionStatus.NoFaceCutOff;
        this.statusZ = SectionStatus.NoFaceCutOff;
        await this.initCuttingSection();
        await this.updateRefGeo();
        await this.initPlane();
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async resetCuttingPlane() {
        this.modelBoundingBox = await this.viewer.model.getModelBounding(true, true);
        if (this.modelBoundingBox) {
            const ration = (this.modelBoundingBox.copy().max.x - this.modelBoundingBox.copy().min.x) / 100
            this.modelBoundingBox.min.x = this.modelBoundingBox.copy().min.x - ration
            this.modelBoundingBox.min.y = this.modelBoundingBox.copy().min.y - ration
            this.modelBoundingBox.min.z = this.modelBoundingBox.copy().min.z - ration
            this.modelBoundingBox.max.x = this.modelBoundingBox.copy().max.x - ration
            this.modelBoundingBox.max.y = this.modelBoundingBox.copy().max.y - ration
            this.modelBoundingBox.max.z = this.modelBoundingBox.copy().max.z - ration
        }
        await this.clearAllSection();
        await this.isCutting();
        await this.clearAllProps();
    }

    // only for markup
    public async closeCuttingPlanes(): Promise<CuttingPlaneState> {
        const stateActive: CuttingType[] = [];
        for (let i = 0; i < 6; i++) {
            const section = this._viewer.cuttingManager.getCuttingSection(i);
            const numOfPlanes = section?.getCount();
            if (i === 0 && numOfPlanes === 1) stateActive.push('plane-x');
            if (i === 1 && numOfPlanes === 1) stateActive.push('plane-y');
            if (i === 2 && numOfPlanes === 1) stateActive.push('plane-z');
            if (i === 3 && numOfPlanes === 1) stateActive.push('multi');
            if (i === 4 && numOfPlanes === 1 && !stateActive.includes('multi')) stateActive.push('multi');
            if (i === 5 && numOfPlanes === 1 && !stateActive.includes('multi')) stateActive.push('multi');
        }
        this.updateState({ active: stateActive });
        if (stateActive.includes('plane-x')) this.statusX = SectionStatus.PresentCutOff;
        if (stateActive.includes('plane-y')) this.statusY = SectionStatus.PresentCutOff;
        if (stateActive.includes('plane-z')) this.statusZ = SectionStatus.PresentCutOff;
        await this.visibility(true);
        this.cuttingPlaneOperator.handleOperatorActive.mapGroupHandelId_NodeId.clear();
        return this.state;
    }

    public async closeCuttingSections(): Promise<CuttingPlaneState> {
        const markupMode = GlobalState.markupMode
        if ((this.state && this.state.active && this.state.active.length > 0) || markupMode === 'viewMode') {
            await this.resetCuttingPlane();
            this.cuttingPlaneOperator.unregisterCustomHandleOperator();
            return this.initState();
        }
        return this.state;
    }
}
