import { LineStyle, MarkupFillType } from "common/type-markup";
import MathHelper from "container/pdf-viewer/helper/math.helper";
import { BaseElementCanvas } from "./markup.base-canvas.element";

/* eslint-disable indent */
export class CircleElementCanvas extends BaseElementCanvas {
    private radius = 0;
    constructor() {
        super();
        this.offSet = 20;
        this.baseCanvas = document.createElement('canvas');
        this.baseCanvas.style.position = 'absolute';
        this.baseCanvas.style.zIndex = '1';
        this.baseCanvas.style.width = 'inherit';
        this.baseCanvas.style.height = 'inherit';
    }

    getCircleCanvas(): HTMLElement {
        switch (this._lineStyle) {
            case LineStyle.cloud:
                this.drawCloudStyle();
                break;
            case LineStyle.zigzag:
                this.drawZizZacStyle();
                break;
            default:
                this.draw();
        }
        return this.baseCanvas;
    }

    draw(): void {
        if (!this.firstPoint) return;
        const width = (this.radius + this.strokeWidth + this.offSet) * 2;
        const height = (this.radius + this.strokeWidth + this.offSet) * 2;
        this.baseCanvas.style.left = `${this.firstPoint.x - this.radius - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.style.top = `${this.firstPoint.y - this.radius - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.width = width;
        this.baseCanvas.height = height;
        this.baseCanvas.style.width = `${width}px`;
        this.baseCanvas.style.height = `${height}px`;
        this.baseCanvas.style.transform = `rotate(${this.getRotation()}deg)`;
        const ctx = this.baseCanvas.getContext('2d');
        if (ctx) {
            ctx.lineJoin = this.lineJoin;
            ctx.lineCap = this.lineCap;
            MathHelper.applyLineStyle(ctx, this._lineStyle);
            ctx.clearRect(0, 0, this.baseCanvas.width, this.baseCanvas.height);

            ctx.beginPath();
            ctx.lineWidth = this.strokeWidth;
            ctx.arc(this.radius + this.offSet + this.strokeWidth, this.radius + this.offSet + this.strokeWidth, this.radius, 0, 2 * Math.PI);
            if (this.fillType === MarkupFillType.Opaque && this.fillColor) {
                const color = MathHelper.communicatorColorToRGBAString(this.fillColor, this.fillOpacity);
                if (color) {
                    ctx.fillStyle = color;
                    ctx.fill();
                }
            }
            if (this.strokeColor) {
                const color = MathHelper.rgbToHex(this.strokeColor.r, this.strokeColor.g, this.strokeColor.b);
                if (color) {
                    ctx.strokeStyle = color;
                }
            }
            ctx.stroke();
            ctx.closePath();
        }

    }

    drawZizZacStyle(): void {
        if (!this.firstPoint) return;
        const width = (this.radius + this.strokeWidth + this.offSet) * 2;
        const height = (this.radius + this.strokeWidth + this.offSet) * 2;
        this.baseCanvas.style.left = `${this.firstPoint.x - this.radius - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.style.top = `${this.firstPoint.y - this.radius - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.width = width;
        this.baseCanvas.height = height;
        this.baseCanvas.style.width = `${width}px`;
        this.baseCanvas.style.height = `${height}px`;
        const ctx = this.baseCanvas.getContext('2d');
        if (ctx) {
            ctx.lineJoin = this.lineJoin;
            ctx.lineCap = this.lineCap;
            ctx.clearRect(0, 0, this.baseCanvas.width, this.baseCanvas.height);

            ctx.beginPath();

            this.zicZacCircle(new Communicator.Point2(this.radius + this.offSet + this.strokeWidth,
                this.radius + this.offSet + this.strokeWidth),
                this.radius, this.strokeWidth, ctx);

            ctx.lineWidth = this.strokeWidth;
            if (this.fillType === MarkupFillType.Opaque && this.fillColor) {
                const color = MathHelper.communicatorColorToRGBAString(this.fillColor, this.fillOpacity);
                if (color) {
                    ctx.fillStyle = color;
                    ctx.fill();
                }
            }
            if (this.strokeColor) {
                const color = MathHelper.rgbToHex(this.strokeColor.r, this.strokeColor.g, this.strokeColor.b);
                if (color) {
                    ctx.strokeStyle = color;
                }
            }
            ctx.stroke();
            ctx.closePath();
        }
    }

    zicZacCircle(center: Communicator.Point2, radius: number, strokeWidth: number, ctx: CanvasRenderingContext2D): void {
        const length = 2 * Math.PI * radius;
        const a = length / (this.offSet / 2);
        const step = Math.ceil(a);
        const args: number[] = [];

        for (let i = 0; i <= step; i++) {
            args.push(((2 * Math.PI) / step) * i);
        }
        for (let i = 0; i < args.length - 1; i++) {
            const strArg = args[i];
            const endArg = args[i + 1];

            const strPnt = new Communicator.Point2(center.x + radius * Math.cos(strArg), center.y + radius * Math.sin(strArg));
            const endPnt = new Communicator.Point2(center.x + radius * Math.cos(endArg), center.y + radius * Math.sin(endArg));

            if (i === 0) {
                ctx.moveTo(strPnt.x, strPnt.y);
            }

            const vec = endPnt.copy().subtract(strPnt.copy());
            const tmpVec = vec.copy().scale(1 / 4);
            const upVec = new Communicator.Point2(vec.y, -vec.x);
            upVec.scale(1 / 4);
            const downVec = new Communicator.Point2(-upVec.x, -upVec.y);

            let upPnt = strPnt.copy().add(tmpVec.copy());
            upPnt = upPnt.copy().add(upVec.copy());
            ctx.lineTo(upPnt.x, upPnt.y);

            let downPnt = strPnt.copy().add(tmpVec.copy().scale(3));
            downPnt = downPnt.copy().add(downVec.copy());
            ctx.lineTo(downPnt.x, downPnt.y);

            ctx.lineTo(endPnt.x, endPnt.y);
        }
    }

    drawCloudStyle(): void {
        if (!this.firstPoint) return;
        const width = (this.radius + this.strokeWidth + this.offSet) * 2;
        const height = (this.radius + this.strokeWidth + this.offSet) * 2;
        this.baseCanvas.style.left = `${this.firstPoint.x - this.radius - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.style.top = `${this.firstPoint.y - this.radius - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.width = width;
        this.baseCanvas.height = height;
        this.baseCanvas.style.width = `${width}px`;
        this.baseCanvas.style.height = `${height}px`;
        const ctx = this.baseCanvas.getContext('2d');
        if (ctx) {
            ctx.lineJoin = this.lineJoin;
            ctx.lineCap = this.lineCap;
            ctx.clearRect(0, 0, this.baseCanvas.width, this.baseCanvas.height);

            ctx.beginPath();
            this.cloudCircle(new Communicator.Point2(this.radius + this.offSet + this.strokeWidth,
                this.radius + this.offSet + this.strokeWidth),
                this.radius, this.strokeWidth, ctx);

            ctx.lineWidth = this.strokeWidth;
            if (this.fillType === MarkupFillType.Opaque && this.fillColor) {
                const color = MathHelper.communicatorColorToRGBAString(this.fillColor, this.fillOpacity);
                if (color) {
                    ctx.fillStyle = color;
                    ctx.fill();
                }
            }
            if (this.strokeColor) {
                const color = MathHelper.rgbToHex(this.strokeColor.r, this.strokeColor.g, this.strokeColor.b);
                if (color) {
                    ctx.strokeStyle = color;
                }
            }
            ctx.stroke();
            ctx.closePath();
        }

    }

    cloudCircle(center: Communicator.Point2, radius: number, strokeWidth: number, ctx: CanvasRenderingContext2D): void {
        const length = 2 * Math.PI * radius;
        const a = length / (this.offSet / 2);
        const step = Math.ceil(a);
        const args: number[] = [];

        for (let i = 0; i <= step; i++) {
            args.push(((2 * Math.PI) / step) * i);
        }

        for (let i = 0; i < args.length - 1; i++) {
            const strArg = args[i];
            const endArg = args[(i + 1) % args.length];

            const strPnt = new Communicator.Point2(center.x + radius * Math.cos(strArg), center.y + radius * Math.sin(strArg));
            const endPnt = new Communicator.Point2(center.x + radius * Math.cos(endArg), center.y + radius * Math.sin(endArg));

            const vec = strPnt.copy().subtract(endPnt.copy());
            const b = Math.atan2(vec.y, vec.x);
            ctx.arc((strPnt.x + endPnt.x) / 2, (strPnt.y + endPnt.y) / 2, vec.length() / 2, b, b + Math.PI);
        }
    }
    getRadius(): number {
        return this.radius;
    }

    setRadius(radius: number): void {
        this.radius = radius;
        const d = this.radius / (3 * Math.E);

        this.offSet = d > 4 * this.strokeWidth ? 2 * d : this.strokeWidth * 8;
    }
}
