import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initLeftNavPanel, ModeLeftPanelNew } from "pages/main-viewer/left-nav-bar/helper/left-nav-bar-helper";

const leftPanelAppSlice = createSlice({
    name: 'leftPanelApp',
    initialState: initLeftNavPanel,
    reducers: {
        setActiveLeftPanel: (state, action: PayloadAction<ModeLeftPanelNew[]>) => {
            state.forEach(app => {
                if (action.payload.includes(app.leftMode)) {
                    app.active = true
                } else {
                    app.active = false
                }
            })
        }
    }
})

export const {
    setActiveLeftPanel
} = leftPanelAppSlice.actions;

export default leftPanelAppSlice.reducer
