/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AntdTreeNodeAttribute } from "antd/lib/tree";
import { TagType } from "components/CAddTags";
import { MarkupBaseItem } from "container/viewer3d/markup/markup-items/markup.base.item";
import { RGBColor } from 'react-color';
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { ThemePalette } from "theme/ThemeProvider";
import { IconName } from "./define-name-icon";
import { LineStyle, Point } from "./type-markup";
import { StatusIssueType } from "./type-state";
import { Context3DType, ContextPdfType, CuttingType, OperatorType, PlaneBoxChild } from "./type-viewer";

export type RotateDeg = 0 | 45 | 90 | 135 | 180 | 225 | 270;

export interface TooltipPropsIcon {
    title: string;
    placement?: Placement
}
export interface PropsIcon extends Props {
    name?: IconName;
    colorSvg?: ColorCustom;
    hexColor?: string;
    svgSize?: Size;
    rotateDeg?: RotateDeg;
    tooltip?: TooltipPropsIcon;
    id?: string;
    canHover?: boolean;
    activeStyle?: boolean;
    disableStyle?: boolean;
    dropdownIcon?: boolean;
    borderStyle?: boolean;
    colorIndicator?: RGBColor | null;
    cornerIcon?: boolean;
    overrideFill?: boolean;
    text?: string;
}

export type Size = "default" | "mini" | "small" | "medium" | "big" | "large" | "middle" | "mediumXs";
export const SvgSizeNumber: Record<Size, string> = {
    default: "24px",
    mini: "6px",
    small: "14px",
    medium: "18px",
    big: "30px",
    large: "35px",
    middle: '21px',
    mediumXs: '16px'
};
export type SizeButton = 'default' | 'small' | 'large';
export const SizeButtonValue: Record<SizeButton, number> = {
    default: 34,
    small: 14,
    large: 40,
}
export type ColorCustom = keyof ThemePalette;

export interface FieldInputLoginRegister {
    loginName: string;
    password: string;
    confirm: string;
    email: string;
}

export interface ErrorValidate {
    required?: string;
    min?: string;
    max?: string;
    maxLength?: string;
    minLength?: string;
    pattern?: string;
}
export interface FieldInputItemLoginRegister {
    label: string;
    name: keyof FieldInputLoginRegister;
    icon: JSX.Element;
    type: string;
    rules: RegisterOptions;
    validateErrors: ErrorValidate;
}

export type RenderType = "ssr_session" | "csr_session";
export interface FileInfoResponse extends PayloadView {
    id?: string;
    filename: string;
    streamLocation: string;
    converter?: FormatViewer;
    modelFileId: string;
    creator?: { id: string; name: string };
    cacheSize?: number | string;
    originalSize?: number | string;
    importAssemblyTreeTime?: number | string;
    importTime?: number | string;
    createdDate?: Date | string;
    originalFile?: string;
    multiStream?: boolean;
    isRootModel?: number;
    extraConvertOutput?: string;
    originalFilePath?: string;
    cacheStatus?: number;
    cacheFilename?: string;
}
export interface ModelConvertStatus {
    convert2DSheet: ConvertStatus,
    convert3DModel: ConvertStatus,
    extractProperties:ConvertStatus
}
export enum ConvertStatus {
    NotConverted = 0,
    Converting,
    Converted,
    Error,
    Preparing,
    Waiting
}
export interface FileInfo extends FileInfoResponse {
    accountCacheFile?: string;
    viewId: string;
    loadingTime?: number | string;
}

export interface CombineChild {
    fileInfo: FileInfo,
    viewId: ViewId,
    modelFileId: ModelFileId,
    nodeRootChild: number[],
    isMulti?: boolean
}

export interface CombineChildrenProps  {
    combineChild?: CombineChild,
    rootCombineId?: ViewId
}

export type FormatViewer = 'Hoops' | 'Foxit';
export interface ViewActive {
    index?: number;
    baseFileId: string | null;
    name?: string;
    viewId: string;
    formatViewer?: FormatViewer;
    parentIdMerge?: string;
    extension: string;
    modelFileId: string
}

export enum Layout {
    Full = "full",
    TwoAndTwo = "2And2",
    OneOnTwo = "1On2",
    TwoOnOne = "2On1",
    OneAndTwo = "1And2",
    TwoAndOne = "2And1",
    OneAndOne = "1And1",
    OneOnOne = "1On1",
}

export enum UploadFileStatus {
    Uploading = 1,
    Success = 2,
    Error = 3
}

export interface PayloadOriginFile {
    baseFileId: string;
    baseMajorRev: number;
    baseMinorRev: number;
    fileName?: string;
    originalFilePath?: string;
    viewItemParameters?: any;
    modelFileId?: string;
}

export interface UploadFileInfo {
    filename: string,
    status: UploadFileStatus
}

export enum ErrorValidateForm {
    minLength = "minLength",
    required = "required",
    pattern = "pattern"
}

export type WrappedHOC = (props: Props) => JSX.Element;
export interface FilesTree {
    _id?: string;
    id: string;
    Id?: string;
    name: string;
    Name?: string;
    bodyInstance?: FilesTree[] | any;
    persistentId?: string,
    type?: number,
    childs?: FilesTree[];
    Childs?: FilesTree[];
}
export interface TreeNode {
    title: string | undefined;
    key: number;
    level: number;
    persistentId?: string;
    type?: number;
    children?: TreeNode[];
}
export interface DocumentNode extends BookmarkNode {
    key: number;
    children: DocumentNode[];
    level: number;
}
export interface DataDocumentsPdf {
    data: DocumentNode[],
    maxLevel: number,
    arrAllParent: number[]
}
export enum KeyCode {
    num0 = '0',
    num1 = '1',
    num2 = '2',
    num3 = '3',
    num4 = '4',
    num5 = '5',
    num6 = '6',
    num7 = '7',
    num8 = '8',
    num9 = '9',
    F1 = 'F1',
    F5 = 'F5',
    F12 = 'F12',
    F11 = 'F11',
    escape = 'Escape',
    enter = 'Enter',
    equals = '=',
    add = '+',
    p = 'p',
    P = 'P',
    f = 'f',
    F = 'F',
    g = 'g',
    G = 'G',
    a = 'a',
    A = 'A',
    z = 'z',
    Z = 'Z',
    y = 'y',
    Y = 'Y',
    s = 's',
    S = 'S',
    m = 'm',
    M = 'M',
    n = 'n',
    N = 'N',
    h = 'h',
    H = 'H',
    o = 'o',
    O = 'O',
    c = 'c',
    C = 'C',
    w = 'w',
    W = 'W',
    v = 'v',
    V = 'V',
    digit0 = '0',
    subtract = '-',
    minus = '_',
    pageUp = 'PageUp',
    pageDown = 'PageDown',
    arrowUp = 'ArrowUp',
    arrowDown = 'ArrowDown',
    arrowLeft = 'ArrowLeft',
    arrowRight = 'ArrowRight',
    home = 'Home',
    end = 'End',
    delete = 'Delete',
    BackQuote = 'Backquote',
    backQuote = '`',
    shift = 'Shift',
    ctrl = 'Control',
    alt = 'Alt',
    meta = 'Meta',
    tab = 'Tab',
    CapsLock = 'CapsLock',
    x = 'x',
    X = 'X',
    l = 'l',
    L = 'L',
    r = 'r',
    R = 'R',
}
export enum MouseButton {
    Left = 0,
    Middle = 1,
    Right = 2
}
export type Placement = | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';

export type MapStreamFile = Pick<StreamFile, 'baseFileId' | 'baseMajorRev' | 'baseMinorRev'>;
export interface ConvertOptions {
    convert3DModel: number;
    convert2DSheet: number;
    extractProperties: number;
    childModels: number
}
export interface StreamFile {
    filename: string;

    baseFileId: string;

    baseMajorRev: number;

    baseMinorRev: number;

    isChecked: boolean;

    status: any;
    child: StreamFile[];

    isDirectory: boolean;

    createdDate: string;

    cacheStatus: number;
    modelFileId: string;
    id: string;
    originalFilePath: string;
    convertOptions: ConvertOptions;
    drawingConvertStatus: ModelConvertStatus;
}

export interface ContextMenuState {
    visibleContext: boolean;
    mouseEventContext: React.MouseEvent | null;
    contentContext: React.ReactNode | null;
    contextMenuId: string;
    visibleConfirm: boolean;
    titleContent: TitleContentDialog;
    visibleInfo: boolean;
    visiblePrint: boolean;
}
export interface Action {
    type: string;
    payload?: unknown
}
export interface ItemListContextMenu {
    key: string;
    content: string | React.ReactNode;
}
export interface StatePdfCreate {
    pdfUI: PdfUI;
    pdfViewer: PdfViewer;
}
export interface ItemFolderViewer {
    name: string,
    isItemCad: boolean
}
export interface FolderView {
    name: string;
}

export type TypeMarkup = 'text' | 'shape' | 'line' | 'oval';

export interface MarkupItem {
    name: string;
    type: TypeMarkup
}
export interface MarkupLineFormat {
    lineColor: RGBColor;
    lineWeight: number;
    lineOpacity: number;
    fillColor: RGBColor;
    fillColorOption: boolean;
    startLineShapeType: number;
    endLineShapeType: number;
    lineStyle: LineStyle;
    rect: Rectangle;
    ratio: number;
}
export interface MarkupTextFormat {
    textFontSize: number;
    textColor: RGBColor;
    textFontFamily: string;
    textIsBold: boolean;
    textIsItalic: boolean;
    textIsUnderline: boolean;
}

export enum MarkupToolbarAction {
    DrawLine = 1,
    DrawArrow,
    DrawCircle,
    DrawEllipse,
    DrawRectangle,
    DrawPolygon,
    DrawPolyline,
    DrawSignature,
    DrawText,
    DrawTextBox,
    DrawNote,
    DrawImage,
    DrawStamp,
    DrawDrawing,
    DrawEraser,
    DrawHighlight,
    DrawRectHighlight,
    Undo,
    Redo,
    Save,
    Cancel,
    ShowPolygonInput,
    SelectImageFilePath,
    DrawPolygonCloud,
    DrawArc,
    DrawCallout,
    DrawNotePin3d,
    DrawCloud,
    DrawPinMarker,
    LineSetting,
    FormatSetting
}

export enum ViewType {
    SaveView = 0,
    MarkupView,
}

export interface PayloadView {
    baseFileId: string
    baseMajorRev?: number
    baseMinorRev?: number
    fileLocation?: string
}

export interface CameraCadView {
    Projection: string;
    definition: string;
    field: string;
}
export interface CadView {
    Camera: CameraCadView;
    Id: string;
    IsCameraSet: any;
    IsCombineState: any;
    IsCrosssectionSet: any;
    IsExplosionSet: any;
    IsGeomFilteringSet: any;
    IsPMIFilteringSet: any;
    ModelFileId: string;
    Name: string;
    isAnnotationView: string;
}


export interface CameraSaveView {
    className: string;
    height: number;
    nearLimit: number;
    position: any;
    projection: number;
    target: any;
    up: any;
    width: number;
}
export interface SaveView {
    Camera: any;
    CuttingPlane: any;
    Id?: string;
    ModelFileId: string;
    Name: string;
    NodeSetting: string;
    NodesHide: [];
    NodesShow: [];
    SheetId: any;
    createdBy: string;
    createdDate: number;
    drawMode: number;
    explodeMagnitude: number;
    isolateMode: number;
    modifiedDate: number;
    thumnail?: string;
    uniqueId?: string;
    viewType: ViewType;
    arrSelected: number[];
    visibilityState: Communicator.VisibilityState;
}

export interface Views {
    cadViews: CadView[];
    savedViews: SaveView[];
}
export interface PayloadActionInitViews {
    viewId: string;
    payload: PayloadView
}
export interface PairIDView {
    viewId: string;
    views: Views
}

export interface PayloadActionSavedViews {
    viewId: string;
    saveViews: SaveView[]
}

export interface PayloadLoadingSelectedView {
    viewId: string;
    value: boolean;
}

export interface PayloadActionSavedViewRename {
    viewId: string;
    saveViews: SaveView[];
    newName: string;
    id?: string
}

export interface PayloadActionSavedViewDelete {
    viewId: string;
    saveViews: SaveView[];
    id: string
}

export interface PayloadActionCadViews {
    viewId: string;
    cadViews: CadView[]
}
export type TypeItemLayoutMenu = 1 | 2 | 3 | 4 | 'view-only';
export interface ItemLayoutMenuChild {
    icon: IconName;
    type: Layout
}
export interface ItemLayoutMenu {
    title: string;
    icon: IconName;
    hasChild?: boolean;
    type: TypeItemLayoutMenu
}
export interface ImageItem {
    isSelected: boolean;
    image: string;
}
export type ModeChildrenPopover = 'default' | 'explode' | 'cutting' | 'clipping' | 'measure';
export type ModeNotDefault = Extract<OperatorType, 'explode' | 'save' | 'setting' | 'cutting-plane' | 'clipping-commands' | 'measureSetting'>;
export type ModeNotDefaultExtend = Exclude<ModeChildrenPopover, 'default'> | 'save' | 'setting';
export interface BtnCutting {
    type: CuttingType,
    icon: IconName,
    name: string
}
export interface BtnCuttingChild {
    type: PlaneBoxChild,
    icon: IconName,
    name: string
}
export interface StampItem {
    category: string,
    name: string,
    url: string,
    showUrl?: string,
    width?: number,
    height?: number,
    fileType?: string,
    annotType: string
}
export interface ItemContextMenu3D extends ItemListContextMenu {
    key: Context3DType;
}
export interface ItemContextMenuPdf extends ItemListContextMenu {
    key: ContextPdfType
}

export enum FolderViews {
    SavedViews = 'Folder of saved custom views',
    CadViews = 'Folder of cad views'
}

export enum TabsView {
    View = 'View',
    Other = 'Other'
}
export enum TabTreeViewsKeys {
    Models = 'Models',
    Sheets = 'Sheets',
    Views = 'Views',
    Levels = 'Levels'
}
export enum FileExtension {
    Revit = 'rvt',
    IFC = 'ifc',
    DWG = 'dwg',
    DGN = 'dgn'
}
export type ValueProperties = { [key: string]: unknown } | undefined;
export interface MapValueProperties {
    models: ValueProperties[];
    sheets: ValueProperties[];
    views: ValueProperties[]
}
export interface DateTimeSize {
    date: string;
    time: string;
    size: string
}
export interface Thumbnail {
    pageIndex: number;
    thumbnail: string
}
export interface MarkupGroupItem {
    uniqueId: Communicator.Uuid;
    title: string;
    description: string;
    type: string;
    priority: string;
    status: StatusIssueType;
    zoneArea: string;
    discipline: string;
    issueGroup: string;
    phase: string;
    assignedTo: string;
    startDate: Date;
    dueDate: Date;
    tags: TagType[];
}
export interface AllMarkupGroups {
    markupEntities: any[],
    listGroups: MarkupGroupItem[],
    markupViews: [],
}
export type NameMarkupItem =
    'MarkupBaseItem' |
    'Arrow' |
    'Circle' |
    'Ellipse' |
    'Line' |
    'Note' |
    'Polygon' |
    'Polyline' |
    'Rectangle' |
    'Freehand' |
    'Text Box' |
    'Callout' |
    'Cloud' |
    'Note Pin' |
    'Pin Marker'
    ;

export interface BookmarkCustom {
    id: string;
    rect: Boundary;
    scale: number | string;
    title: string;
    page: number;
}
export const DndViewer = 'viewer-filelist';
type PlaceDragged = 'content-viewer' | 'file-list';
export interface DragItem {
    type: string;
    viewId: ViewId;
    place: PlaceDragged
}
export interface DragAndDropResult {
    from: ViewId;
    to: ViewId;
    place: PlaceDragged
}

export interface FileUpload {
    index: number;
    all: StreamFile[]
}
export type TabKeySetting = 'general' | 'effect' | 'color' | 'point-cloud';
export type ProjectModelDefault = 'perspective' | 'orthographic';
export interface SettingState {
    navigation: NavigationSetting;
    general: GeneralSettingState;
    effect: EffectSetting;
    color: ColorSetting;
    fetchSettingFail?: boolean;
    customSetting: boolean;
}
export interface NavigationSetting { // Add new
    rotateSpeed: number;
    walkSpeed: number;
    elevationSpeed: number;
    zoomSpeed: number;
    mouseSpeed: number;
}
export interface GeneralSettingState {
    axis: boolean;
    cube: boolean;
    projectionModelDefault: Communicator.Projection;
    xRayOpacity: number;
    ghostingOpacity: number;
    backGroundColor: RGBColor;
    sheetBackGroundColor: RGBColor;
    gradient: boolean;
    gradientColor: RGBColor;
    sheetColor: RGBColor;
    sheetShadow: RGBColor;
    showLineWeight: boolean;
    displayUnit: DisplayUnits;
    precision: number;
}
export interface EffectSetting {
    ambientOcclusion: boolean;
    ambientOcclusionRadius: number;
    antiAliasing: boolean;
    bloom: boolean;
    bloomIntensityScale: number,
    threshold: number;
    silhoutteEdges: boolean;
    reflectionPlane: boolean;
    shadows: boolean;
    interaction: boolean;
    blurSample: number;
}
export interface ColorSetting {
    capingGeometryFace: RGBColor;
    capingGeometryLine: RGBColor;
    capingGeometryPlane: RGBColor
    selectionMeasurement: RGBColor;
    enablePMIOverride: boolean;
    selectionPMI: RGBColor;
}
export interface PointCloudSetting {
    splatSize: number;
    enableSplat: boolean;
    enableEyeLighting: number;
    cloudSplatMode: number;
}
export interface TitleContentDialog {
    title?: string;
    content?: string;
}
export type ResultConfirm = 'cancel' | 'yes' | 'no';
export type ResultChildrenCombine = { [key: string]: number[] };

export interface AnnotAttribute extends MarkupLineFormat, MarkupTextFormat { }

export interface NotepinCategory {
    type: PinMarkerType,
    color: string,
}

export enum PinMarkerType {
    DOC = 'DOC',
    RFI = 'RFI',
    SUB = 'SUB',
    PCI = 'PCI',
    ISS = 'ISS',
}
export enum PinMarkerColor {
    RED = '#D4380D',
    YELLOW = '#FF9900',
    GREEN = '#389E0D',
    BLUE = '#096DD9',
    WHITE = '#000000',
}

export interface NotepinData {
    props: number[],
    activePin: MarkupBaseItem,
    activeViewId: string,
}

export interface GroupedNotepin {
    nodeList: number[],
    notePin: NotepinJson,
    activeViewId: string,
}

export interface NotepinJson {
    uniqueId: string,
    className: string,
    selectionPosition: Object,
    selectionNormal: Object,
    text: string,
    color: Communicator.Color,
    hexColor: string,
    partId: number,
    type: string,
    createdAt: string,
    createdBy: string,
    description: string,
    title: string,
    sphereInstanceId: number,
    stemInstanceId: number,
}

export interface NotepinSelection {
    viewId: ViewId,
    selectionArray: NotepinJson[]
}
export interface StraightLine {
    startPoint: Point,
    endPoint: Point,
}

export interface DefaultLineFormat {
    lineColor: RGBColor;
    lineWeight: number;
    lineOpacity: number;
    fillColor: RGBColor;
    fillColorOption: boolean;
    startLineShapeType: number;
    endLineShapeType: number;
    lineStyle: LineStyle;
    rotation: 0;
}

export interface DefaultTextFormat {
    textFontSize: number;
    textColor: RGBColor;
    textFontFamily: string;
    textIsBold: boolean;
    textIsItalic: boolean;
    textIsUnderline: boolean;
}

export interface IPinMarkerData {
    type: PinMarkerType,
    nodes: number[],
    objectId: string,
}

export enum SearchOption {
    Default = 0,
    Case,
    Whole,
    Both,
}

export type PanelFocus = 'Linked Objects' | 'Model' | 'Right Panel' | 'Property Grid' | 'Markup Toolbar' | 'Settings' | 'Left Panel' | 'Preparing the document for printing';
export interface RawFlatTreeNode {
    Id: string, // Id > -100 => real id, otherwise virtual node
    Name: string, // = ComponentName
    Type: TreeNodeType,
    // optional
    PersistentId?: string, // rvt only
    Childs?: string[],
    Parent?: string,
    CategoryName?: string,
    FamilyName?: string,
    TypeName?: string,
    CategoryFamily?: string,
    ModelFileId?: ModelFileId,
}

export interface FlatTreeNode extends RawFlatTreeNode {
    key: string,
}
export enum TreeNodeType {
    Root = -1,
    Normal = 0, // real node
    Category,
    Family,
    Type,
    Component, // real node, leaf node
    Group,
}
interface AnyProperties {
    [prop: string]: any
}
export interface LinkedObjectAPI extends AnyProperties {
    _type: PinMarkerType
}
export interface CMICPropertyItem extends AnyProperties {
    _name: string,
}
export enum NodeVisibility {
    Hidden = 0,
    Intermediate,
    Visible,
}
export interface TreeNodeProp extends AntdTreeNodeAttribute, FlatTreeNode { };

export enum DisplayUnits {
    Milimeter = 1,
    Centimeter = 2,
    Meter = 3,
    Feet = 4,
}

export enum ePageSize {
    A0,
    A1,
    A2,
    A3,
    A4,
    A5,
    A6,
    A7
}

export interface PrintingState {
    /**
     * 0: extents, 1: display
     */
    pageArea: number;
    /**
     * 0: automatic, 1: landscape, 2: portrait
     */
    layout: number;
    /**
     * 0: Fit to Page, 1: custom
     */
    scaling?: number;
    scalingValue?: number; // 0 - 100
    /**
     * 0: color, 1: monochrome
     */
    color?: number;
    /**
       * pageSize
       */
    pageSize?: ePageSize;
}

export interface PageSize {
    width: number;
    height: number;
}

export interface PrintParam {
    pageSize: PageSize;
}

export interface PrintResults {
    content: HTMLDivElement;
    width: number;
    height: number;
}

export interface PrintImageResults {
    img: HTMLImageElement;
    width: number;
    height: number;
}

export enum UnitMeasure {
    milimet = 1,
    centimet = 10,
    decimet = 100,
    met = 1000,
    inch = 25.4,
    feet = 304.8
}

export enum UnitType {
    milimet = 0,
    centimet = 1,
    decimet = 2,
    met = 3,
    inch = 4,
    feet = 5
}
export enum FilesTabsKeys {
    Models = 'Models',
    Sheets = 'Sheets',
    Views = 'Views'
}
export interface IssueItem {
    Id: string;
    titlevalue?: string;
    descriptionvalue?: string;
    selectedTypeValue?: string;
    selectedStatusValue: string;
    selectedPriorityValue?: string;
    selectedAssignToValue?: string;
    selectedWatcherValue?: string,
    selectedGroupValue?: string;
    selectedCategoryValue?: string;
    selectedLocationValue?: string;
    dueDatevalueEnd?: Date;
    modelFileIds: string[];
    createDate: string;
    updatedDate: string;
    createdBy: { name: string };
    disinfo?: any;
}

export interface PostIssueitem {
    action: string;
    title: string;
    description: string;
    priority: string;
    creator: string;
    assigned: string;
    type: string;
    status: string;
    watcher: string;
    group: string;
    category: string;
    location: string;
    create_date: string;
    gid: string;
    modelFileIds: string[];
    createDate: string;
    updatedDate: string;
    disinfo: any;
}

export interface ResponseMeta {
    status: string;
    statuscode: number;
    message: string;
}

export interface IssueResult {
    data: any;
    meta: ResponseMeta;
}

export interface IssueResponse {
    ocs: IssueResult;
}