/* eslint-disable indent */
import { MarkupAttachType } from "common/type-markup";

/* eslint-disable max-classes-per-file */
export class SelectionPoints {
    public readonly worldPosition: Communicator.Point3 | null;

    public readonly screenPosition: Communicator.Point2;

    public readonly selectionItem: Communicator.Selection.SelectionItem;

    public constructor(worldPosition: Communicator.Point3 | null, screenPosition: Communicator.Point2, selectionItem: Communicator.Selection.SelectionItem) {
        this.worldPosition = worldPosition;
        this.screenPosition = screenPosition;
        this.selectionItem = selectionItem;
    }
}
const strokeColor = new Communicator.Color(255, 153, 51);
const fillOpacity = 0;
const strokeWidth = 2;
const size = 12;
const defaultSize = new Communicator.Point2(size, size);
const pointOrigin = new Communicator.Point2(0, 0);

export class MarkupCursorItem extends Communicator.Markup.Redline.RedlineItem {
    private readonly _cursorSprite = new Communicator.Markup.Shape.Circle();
    private readonly _cursorLines = new Communicator.Markup.Shape.LineCollection();

    private readonly _cursorLine = new Communicator.Markup.Shape.Line();
    private readonly _cursorRec = new Communicator.Markup.Shape.Rectangle();

    private readonly _markupId: string;
    private attachMode: MarkupAttachType | null = null;
    private bMidPoint = false;
    private bEndpoint = false;
    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this._cursorSprite.setFillOpacity(fillOpacity);
        this._cursorSprite.setStrokeWidth(strokeWidth);
        this._cursorSprite.setStrokeColor(strokeColor);

        this._cursorLines.setStrokeColor(strokeColor);
        this._cursorLines.setStrokeWidth(strokeWidth);

        this._cursorLine.setStrokeColor(strokeColor);
        this._cursorLine.setStrokeWidth(strokeWidth);

        this._cursorRec.setStrokeWidth(strokeWidth);
        this._cursorRec.setStrokeColor(strokeColor);
        this._cursorRec.setFillOpacity(fillOpacity);
        this._markupId = viewer.markupManager.registerMarkup(this);
    }

    public draw(): void {
        const renderer = this._viewer.markupManager.getRenderer();
        switch (this.attachMode) {
            case MarkupAttachType.Edge:
                {
                    const cursorLine1 = new Communicator.Markup.Shape.Line();
                    const cursorLine2 = new Communicator.Markup.Shape.Line();
                    const cursorLine3 = new Communicator.Markup.Shape.Line();


                    const pCenter = this._cursorLine.getP1();

                    if (this.bEndpoint === true) {
                        this._cursorRec.setStrokeWidth(2);
                        this._cursorRec.setStrokeColor(strokeColor);
                        renderer.drawRectangle(this._cursorRec);
                        break;
                    }
                    else if (this.bMidPoint === false) {

                        cursorLine1.setP1(pCenter)
                        cursorLine1.setP2(new Communicator.Point2(pCenter.x, pCenter.y - size / 2));
                        cursorLine1.setStrokeColor(strokeColor);
                        cursorLine1.setStrokeWidth(2);

                        cursorLine2.setP1(pCenter)
                        cursorLine2.setP2(new Communicator.Point2(pCenter.x - size / 2, pCenter.y + size / 2));
                        cursorLine2.setStrokeColor(strokeColor);
                        cursorLine2.setStrokeWidth(2);

                        cursorLine3.setP1(pCenter)
                        cursorLine3.setP2(new Communicator.Point2(pCenter.x + size / 2, pCenter.y + size / 2));
                        cursorLine3.setStrokeColor(strokeColor);
                        cursorLine3.setStrokeWidth(2);

                        renderer.drawLine(cursorLine1);
                        renderer.drawLine(cursorLine2);
                        renderer.drawLine(cursorLine3);
                    }
                    else {
                        cursorLine1.setP1(new Communicator.Point2(pCenter.x, pCenter.y - size / 2));
                        cursorLine1.setP2(new Communicator.Point2(pCenter.x - size / 2, pCenter.y + size / 2));
                        cursorLine1.setStrokeColor(strokeColor);
                        cursorLine1.setStrokeWidth(strokeWidth);

                        cursorLine2.setP1(new Communicator.Point2(pCenter.x - size / 2, pCenter.y + size / 2));
                        cursorLine2.setP2(new Communicator.Point2(pCenter.x + size / 2, pCenter.y + size / 2));
                        cursorLine2.setStrokeColor(strokeColor);
                        cursorLine2.setStrokeWidth(strokeWidth);

                        cursorLine3.setP1(new Communicator.Point2(pCenter.x, pCenter.y - size / 2));
                        cursorLine3.setP2(new Communicator.Point2(pCenter.x + size / 2, pCenter.y + size / 2));
                        cursorLine3.setStrokeColor(strokeColor);
                        cursorLine3.setStrokeWidth(strokeWidth);

                        renderer.drawLine(cursorLine1);
                        renderer.drawLine(cursorLine2);
                        renderer.drawLine(cursorLine3);
                    }

                    break;
                }
            case MarkupAttachType.Vertex:
                {
                    this._cursorSprite.setStrokeColor(strokeColor);
                    renderer.drawRectangle(this._cursorRec);
                    break;
                }
            case MarkupAttachType.Face:
                {
                    this._cursorRec.setStrokeWidth(1);
                    this._cursorRec.setStrokeColor(strokeColor);
                    renderer.drawRectangle(this._cursorRec);
                    break;
                }
            default:
                renderer.drawCircle(this._cursorSprite);
                break;
        }
    }

    public enable(enable: boolean): void {
        this._cursorSprite.setRadius(enable ? 2 : 0);

        this._cursorRec.setSize(enable ? defaultSize : pointOrigin);
        if (enable === false) {
            this._cursorSprite.setStrokeWidth(0)
            this._cursorRec.setStrokeWidth(0)
            this._cursorLine.setStrokeWidth(0)
            this._cursorLine.setP1(pointOrigin);
            this._cursorLine.setP2(pointOrigin);
        }
    }
    public isEnabled(): boolean {
        return this._cursorSprite.getRadius() > 0;
    }

    public setPosition(point: Communicator.Point2): void {
        const cursorLine1 = new Communicator.Markup.Shape.Line();

        this._cursorSprite.setCenter(point);
        this._cursorRec.setSize(defaultSize);
        this._cursorRec.setPosition(new Communicator.Point2(point.x - size / 2, point.y - size / 2));

        const pCenter = new Communicator.Point2(point.x, point.y);
        cursorLine1.setP1(pCenter)
        cursorLine1.setP2(new Communicator.Point2(pCenter.x, pCenter.y + size / 2));

        this._cursorLine.setP1(cursorLine1.getP1());
        this._cursorLine.setP2(cursorLine1.getP2());
    }

    public setMarkupAttachType(attachMode: MarkupAttachType, bMidPoint: boolean, bEndpoint = false): void {
        this.attachMode = attachMode;
        this.bMidPoint = bMidPoint;
        this.bEndpoint = bEndpoint;
    }

    public destroy(): void {
        this._viewer.markupManager.unregisterMarkup(this._markupId);
    }
}
