/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { IPinMarkerData } from "common/define";
import { defaultNotepinCategory } from "utils/utils";
import { BaseElementCanvas } from "./markup.base-canvas.element";
export class PinMarkerElementCanvas extends BaseElementCanvas {

    private _radius = 32;
    public isEnabled = false;
    private _pinMarkerData: IPinMarkerData;
    private _primaryColor = 'red';
    constructor(pinMarkerData: IPinMarkerData) {
        super();
        this.offSet = 2;
        this._pinMarkerData = pinMarkerData;
        this.createPinMarker();
    }
    getPinMarkerCanvas(): HTMLElement {
        this.draw();
        return this.pinMarkerCanvas!;
    }

    createPinMarker(): void {
        this.pinMarkerCanvas = document.createElement('div');
        this.pinMarkerCanvas.style.position = 'absolute';
        this.pinMarkerCanvas.style.zIndex = '2';
        this.pinMarkerCanvas.style.width = `${this._radius}px`;
        this.pinMarkerCanvas.style.height = `${this._radius}px`;
        this.pinMarkerCanvas.style.borderRadius = '50%';
        this.pinMarkerCanvas.style.border = "4px solid white";
        this.pinMarkerCanvas.style.pointerEvents = 'none';
        this.pinMarkerCanvas.spellcheck = false;
        this.pinMarkerCanvas.style.overflow = 'hidden';
        this.pinMarkerCanvas.style.display = 'initial';
        const pinData = defaultNotepinCategory.find(v => v.type === this._pinMarkerData.type);

        if (pinData) {
            this._primaryColor = pinData.color;
            this.pinMarkerCanvas.style.background = pinData.color;
        }
    }

    draw(): void {
        if (!this.firstPoint || !this.pinMarkerCanvas) return;
        this.pinMarkerCanvas.style.left = `${this.firstPoint.x - this._radius / 2}px`;
        this.pinMarkerCanvas.style.top = `${this.firstPoint.y - this._radius / 2}px`;
        this.pinMarkerCanvas.style.width = `${this._radius}px`;
        this.pinMarkerCanvas.style.height = `${this._radius}px`;
    }
    setDisabledStyle(isDisabled: boolean): void {
        if (!this.pinMarkerCanvas) return;
        this.isEnabled = isDisabled
        this.pinMarkerCanvas.style.background = isDisabled ? this._primaryColor : 'gray';
    }
    setVisible(vis: boolean): void {
        if (!this.pinMarkerCanvas) return;
        this.pinMarkerCanvas.style.display = vis ? 'initial' : 'none';
    }
    setPinMarkerData(data: IPinMarkerData): void {
        this._pinMarkerData = data;
    }

    toggleActiveBorder(isActive: boolean): void {
        if (!this.pinMarkerCanvas) return;
        this.pinMarkerCanvas.style.border = `4px solid ${isActive ? 'yellow' : 'white'}`
    }
}
