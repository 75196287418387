import { GlobalState } from "common/global";
import { MarkupNotePinOperator } from "../markup/markup-3d/markup.notepin.operator";
import { NotepinTooltip } from "../markup/markup-custom/markup.notepin-tooltip.item";
import { MarkupAction } from "../markup/markup.action";
import Utils from "utils/utils";
import ModelHelper from "../model/model.helper";
import { MarkupPinMarkerOperator } from "../markup/markup-3d/markup.pinmarker.operator";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default class CameraOrbitOperator extends Communicator.Operator.CameraOrbitOperator {
    viewer: Communicator.WebViewer;
    private _nodeId = 0;
    private _cubeMeshId: Communicator.MeshId | undefined;
    translationMatrix: Communicator.Matrix | undefined;
    meshInstanceData: Communicator.MeshInstanceData | undefined;
    isHandleFocus = false; // fix conflict with handle
    private markupAction: MarkupAction | undefined;
    private _tooltipIdActive: string | undefined = undefined;
    private _defaultCursor = 'default';
    private _notepinTooltipCursor = 'pointer';
    private _viewId = '';
    private _sphereRadius = 0.008;
    private _mouseDownPos: Communicator.Point2 = Communicator.Point2.zero();
    constructor(hwv: Communicator.WebViewer, viewId: string) {
        super(hwv);
        this.viewer = hwv;
        this.createCubeMesh();
        if (this.viewer) {
            this.viewer.setCallbacks(this.callbacks)
        }
        this._viewId = viewId;
        this.markupAction = GlobalState.getMarkupAction(viewId);
    }
    async onMouseDown(event: Communicator.Event.MouseInputEvent) {
        this._mouseDownPos = event.getPosition();
        const opeHandle = this.viewer.operatorManager.getOperator(Communicator.OperatorId.Handle) as Communicator.Operator.HandleOperator;
        if (opeHandle && opeHandle._highlightedHandleId) this.isHandleFocus = true;
        else this.isHandleFocus = false;
        await this.insertGeometry(this.getOrbitTarget());
        // this.handleShiftOrbit();
        super.onMouseDown(event);
    }

    async onMousewheel(event: Communicator.Event.MouseWheelInputEvent) {
        const center2 = event.getPosition();
        const newTarget = await this.calTargetPoint(center2);
        if (!newTarget) return;
        this.setOrbitTarget(newTarget);
        await this.insertGeometry(this.getOrbitTarget());
    }

    onMouseMove(event: Communicator.Event.MouseInputEvent) {
        if (!this.isHandleFocus) {
            super.onMouseMove(event);
        }
    }
    public handleShowTooltipNotepin(event: Communicator.Event.MouseInputEvent): void {
        if (this.markupAction) {
            if (this._tooltipIdActive) {
                this.viewer.markupManager.removeMarkupElement(this._tooltipIdActive);
                this.viewer.markupManager.refreshMarkup();
            }
            const notepinTooltipItem = new NotepinTooltip(this.viewer, event, this.markupAction);
            this._tooltipIdActive = notepinTooltipItem.drawTooltip();
            this._viewer.getViewElement().style.cursor = this._tooltipIdActive ? this._notepinTooltipCursor : this._defaultCursor;
        }
    }

    public handleUpdateMouseCursor(): void {
        const currentOperatorId = this.viewer.operatorManager.peek();
        if (currentOperatorId) {
            const currentOperator = this.viewer.operatorManager.getOperator(currentOperatorId);
            if (currentOperator instanceof MarkupNotePinOperator || currentOperator instanceof MarkupPinMarkerOperator) {
                this.viewer.getViewElement().style.cursor = 'crosshair';
            }
        }
    }
    async onMouseUp(event: Communicator.Event.MouseInputEvent) {
        super.onMouseUp(event);
        if (event.getButton() === Communicator.Button.Left) {
            if (this._mouseDownPos && event.getPosition().equals(this._mouseDownPos)) {
                const pickConfig = new Communicator.PickConfig(
                    Communicator.SelectionMask.Face,
                );
                let newTarget = await this.viewer.view.pickFromPoint(this._mouseDownPos, pickConfig).then((select) => {
                    if (select.isFaceSelection()) return Utils.getRayCastPoint(select, this._mouseDownPos, this.viewer);
                    return select.getPosition();
                });
                if (!newTarget) {
                    this.viewer.model.getModelBounding(true, false).then((box) => {
                        this.UpdateTargetPoint(box.center());
                    });
                } else {
                    newTarget = await this.calTargetPoint(this._mouseDownPos)
                    newTarget && this.UpdateTargetPoint(newTarget);
                }
            }
        }
    }

    public UpdateTargetPoint(newTarget: Communicator.Point3) {
        this.setOrbitTarget(newTarget);
        this.insertGeometry(this.getOrbitTarget());
    }

    public async DeleteTargetPointMarker() {
        try {
            if (this._nodeId !== 0) {
                await this.viewer.model.deleteMeshInstances([this._nodeId]);
                this._nodeId = 0;
            }
        }
        catch (error) {
            return error
        }
    }

    private async insertGeometry(position: Communicator.Point3) {
        if (!this.translationMatrix || !this.meshInstanceData) return;
        if (this._nodeId !== 0) {
            //translate
            const curMatrix = this.viewer.model.getNodeMatrix(this._nodeId);
            curMatrix.setTranslationComponent(position.x, position.y, position.z);
            this.viewer.model.setNodeMatrix(this._nodeId, curMatrix);
        } else if (!this.viewer.sheetManager.isDrawingSheetActive()) { // on drawing mode: createMeshInstance --> error
            this.translationMatrix.setTranslationComponent(position.x, position.y, position.z);
            this.translationMatrix.setScaleComponent(this._sphereRadius, this._sphereRadius, this._sphereRadius);
            this.meshInstanceData.setMatrix(this.translationMatrix);
            await this.viewer.model.createMeshInstance(this.meshInstanceData, null, false, true).then((nodeId) => {
                this._nodeId = nodeId;
                this.viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotExplode, [nodeId], true);
                this.viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotCut, [nodeId], true);
                this.viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotSelect, [nodeId], true);
                this.viewer.model.setInstanceModifier(Communicator.InstanceModifier.SuppressCameraScale, [nodeId], true);
                this.viewer.model.setInstanceModifier(Communicator.InstanceModifier.OverrideSceneVisibility, [nodeId], true);
                // this.viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotLight, [nodeId], true);
                this.viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotOutlineHighlight, [nodeId], true);
                this.viewer.model.setInstanceModifier(Communicator.InstanceModifier.ExcludeBounding, [nodeId], true);
                this.viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotUseVertexColors, [nodeId], true);
                this.viewer.model.setInstanceModifier(Communicator.InstanceModifier.AlwaysDraw, [nodeId], true);
                this.viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotXRay, [nodeId], true);
            });
        }
    }

    private createCubeMesh() {
        const cubeMeshData = Communicator.Util.generateSphereMeshData();

        this.viewer.model.createMesh(cubeMeshData).then((meshId) => {
            this._cubeMeshId = meshId;
            this.translationMatrix = new Communicator.Matrix();
            this.meshInstanceData = new Communicator.MeshInstanceData(this._cubeMeshId);
            this.meshInstanceData.setFaceColor(Communicator.Color.green());
            this.meshInstanceData.setCreationFlags(Communicator.MeshInstanceCreationFlags.SuppressCameraScale);
        });
    }

    selectionArray(selectionEvents: Communicator.Event.NodeSelectionEvent[], viewer: Communicator.WebViewer, isRemove: boolean) {
        if (viewer && !isRemove) {
            const listSelectId = viewer.selectionManager.getResults().map(node => node.getNodeId());
            if (listSelectId && listSelectId.length > 0) {
                for (let i = 0; i < listSelectId.length; i++) {
                    const type = this.viewer.model.getNodeType(listSelectId[i]);
                    if (type === Communicator.NodeType.AssemblyNode) {
                        return;
                    }
                }
            }
        }
    }
    callbacks: Communicator.CallbackMap = {
        selectionArray: (selectionEvents, isRemove) => {
            this.selectionArray(selectionEvents, this.viewer, isRemove)
            //setTimeout(() => this.selectionArray(selectionEvents, this.viewer, isRemove), 0)
        }
    }
    unCallBacks() {
        if (this.viewer) {
            this.DeleteTargetPointMarker();
            this.viewer.unsetCallbacks(this.callbacks)
        }
    }
    private async handleShiftOrbit() {
        const arrSelected = ModelHelper.getSelectionItemId(this.viewer);
        if (arrSelected.length > 0) {
            const box = await ModelHelper.getBoundingBox(this.viewer, arrSelected);
            if (box) {
                const target = box.center();
                this.UpdateTargetPoint(target)
            }
        }
    }

    async calTargetPoint(center2: Communicator.Point2) {
        const { view } = this.viewer;

        const pickConfig = new Communicator.PickConfig(
            Communicator.SelectionMask.All,
        );
        const listNodeId = ModelHelper.getNodeIdCuttingPlane(this.viewer);
        listNodeId.length > 0 && this.viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotSelect, listNodeId, true);
        let newTarget = await view.pickFromPoint(center2, pickConfig).then((select) => {
            if (select.isFaceSelection()) return Utils.getRayCastPoint(select, center2, this.viewer);
            return select.getPosition();
        });
        listNodeId.length > 0 && this.viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotSelect, listNodeId, false);
        if (newTarget == null) {
            newTarget = this.AdjustPositionToPlane(
                view,
                center2,
                view.getCamera().getTarget(),
            );
        }
        return newTarget;
    }
    
    public showSubInformation(isShow: boolean): void {
        if (!isShow) {
            this.DeleteTargetPointMarker();
        }
    }

    public AdjustPositionToPlane(
        view: Communicator.View,
        position2: Communicator.Point2,
        pointInPlane: Communicator.Point3,
    ) {
        const nPointInPlane = view.projectPoint(pointInPlane);
        const nPosition = view.unprojectPoint(
            position2,
            nPointInPlane.z,
        );
        return nPosition;
    }

}