/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import RectangleRedline from "../operator/rectangle.redline";

export default class AreaSelectionOperator extends Communicator.Operator
    .AreaSelectionOperator {
    private _markupItem!: RectangleRedline;
    isMouseDown = false;

    isHandleFocus = false;
    private refreshed = false;
    constructor(private viewer: Communicator.WebViewer) {
        super(viewer);
        this._markupItem = new RectangleRedline(viewer);
        this._markupItem.registerMarkup();
    }
    public clearTemp() {
        this._markupItem.setVisible(false);
    }

    unRegisterMarkup() {
        this.clearTemp();
        this._markupItem.unRegisterMarkup();
        this.viewer.markupManager.refreshMarkup();
        this.viewer.redraw();
    }
    public escape() {
        // Cancel current operation
        this.isMouseDown = false;
        this.unRegisterMarkup();
        this.refreshed = false
    }
    onMouseDown(event: Communicator.Event.MouseInputEvent) {
        const opeHandle = this.viewer.operatorManager.getOperator(
            Communicator.OperatorId.Handle
        ) as Communicator.Operator.HandleOperator;
        // eslint-disable-next-line no-underscore-dangle
        if (opeHandle && opeHandle._highlightedHandleId)
            this.isHandleFocus = true;
        else this.isHandleFocus = false;

        if (
            event.getButton() === Communicator.Button.Left &&
            event.controlDown() === false &&
            event.shiftDown() === false
        ) {
            this.isMouseDown = true;
            this._markupItem.setFirstPoint(event.getPosition());
            this._markupItem.setVisible(true);
        } else {
            this.isMouseDown = false;
            super.onMouseDown(event);
        }
    }
    onMouseMove(event: Communicator.Event.MouseInputEvent) {
        if (this.isMouseDown && !this.isHandleFocus) {
            this._markupItem.setSecondPoint(event.getPosition());
            this.viewer.markupManager.refreshMarkup();
            event.setHandled(true);
        }
    }


    private createSphereMesh(position: Communicator.Point3, color: Communicator.Color) {
        const sphereMeshData = Communicator.Util.generateSphereMeshData();
        const translationMatrix = new Communicator.Matrix();
        const translationScale = 0.01;
        translationMatrix.setTranslationComponent(position.x, position.y, position.z);
        translationMatrix.setScaleComponent(translationScale, translationScale, translationScale);
        this.viewer.model.createMesh(sphereMeshData).then((meshId) => {

            const meshInstanceData = new Communicator.MeshInstanceData(meshId);
            meshInstanceData.setMatrix(translationMatrix);
            meshInstanceData.setFaceColor(color);
            meshInstanceData.setCreationFlags(Communicator.MeshInstanceCreationFlags.SuppressCameraScale);
            this.viewer.model.createMeshInstance(meshInstanceData);
        });
    }
    drawCamera(camera: Communicator.Camera) {
        const old_w = camera.getWidth() / 2;
        const old_h = camera.getHeight() / 2;
        const pos = camera.getPosition().copy();
        const tar = camera.getTarget().copy();
        const vecUp = camera.getUp().copy();
        const vecView = Communicator.Point3.subtract(pos, tar).normalize();
        const vectRight = Communicator.Point3.cross(vecView, vecUp).normalize();

        this.createSphereMesh(pos.copy(), Communicator.Color.blue())
        this.createSphereMesh(tar.copy(), Communicator.Color.yellow())

        this.createSphereMesh(tar.copy().add(vecUp.copy().scale(old_h)).add(vectRight.copy().scale(old_w)), Communicator.Color.red());
        this.createSphereMesh(tar.copy().add(vecUp.copy().scale(old_h)).subtract(vectRight.copy().scale(old_w)), Communicator.Color.red());
        this.createSphereMesh(tar.copy().subtract(vecUp.copy().scale(old_h)).add(vectRight.copy().scale(old_w)), Communicator.Color.red());
        this.createSphereMesh(tar.copy().subtract(vecUp.copy().scale(old_h)).subtract(vectRight.copy().scale(old_w)), Communicator.Color.red())
    }
    private zoomHelper(view: Communicator.View, scale: number, camera: Communicator.Camera): void {
        const camPos = camera.getPosition().copy();
        const camTarget = camera.getTarget().copy();
        //c1

        //c2
        const newW = camera.getWidth(), newH = camera.getHeight();
        const diagonalLen = Math.sqrt(newW * newW + newH * newH);
        const viewingVector = camPos.copy().subtract(camTarget).normalize();
        const newPos = camTarget.copy().add(viewingVector.copy().scale(2.5 * diagonalLen));
        camera.setPosition(newPos);
        view.setCamera(camera)
    };

    doZoomTest(viewer: Communicator.WebViewer, max: Communicator.Point2, min: Communicator.Point2) {
        viewer.pauseRendering(() => {
            const cameraNew = this._viewer.view.getCamera();
            const _pinchZoomModifier = 2.5;
            const view = this._viewer.view;
            const _lastTouch1 = view.pointToWindowPosition(max);
            const _lastTouch2 = view.pointToWindowPosition(min);
            const g = Communicator.Point2.subtract(_lastTouch2, _lastTouch1).length();
            const h = (0 - g) * _pinchZoomModifier;
            this.zoomHelper(this._viewer.view, h, cameraNew);
            viewer.view.setCamera(cameraNew)
        });
    }
    doZoom2(viewer: Communicator.WebViewer, max: Communicator.Point2, min: Communicator.Point2): void {
        const cameraNew = viewer.view.getCamera()
        const min2 = min;
        const maxX2 = new Communicator.Point2(max.x, min.y);
        const maxY2 = new Communicator.Point2(min.x, max.y);
        const max2 = max;

        const min3 = cameraNew.getCameraPlaneIntersectionPoint(min2, viewer.view);
        const maxX3 = cameraNew.getCameraPlaneIntersectionPoint(maxX2, viewer.view);
        const maxY3 = cameraNew.getCameraPlaneIntersectionPoint(maxY2, viewer.view);
        const max3 = cameraNew.getCameraPlaneIntersectionPoint(max2, viewer.view);

        let aInput = -5;
        if (min3 != null && maxY3 != null && maxX3 != null && max3 != null) {
            const wIn = Communicator.Point3.subtract(min3.copy(), maxX3.copy()).length();
            const hIn = Communicator.Point3.subtract(min3.copy(), maxY3.copy()).length();
            const w = cameraNew.getWidth();
            const h = cameraNew.getHeight();

            const raitoW = wIn / w;
            const raitoH = hIn / h;
            if (raitoW > raitoH) {
                aInput = raitoW;
            }
            else {
                aInput = raitoH;
            }
            viewer.pauseRendering(async () => {
                const centerMouse = max.copy().add(min.copy()).scale(0.5).copy();
                const newTar = cameraNew.getCameraPlaneIntersectionPoint(centerMouse, this.viewer.view);
                const curTar = cameraNew.getTarget().copy();
                null !== newTar && cameraNew.dolly(Communicator.Point3.subtract(curTar, newTar));
                cameraNew.setWidth(wIn);
                cameraNew.setHeight(hIn);
                this.zoomHelper(viewer.view, aInput, cameraNew);
                viewer.view.setCamera(cameraNew);
            });
        }
    }

    onMouseUp(event: Communicator.Event.MouseInputEvent) {
        if (
            this.isMouseDown &&
            !this.isHandleFocus &&
            this._markupItem.firstPoint &&
            this._markupItem.seccondPoint
        ) {
            const x1 = this._markupItem.firstPoint.x;
            const y1 = this._markupItem.firstPoint.y;
            const x2 = this._markupItem.seccondPoint.x;
            const y2 = this._markupItem.seccondPoint.y;
            const pt1 = new Communicator.Point2(
                Math.min(x1, x2),
                Math.min(y1, y2)
            );
            const pt2 = new Communicator.Point2(
                Math.max(x1, x2),
                Math.max(y1, y2)
            );

            if (this.isRectangleEnoughLarge(x1, y1, x2, y2)) {
                event.setHandled(true);
                this.doZoom2(this.viewer, pt1, pt2);
            }
        } else {
            super.onMouseUp(event);
        }
        this.isMouseDown = false;
        this._markupItem.setVisible(false);
        this.viewer.markupManager.refreshMarkup();
        this.refresh();
    }

    /** private  */
    private isRectangleEnoughLarge(
        x1: number,
        y1: number,
        x2: number,
        y2: number
    ) {
        return Math.abs(x2 - x1) >= 2 && Math.abs(y2 - y1) >= 2;
    }
    private adjustPositionToPlane(
        view: Communicator.View,
        position: Communicator.Point3 | null,
        pointInPlane: Communicator.Point3
    ) {
        let nPosition: Communicator.Point3 | null = null;
        nPosition = view.projectPoint(position ?? new Communicator.Point3(0, 0, 0));
        const nPointInPlane = view.projectPoint(pointInPlane);
        nPosition.z = nPointInPlane.z;
        nPosition = view.unprojectPoint(
            new Communicator.Point2(nPosition.x, nPosition.y),
            nPosition.z
        );
        return nPosition;
    }
    private async computeReasonableTarget(
        viewer: Communicator.WebViewer,
        min: Communicator.Point2,
        max: Communicator.Point2,
        oriTar: Communicator.Point3
    ) {
        const { view } = viewer;
        const pickConfig = new Communicator.PickConfig(
            Communicator.SelectionMask.Point
        );
        const center = min.copy().add(max).scale(0.5);
        let newTarget = await view.pickFromPoint(center, pickConfig).then((select) => select.getPosition());

        if (newTarget == null) {
            const X_STEPS = 100;
            const Y_STEPS = 100;
            let count = 0;
            const pickFound = new Communicator.Point3(0, 0, 0);
            const xx = (1.0 * (max.x - min.x)) / X_STEPS;
            const yy = (1.0 * (max.y - min.y)) / Y_STEPS;
            const p = min.copy();
            // eslint-disable-next-line no-plusplus
            for (let row = 0; row <= Y_STEPS; row++) {
                p.y = min.y + row * yy;
                // eslint-disable-next-line no-plusplus
                for (let col = 0; col <= X_STEPS; col++) {
                    p.x = min.x + col * xx;
                    // eslint-disable-next-line no-await-in-loop
                    const pick = await view
                        .pickFromPoint(p, pickConfig)
                        .then((select) => select.getPosition());
                    if (pick != null) {
                        pickFound.add(pick);
                        // eslint-disable-next-line no-plusplus
                        count++;
                    }
                }
            }

            newTarget = view.unprojectPoint(center, 0);
            if (count > 0) {
                newTarget = this.adjustPositionToPlane(
                    view,
                    newTarget,
                    pickFound.scale(1.0 / count)
                );
            } else {
                newTarget = this.adjustPositionToPlane(view, newTarget, oriTar);
            }
        }
        return newTarget;
    }
    private computeNewField(
        viewer: Communicator.WebViewer,
        min: Communicator.Point2,
        max: Communicator.Point2,
        newTar: Communicator.Point3
    ) {
        const center = max.add(min).scale(0.5).copy();

        const xmax2 = new Communicator.Point2(max.x, center.y);
        const ymax2 = new Communicator.Point2(center.x, max.y);
        const xmin2 = new Communicator.Point2(min.x, center.y);
        const ymin2 = new Communicator.Point2(center.x, min.y);

        const { view } = viewer;

        let xmax3 = view.unprojectPoint(xmax2, 0);
        let ymax3 = view.unprojectPoint(ymax2, 0);
        let xmin3 = view.unprojectPoint(xmin2, 0);
        let ymin3 = view.unprojectPoint(ymin2, 0);
        xmax3 = this.adjustPositionToPlane(view, xmax3, newTar);
        ymax3 = this.adjustPositionToPlane(view, ymax3, newTar);
        xmin3 = this.adjustPositionToPlane(view, xmin3, newTar);
        ymin3 = this.adjustPositionToPlane(view, ymin3, newTar);
        const w = xmax3 && xmin3 ? xmax3.subtract(xmin3).length() : 0;
        const h = ymax3 && ymin3 ? ymax3.subtract(ymin3).length() : 0;

        return [w, h];
    }
    private async doZoom(
        viewer: Communicator.WebViewer,
        pt1: Communicator.Point2,
        pt2: Communicator.Point2
    ) {
        const cam = this.viewer.view.getCamera().copy();
        const camUp = cam.getUp();
        const camPos = cam.getPosition();
        const camTarget = cam.getTarget();
        let newTarget = await this.computeReasonableTarget(viewer, pt1, pt2, camTarget).then((res) => res);
        if (newTarget != null) {
            const sc = 2.5;
            let [newW, newH] = this.computeNewField(viewer, pt1, pt2, newTarget);
            let diagonalLen = Math.sqrt(newW * newW + newH * newH);
            const viewingVector = camPos.copy().subtract(camTarget).normalize();
            const newPos = newTarget.copy().add(viewingVector.copy().scale(sc * diagonalLen));
            const camDist = newTarget.length() + 1;
            const minCam = 0.0005 * camDist;
            if (diagonalLen < minCam) {
                const x = minCam / diagonalLen;
                newW *= x;
                newH *= x;
                diagonalLen = Math.sqrt(newW * newW + newH * newH);
                const dir2Pos = camPos.copy().subtract(camTarget);
                dir2Pos.normalize();
                newTarget = newPos.copy().subtract(dir2Pos.copy().scale(sc * diagonalLen));
            }
            const newCam = Communicator.Camera.create(
                newPos,
                newTarget,
                camUp,
                cam.getProjection(),
                newW * sc,
                newH * sc,
                cam.getNearLimit()
            );
            viewer.view.setCamera(newCam);
        }
    }
    private refresh() {
        if (!this.refreshed) {
            setTimeout(() => {
                this.viewer.view.setProjectionMode(Communicator.Projection.Orthographic)
                this.refreshed = true
            }, 10);
        }
    }
}
