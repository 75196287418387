/* eslint-disable indent */
import React, { useCallback, useContext, useEffect, useReducer, useRef } from "react";
import { fromEvent } from "rxjs";
import { switchMap } from "rxjs/operators";
import Utils from "utils/utils";
import BaseKeyBindingController from "./base-key-binding.controller";
import { CommandType, CommandValue, LastKey, ParamSetKeyBinding } from "./type";

interface ShortcutKeysContextType {
    state: StateReducer,
    callActions: (action: ActionType) => unknown,
    registerKeyMap: (action: ParamSetKeyBinding[] | ParamSetKeyBinding) => void,
    keyCommandUpdate: (cmdType: CommandType, value: Partial<CommandValue>) => void,
    dispatchKeyBinding: (action: ActionType) => void,
}
type StateReducer = {
    instanceBaseClass: BaseKeyBindingController | undefined,
    visiblePopoverKeyMapping: boolean,
    edited: boolean
}

type ActionType =
    { type: 'set-value-map-key', payload: ParamSetKeyBinding } |
    { type: 'set-arr-value-map-key', payload: ParamSetKeyBinding[] } |
    { type: 'set-instance-base-class', payload: BaseKeyBindingController } |
    { type: 'set-last-key', payload: LastKey } |
    { type: 'restore-default' } |
    { type: 'toggle-popover-key-mapping' } |
    { type: 'edit-status', payload: boolean } |
    { type: 'set-visible-popover-key-mapping', payload: boolean } |
    { type: 'set-can-run-cmd', payload: boolean };

export const KeyBindingContext = React.createContext<ShortcutKeysContextType | null>(null);
export function useContextKeyBinding(): ShortcutKeysContextType {
    return useContext(KeyBindingContext) as ShortcutKeysContextType
}

const initState: StateReducer = {
    instanceBaseClass: undefined,
    visiblePopoverKeyMapping: false,
    edited: false
}

function reducer(state: StateReducer, action: ActionType) {
    switch (action.type) {
        case 'set-instance-base-class': {
            return Utils.setPartialState(state, { instanceBaseClass: action.payload })
        }
        case 'toggle-popover-key-mapping': {
            return Utils.setPartialState(state, { visiblePopoverKeyMapping: !state.visiblePopoverKeyMapping })
        }
        case 'set-visible-popover-key-mapping': {
            return Utils.setPartialState(state, { visiblePopoverKeyMapping: action.payload })
        }
        case 'edit-status': {
            return Utils.setPartialState(state, { edited: action.payload })
        }
        default: return state
    }
}

export function useReducerKeyBinding(): ShortcutKeysContextType {
    const [state, dispatchKeyBinding] = useReducer(reducer, initState);
    const controller = useRef<BaseKeyBindingController>(new BaseKeyBindingController());

    useEffect(() => {
        dispatchKeyBinding({ type: 'set-instance-base-class', payload: controller.current })
    }, [])

    useEffect(() => {
        const keyDown = fromEvent<KeyboardEvent>(document, 'keydown').pipe(
            switchMap(key => controller.current.runCommand(key))
        ).subscribe()

        return () => {
            keyDown && keyDown.unsubscribe()
        }
    }, [])

    const callActions = useCallback((action: ActionType) => {
        const instanceBaseClass = controller.current;
        let result: unknown;
        switch (action.type) {
            case 'set-value-map-key': {
                instanceBaseClass.registerKeyMap([action.payload])
                break
            }
            case 'set-arr-value-map-key': {
                instanceBaseClass.registerKeyMap(action.payload)
                break
            }
            case 'set-can-run-cmd': {
                instanceBaseClass.canRunCmd = action.payload;
                break
            }
            case 'set-last-key': {
                result = instanceBaseClass.setKeyLastMapCommand(action.payload);
                break
            }
            case 'restore-default': {
                result = instanceBaseClass.restoreDefault();
                break
            }
            default: break
        }
        return result
    }, [])

    const registerKeyMap = useCallback((action: ParamSetKeyBinding[] | ParamSetKeyBinding) => {
        const param = Array.isArray(action) ? action : [action];
        controller.current.registerKeyMap(param)
    }, [])

    const keyCommandUpdate = useCallback((cmdType: CommandType, value: Partial<CommandValue>): void => {
        return controller.current.keyCommandUpdate(cmdType, value)
    }, [])

    return {
        state,
        callActions,
        registerKeyMap,
        keyCommandUpdate,
        dispatchKeyBinding
    }
}