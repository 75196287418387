/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RootState } from "redux/rootReducers";

export const selectSelf = (state: RootState): RootState => state;
export const selectSelfApp = (state: RootState) => state.app;
export const selectSelfBookmark = (state: RootState) => state.bookmark;
export const selectSelfBootstrap = (state: RootState) => state.bootstrap;
export const selectSelfCombine = (state: RootState) => state.combineModel;
export const selectSelfDashboard = (state: RootState) => state.dashboard;
export const selectSelfFileList = (state: RootState) => state.filesList;
export const selectSelfLogin = (state: RootState) => state.login;
export const selectSelfMarkup = (state: RootState) => state.markup;
export const selectSelfLineFormat = (state: RootState) => state.markupLineFormat;
export const selectSelfMarkupPanel = (state: RootState) => state.markupPanel;
export const selectSelfTextFormat = (state: RootState) => state.markupTextFormat;
export const selectSelfMarkupToolbar = (state: RootState) => state.markupToolbar;
export const selectSelfMultiViewer = (state: RootState) => state.multiViewer;
export const selectSelfPanel = (state: RootState) => state.panelVisible;
export const selectSelfPdfViewer = (state: RootState) => state.pdfViewer;
export const selectSelfProperties = (state: RootState) => state.properties;
export const selectRFIstatus = (state: RootState) => state.RFIstatus;
export const selectSelfPanelApp = (state: RootState) => state.panelApp;
export const selectSelfSetting = (state: RootState) => state.setting;
export const selectSelfSheet = (state: RootState) => state.sheets;
export const selectSelfTree = (state: RootState) => state.tree;
export const selectSelfViewer3D = (state: RootState) => state.viewer3D;
export const selectSelfViews = (state: RootState) => state.views;
export const selectNotepin = (state: RootState) => state.notepin;
export const selectMarkup3d = (state: RootState) => state.markup3d;
export const selectLeftPanelApp = (state: RootState) => state.leftPanelApp
export const selectSelfIssues = (state: RootState) => state.issues
