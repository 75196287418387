import { StreamFile } from "common/define";
import { GlobalState } from "common/global";
import { useContextListOpenFile } from "context/listopenfile.context";
import { sortListFiles } from "helper/dashboard.helper";
import { useEffect, useState } from "react";

export default function useHandleListFile(): StreamFile[] {
    const [listFile, setListFile] = useState<StreamFile[]>([]);
    const {state: {searchText, currentPath, uploadedFiles}} = useContextListOpenFile();

    useEffect(() => {
        //const allFile = GlobalState.getAllFilesInFolder(currentPath);
        const allFile = GlobalState.getAllFile();
        if (searchText === '') {
            const list = allFile.filter(val => val.originalFilePath === currentPath);
            setListFile(sortListFiles(list));
        }
        else {
            const list = allFile.filter(val => {
                const originalFilePath = val.originalFilePath + '/';
                if(!originalFilePath.startsWith(currentPath + '/')) return false;
                return val.filename.toLowerCase().includes(searchText.toLowerCase())
            });
            setListFile(sortListFiles(list));
        }
    }, [currentPath, searchText, uploadedFiles])

    useEffect(() => {
        const sub = GlobalState.allFilesUpdate$.subscribe(result => {
            if (result.length > 0) {
                setListFile(result);
            }
        })
        return () => {
            sub && sub.unsubscribe();
        }
    },[])

    return listFile;
}