/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */

import { MarkupBaseJson, MarkupFillType } from 'common/type-markup';
import MathHelper from 'container/pdf-viewer/helper/math.helper';
import { boundPadding, cloudPadding } from 'utils/utils';
import { GripPoint, MarkupBaseBounding } from '../markup-canvas-elements/markup.bounding.element';
import { RectangleElementCanvas } from '../markup-canvas-elements/markup.rectangle-canvas.element';
import { RotateTooltipElement } from '../markup-canvas-elements/markup.rotate-tooltip.element';
import { MarkupBaseItem } from './markup.base.item';
export class MarkupRectangleItem extends MarkupBaseItem {
    private _uniqueId: string;
    private rectangleHTML: RectangleElementCanvas = new RectangleElementCanvas();

    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.iconName = 'markupRectangle';
        this.shapeName = 'Rectangle';
        this._uniqueId = this.uniqueId;
        const rectangleItem = this;
        this.rotateTooltip = new RotateTooltipElement();
        this.redlineBounding = new MarkupBaseBounding(viewer);
        this.redlineBounding.setCanRotate(true);
        this.redlineBounding.setGripPointCallback(
            (point: Communicator.Point2, type: GripPoint) => {
                rectangleItem.gripPointDragStartCallback(point, type);
            },
            (point: Communicator.Point2) => {
                rectangleItem.gripPointDragMoveCallback(point);
            },
            (point: Communicator.Point2) => {
                rectangleItem.gripPointDragEndCallback(point);
            },
        );
        this.redlineBounding.setRotateGripPointCallback(
            (point: Communicator.Point2, type: GripPoint) => {
                rectangleItem.gripPointDragStartCallback(point, type);
            },
            (point: Communicator.Point2, tooltipPos: Communicator.Point2) => {
                this.rotateGripPointDragMoveCallback(point, tooltipPos);
            },
            (point: Communicator.Point2) => {
                rectangleItem.rotateGripPointDragEndCallback(point);
            },
        );
        this.redlineBounding.setBoundingBoxClickCallback(
            (point: Communicator.Point2, event: MouseEvent) => this.onClickBoundingCallBack(event),
        );
        this.redlineBounding.createBoundingBox();
    }
    private _update(): void {
        if (!this._point1) return;
        this.rectangleHTML.setStrokeWidth(this._lineWeight);
        this.rectangleHTML.setStrokeColor(new Communicator.Color(this._lineColor.r, this._lineColor.g, this._lineColor.b));
        this.rectangleHTML.setFillType(this._fillColorOption ? MarkupFillType.Opaque : MarkupFillType.None);
        this.rectangleHTML.setFillColor(new Communicator.Color(this._fillColor.r, this._fillColor.g, this._fillColor.b));
        this.rectangleHTML.setFillOpacity(this._lineOpacity);
        this.rectangleHTML.setLineStyle(this._lineStyle);
        this.rectangleHTML.setRotation(this._rotation);

        if (this.redlineBounding) {
            this.redlineBounding.padding = this._lineStyle > 5 ? cloudPadding : boundPadding;
            this.redlineBounding.setRotation(this._rotation);
            this.redlineBounding.updateGripPoints();
            this.redlineBounding.updateRotationTransform(this._rotation);
        }
        this.updateRotateTransform(this._rotation);

        const { view } = this._viewer;
        const firstPoint = Communicator.Point2.fromPoint3(
            view.projectPoint(this._point1.copy()),
        );
        let secondPoint = firstPoint.copy();
        if (this._point2 !== null) {
            secondPoint = Communicator.Point2.fromPoint3(
                view.projectPoint(this._point2.copy()),
            );

            // Calculate size of rectangle
            const b = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y));
            const a = new Communicator.Point2(Math.max(firstPoint.x, secondPoint.x), Math.max(firstPoint.y, secondPoint.y));
            const size = Communicator.Point2.subtract(a, b);
            this.rectangleHTML.setFirstPoint(b);
            this.rectangleHTML.setSize(size);
            this._isReady = true;
            this.vertices = [b, new Communicator.Point2(a.x, b.y),
                a, new Communicator.Point2(b.x, a.y)];
        }

        this.updateBoundingBox(firstPoint, secondPoint);
    }

    updateBoundingBox(firstPoint: Communicator.Point2, secondPoint: Communicator.Point2) {
        if (!firstPoint || !secondPoint) return;
        const pos = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y));
        const size = this.rectangleHTML.getSize();
        const stroke = this.rectangleHTML.getStrokeWidth();
        const boxPos = new Communicator.Point2(pos.x - stroke / 2, pos.y - stroke / 2);
        if (this.redlineBounding) {
            this.redlineBounding.setCenter(firstPoint, secondPoint);
            this.redlineBounding.setPosition(boxPos);
            this.redlineBounding.setSize(new Communicator.Point2(size.x + stroke, size.y + stroke));
        }
    }

    public draw(): void {
        this._update();
        if (!this.isHiding) {
            if (this._isReady) {
                this._redlineElementId = this._viewer.markupManager.addMarkupElement(
                    this.rectangleHTML.getRectangleCanvas(),
                );
                if (this.rotateTooltip) {
                    this.rotateTooltipId = this._viewer.markupManager.addMarkupElement(
                        this.rotateTooltip.getRotateTooltip(),
                    );
                }
            }
        } else {
            this._redlineElementId && this._viewer.markupManager.removeMarkupElement(this._redlineElementId);
            this._redlineElementId = null;
            this.rotateTooltipId && this._viewer.markupManager.removeMarkupElement(this.rotateTooltipId);
            this.rotateTooltipId = null;
        }
        this.handleBoundingRectInteraction();
    }

    public getClassName(): string {
        return "Communicator.Markup.MarkupRectangleItem";
    }
    toJson(): MarkupBaseJson {
        const rectObj = {
            className: this.getClassName(),
            lineColor: this._lineColor,
            lineOpacity: this._lineOpacity,
            lineStyle: this._lineStyle,
            lineWeight: this._lineWeight,
            fillColor: this._fillColor,
            fillColorOption: this._fillColorOption,
            iconName: this.iconName,
            shapeName: this.shapeName,
            uniqueIdGroup: this.uniqueIdGroup,
            uniqueId: this.uniqueId,
            point1: this._point1?.copy(),
            point2: this._point2?.copy(),
            rotation: this._rotation,
            modifiedDate: this._modifiedDate,
            lastModifiedBy: this._lastModifiedBy,
        };
        return rectObj;
    }
    fromJson(data: any): void {
        this._lineColor = data.lineColor;
        this._lineWeight = data.lineWeight;
        this._lineOpacity = data.lineOpacity;
        this._fillColor = data.fillColor
        this._fillColorOption = data.fillColorOption;
        this._point1 = data.point1;
        this._point2 = data.point2;
        this.uniqueIdGroup = data.uniqueIdGroup;
        this._uniqueId = data.uniqueId;
        this._rotation = data.rotation;
        this._modifiedDate = data.modifiedDate;
        this._lastModifiedBy = data.lastModifiedBy;
    }

    gripPointDragStartCallback(point: Communicator.Point2, type: GripPoint) {
        this.isUpdate = false;
        if (!this._isCanEdit) return;
        this.gripPointDragType = type;
        this.updateOppositePoint();
    }
    rotateGripPointDragMoveCallback(point: Communicator.Point2, tooltipPos: Communicator.Point2) {
        if (this.gripPointDragType === null) return;
        if (!this.isUpdate) this.updateDefinePoints();
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        if (b) {
            const boundRects = this.rectangleHTML.baseCanvas.getBoundingClientRect();
            const pointX = boundRects.left + boundRects.width / 2;
            const pointY = boundRects.top + boundRects.height / 2;
            const rotateAngle = ((Math.atan2(point.y - pointY, point.x - pointX) + Math.PI / 2) * 180 / Math.PI - 180) % 360;
            this.setRotation(rotateAngle);
            if (this.rotateTooltip) {
                this.rotateTooltip.setPosition(tooltipPos);
                this.rotateTooltip.setRotateValue(rotateAngle);
                this.rotateTooltip.setVisible(true);
                this.rotateTooltip.draw();
            }
            this._update();
            if (this.redlineBounding) {
                this.redlineBounding.setRotation(rotateAngle);
                this.redlineBounding.updateGripPoints();
            }
        }
    }
    onDragStart(point: Communicator.Point2) {
        this.isUpdate = false;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        b !== null && this._previousDragPlanePosition.assign(b);
        return !1;
    }
    onDragMove(point: Communicator.Point2) {
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);
        let c = null;
        if (b !== null) {
            c = Communicator.Point3.subtract(b, this._previousDragPlanePosition);
            this._point1!.add(c);
            this._point2!.add(c);
            this._previousDragPlanePosition.assign(b);
        }
        this._viewer.markupManager.refreshMarkup();
        return !0;
    }
    hit(point: Communicator.Point2) {
        let ct = Communicator.Point2.zero();
        if (this.redlineBounding) ct = this.redlineBounding.center;
        const pos = MathHelper.convertPointWithRotation(point, ct, 360 - this._rotation);
        return this.rectangleHTML.hit(pos);
    }
    setMarkupVisible(visible: boolean): void {
        this.rectangleHTML.baseCanvas.style.display = visible ? 'initial' : 'none';
        super.setMarkupVisible(visible);
    }

    getHTMLElement() {
        return this.rectangleHTML.baseCanvas;
    }
}


