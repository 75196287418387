/* eslint-disable indent */
import { LineStyle, MarkupFillType } from "common/type-markup";
import MathHelper from "container/pdf-viewer/helper/math.helper";
import { BaseElementCanvas } from "./markup.base-canvas.element";

export class PolylineElementCanvas extends BaseElementCanvas {
    private points: Communicator.Point2[] = [];
    private isPolygon = false;
    constructor(isPolygon?: boolean) {
        super();
        if (isPolygon) this.setIsPolygon(isPolygon);
        this.baseCanvas = document.createElement('canvas');
        this.baseCanvas.style.position = 'absolute';
        this.baseCanvas.style.zIndex = '1';
        this.baseCanvas.style.width = 'inherit';
        this.baseCanvas.style.height = 'inherit';
        this.lineJoin = 'round';
    }

    getCanvas(): HTMLElement {
        switch (this._lineStyle) {
            case LineStyle.cloud:
                this.drawCloudStyle();
                break;
            case LineStyle.zigzag:
                this.drawZicZacStyle();
                break;
            default:
                this.draw();
        }
        return this.baseCanvas;
    }

    draw(): void {
        if (!this.points) return;
        const minPnt = this.points[0].copy();
        const maxPnt = this.points[0].copy();
        this.points.forEach((point) => {
            if (point.x < minPnt.x) minPnt.x = point.x;
            if (point.y < minPnt.y) minPnt.y = point.y;
            if (point.x > maxPnt.x) maxPnt.x = point.x;
            if (point.y > maxPnt.y) maxPnt.y = point.y;
        });

        const size = maxPnt.copy().subtract(minPnt.copy());
        const offset = this.strokeWidth + this.offSet;

        const width = size.x + offset * 2;
        const height = size.y + offset * 2;
        this.baseCanvas.style.left = `${minPnt.x - offset}px`;
        this.baseCanvas.style.top = `${minPnt.y - offset}px`;
        this.baseCanvas.width = width;
        this.baseCanvas.height = height;
        this.baseCanvas.style.width = `${width}px`;
        this.baseCanvas.style.height = `${height}px`;
        const ctx = this.baseCanvas.getContext('2d');
        if (!ctx) return;
        ctx.lineJoin = this.lineJoin;
        ctx.lineCap = this.lineCap;
        if (this.points.length === 1) {
            ctx.beginPath();
            ctx.lineWidth = this.strokeWidth;
            ctx.arc(this.points[0].x - minPnt.x + offset, this.points[0].y - minPnt.y + offset, this.strokeWidth / 4, 0, 2 * Math.PI);
            if (this.strokeColor) {
                const color = MathHelper.rgbToHex(this.strokeColor.r, this.strokeColor.g, this.strokeColor.b);
                if (color) {
                    ctx.strokeStyle = color;
                    ctx.fillStyle = color;
                    ctx.fill();
                }
            }
            ctx.stroke();
            ctx.closePath();
        } else {
            MathHelper.applyLineStyle(ctx, this._lineStyle);
            ctx.clearRect(0, 0, this.baseCanvas.width, this.baseCanvas.height);

            ctx.beginPath();
            ctx.lineWidth = this.strokeWidth;

            // Draw polyline
            ctx.moveTo(this.points[0].x - minPnt.x + offset, this.points[0].y - minPnt.y + offset);
            for (let i = 1; i < this.points.length; i++) {
                ctx.lineTo(this.points[i].x - minPnt.x + offset, this.points[i].y - minPnt.y + offset);
            }

            if (this.fillType === MarkupFillType.Opaque && this.fillColor) {
                const color = MathHelper.communicatorColorToRGBAString(this.fillColor, this.fillOpacity);
                if (color) {
                    ctx.fillStyle = color;
                    ctx.fill();
                }
            }
            if (this.strokeColor) {
                const color = MathHelper.rgbToHex(this.strokeColor.r, this.strokeColor.g, this.strokeColor.b);
                if (color) {
                    ctx.strokeStyle = color;
                }
            }
            ctx.stroke();
            ctx.closePath();
        }
    }

    drawZicZacStyle(): void {
        if (!this.points || this.points.length < 2) return;
        const minPnt = this.points[0].copy();
        const maxPnt = this.points[0].copy();
        this.points.forEach((point) => {
            if (point.x < minPnt.x) minPnt.x = point.x;
            if (point.y < minPnt.y) minPnt.y = point.y;
            if (point.x > maxPnt.x) maxPnt.x = point.x;
            if (point.y > maxPnt.y) maxPnt.y = point.y;
        });

        const size = maxPnt.copy().subtract(minPnt.copy());
        const offset = this.strokeWidth * 8;

        const width = size.x + offset * 2;
        const height = size.y + offset * 2;
        this.baseCanvas.style.left = `${minPnt.x - offset}px`;
        this.baseCanvas.style.top = `${minPnt.y - offset}px`;
        this.baseCanvas.width = width;
        this.baseCanvas.height = height;
        this.baseCanvas.style.width = `${width}px`;
        this.baseCanvas.style.height = `${height}px`;
        const ctx = this.baseCanvas.getContext('2d');
        if (!ctx) return
        ctx.clearRect(0, 0, this.baseCanvas.width, this.baseCanvas.height);
        ctx.lineJoin = "round";
        ctx.lineCap = "round";
        ctx.beginPath();

        // Draw rectangle
        ctx.moveTo(this.points[0].x - minPnt.x + offset, this.points[0].y - minPnt.y + offset);

        for (let i = 0; i < this.points.length - 1; i++) {
            ctx.lineTo(this.points[i].x - minPnt.x + offset, this.points[i].y - minPnt.y + offset);
            const strPnt = new Communicator.Point2(this.points[i].x - minPnt.x + offset, this.points[i].y - minPnt.y + offset);
            const endPnt = new Communicator.Point2(this.points[i + 1].x - minPnt.x + offset, this.points[i + 1].y - minPnt.y + offset);
            this.zicZacLine(strPnt, endPnt, ctx);
        }

        ctx.lineWidth = this.strokeWidth;
        if (this.fillType === MarkupFillType.Opaque && this.fillColor) {
            const color = MathHelper.communicatorColorToRGBAString(this.fillColor, this.fillOpacity);
            if (color) {
                ctx.fillStyle = color;
                ctx.fill();
            }
        }
        if (this.strokeColor) {
            const color = MathHelper.rgbToHex(this.strokeColor.r, this.strokeColor.g, this.strokeColor.b);
            if (color) {
                ctx.strokeStyle = color;
            }
        }
        ctx.stroke();
        ctx.closePath();
    }

    zicZacLine(startPoint: Communicator.Point2, endPoint: Communicator.Point2, ctx: CanvasRenderingContext2D): void {
        if (!startPoint || !endPoint || !ctx) return;
        let vec = endPoint.copy().subtract(startPoint);
        const length = vec.length();
        const offSet = this.strokeWidth * 8;
        const a = length / (offSet / 2);
        const step = a > Math.ceil(a) - 0.5 ? Math.ceil(a) + 0.5 : Math.ceil(a) - 0.5;
        const spacing = length / step;
        vec = vec.scale(spacing / length);

        const points: Communicator.Point2[] = [startPoint];
        let tmpPnt = startPoint;
        for (let i = 1; i < step; i++) {
            tmpPnt = tmpPnt.copy().add(vec);
            points.push(tmpPnt);
        }
        points.push(endPoint);

        const tmpVec = vec.copy().scale(1 / 4);
        const upVec = new Communicator.Point2(vec.y, -vec.x);
        upVec.scale(1 / 4);
        const downVec = new Communicator.Point2(-upVec.x, -upVec.y);

        for (let i = 0; i < points.length - 2; i++) {
            const fstPnt = points[i];
            const scndPnt = points[i + 1];

            let upPnt = fstPnt.copy().add(tmpVec.copy());
            upPnt = upPnt.copy().add(upVec.copy());
            ctx.lineTo(upPnt.x, upPnt.y);

            let downPnt = fstPnt.copy().add(tmpVec.copy().scale(3));
            downPnt = downPnt.copy().add(downVec.copy());
            ctx.lineTo(downPnt.x, downPnt.y);

            ctx.lineTo(scndPnt.x, scndPnt.y);
        }

        let upPnt = points[points.length - 2];
        upPnt = upPnt.copy().add(tmpVec.copy());
        upPnt = upPnt.copy().add(upVec.copy());
        ctx.lineTo(upPnt.x, upPnt.y);
        ctx.lineTo(endPoint.x, endPoint.y);
    }

    drawCloudStyle(): void {
        if (!this.points || this.points.length < 2) return;
        const minPnt = this.points[0].copy();
        const maxPnt = this.points[0].copy();
        this.points.forEach((point) => {
            if (point.x < minPnt.x) minPnt.x = point.x;
            if (point.y < minPnt.y) minPnt.y = point.y;
            if (point.x > maxPnt.x) maxPnt.x = point.x;
            if (point.y > maxPnt.y) maxPnt.y = point.y;
        });

        const size = maxPnt.copy().subtract(minPnt.copy());
        const offset = this.strokeWidth * 8;

        const width = size.x + offset * 2;
        const height = size.y + offset * 2;
        this.baseCanvas.style.left = `${minPnt.x - offset}px`;
        this.baseCanvas.style.top = `${minPnt.y - offset}px`;
        this.baseCanvas.width = width;
        this.baseCanvas.height = height;
        this.baseCanvas.style.width = `${width}px`;
        this.baseCanvas.style.height = `${height}px`;
        const ctx = this.baseCanvas.getContext('2d');
        if (!ctx) return
        ctx.clearRect(0, 0, this.baseCanvas.width, this.baseCanvas.height);
        ctx.lineJoin = "round";
        ctx.lineCap = "round";
        ctx.beginPath();

        // Draw rectangle
        ctx.moveTo(this.points[0].x - minPnt.x + offset, this.points[0].y - minPnt.y + offset);

        for (let i = 0; i < this.points.length - 1; i++) {
            ctx.lineTo(this.points[i].x - minPnt.x + offset, this.points[i].y - minPnt.y + offset);
            const strPnt = new Communicator.Point2(this.points[i].x - minPnt.x + offset, this.points[i].y - minPnt.y + offset);
            const endPnt = new Communicator.Point2(this.points[i + 1].x - minPnt.x + offset, this.points[i + 1].y - minPnt.y + offset);
            this.cloudLine(strPnt, endPnt, ctx);
        }
        ctx.lineWidth = this.strokeWidth;
        if (this.fillType === MarkupFillType.Opaque && this.fillColor) {
            const color = MathHelper.communicatorColorToRGBAString(this.fillColor, this.fillOpacity);

            if (color) {
                ctx.fillStyle = color;
                ctx.fill();
            }
        }
        if (this.strokeColor) {
            const color = MathHelper.rgbToHex(this.strokeColor.r, this.strokeColor.g, this.strokeColor.b);
            if (color) {
                ctx.strokeStyle = color;
            }
        }
        ctx.stroke();
        ctx.closePath();
    }

    cloudLine(startPoint: Communicator.Point2, endPoint: Communicator.Point2, ctx: CanvasRenderingContext2D): void {
        if (!startPoint || !endPoint || !ctx) return;
        let vec = endPoint.copy().subtract(startPoint);
        const strArg = Math.atan2(-vec.y, -vec.x);
        const endArg = strArg + Math.PI;
        const length = vec.length();
        const offSet = this.strokeWidth * 8;
        const a = length / (offSet / 2);
        const step = Math.ceil(a);
        const spacing = length / step;
        vec = vec.scale(spacing / length);

        const points: Communicator.Point2[] = [startPoint];
        let pnt = startPoint;

        for (let i = 0; i < step - 1; i++) {
            pnt = pnt.copy().add(vec);
            points.push(pnt);
        }

        points.push(endPoint);

        for (let i = 0; i < points.length - 1; i++) {
            const fstPnt = points[i];
            const scndPnt = points[i + 1];
            ctx.arc((fstPnt.x + scndPnt.x) / 2, (fstPnt.y + scndPnt.y) / 2, spacing / 2, strArg, endArg);
        }
    }

    pushPoint(point: Communicator.Point2): void {
        if (point) {
            this.points.push(point);
        }
    }
    clearPoints(): void {
        this.points = [];
    }

    getPoints(): Communicator.Point2[] {
        const rets = this.points.map(v => v.copy());
        return rets;
    }

    setIsPolygon(isPolygon: boolean): void {
        this.isPolygon = isPolygon;
    }
}
