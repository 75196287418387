
import CDialogConfirmHandle from "components/CDialogConfirmHandle";
import CDialogInfo from "components/CDialogInfo";
import { BoostrapContext, useReducerBoostrap } from "context/boostrap.context";
import { createAllWorker } from "helper/worker.helper";
import usePreventEvent from "hooks/usePreventEvent";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchConfig } from "redux/bootstrap.slice";
import { getBootstrapState } from "redux/selectors";
import { useSelectorRoot } from "redux/store";

const BootstrapSystem = ({ children }: Props): JSX.Element | null => {
    const value = useReducerBoostrap();
    const {isSuccess} = useSelectorRoot(getBootstrapState);
    usePreventEvent();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchConfig());
        createAllWorker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isSuccess) {
        return null;
    }
    return (
        <BoostrapContext.Provider value={value}>
            {children}
            <CDialogConfirmHandle />
            <CDialogInfo />
            {/* <CContextMenu /> */}
        </BoostrapContext.Provider>
    )
};

export default BootstrapSystem;
