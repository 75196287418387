import { from, Observable, Subject, Subscription } from "rxjs";
import { concatMap } from "rxjs/operators";
import { Lodash } from "utils/utils";
import KeyCommand from "./key-command";
import KeyMappingHelper from "./keymapping.helper";
import { CommandMap, CommandType, CommandValue, KeysGroup, LastKey, MapKeyType, ModeSetLastKey, ParamSetKeyBinding } from "./type";

export default class BaseKeyBindingController {

    /**
     * Map command by 1 object with key is keytype and value
     * is type CommandValue: {'app.escape': {cmdExc: () => void, ...}}
     */
    mapCommand: CommandMap = {}
    /**
     * Map array keytype. Ex: [['0.0.0.0.F1']: CommandValue]
     */
    mapKeyGroupType: MapKeyType[] = []
    canRunCmd = true
    constructor() { this.init() }

    /**
     * run command, find frist cmd match with condition: has key group = key input
     * , and has 'When' field = true, and has max priority
     * @param keyEvent Keyboard event
     */
    runCommand(keyEvent: KeyboardEvent): Observable<void> {
        return from(this.runCmd(keyEvent))
    }

    registerKeyMap(param: ParamSetKeyBinding[]): void {
        this.emitEventRegister.next(param)
    }

    keyCommandUpdate(cmdType: CommandType, value: Partial<CommandValue>): void {
        let result = this.mapCommand[cmdType];
        if (!result) {
            result = new KeyCommand({ cmdType })
        }
        result.update(value);
        if (value.keyFinal) {
            this.updateMapKeyGroupTypeByCommandType(cmdType, result)
        }
    }

    setKeyLastMapCommand(lastKey: LastKey): boolean {
        const { keyType, keyGroup, mode } = lastKey;
        const preValueMap = this.mapCommand[keyType];
        if (preValueMap) {
            let keyGroupResult: KeysGroup | KeysGroup[] = {};
            if (mode === ModeSetLastKey.override) {
                keyGroupResult = keyGroup
            } else {
                let currKey = preValueMap.keyLast ?? preValueMap.keyFinal;
                currKey = Array.isArray(currKey) ? currKey : [currKey];
                keyGroupResult = [...currKey, keyGroup]
            }
            preValueMap.setKeyLast(keyGroupResult);
            this.updateMapKeyGroupTypeByCommandType(keyType, preValueMap)
            return true
        }
        return false
    }

    restoreDefault(): Promise<void> {
        return new Promise<void>((resolve) => {
            const entriesMap = Object.entries(this.mapCommand);
            this.mapKeyGroupType = [];
            entriesMap.forEach(mapCmd => {
                const [, keyCommand] = mapCmd;
                if (keyCommand) {
                    keyCommand.restoreDefault();
                    const keyGroupString = KeyMappingHelper.transformKeyGroupToString(keyCommand.keyFinal);
                    this.pushMapKeyGroupType(keyGroupString, keyCommand)
                }
            })
            resolve()
        })
    }

    onDestroy(): void {
        this.sub && this.sub.unsubscribe()
    }

    // private
    private sub: Subscription = Subscription.EMPTY;
    private emitEventRegister: Subject<ParamSetKeyBinding[]> = new Subject();
    private init() {
        this.mapCmdGroup(this.mapCommand);
        this.handleEventRegister();
    }

    private handleEventRegister() {
        const obserRegister = (param: ParamSetKeyBinding[]): Observable<unknown> => {
            const registerPromise = new Promise<void>((resolve) => {
                param.forEach(item => {
                    this.setValueMapCommand(item)
                });
                resolve()
            });
            return from(registerPromise)
        }
        this.sub = this.emitEventRegister.pipe(
            concatMap(obserRegister)
        ).subscribe()
    }

    /**
     * Map object mapcommand -> array type [keyString, CommandValue]
     * @param mapCommand Object key-> value type CommandMap. Ex: {'app.escape': {cmdExc: () => void, ...}}
     */
    private mapCmdGroup(mapCommand: CommandMap) {
        const entriesMap = Object.entries(mapCommand);
        if (entriesMap.length > 0) {
            entriesMap.forEach(mapCmd => {
                const [, commandvalue] = mapCmd;
                if (commandvalue) {
                    const keyGroupString = KeyMappingHelper.transformKeyGroupToString(commandvalue.keyFinal);
                    this.pushMapKeyGroupType(keyGroupString, commandvalue)
                }
            })
        }
    }

    /**
     * Update this.mapKeyGroupType by commandtype
     * @param type CommandType. Ex: 'app.escape'
     * @param value CommandValue
     */
    private updateMapKeyGroupTypeByCommandType(type: CommandType, value: KeyCommand) {
        if (this.mapKeyGroupType.length > 0) {
            // remove old value
            Lodash.remove(this.mapKeyGroupType, mapType => {
                const preValueMap = mapType[1];
                return preValueMap.cmdType === type
            })
            //update
            const keys = value.keyLast ?? value.keyFinal;
            const newKeyString = KeyMappingHelper.transformKeyGroupToString(keys);
            this.pushMapKeyGroupType(newKeyString, value)
        }
    }

    /**
     * Set value map command by type
     * @param param Object {keyType: CommandType, value: Partial<CommandValue>}
     * @returns boolean
     */
    private setValueMapCommand(param: ParamSetKeyBinding): boolean {
        if (this.mapCommand) {
            const { keyType, value } = param;
            const preValueMap = this.mapCommand[keyType];
            if (!preValueMap) {
                const initKeyCommand = new KeyCommand({ cmdType: keyType });
                initKeyCommand.update(value)
                this.mapCommand[keyType] = initKeyCommand;
                const keyGroupString = KeyMappingHelper.transformKeyGroupToString(initKeyCommand.keyFinal);
                this.pushMapKeyGroupType(keyGroupString, initKeyCommand)
            } else {
                preValueMap.update(value)
                if (value.keyFinal) {
                    this.updateMapKeyGroupTypeByCommandType(keyType, preValueMap);
                }
            }
        }
        return false
    }

    private pushMapKeyGroupType(keyString: string[], keyCommand: KeyCommand): void {
        keyString.forEach(key => this.mapKeyGroupType.push([key, keyCommand]))
    }

    private runArrCmd(arrCmdFilter: KeyCommand[], keyEvent: KeyboardEvent): void {
        let maxPriority = 0;
        const arrCmd = arrCmdFilter.reduce((pre, curr) => {
            let when = curr.when;
            if (typeof curr.when === 'function') {
                when = curr.when()
            }
            if (when && (curr.priority >= maxPriority)) {
                maxPriority = curr.priority;
                pre.push(curr);
                return pre.filter(cmd => cmd.priority === maxPriority);
            }
            return pre
        }, [] as KeyCommand[]);
        if (arrCmd.length > 0) {
            let canNext = true;
            arrCmd.forEach(async cmd => {
                if (cmd && typeof cmd.cmdExc === 'function' && canNext) {
                    if (cmd.preventAndStopPropagation) {
                        keyEvent.preventDefault();
                        keyEvent.stopPropagation()
                    }
                    const currCanNext = await cmd.cmdExc();
                    canNext = currCanNext ? currCanNext : false;
                }
            })
        }
    }
    /**
     * run command, find frist cmd match with condition: has key group = key input
     * , and has 'When' field = true, and has max priority
     * @param keyEvent Keyboard event
     */
    private runCmd(keyEvent: KeyboardEvent): Promise<void> {
        return new Promise((resolve) => {
            if (this.canRunCmd) {
                const keyCode = KeyMappingHelper.transformKeyEventValueToString(keyEvent);
                const arrCmdFilter = this.mapKeyGroupType.filter(g => g && g[0] === keyCode).map(f => f && f[1]);
                this.runArrCmd(arrCmdFilter, keyEvent);
                resolve()
            }
        })
    }
}