// import OperatorBase = Communicator.Operator.OperatorBase;
// import Event = Communicator.Event;
/* eslint-disable */
export default class MeasureBaseOperator extends Communicator.Operator.OperatorBase {
  public static OPERATOR = 'MEARSURE';

  operatorBase: Communicator.Operator.OperatorBase | undefined;

  handle: any;
  private listNodeId = [];

  constructor(viewer: Communicator.WebViewer) {
    super(viewer);
  }

  /**
   * Active
   *
   */
  public Active() {
    
  }

  public Escape(): boolean {
    return true;
    // return !this.operatorBase.Escape();
  }

  public onMouseMove(event: Communicator.Event.MouseInputEvent): void {
    this.listNodeId = this.getNodeIdCuttingPlane() as any;
    // huy bo chức năng select của các Node này
    this.listNodeId.length > 0 && this._viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotSelect, this.listNodeId, true);
    super.onMouseMove(event);
  }

  public onMouseUp(event: Communicator.Event.MouseInputEvent): void {
    
    super.onMouseUp(event);
    this.listNodeId.length > 0 && this._viewer.model.setInstanceModifier(Communicator.InstanceModifier.DoNotSelect, this.listNodeId, false)
  }

  getNodeIdCuttingPlane() {
    const listNodeId = [];
    const countSection = this._viewer.cuttingManager.getCuttingSectionCount();
    if (countSection > 0) {
      for (let i = 0; i < countSection; i += 1) {
        const section = this._viewer.cuttingManager.getCuttingSection(i);
        if (section) {
          const countPlane = section.getCount();
          if (countPlane > 0) {
            for (let j = 0; j < countPlane; j += 1) {
              const id = section.getNodeId(j);
              if (id) {
                listNodeId.push(id);
              }
            }
          }
        }
      }
    }
    return listNodeId;
  }

  /**
   * InActive
   *
   */
  public InActive() {
    
  }
}
