import { MarkupBaseOperator } from './markup.base.operator';
import { MarkupNoteItem } from '../markup-items/markup.note.item';

export class MarkupNoteOperator extends MarkupBaseOperator {

    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.cursorSprite = true;
    }

    public createMarkupItem(position: Communicator.Point2): void {
        const view = this._viewer.view;
        this._markupItem = new MarkupNoteItem(this._viewer);
        this._markupItem.setLineFormat(this._lineColor, this._lineWeight, this._lineOpacity, this._fillColor, this._fillColorOption, this._lineStyle);
        const itemId = this._viewer.markupManager.registerMarkup(this._markupItem);
        this._markupItem.setMarkupItemId(itemId);
        const cameraPoint = this.getCameraPlaneIntersectionPoint(view.getCamera(), position, view);
        if (cameraPoint !== null) {
            (this._markupItem as MarkupNoteItem).setFirstPoint(cameraPoint);
            (this._markupItem as MarkupNoteItem).setSecondPoint(cameraPoint);
        }
    }

    public updateMarkupItem(position: Communicator.Point2): void {
        if (this._markupItem) {
            const view = this._viewer.view;
            const cameraPoint = this.getCameraPlaneIntersectionPoint(view.getCamera(), position, view);
            if (cameraPoint !== null) {
                (this._markupItem as MarkupNoteItem).setSecondPoint(cameraPoint);
                this._viewer.markupManager.refreshMarkup();
            }
        }
    }
    getCameraPlaneIntersectionPoint(
        camera: Communicator.Camera,
        point: Communicator.Point2,
        view: Communicator.View,
    ): Communicator.Point3 | null {
        const target = camera.getTarget();

        const normal = Communicator.Point3.subtract(
            camera.getPosition(),
            target,
        ).normalize();
        const plane = Communicator.Plane.createFromPointAndNormal(target, normal);

        const ray = view.raycastFromPoint(point);
        if (ray === null) {
            return null;
        }

        const intersectionPoint = Communicator.Point3.zero();

        if (plane.intersectsRay(ray, intersectionPoint)) { return intersectionPoint; }
        return null;
    }
}

