import { createSelector } from "reselect";
import { selectSelfMarkupToolbar } from "./self.selector";

export const getMarkupToolbatState = createSelector(
    selectSelfMarkupToolbar,
    (state) => state
)
export const getMarkupSelectedStamp = createSelector(
    selectSelfMarkupToolbar,
    (state) => state.selectedStamp
)
export const getToolbarUndoRedoBtnStatus = createSelector(
    selectSelfMarkupToolbar,
    (state) => ({
        enableRedoBtn : state.enableRedoBtn,
        enableUndoBtn: state.enableUndoBtn
    })
)
export const getToolbarSaveBtnStatus = createSelector(
    selectSelfMarkupToolbar,
    (state) => state.enableSaveBtn
)