import { NoteData, NoteEntity } from 'common/type-markup';
import { MarkupArrowItem } from './markup-items/markup.arrow.item';
import { MarkupCircleItem } from './markup-items/markup.circle.item';
import { MarkupEllipseItem } from './markup-items/markup.ellipse.item';
import { MarkupLineItem } from './markup-items/markup.line.item';
import { MarkupNoteItem } from './markup-items/markup.note.item';
import { MarkupPolygonItem } from './markup-items/markup.polygon.item';
import { MarkupPolylineItem } from './markup-items/markup.polyline.item';
import { MarkupRectangleItem } from './markup-items/markup.rectangle.item';
import { MarkupSignatureItem } from './markup-items/markup.signature.item';
import { MarkupTextBoxItem } from './markup-items/markup.textbox.item';
import { MarkupArrowOperator } from './markup-operators/markup.arrow.operator';
import { MarkupBaseOperator } from './markup-operators/markup.base.operator';
import { MarkupCircleOperator } from './markup-operators/markup.circle.operator';
import { MarkupEllipseOperator } from './markup-operators/markup.ellipse.operator';
import { MarkupLineOperator } from './markup-operators/markup.line.operator';
import { MarkupNoteOperator } from './markup-operators/markup.note.operator';
import { MarkupPolygonOperator } from './markup-operators/markup.polygon.operator';
import { MarkupPolylineOperator } from './markup-operators/markup.polyline.operator';
import { MarkupRectangleOperator } from './markup-operators/markup.rectangle.operator';
import { MarkupSignatureOperator } from './markup-operators/markup.signature.operator';
import { MarkupTextBoxOperator } from './markup-operators/markup.textbox.operator';
import { MarkupData, UndoRedoAction, UndoRedoActionInfor } from './markup.data';
import { MarkupSelecion } from './markup.selection';
import { MarkupUndoRedo } from './markup.undo.redo';

export class MarkupEdit {
    public viewer: Communicator.WebViewer | null;
    public markupData: MarkupData | null;
    public markupUndoRedo: MarkupUndoRedo;
    public markupSelection: MarkupSelecion | null;
    public endMoveGripMode = false;
    public editTextMode = false;
    public openNoteEditFnc: { (): void; };
    public setNoteDataFnc: { (data: NoteData): void; };
    public constructor(viewer: Communicator.WebViewer | null,
        markupData: MarkupData, markupSelect: MarkupSelecion,
        undoRedo: MarkupUndoRedo) {
        this.viewer = viewer;
        this.markupData = markupData;
        this.markupUndoRedo = undoRedo;
        this.markupSelection = markupSelect;
        this.openNoteEditFnc = () => null;
        this.setNoteDataFnc = (data: NoteData) => null;
    }

    moveGripPointCallback(): void {
        if (this.viewer) {
            // clear selection before move grip point
            if (this.markupSelection) {
                const item = this.markupSelection.getMoveGripPointItem();
                if (item) {
                    this.endMoveGripMode = true;
                    const backUp = item.toJson();
                    const editMarkup: UndoRedoActionInfor = {
                        action: UndoRedoAction.Edit,
                        item: [item],
                        backUp: backUp
                    }
                    this.markupUndoRedo.pushToUndoStack(editMarkup);
                    if (item instanceof MarkupLineItem) {
                        const operatorItem = new MarkupLineOperator(this.viewer);
                        operatorItem.setMoveGripPointItem(item as MarkupLineItem);
                        operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                        const operatorId = this.viewer.registerCustomOperator(operatorItem);
                        operatorItem.setMarkupOperatorId(operatorId);
                        this.viewer.operatorManager.push(operatorId);
                    }
                    else if (item instanceof MarkupArrowItem) {
                        const operatorItem = new MarkupArrowOperator(this.viewer);
                        operatorItem.setMoveGripPointItem(item as MarkupArrowItem);
                        operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                        const operatorId = this.viewer.registerCustomOperator(operatorItem);
                        operatorItem.setMarkupOperatorId(operatorId);
                        this.viewer.operatorManager.push(operatorId);
                    }
                    else if (item instanceof MarkupCircleItem) {
                        const operatorItem = new MarkupCircleOperator(this.viewer);
                        operatorItem.setMoveGripPointItem(item as MarkupCircleItem);
                        operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                        const operatorId = this.viewer.registerCustomOperator(operatorItem);
                        operatorItem.setMarkupOperatorId(operatorId);
                        this.viewer.operatorManager.push(operatorId);
                    }
                    else if (item instanceof MarkupEllipseItem) {
                        const operatorItem = new MarkupEllipseOperator(this.viewer);
                        operatorItem.setMoveGripPointItem(item as MarkupEllipseItem);
                        operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                        const operatorId = this.viewer.registerCustomOperator(operatorItem);
                        operatorItem.setMarkupOperatorId(operatorId);
                        this.viewer.operatorManager.push(operatorId);
                    }
                    else if (item instanceof MarkupPolygonItem) {
                        const operatorItem = new MarkupPolygonOperator(this.viewer);
                        operatorItem.setMoveGripPointItem(item as MarkupPolygonItem);
                        operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                        const operatorId = this.viewer.registerCustomOperator(operatorItem);
                        operatorItem.setMarkupOperatorId(operatorId);
                        this.viewer.operatorManager.push(operatorId);
                    }
                    else if (item instanceof MarkupPolylineItem) {
                        const operatorItem = new MarkupPolylineOperator(this.viewer);
                        operatorItem.setMoveGripPointItem(item as MarkupPolylineItem);
                        operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                        const operatorId = this.viewer.registerCustomOperator(operatorItem);
                        operatorItem.setMarkupOperatorId(operatorId);
                        this.viewer.operatorManager.push(operatorId);
                    }
                    else if (item instanceof MarkupSignatureItem) {
                        const operatorItem = new MarkupSignatureOperator(this.viewer);
                        operatorItem.setMoveGripPointItem(item as MarkupSignatureItem);
                        operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                        const operatorId = this.viewer.registerCustomOperator(operatorItem);
                        operatorItem.setMarkupOperatorId(operatorId);
                        this.viewer.operatorManager.push(operatorId);
                    }
                    else if (item instanceof MarkupRectangleItem) {
                        const operatorItem = new MarkupRectangleOperator(this.viewer);
                        operatorItem.setMoveGripPointItem(item as MarkupRectangleItem);
                        operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                        const operatorId = this.viewer.registerCustomOperator(operatorItem);
                        operatorItem.setMarkupOperatorId(operatorId);
                        this.viewer.operatorManager.push(operatorId);
                    }
                    else if (item instanceof MarkupTextBoxItem) {
                        const operatorItem = new MarkupTextBoxOperator(this.viewer);
                        operatorItem.setMoveGripPointItem(item as MarkupTextBoxItem);
                        operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                        const operatorId = this.viewer.registerCustomOperator(operatorItem);
                        operatorItem.setMarkupOperatorId(operatorId);
                        this.viewer.operatorManager.push(operatorId);
                    }
                    else if (item instanceof MarkupNoteItem) {
                        const operatorItem = new MarkupNoteOperator(this.viewer);
                        operatorItem.setMoveGripPointItem(item as MarkupNoteItem);
                        operatorItem.setOperatorEndCallback(() => this.operatorEndCallback(operatorItem));
                        const operatorId = this.viewer.registerCustomOperator(operatorItem);
                        operatorItem.setMarkupOperatorId(operatorId);
                        this.viewer.operatorManager.push(operatorId);
                    }
                }
            }
        }
    }
    operatorEndCallback(oper: Communicator.Operator.OperatorBase): void {
        // reset move grip point
        if (this.markupSelection) {
            this.markupSelection.resetMoveGripPoint();
        }
        this.endMoveGripMode = false;

        // remove operator move grip point
        if (oper instanceof MarkupBaseOperator) {
            if (this.viewer) {
                this.viewer.operatorManager.remove(oper.getOperatorId());
            }
        }
    }
    editTextModeCallback(): void {
        if (this.viewer) {
            // clear selection before move grip point
            if (this.markupSelection) {
                const item = this.markupSelection.getTextEditItem();
                if (item instanceof MarkupTextBoxItem) {
                    const backUp = item.toJson();
                    const editMarkup: UndoRedoActionInfor = {
                        action: UndoRedoAction.Edit,
                        item: [item],
                        backUp: backUp
                    }
                    this.markupUndoRedo.pushToUndoStack(editMarkup);
                    const textItem = item as MarkupTextBoxItem;
                    textItem.onEditText();
                    this.editTextMode = true;
                }
            }
        }
    }
    parsePointString(str: string): number {
        return Number(str.replace('px', ''));
    }
    endEditTextMode(): void {
        this.editTextMode = false;
    }

    addMarkupToGroup(uniqueId: string, uniqueIdGroup: string): void {
        if (this.markupData) {
            const idx = this.markupData.markupItems.findIndex(t => t.uniqueId === uniqueId);
            if (idx >= 0) {
                const markup = this.markupData.markupItems[idx];
                markup.uniqueIdGroup = uniqueIdGroup;
                this.markupData.updateMarkupItems();
            }
        }
    }

    removeMarkupToGroup(uniqueId: string): void {
        if (this.markupData) {
            this.markupData.markupGroups = this.markupData.markupGroups.filter(val => val.uniqueId !== uniqueId);
            this.markupData.updateMarkupGroup();
        }
    }

    onOkNoteEditFunction(data: NoteEntity): void {
        if (this.viewer && this.markupSelection) {
            const item = this.markupSelection.getTextEditItem();
            if (item instanceof MarkupNoteItem) {
                const noteItem = item as MarkupNoteItem;
                noteItem.textValue = data.content
                this.editTextMode = false;
                this.markupSelection.endEditNoteItem();
            }
        }
    }
    onCancelNoteEdit(): void {
        this.editTextMode = false;
        if (this.markupSelection) {
            this.markupSelection.endEditNoteItem();
        }
    }
    updateSelectedMarkupsLineFormat(): boolean {
        let ret = false;
        if (this.viewer && this.markupData) {
            const markup = this.viewer.markupManager;
            if (this.markupData.markupItems && this.markupData.markupItems.length > 0) {
                for (let i = 0; i < this.markupData.markupItems.length; i++) {
                    if (this.markupData.markupItems[i].isSelected) {
                        const item = this.markupData.markupItems[i];
                        const backUp = item.toJson();
                        item.setLineFormat(
                            this.markupData.lineColor,
                            this.markupData.lineWeight,
                            this.markupData.lineOpacity,
                            this.markupData.fillColor,
                            this.markupData.fillColorOption,
                            this.markupData.lineStyle);

                        const editMarkup: UndoRedoActionInfor = {
                            action: UndoRedoAction.Edit,
                            item: [item],
                            backUp: backUp
                        }
                        this.markupUndoRedo.pushToUndoStack(editMarkup);
                        ret = true;
                    }
                }
                markup.refreshMarkup();
            }
        }
        return ret;
    }
    updateSelectedMarkupsTextFormat(): boolean {
        let ret = false;
        if (this.viewer && this.markupData) {
            const markup = this.viewer.markupManager;
            if (this.markupData.markupItems && this.markupData.markupItems.length > 0) {
                for (let i = 0; i < this.markupData.markupItems.length; i++) {
                    if (this.markupData.markupItems[i].isSelected) {
                        const item = this.markupData.markupItems[i];
                        const backUp = item.toJson();
                        item.setTextFormat(
                            this.markupData.textFontSize,
                            this.markupData.textColor,
                            this.markupData.textFontFamily,
                            this.markupData.textIsBold,
                            this.markupData.textIsItalic,
                            this.markupData.textIsUnderline);
                        const editMarkup: UndoRedoActionInfor = {
                            action: UndoRedoAction.Edit,
                            item: [item],
                            backUp: backUp
                        }
                        this.markupUndoRedo.pushToUndoStack(editMarkup);
                        ret = true;
                    }
                }
                markup.refreshMarkup();
            }
        }
        return ret;
    }
}