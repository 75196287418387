/* eslint-disable indent */
import { LineStyle, MarkupFillType } from "common/type-markup";
import MathHelper from "container/pdf-viewer/helper/math.helper";
import { BaseElementCanvas } from "./markup.base-canvas.element";
export class RectangleElementCanvas extends BaseElementCanvas {

    constructor() {
        super();
        this.offSet = 2;
        this.lineCap = "square";
        this.lineJoin = "miter";
        this.baseCanvas = document.createElement('canvas');
        this.baseCanvas.style.position = 'absolute';
        this.baseCanvas.style.zIndex = '1';
        this.baseCanvas.style.width = 'inherit';
        this.baseCanvas.style.height = 'inherit';
    }

    getRectangleCanvas(): HTMLElement {
        switch (this._lineStyle) {
            case LineStyle.cloud:
                this.drawCloudStyle();
                break;
            case LineStyle.zigzag:
                this.drawZicZacStyle();
                break;
            default:
                this.draw();
        }
        return this.baseCanvas;
    }

    draw(): void {
        if (!this.firstPoint) return;
        const width = this.size.x + (this.strokeWidth + this.offSet) * 2;
        const height = this.size.y + (this.strokeWidth + this.offSet) * 2;
        this.baseCanvas.style.left = `${this.firstPoint.x - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.style.top = `${this.firstPoint.y - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.width = width;
        this.baseCanvas.height = height;
        this.baseCanvas.style.width = `${width}px`;
        this.baseCanvas.style.height = `${height}px`;
        this.baseCanvas.style.transform = `rotate(${this.getRotation()}deg)`;
        const ctx = this.baseCanvas.getContext('2d');
        if (ctx) {
            ctx.lineJoin = this.lineJoin;
            ctx.lineCap = this.lineCap;
            MathHelper.applyLineStyle(ctx, this._lineStyle);
            ctx.clearRect(0, 0, this.baseCanvas.width, this.baseCanvas.height);

            ctx.beginPath();
            ctx.lineWidth = this.strokeWidth;
            ctx.rect(this.offSet + this.strokeWidth, this.offSet + this.strokeWidth, this.size.x, this.size.y);
            if (this.fillType === MarkupFillType.Opaque && this.fillColor) {
                const color = MathHelper.communicatorColorToRGBAString(this.fillColor, this.fillOpacity);
                if (color) {
                    ctx.fillStyle = color;
                    ctx.fill();
                }
            }
            if (this.strokeColor) {
                const color = MathHelper.rgbToHex(this.strokeColor.r, this.strokeColor.g, this.strokeColor.b);
                if (color) {
                    ctx.strokeStyle = color;
                }
            }
            ctx.stroke();
            ctx.closePath();
        }
    }

    drawZicZacStyle(): void {
        if (!this.firstPoint) return;
        const width = this.size.x + (this.strokeWidth + this.offSet) * 2;
        const height = this.size.y + (this.strokeWidth + this.offSet) * 2;
        this.baseCanvas.style.left = `${this.firstPoint.x - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.style.top = `${this.firstPoint.y - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.width = width;
        this.baseCanvas.height = height;
        this.baseCanvas.style.width = `${width}px`;
        this.baseCanvas.style.height = `${height}px`;
        this.baseCanvas.style.transform = `rotate(${this.getRotation()}deg)`;
        const ctx = this.baseCanvas.getContext('2d');
        if (ctx) {
            ctx.clearRect(0, 0, this.baseCanvas.width, this.baseCanvas.height);
            ctx.lineJoin = this.lineJoin;
            ctx.lineCap = this.lineCap;
            ctx.beginPath();

            // Draw rectangle
            let fstPnt = new Communicator.Point2(this.offSet + this.strokeWidth / 2, this.offSet + this.strokeWidth / 2);
            ctx.moveTo(fstPnt.x, fstPnt.y);
            let scndPnt = new Communicator.Point2(fstPnt.x + this.size.x + this.strokeWidth, fstPnt.y);
            this.zicZacLine(fstPnt, scndPnt, ctx);
            fstPnt = scndPnt;
            scndPnt = scndPnt.copy().add(new Communicator.Point2(0, this.size.y + this.strokeWidth));
            this.zicZacLine(fstPnt, scndPnt, ctx);
            fstPnt = scndPnt;
            scndPnt = scndPnt.copy().add(new Communicator.Point2(-(this.size.x + this.strokeWidth), 0));
            this.zicZacLine(fstPnt, scndPnt, ctx);
            fstPnt = scndPnt;
            scndPnt = scndPnt.copy().add(new Communicator.Point2(0, -(this.size.y + this.strokeWidth)));
            this.zicZacLine(fstPnt, scndPnt, ctx);

            ctx.lineWidth = this.strokeWidth;
            if (this.fillType === MarkupFillType.Opaque && this.fillColor) {
                const color = MathHelper.communicatorColorToRGBAString(this.fillColor, this.fillOpacity);
                if (color) {
                    ctx.fillStyle = color;
                    ctx.fill();
                }
            }
            if (this.strokeColor) {
                const color = MathHelper.rgbToHex(this.strokeColor.r, this.strokeColor.g, this.strokeColor.b);
                if (color) {
                    ctx.strokeStyle = color;
                }
            }
            ctx.stroke();
            ctx.closePath();
        }
    }

    zicZacLine(startPoint: Communicator.Point2, endPoint: Communicator.Point2, ctx: CanvasRenderingContext2D): void {
        if (!startPoint || !endPoint || !ctx) return;
        let vec = endPoint.copy().subtract(startPoint);
        const length = vec.length();

        const a = length / (this.offSet / 2);
        const step = a > Math.ceil(a) - 0.5 ? Math.ceil(a) + 0.5 : Math.ceil(a) - 0.5;
        const spacing = length / step;
        vec = vec.scale(spacing / length);

        const points: Communicator.Point2[] = [startPoint];
        let tmpPnt = startPoint;
        for (let i = 1; i < step; i++) {
            tmpPnt = tmpPnt.copy().add(vec);
            points.push(tmpPnt);
        }
        points.push(endPoint);

        const tmpVec = vec.copy().scale(1 / 4);
        const upVec = new Communicator.Point2(vec.y, -vec.x);
        upVec.scale(1 / 4);
        const downVec = new Communicator.Point2(-upVec.x, -upVec.y);

        for (let i = 0; i < points.length - 2; i++) {
            const fstPnt = points[i];
            const scndPnt = points[i + 1];

            let upPnt = fstPnt.copy().add(tmpVec.copy());
            upPnt = upPnt.copy().add(upVec.copy());
            ctx.lineTo(upPnt.x, upPnt.y);

            let downPnt = fstPnt.copy().add(tmpVec.copy().scale(3));
            downPnt = downPnt.copy().add(downVec.copy());
            ctx.lineTo(downPnt.x, downPnt.y);

            ctx.lineTo(scndPnt.x, scndPnt.y);
        }

        let upPnt = points[points.length - 2];
        upPnt = upPnt.copy().add(tmpVec.copy());
        upPnt = upPnt.copy().add(upVec.copy());
        ctx.lineTo(upPnt.x, upPnt.y);
        ctx.lineTo(endPoint.x, endPoint.y);
    }

    drawCloudStyle(): void {
        if (!this.firstPoint) return;
        const width = this.size.x + (this.strokeWidth + this.offSet) * 2;
        const height = this.size.y + (this.strokeWidth + this.offSet) * 2;
        this.baseCanvas.style.left = `${this.firstPoint.x - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.style.top = `${this.firstPoint.y - this.strokeWidth - this.offSet}px`;
        this.baseCanvas.width = width;
        this.baseCanvas.height = height;
        this.baseCanvas.style.width = `${width}px`;
        this.baseCanvas.style.height = `${height}px`;
        this.baseCanvas.style.transform = `rotate(${this.getRotation()}deg)`;
        const ctx = this.baseCanvas.getContext('2d');
        if (ctx) {
            ctx.clearRect(0, 0, this.baseCanvas.width, this.baseCanvas.height);
            ctx.lineJoin = this.lineJoin;
            ctx.lineCap = this.lineCap;
            ctx.beginPath();

            // Draw rectangle
            let fstPnt = new Communicator.Point2(this.offSet + this.strokeWidth / 2, this.offSet + this.strokeWidth / 2);
            ctx.moveTo(fstPnt.x, fstPnt.y);
            let scndPnt = new Communicator.Point2(fstPnt.x + this.size.x + this.strokeWidth, fstPnt.y);
            this.cloudLine(fstPnt, scndPnt, ctx);
            fstPnt = scndPnt;
            scndPnt = scndPnt.copy().add(new Communicator.Point2(0, this.size.y + this.strokeWidth));
            this.cloudLine(fstPnt, scndPnt, ctx);
            fstPnt = scndPnt;
            scndPnt = scndPnt.copy().add(new Communicator.Point2(-(this.size.x + this.strokeWidth), 0));
            this.cloudLine(fstPnt, scndPnt, ctx);
            fstPnt = scndPnt;
            scndPnt = scndPnt.copy().add(new Communicator.Point2(0, -(this.size.y + this.strokeWidth)));
            this.cloudLine(fstPnt, scndPnt, ctx);

            ctx.lineWidth = this.strokeWidth;
            if (this.fillType === MarkupFillType.Opaque && this.fillColor) {
                const color = MathHelper.communicatorColorToRGBAString(this.fillColor, this.fillOpacity);
                if (color) {
                    ctx.fillStyle = color;
                    ctx.fill();
                }
            }
            if (this.strokeColor) {
                const color = MathHelper.rgbToHex(this.strokeColor.r, this.strokeColor.g, this.strokeColor.b);
                if (color) {
                    ctx.strokeStyle = color;
                }
            }
            ctx.stroke();
            ctx.closePath();
        }
    }

    cloudLine(startPoint: Communicator.Point2, endPoint: Communicator.Point2, ctx: CanvasRenderingContext2D): void {
        if (!startPoint || !endPoint || !ctx) return;
        let vec = endPoint.copy().subtract(startPoint);
        const strArg = Math.atan2(-vec.y, -vec.x);
        const endArg = strArg + Math.PI;
        const length = vec.length();

        const a = length / (this.offSet / 2);
        const step = Math.ceil(a);
        const spacing = length / step;
        vec = vec.scale(spacing / length);

        const points: Communicator.Point2[] = [startPoint];
        let pnt = startPoint;

        for (let i = 0; i < step - 1; i++) {
            pnt = pnt.copy().add(vec);
            points.push(pnt);
        }

        points.push(endPoint);

        for (let i = 0; i < points.length - 1; i++) {
            const fstPnt = points[i];
            const scndPnt = points[i + 1];
            ctx.arc((fstPnt.x + scndPnt.x) / 2, (fstPnt.y + scndPnt.y) / 2, spacing / 2, strArg, endArg);
        }
    }
}
