import { MarkupAttachType } from "common/type-markup";

/* eslint-disable */
/* eslint-disable max-classes-per-file */
export class SelectionPoints {
    public readonly worldPosition: Communicator.Point3 | null;

    public readonly screenPosition: Communicator.Point2;

    public readonly selectionItem: Communicator.Selection.SelectionItem;

    public constructor(worldPosition: Communicator.Point3 | null, screenPosition: Communicator.Point2, selectionItem: Communicator.Selection.SelectionItem) {
      this.worldPosition = worldPosition;
      this.screenPosition = screenPosition;
      this.selectionItem = selectionItem;
    }
}

export class CursorMarkup extends Communicator.Markup.Redline.RedlineItem {
    private readonly _cursorSprite = new Communicator.Markup.Shape.Circle();
    private readonly _cursorLines = new Communicator.Markup.Shape.LineCollection();
    
    private readonly _cursorLine = new Communicator.Markup.Shape.Line();
    private readonly _cursorRec = new Communicator.Markup.Shape.Rectangle();

    private readonly _markupId: string;
    attachMode:MarkupAttachType | undefined;
    bMidPoint:boolean = false;
    bEndpoint:boolean = false;
    public constructor(viewer: Communicator.WebViewer) {
      super(viewer);
      this._cursorSprite.setFillOpacity(0);
      this._cursorSprite.setStrokeWidth(2);
      this._cursorSprite.setStrokeColor(new Communicator.Color(255, 153, 51));

      this._cursorLines.setStrokeColor(new Communicator.Color(255, 153, 51));
      this._cursorLines.setStrokeWidth(2);

      this._cursorLine.setStrokeColor(new Communicator.Color(255, 153, 51));
      this._cursorLine.setStrokeWidth(2);

      this._cursorRec.setStrokeWidth(2);
      this._cursorRec.setStrokeColor(new Communicator.Color(255, 153, 0));
      this._cursorRec.setFillOpacity(0);
      this._markupId = viewer.markupManager.registerMarkup(this);
    }

    public draw(): void {
      const renderer = this._viewer.markupManager.getRenderer();
      // renderer.drawCircle(this._cursorSprite);
      switch (this.attachMode) {
        case MarkupAttachType.Edge:
        {
          let cursorLine1 = new Communicator.Markup.Shape.Line();
          let cursorLine2 = new Communicator.Markup.Shape.Line();
          let cursorLine3 = new Communicator.Markup.Shape.Line();
    
       
          let pCenter = this._cursorLine.getP1();

          if(this.bEndpoint === true){
            this._cursorRec.setStrokeWidth(2);
            this._cursorRec.setStrokeColor(new Communicator.Color(255, 153, 0));
            renderer.drawRectangle(this._cursorRec);
            break;
          }
          else if(this.bMidPoint === false){

            cursorLine1.setP1(pCenter)
            cursorLine1.setP2(new Communicator.Point2(pCenter.x, pCenter.y - 6));
            cursorLine1.setStrokeColor(new Communicator.Color(255, 153, 51));
            cursorLine1.setStrokeWidth(2);

            cursorLine2.setP1(pCenter)
            cursorLine2.setP2(new Communicator.Point2(pCenter.x - 6, pCenter.y + 6));
            cursorLine2.setStrokeColor(new Communicator.Color(255, 153, 51));
            cursorLine2.setStrokeWidth(2);

            cursorLine3.setP1(pCenter)
            cursorLine3.setP2(new Communicator.Point2(pCenter.x + 6, pCenter.y + 6));
            cursorLine3.setStrokeColor(new Communicator.Color(255, 153, 51));
            cursorLine3.setStrokeWidth(2);

            renderer.drawLine(cursorLine1);
            renderer.drawLine(cursorLine2);
            renderer.drawLine(cursorLine3);
          }
          else {
            cursorLine1.setP1(new Communicator.Point2(pCenter.x, pCenter.y - 6));
            cursorLine1.setP2(new Communicator.Point2(pCenter.x - 6, pCenter.y + 6));
            cursorLine1.setStrokeColor(new Communicator.Color(255, 153, 51));
            cursorLine1.setStrokeWidth(2);

            cursorLine2.setP1(new Communicator.Point2(pCenter.x - 6, pCenter.y + 6));
            cursorLine2.setP2(new Communicator.Point2(pCenter.x + 6, pCenter.y + 6));
            cursorLine2.setStrokeColor(new Communicator.Color(255, 153, 51));
            cursorLine2.setStrokeWidth(2);

            cursorLine3.setP1(new Communicator.Point2(pCenter.x, pCenter.y - 6));
            cursorLine3.setP2(new Communicator.Point2(pCenter.x + 6, pCenter.y + 6));
            cursorLine3.setStrokeColor(new Communicator.Color(255, 153, 51));
            cursorLine3.setStrokeWidth(2);

            renderer.drawLine(cursorLine1);
            renderer.drawLine(cursorLine2);
            renderer.drawLine(cursorLine3);
          }
          
          break;
        }
        case MarkupAttachType.Vertex:
        {
          this._cursorSprite.setStrokeColor(new Communicator.Color(255, 153, 51));
          renderer.drawRectangle(this._cursorRec);
          break;
        }
        case MarkupAttachType.Face:
        {
          this._cursorRec.setStrokeWidth(1);
          this._cursorRec.setStrokeColor(new Communicator.Color(255, 153, 0));
          renderer.drawRectangle(this._cursorRec);
          break;
        }
        default:
          renderer.drawCircle(this._cursorSprite);
          break;
      }
    }

    public enable(enable: boolean): void {
      this._cursorSprite.setRadius(enable ? 2 : 0);
      this._cursorRec.setSize(enable ? new Communicator.Point2(12, 12):new Communicator.Point2(0, 0));
      if(enable === false){
        this._cursorLine.setP1(new Communicator.Point2(0, 0));
        this._cursorLine.setP2(new Communicator.Point2(0, 0));
      }
    }

    public remove(): void {
      this._cursorSprite.setRadius(0);
      this._cursorRec.setSize(new Communicator.Point2(0, 0));
    }

    public isEnabled(): boolean {
      return this._cursorSprite.getRadius() > 0;
    }

    public setPosition(point: Communicator.Point2): void {
      let cursorLine1 = new Communicator.Markup.Shape.Line();

      this._cursorSprite.setCenter(point);
      this._cursorRec.setSize(new Communicator.Point2(12, 12));
      this._cursorRec.setPosition(new Communicator.Point2(point.x - 6, point.y - 6));

      let pCenter = new Communicator.Point2(point.x, point.y);
      cursorLine1.setP1(pCenter)
      cursorLine1.setP2(new Communicator.Point2(pCenter.x, pCenter.y + 6));

      this._cursorLine.setP1(cursorLine1.getP1());
      this._cursorLine.setP2(cursorLine1.getP2());
    }

    public setMarkupAttachType(attachMode: MarkupAttachType, bMidPoint: boolean, bEndpoint:boolean = false): void {
      this.attachMode = attachMode;
      this.bMidPoint = bMidPoint;
      this.bEndpoint = bEndpoint;
    }

    public destroy(): void {
      this._viewer.markupManager.unregisterMarkup(this._markupId);
    }
}
