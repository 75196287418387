/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MarkupAttachType } from "common/type-markup";
import MathHelper from "container/pdf-viewer/helper/math.helper";
import { MarkupCursorItem } from "../markup/markup-items/markup.cursor.item";

export default class CursorSprite {
    private _viewer: Communicator.WebViewer | null = null;
    private _cursorMarkup: MarkupCursorItem | null = null;
    private _attachMode: MarkupAttachType | null = null;
    private _selectionItem: any = null;
    private bMidPoint = false;
    private bEndpoint = false;
    constructor(viewer: Communicator.WebViewer) {
        this._viewer = viewer;
        this._cursorMarkup = new MarkupCursorItem(this._viewer);
    }

    async updateCursorSpriteImpl(
        mousePosition: Communicator.Point2,
        useSnapping: boolean,
    ): Promise<void> {
        if (this._cursorMarkup) {
            if (useSnapping) {
                const worldSnapPosition = await this.getMarkupAttachType(mousePosition, this._viewer!);
                if (!worldSnapPosition) return;
                const selection = await this.getSelectionCursorPointsEx(mousePosition, this._viewer!, this._selectionItem, worldSnapPosition);
                if (selection) {
                    this._cursorMarkup.setPosition(selection.screenPosition);
                    this._activateCursorSprite(true);
                } else {
                    this._activateCursorSprite(false);
                }
            } else {
                this._cursorMarkup.setPosition(mousePosition);
            }
        }
        this._draw();
    }

    private _draw(): void {
        if (this._cursorMarkup) {
            this._cursorMarkup.draw();
            this._viewer!.markupManager.refreshMarkup();
        }

    }

    private _activateCursorSprite(enable: boolean): void {
        if (this._cursorMarkup !== null) {
            this._cursorMarkup.enable(enable);
        }
    }

    async getMarkupAttachType(mousePosition: Communicator.Point2, viewer: Communicator.WebViewer): Promise<Communicator.Point3 | null> {
        let result = true;
        let worldSnapPosition: Communicator.Point3 | null = null;

        const attachModes = [MarkupAttachType.Edge, MarkupAttachType.Vertex, MarkupAttachType.Face, MarkupAttachType.None]
        for (const attachMode of attachModes) {
            const config = this.createConfig(attachMode);
            if (!config) return null;
            let selectionItem = null;
            try {
                selectionItem = await viewer.view.pickFromPoint(mousePosition, config);
            } catch (e) {
                return null;
            }
            if (!selectionItem) return null;
            if (selectionItem.overlayIndex() !== 0) {
                result = false;
                this.enableCursorMarkup(false);
                this._viewer && this._viewer.markupManager.refreshMarkup()
                continue;
            }
            this.bMidPoint = false;
            this.bEndpoint = false;
            switch (attachMode) {
                case MarkupAttachType.Edge:
                    {
                        const lineEnt = selectionItem.getLineEntity();
                        if (lineEnt) {
                            worldSnapPosition = lineEnt.getPosition();
                            this.bMidPoint = this.checkMidPoint(worldSnapPosition, lineEnt.getBounding().center(), lineEnt)
                            const result = this.checkEndPoint(worldSnapPosition, lineEnt);
                            if (result === 1) {
                                this.bEndpoint = true;
                                worldSnapPosition = lineEnt.getBounding().max;
                            }
                            if (result === 2) {
                                this.bEndpoint = true;
                                worldSnapPosition = lineEnt.getBounding().min;
                            }
                            if (this.bMidPoint === true) {
                                worldSnapPosition = lineEnt.getBounding().center();
                            }
                        }

                        break;
                    }
                case MarkupAttachType.Vertex:
                    {
                        const lineEnt = selectionItem.getLineEntity();
                        if (lineEnt) {
                            const points = lineEnt.getPoints();
                            const bestVertex = lineEnt.getBestVertex();
                            if (!bestVertex) break;
                            if (bestVertex.equals(points[0]) || bestVertex.equals(points[points.length - 1])) {
                                worldSnapPosition = bestVertex;
                            }
                        }
                        break;
                    }
                case MarkupAttachType.Face:
                    {
                        const faceEnt = selectionItem.getFaceEntity();
                        if (faceEnt) worldSnapPosition = faceEnt.getPosition();
                        break;
                    }
                default:
                    break;
            }
            if (worldSnapPosition !== null && this._cursorMarkup) {
                this._cursorMarkup.setMarkupAttachType(attachMode, this.bMidPoint, this.bEndpoint);
                this._attachMode = attachMode;
                this._selectionItem = selectionItem;
                result = true;
                break;
            }
        }
        if (result === false) {
            this._attachMode = null;
            this._selectionItem = null;
        }

        return worldSnapPosition;
    }
    createConfig(attachMode: MarkupAttachType): Communicator.PickConfig | null {
        switch (attachMode) {
            case MarkupAttachType.Vertex:
                return new Communicator.PickConfig(Communicator.SelectionMask.Line);
            case MarkupAttachType.Face:
                return new Communicator.PickConfig(Communicator.SelectionMask.Face);
            case MarkupAttachType.Edge:
                return new Communicator.PickConfig(Communicator.SelectionMask.Line);
            default:
                return null;
        }
    }

    checkMidPoint(pSnapPoint: Communicator.Point3, pCenter: Communicator.Point3, lineEnt: Communicator.Selection.LineEntity): boolean {
        const pStartPoint: Communicator.Point3 = lineEnt.getBounding().max;
        const pEndPoint: Communicator.Point3 = lineEnt.getBounding().min;
        if ((Math.abs(pSnapPoint.x - pCenter.x) - Math.abs((pEndPoint.x - pStartPoint.x) / 60)) <= 0.01 &&
            (Math.abs(pSnapPoint.y - pCenter.y) - Math.abs((pEndPoint.y - pStartPoint.y) / 60)) <= 0.01 &&
            (Math.abs(pSnapPoint.z - pCenter.z) - Math.abs((pEndPoint.z - pStartPoint.z) / 60)) <= 0.01)
            return true;
        return false;
    }

    checkEndPoint(pSnapPoint: Communicator.Point3, lineEnt: Communicator.Selection.LineEntity): number {

        const pStartPoint: Communicator.Point3 = lineEnt.getBounding().max;
        const pEndPoint: Communicator.Point3 = lineEnt.getBounding().min;
        if ((Math.abs(pSnapPoint.x - pStartPoint.x) - Math.abs((pEndPoint.x - pStartPoint.x) / 90)) <= 0.01 &&
            (Math.abs(pSnapPoint.y - pStartPoint.y) - Math.abs((pEndPoint.y - pStartPoint.y) / 90)) <= 0.01 &&
            (Math.abs(pSnapPoint.z - pStartPoint.z) - Math.abs((pEndPoint.z - pStartPoint.z) / 90)) <= 0.01) {
            return 1;
        }
        if ((Math.abs(pSnapPoint.x - pEndPoint.x) - Math.abs((pEndPoint.x - pStartPoint.x) / 90)) <= 0.01 &&
            (Math.abs(pSnapPoint.y - pEndPoint.y) - Math.abs((pEndPoint.y - pStartPoint.y) / 90)) <= 0.01 &&
            (Math.abs(pSnapPoint.z - pEndPoint.z) - Math.abs((pEndPoint.z - pStartPoint.z) / 90)) <= 0.01) {
            return 2;
        }
        return 0;
    }
    async getSelectionCursorPointsEx(
        mousePosition: Communicator.Point2,
        viewer: Communicator.WebViewer,
        selectionItem: any,
        worldSnapPosition: Communicator.Point3 | null
    )
        : Promise<SelectionPoints | null> {

        if (selectionItem === null || selectionItem.overlayIndex() !== 0) {
            return null;
        }

        let worldPosition = selectionItem.getPosition();
        let screenPosition: Communicator.Point2 | null = mousePosition;


        if (worldSnapPosition) {
            worldPosition = worldSnapPosition;
            screenPosition = MathHelper.worldPointToScreenPoint(viewer, worldPosition);
        } else {
            screenPosition = null;
            worldPosition = null;
        }

        return new SelectionPoints(worldPosition, screenPosition!, selectionItem);
    }

    removeCursorSprite(): void {
        if (this._cursorMarkup) this._cursorMarkup.destroy();
    }

    enableCursorMarkup(enable: boolean) {
        if (this._cursorMarkup) this._cursorMarkup.enable(enable);

    }

}

class SelectionPoints {
    public readonly worldPosition: Communicator.Point3 | null;

    public readonly screenPosition: Communicator.Point2;

    public readonly selectionItem: Communicator.Selection.SelectionItem;

    public constructor(worldPosition: Communicator.Point3 | null, screenPosition: Communicator.Point2, selectionItem: Communicator.Selection.SelectionItem) {
        this.worldPosition = worldPosition;
        this.screenPosition = screenPosition;
        this.selectionItem = selectionItem;
    }
}