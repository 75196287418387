import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from 'common/global';
import { RootEpic } from 'common/type-state';
import { withLatestFrom, switchMap, filter } from 'rxjs/operators';
interface MarkupToolbarState {
    enableSaveBtn: boolean;
    enableUndoBtn: boolean;
    enableRedoBtn: boolean;
    selectedStamp: string;
}
const defaultValue: MarkupToolbarState = {
    enableSaveBtn: false,
    enableUndoBtn: false,
    enableRedoBtn: false,
    selectedStamp: '',
};

export const markupToolbarSlice = createSlice({
    name: 'markupToolbar',
    initialState: defaultValue,
    reducers: {
        setEnableSaveBtn: (state, action: PayloadAction<boolean>) => { return },
        applyEnableSaveBtn: (state, action: PayloadAction<boolean>) => {
            state.enableSaveBtn = action.payload;
        },
        setEnableUndoBtn: (state, action: PayloadAction<boolean>) => { return },
        applyEnableUndoBtn: (state, action: PayloadAction<boolean>) => {
            state.enableUndoBtn = action.payload;
        },
        setEnableRedoBtn: (state, action: PayloadAction<boolean>) => { return },
        applyEnableRedoBtn: (state, action: PayloadAction<boolean>) => {
            state.enableRedoBtn = action.payload;
        },
        setSelectedStamp: (state, action: PayloadAction<string>) => {
            state.selectedStamp = action.payload;
        },
    },
});

const setEnableSaveBtn$: RootEpic = (action$, state$) => action$.pipe(
    filter(setEnableSaveBtn.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        GlobalState.mapEnableSaveBtn.set(viewActive.viewId, action.payload);
        return [applyEnableSaveBtn(action.payload)];
    }))

const setEnableUndoBtn$: RootEpic = (action$, state$) => action$.pipe(
    filter(setEnableUndoBtn.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        GlobalState.mapEnableUndoBtn.set(viewActive.viewId, action.payload);
        return [applyEnableUndoBtn(action.payload)];
    }))

const setEnableRedoBtn$: RootEpic = (action$, state$) => action$.pipe(
    filter(setEnableRedoBtn.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewActive } = state.multiViewer;
        GlobalState.mapEnableRedoBtn.set(viewActive.viewId, action.payload);
        return [applyEnableRedoBtn(action.payload)];
    }))

export const MarkupToolbarActionEpics = [
    setEnableSaveBtn$,
    setEnableUndoBtn$,
    setEnableRedoBtn$,
]

export const {
    setEnableSaveBtn,
    applyEnableSaveBtn,
    setEnableUndoBtn,
    applyEnableUndoBtn,
    setEnableRedoBtn,
    applyEnableRedoBtn,
    setSelectedStamp,
} = markupToolbarSlice.actions;

export default markupToolbarSlice.reducer;