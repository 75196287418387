/* eslint-disable indent */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FileInfo, FlatTreeNode } from "common/define";
import { GlobalState } from "common/global";
import {
    PayloadCuttingBoxState,
    PayloadCuttingState,
    PayloadExplodeValue,
    PayloadHidedItemNode3D,
    PayloadMeasureSettingState,
    PayloadRemoveHidedItemNode3D,
    PayloadSelectionItem3D,
    PayloadSetListOperator,
    PayloadSetListOperatorDisable,
    PayloadSetOperatorActive,
    PayloadUpdateStateCallback,
    RootEpic
} from "common/type-state";
import { mapArrTypeChildrenRender } from "helper/operator.helper";
import { catchError, distinctUntilChanged, filter, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import Utils, { Lodash } from "utils/utils";
import { Viewer3DHelper } from './helper';
import { loadSheetDataDefault } from "./sheets.slice";
import { fetchDataTreeFlat } from "./tree.slice";

const initStateViewer3D: Viewer3DHelper.Viewer3DState = {
    operator: {},
    cuttingPlane: {},
    planeBoxChildState: {},
    loadingViewer: {},
    webViewerState: {},
    modelStructureReady: {},
    selectionItem: {},
    hidedItem: {},
    removeAllHidedItem: false,
    measureSetting: {},
};

const viewer3DSlice = createSlice({
    name: 'viewer3D',
    initialState: initStateViewer3D,
    reducers: {
        createStream(state, action: PayloadAction<{ fileInfo: FileInfo, containerId: string }>) {
            const { fileInfo } = action.payload;
            state.loadingViewer[fileInfo.viewId] = true;
            state.webViewerState[fileInfo.viewId] = false;
        },
        createWebViewer(state, action: PayloadAction<string>) {
            const viewId = action.payload;
            state.loadingViewer[viewId] = false;
            state.webViewerState[viewId] = true;
        },
        initOperatorState(state, action: PayloadAction<ViewId>) { return },
        initOperatorStateComplete(state, action: PayloadAction<Viewer3DHelper.ResultInitOperatorState>) {
            const { operatorState, cuttingPlaneState, measureSetting, viewId } = action.payload;
            if (viewId) {
                state.operator[viewId] = operatorState;
                state.cuttingPlane[viewId] = cuttingPlaneState;
                state.measureSetting[viewId] = measureSetting;
            }
            GlobalState.measureSetting$.next(measureSetting);
        },
        updateStateCallback(state, action: PayloadAction<PayloadUpdateStateCallback>) {
            const { viewId, key, value } = action.payload;
            if (key === 'modelStructureReady') {
                state.modelStructureReady[viewId] = value as boolean
            }
        },
        setOperatorState3D(state, action: PayloadAction<PayloadSetOperatorActive>) {
            const { viewId, operator } = action.payload;
            if (viewId.includes('blank')) return
            const currentStateOperator = state.operator[viewId]?.currentOperator;
            let finalOperatorActive = [operator];
            if (currentStateOperator) {
                const isDrawOp = mapArrTypeChildrenRender.includes(operator);
                let operatorFind;
                if (isDrawOp) {
                    operatorFind = currentStateOperator.find(op => !mapArrTypeChildrenRender.includes(op));
                } else {
                    operatorFind = currentStateOperator.find(op => mapArrTypeChildrenRender.includes(op));
                }
                if (operatorFind) {
                    finalOperatorActive = [operatorFind, operator]
                }
                state.operator[viewId].currentOperator = finalOperatorActive;
            }
        },
        setOperatorDisable3D(state, action: PayloadAction<PayloadSetListOperatorDisable>) {
            const { viewId, listOperator } = action.payload;
            if (state.operator[viewId]) {
                state.operator[viewId].listOperatorDisable = listOperator;
            }
        },
        setListOperator3D(state, action: PayloadAction<PayloadSetListOperator>) {
            const { viewId, listOperator } = action.payload;
            state.operator[viewId].listCurrentOperator = listOperator;
        },
        setExplodeValue(state, action: PayloadAction<PayloadExplodeValue>) {
            const { viewId, value } = action.payload;
            const { currentOperator, currentValueExplode } = Viewer3DHelper.setExplodeState(value, state.operator[viewId]);
            currentValueExplode && (state.operator[viewId].currentValueExplode = currentValueExplode);
            currentOperator && (state.operator[viewId].currentOperator = currentOperator)
        },
        updateCuttingState(state, action: PayloadAction<PayloadCuttingState>) {
            const { viewId, cuttingState } = action.payload;
            state.cuttingPlane[viewId] = {
                active: cuttingState.active,
                disable: cuttingState.disable
            };
            if (cuttingState.planeBoxChildState) {
                state.planeBoxChildState[viewId] = {
                    planeBoxChild: cuttingState.planeBoxChildState.planeBoxChild,
                    isClipping: cuttingState.planeBoxChildState.isClipping
                };
            } else if (cuttingState.active?.includes('plane-box')) {
                state.planeBoxChildState[viewId] = {
                    planeBoxChild: [],
                    isClipping: true
                };
            }
            if (cuttingState.active?.includes('toggle-plane')) {
                state.planeBoxChildState[viewId] = {
                    planeBoxChild: [],
                    isClipping: false
                };
            }
            const { currentOperator } = state.operator[viewId];
            const resultCurrentOperator = Viewer3DHelper.updateCuttingPlaneState(cuttingState, currentOperator);
            if (resultCurrentOperator) {
                state.operator[viewId].currentOperator = resultCurrentOperator
            }
        },
        updateCuttingPlaneBoxState(state, action: PayloadAction<PayloadCuttingBoxState>) {
            const { viewId, planeBoxChildState, isClipping, actionClipping } = action.payload;
            state.planeBoxChildState[viewId] = actionClipping !== 'turnOffClipping' ? {
                planeBoxChild: planeBoxChildState,
                isClipping: isClipping
            } : { ...state.planeBoxChildState[viewId], isClipping: isClipping };
        },
        resetOperatorState(state, action: PayloadAction<ViewId>) { return },
        resetOperatorStateComplete(state, action: PayloadAction<Viewer3DHelper.ResultResetOperatorState>) {
            const { operatorState, viewId } = action.payload;
            if (viewId) {
                state.operator[viewId] = operatorState;
            }
        },
        updateSelectionItem(state, action: PayloadAction<PayloadSelectionItem3D>) { return },
        updateSelectionItemComplete(state, action: PayloadAction<PayloadSelectionItem3D>) {
            const { viewId, selectionArray, type } = action.payload;
            state.selectionItem[viewId] = { nodes: selectionArray, type }
        },
        updateHidedItem(state, action: PayloadAction<PayloadHidedItemNode3D>) {
            const { viewId, hidedItems } = action.payload;
            const currentHidedItems = state.hidedItem[viewId] ?? [];
            const newCurrentHidedItems = [...currentHidedItems, ...hidedItems];
            const newArrSet = Array.from(new Set(newCurrentHidedItems));
            state.hidedItem[viewId] = newArrSet;
        },
        removeHideItem(state, action: PayloadAction<PayloadRemoveHidedItemNode3D>) {
            const { viewId, node, removeAll } = action.payload;
            let result: NodeId[] = [];
            if (removeAll) {
                result = []
                state.removeAllHidedItem = true;
            } else {
                result = Viewer3DHelper.removeItemInArrHide(state.hidedItem[viewId] ?? [], node ?? []);
            }
            state.hidedItem[viewId] = result;
        },
        setRemoveAllHidedItem(state, action: PayloadAction<boolean>) {
            state.removeAllHidedItem = false;
        },
        errorCreateWebViewer: (state, action: PayloadAction<string>) => {
            state.loadingViewer[action.payload] = false;
        },
        deleteStateByViewIdViewer3D(state, action: PayloadAction<ViewId>) {
            const viewId = action.payload;
            const reDeleteLoading = Utils.unsetPathObject(state.loadingViewer, viewId);
            reDeleteLoading && (state.loadingViewer = reDeleteLoading);
            const reDeleteCutting = Utils.unsetPathObject(state.cuttingPlane, viewId);
            reDeleteCutting && (state.cuttingPlane = reDeleteCutting);
            const reDeleteViewerState = Utils.unsetPathObject(state.webViewerState, viewId);
            reDeleteViewerState && (state.webViewerState = reDeleteViewerState);
            const reDeleteOperator = Utils.unsetPathObject(state.operator, viewId);
            reDeleteOperator && (state.operator = reDeleteOperator);
            const reDeleteStructureReady = Utils.unsetPathObject(state.modelStructureReady, viewId);
            reDeleteStructureReady && (state.modelStructureReady = reDeleteStructureReady);
            const reDeleteSelectionItem = Utils.unsetPathObject(state.selectionItem, viewId);
            reDeleteSelectionItem && (state.selectionItem = reDeleteSelectionItem);
            const reDeleteHidedItem = Utils.unsetPathObject(state.hidedItem, viewId);
            reDeleteHidedItem && (state.hidedItem = reDeleteHidedItem);
            const reDeleteMeasureSetting = Utils.unsetPathObject(state.measureSetting, viewId);
            reDeleteMeasureSetting && (state.measureSetting = reDeleteMeasureSetting);
        },
        setMeasureSettingValue(state, action: PayloadAction<PayloadMeasureSettingState>) {
            const { viewId, setting } = action.payload;
            state.measureSetting[viewId] = setting;
            GlobalState.measureSetting$.next(setting);
        },
        resetModel(state, action: PayloadAction<ViewId>) { return }
    }
});
/** private */
const actions = viewer3DSlice.actions;

const viewer3D$: RootEpic = (action$) => action$.pipe(
    filter(createStream.match),
    mergeMap((action) => {
        const { fileInfo, containerId } = action.payload;
        const { viewId } = fileInfo;
        return Viewer3DHelper.createDataStream$(fileInfo, containerId).pipe(
            mergeMap((resCall) => [
                createWebViewer(resCall),
                viewer3DSlice.actions.initOperatorState(viewId),
                loadSheetDataDefault(viewId),
                // fetchDataTree({ modelFileId, fileName: originalFile, viewId }), // remove hierarchy tree
                fetchDataTreeFlat({
                    fileInfo
                }), // testflat tree
            ]),
            catchError(err => [actions.errorCreateWebViewer(viewId)])
        )
    })
)
const initOperatorState$: RootEpic = (action$, state$) => action$.pipe(
    filter(viewer3DSlice.actions.initOperatorState.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
        const viewId = action.payload;
        const isInViewOnly = state.multiViewer.listViewOnly.includes(viewId);
        return Viewer3DHelper.initOperatorStateObser(isInViewOnly).pipe(
            map(result => {
                result.viewId = viewId;
                return actions.initOperatorStateComplete(result);
            })
        )
    })
)
const resetOperatorState$: RootEpic = (action$, state$) => action$.pipe(
    filter(resetOperatorState.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const viewId = action.payload;
        const isInViewOnly = state.multiViewer.listViewOnly.includes(viewId);
        return Viewer3DHelper.resetOperatorStateObser(isInViewOnly).pipe(
            map(result => {
                result.viewId = viewId;
                return actions.resetOperatorStateComplete(result);
            })
        )
    })
)
const updateStateModelStructureReady$: RootEpic = (action$) => action$.pipe(
    filter(updateStateCallback.match),
    filter(action => {
        const { key, value } = action.payload;
        return key === 'modelStructureReady' && value === true;
    }),
    map(actionReady => actionReady.payload.viewId),
    mergeMap(viewId => {
        GlobalState.observableRender.next(viewId);
        GlobalState.mapFileInfoRenderSuccess.set(viewId, viewId);
        return [
            // loadSheetDataDefault(viewId)
        ]
    })
)

const updateSelectionItem$: RootEpic = (action$, state$) => action$.pipe(
    filter(updateSelectionItem.match),
    distinctUntilChanged((pre, curr) => Lodash.isEqual(pre.payload, curr.payload)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { viewId: viewIdApply, selectionArray, type } = action.payload;
        const viewIdActive = state.multiViewer.viewActive.viewId;
        const webViewerActive = GlobalState.map3DViewer.get(viewIdActive);
        const fileList = state.filesList.filesOrigin;

        const ViewIdModelTree = Utils.isCaseMultiStreamUseOneTree(
            viewIdApply,
            `.${state.multiViewer.viewActive.extension}`
        );
        const prevSelected = state.viewer3D.selectionItem[viewIdApply]
        const { modelFileId: mainModelId, fileInfo: fileInfoFinal } = Utils.getModelFileIdFromViewId(ViewIdModelTree, fileList);

        if (webViewerActive && mainModelId && fileInfoFinal) {
            const treeMapData = state.tree.currentTreeMap || new Map<string, FlatTreeNode>();
            // truong hop binh thuong
            if (ViewIdModelTree === viewIdApply) {
                let isAllSelectionTrue = true;
                if (type === 'InTree') {
                    Viewer3DHelper.Update3dViewerSelection(webViewerActive, selectionArray);
                    return [actions.updateSelectionItemComplete({ selectionArray, viewId: viewIdApply, type })]
                } else if (type === 'InModel' && prevSelected?.type === 'InTree') {
                    if (GlobalState.arrayEquals(selectionArray, prevSelected?.nodes))
                        isAllSelectionTrue = false;
                    else
                        Viewer3DHelper.UnSelectNodes(webViewerActive, prevSelected?.nodes)
                }
                if (type === "InModel" && isAllSelectionTrue) {
                    const mapNodes = Viewer3DHelper.GetNodeInTreePerNode(webViewerActive, mainModelId, selectionArray, treeMapData);
                    mapNodes.forEach(function (value, key) {
                        if (value !== key) {
                            Viewer3DHelper.UnSelectNodes(webViewerActive, [key])
                            Viewer3DHelper.SelectNodesWithTriggerFlag(webViewerActive, [value]);
                            isAllSelectionTrue = false;
                        } else if (prevSelected?.type === 'InTree') {
                            Viewer3DHelper.SelectNodesWithTriggerFlag(webViewerActive, [key]);//sellect lai vi bi unselect trong truong hop phia trên
                        } else {
                            Viewer3DHelper.UnHightLightFaceLightPoint(webViewerActive);
                        }
                    });
                    if (isAllSelectionTrue) {
                        return [actions.updateSelectionItemComplete({ selectionArray, viewId: viewIdApply, type })]
                    } else {
                        return []
                    }
                }
                // truong hop nhieu stream dung 1 tree - Revit
            }
            // else { // linking 2d
            //     if (fileInfoFinal) {
            //         const extension = Utils.getFileExtension(fileInfoFinal.originalFile);
            //         if (extension === 'rvt') {
            //             const { modelFileId: subModelId } = Utils.getModelFileIdFromViewId(viewIdActive, fileList);
            //             let isAllSelectionTrue = true;
            //             if (selectionArray.length > 0 && mainModelId && subModelId && mainModelId !== subModelId) {
            //                 const TreenodeIds: number[] = [];
            //                 let selectionArrayDispatch: number[] = Array.from(selectionArray);
            //                 if (type === 'InTree') {
            //                     Viewer3DHelper.ClearSelectNodesWithoutTrigger(webViewerActive);
            //                     //get list persitent by in tree
            //                     const nodeIdsInsheet: number[] = [];
            //                     // const TreeNodeHasPeristents = GlobalState.getTreeNodesByTreeNodeId(mainModelId, selectionArray);
            //                     const TreeNodeHasPeristents = ModelTreeHelper.getTreeNodesByTreeNodeId(treeMapData, selectionArray);
            //                     TreeNodeHasPeristents.forEach(treeNode => {
            //                         if (treeNode?.PersistentId) {
            //                             //get nodeids in sheet viewer by persistanceID
            //                             const nodeIdInsheetGet = Viewer3DHelper.getNodeIdsByPersistentIds(webViewerActive, viewIdActive, [treeNode.PersistentId]);
            //                             if (nodeIdInsheetGet && nodeIdInsheetGet.length > 0) {
            //                                 nodeIdInsheetGet.forEach(nodeIdInSheet => {
            //                                     const type = webViewerActive.model.getNodeType(nodeIdInSheet);
            //                                     if (type !== Communicator.NodeType.AssemblyNode) {
            //                                         !nodeIdsInsheet.includes(nodeIdInSheet) && nodeIdsInsheet.push(nodeIdInSheet);
            //                                     }

            //                                 });
            //                                 TreenodeIds.push(Number(treeNode.Id));
            //                             }
            //                         }
            //                     });
            //                     //select node in sheet viewer
            //                     Viewer3DHelper.SelectNodesWithTriggerFlag(webViewerActive, nodeIdsInsheet);
            //                     selectionArrayDispatch = nodeIdsInsheet;
            //                 } else if (type === 'InModel' && prevSelected?.type === 'InTree') {
            //                     if (GlobalState.arrayEquals(selectionArray, prevSelected?.nodes)) {
            //                         isAllSelectionTrue = false;
            //                     }
            //                     else {
            //                         Viewer3DHelper.UnSelectNodes(webViewerActive, prevSelected?.nodes)
            //                     }
            //                 }
            //                 if (type === "InModel") {
            //                     //get list pair persitentId and nodeID of sheet view
            //                     const persistentMap = Viewer3DHelper.getPersistentIdsMapInNodeIds(webViewerActive, viewIdActive, selectionArray);
            //                     //get List node tree and persistanceId in tree correct
            //                     persistentMap.forEach((listPersistentNodes, baseNodeId) => {
            //                         for (let index = 0; index < listPersistentNodes.length; index++) {
            //                             const persistentNode = listPersistentNodes[index];
            //                             if (persistentNode && persistentNode["_persistentId"]) {
            //                                 // const treeNodeSampPersistent = GlobalState.getNodeTreeByPersistanceId(mainModelId, persistentNode["_persistentId"]);
            //                                 const treeMapPersistentId = state.tree.treeMapPersistentId[mainModelId];
            //                                 const treeNodeSampPersistent = ModelTreeHelper.getNodeTreeByPersistentId(treeMapPersistentId, persistentNode["_persistentId"]);
            //                                 if (treeNodeSampPersistent) {
            //                                     //remove base nodeId
            //                                     const nodeId = persistentNode["_nodeId"]
            //                                     if (baseNodeId !== nodeId) {
            //                                         //select node in sheet viewer
            //                                         isAllSelectionTrue = false;
            //                                         Viewer3DHelper.UnSelectNodes(webViewerActive, [baseNodeId])
            //                                         Viewer3DHelper.SelectNodesWithTriggerFlag(webViewerActive, [nodeId]);
            //                                     }
            //                                     TreenodeIds.push(Number(treeNodeSampPersistent.key));

            //                                     break;
            //                                 }
            //                             }
            //                         }
            //                     });
            //                 }
            //                 if (isAllSelectionTrue) {
            //                     return [
            //                         actions.updateSelectionItemComplete({ selectionArray: TreenodeIds, viewId: ViewIdModelTree, type }),
            //                         actions.updateSelectionItemComplete({ selectionArray: selectionArrayDispatch, viewId: viewIdApply, type })]
            //                 } else {
            //                     return []
            //                 }
            //             } else if (selectionArray.length === 0) {
            //                 if (prevSelected?.nodes?.length > 0) Viewer3DHelper.UnSelectNodes(webViewerActive, prevSelected.nodes);
            //                 return [actions.updateSelectionItemComplete({ selectionArray: [], viewId: ViewIdModelTree, type }),
            //                 actions.updateSelectionItemComplete({ selectionArray: [], viewId: viewIdApply, type })]
            //             }
            //         }
            //     }
            // }
        }
        return []
    })
)
const resetModel$: RootEpic = (action$) => action$.pipe(
    filter(resetModel.match),
    switchMap(action => {
        const viewId = action.payload;
        return [
            actions.removeHideItem({ viewId, removeAll: true }),
            actions.setExplodeValue({ viewId, value: 0 })
        ]
    })
)


export const Viewer3DEpics = [
    viewer3D$,
    initOperatorState$,
    resetOperatorState$,
    updateStateModelStructureReady$,
    updateSelectionItem$,
    resetModel$
]

export const {
    createStream,
    createWebViewer,
    setOperatorState3D,
    setOperatorDisable3D,
    setListOperator3D,
    setExplodeValue,
    updateStateCallback,
    updateCuttingState,
    updateCuttingPlaneBoxState,
    resetOperatorState,
    updateSelectionItem,
    updateHidedItem,
    removeHideItem,
    deleteStateByViewIdViewer3D,
    resetModel,
    updateSelectionItemComplete,
    setRemoveAllHidedItem,
    setMeasureSettingValue,
} = viewer3DSlice.actions;
export default viewer3DSlice.reducer;
