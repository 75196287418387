import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IssueItem, IssueResponse } from "common/define";
import { BimApi } from "api/bim.api";
import { RootEpic } from "common/type-state";
import { merge } from "rxjs";
import { catchError, filter, switchMap, withLatestFrom } from "rxjs/operators";
import { deleteMarkup3d, saveMarkup3d, updateMarkup3d } from "./markup3d.slice";
import { FillerMarkupViewEntity, MarkupEntity } from "common/type-markup";

interface UserItem {
    uid: string;
}

export interface IssuesState {
    listPinMarker: MarkupEntity[];
    loadingIssues: boolean;
    listIssues: IssueItem[];
    listUser: string[];
    selectionItem: IssueItem | null;
    createHidden: boolean;
    filterIssueView: FillerMarkupViewEntity;
    clonePinMarker: MarkupEntity | null;
}

interface PayloadIssue {
    issueItem: IssueItem,
    entity: MarkupEntity,
    securedString: string,
}

const initState: IssuesState = {
    listPinMarker: [],
    loadingIssues: false,
    listIssues: [],
    listUser: [],
    selectionItem: null,
    createHidden: true, // 1: view list issues, 0: form issue,
    filterIssueView: {createdBy: 'All', startDate: undefined, endDate: undefined, isFilter: false},
    clonePinMarker: null,
};

const issuesSlice = createSlice({
    name: 'issues',
    initialState: initState,
    reducers: {
        setIssueFetching(state, action: PayloadAction<boolean>) {
            state.loadingIssues = action.payload;
        },
        setListPinMarker(state, action: PayloadAction<MarkupEntity[]>) {
            state.listPinMarker = action.payload;
        },
        setIssues(state, action: PayloadAction<IssueItem[]>) {
            state.listIssues = action.payload;
        },
        setListUser(state, action: PayloadAction<string[]>) {
            state.listUser = action.payload
            state.loadingIssues = false;
        },
        setCreateHidden: (state, action: PayloadAction<boolean>) => {
            state.createHidden = action.payload;
        },
        setSelectionItem: (state, action: PayloadAction<IssueItem | null>) => {
            state.selectionItem = action.payload;
        },
        setFilterIssueView: (state, action: PayloadAction<FillerMarkupViewEntity>) => {
            state.filterIssueView = action.payload;
            state.loadingIssues = false;
        },
        setClonePinMarker: (state, action: PayloadAction<MarkupEntity | null>) => {
            state.clonePinMarker = action.payload;
        },
        setCancelIssue: (state) => {
            state.loadingIssues = false;
            state.selectionItem = null;
            state.createHidden = true;
            state.clonePinMarker = null;
        },
        fetchListUser(state, action: PayloadAction<string>) { return },
        saveIssueDMS(state, action: PayloadAction<PayloadIssue>) { return },
        updateIssueDMS(state, action: PayloadAction<PayloadIssue>) { return },
        deleteIssueDMS(state, action: PayloadAction<PayloadIssue>) { return },
    }
})

const fetchListUser$: RootEpic = (action$, state$) => action$.pipe (
    filter(fetchListUser.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const gid = action.payload;
        return merge(
            [setIssueFetching(true)],
            BimApi.getSITmember(gid).pipe(
                switchMap((value: IssueResponse) => {
                    const dataIssue = value.ocs.data as UserItem[];
                    let listUser: string[] = [];
                    if (value && dataIssue.length > 0) {
                        listUser = dataIssue.map(v => v.uid);
                    }
                    return [setListUser(listUser)];
                }),
                catchError(err => [setIssueFetching(false)])
            )
        )
    })
)

const saveIssueDMS$: RootEpic = (action$, state$) => action$.pipe (
    filter(saveIssueDMS.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { issueItem, entity, securedString } = action.payload;
        return merge(
            [setIssueFetching(true)],
            BimApi.postNewIssue(securedString).pipe(
                switchMap((val: IssueResponse) => {
                    if (val !== undefined) {
                        const { data, meta } = val.ocs;
                        if (meta.statuscode === 200) {
                            issueItem.Id = String(data.id);
                            const newEntity = Object.assign({}, entity);
                            const pinMarkerData = {...entity.originData.pinMarkerData, objectId: issueItem.Id};
                            const originData = {...entity.originData, issue: issueItem, pinMarkerData};
                            newEntity.originData = originData;
                            return [saveMarkup3d(newEntity)];
                        }
                    }
                    return [setIssueFetching(false)];
                }),
                catchError(err => [setIssueFetching(false)])
            )
        )
    })
)

const updateIssueDMS$: RootEpic = (action$, state$) => action$.pipe (
    filter(updateIssueDMS.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { issueItem, entity, securedString } = action.payload;
        return merge(
            [setIssueFetching(true)],
            BimApi.postNewIssue(securedString).pipe(
                switchMap((val: IssueResponse) => {
                    if (val !== undefined) {
                        const { meta } = val.ocs;
                        if (meta.statuscode === 200) {
                            const newEntity = Object.assign({}, entity);
                            const pinMarkerData = {...entity.originData.pinMarkerData, objectId: issueItem.Id};
                            const originData = {...entity.originData, issue: issueItem, pinMarkerData};
                            newEntity.originData = originData;
                            return [updateMarkup3d(newEntity)];
                        }
                    }
                    return [setIssueFetching(false)];
                }),
                catchError(err => [setIssueFetching(false)])
            )
        )
    })
)

const deleteIssueDMS$: RootEpic = (action$, state$) => action$.pipe (
    filter(deleteIssueDMS.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const { entity, securedString } = action.payload;
        return merge(
            [setIssueFetching(true)],
            BimApi.postNewIssue(securedString).pipe(
                switchMap((val: IssueResponse) => {
                    if (val !== undefined) {
                        const { meta } = val.ocs;
                        if (meta.statuscode === 200) {
                            return [deleteMarkup3d(entity)];
                        }
                    }
                    return [setIssueFetching(false)];
                }),
                catchError(err => [setIssueFetching(false)])
            )
        )
    })
)

export const IssuesActionEpics = [
    fetchListUser$,
    saveIssueDMS$,
    updateIssueDMS$,
    deleteIssueDMS$,
]

export const {
    setListUser,
    fetchListUser,
    setListPinMarker,
    setIssueFetching,
    setIssues,
    setCreateHidden,
    setSelectionItem,
    saveIssueDMS,
    updateIssueDMS,
    deleteIssueDMS,
    setFilterIssueView,
    setClonePinMarker,
    setCancelIssue,
} = issuesSlice.actions;

export default issuesSlice.reducer
