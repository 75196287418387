import { MarkupBaseItem } from './markup-items/markup.base.item';
import { MarkupSelectionOperator } from './markup-operators/markup.selection.operator';
import { MarkupAction } from './markup.action';


export class MarkupSelecion {
    public viewer: Communicator.WebViewer | null;
    public markupAction: MarkupAction;
    public selectionOperatorId: Communicator.OperatorId | null = null;
    public currentOperators: Communicator.OperatorId[] = [];
    public constructor(viewer: Communicator.WebViewer | null, action: MarkupAction) {
        this.viewer = viewer;
        this.markupAction = action;
    }

    addSelectOperator(): void {
        if (this.viewer) {
            if (this.selectionOperatorId === null) {
                // add selection operator
                const selectOperator = new MarkupSelectionOperator(this.viewer);
                if (this.markupAction && this.markupAction.markupData) {
                    selectOperator.setMarkupData(this.markupAction.markupData);
                }
                this.selectionOperatorId = this.viewer.registerCustomOperator(selectOperator);
                if (this.markupAction && this.markupAction.markupEdit) {
                    selectOperator.setMoveGripPointCallback(() => this.moveGripPointCallback());
                    selectOperator.setEditTextModeCallback(() => this.editTextModeCallback());
                    selectOperator.setEndEditTextModeCallback(() => this.endEditTextMode());
                    selectOperator.setUpdateUICallback(() => this.updateUI());
                }
                selectOperator.setThisOperatorId(this.selectionOperatorId);
            }
            if (this.selectionOperatorId) {
                this.viewer.operatorManager.push(this.selectionOperatorId);
            }
        }
    }

    moveGripPointCallback(): void {
        if (this.markupAction && this.markupAction.markupEdit) {
            this.markupAction.markupEdit.moveGripPointCallback();
        }
    }

    editTextModeCallback(): void {
        if (this.markupAction && this.markupAction.markupEdit) {
            this.markupAction.markupEdit.editTextModeCallback();
        }
    }

    updateUI(): void {
        if (this.markupAction && this.markupAction.markupData) {
            this.markupAction.markupData.updateMarkupItems();
        }
    }
    endEditTextMode(): void {
        if (this.markupAction && this.markupAction.markupEdit) {
            this.markupAction.markupEdit.endEditTextMode();
        }
    }

    removeSelectOperator(): void {
        if (this.selectionOperatorId && this.viewer) {
            const selectionOperator = this.viewer.operatorManager.getOperator(this.selectionOperatorId);
            if (selectionOperator instanceof MarkupSelectionOperator) {
                const oper = selectionOperator as MarkupSelectionOperator;
                oper.clearSelection();
                oper.remove();
            }
        }
    }

    selectAll(): void {
        if (this.viewer && this.markupAction && this.markupAction.markupData) {
            if (this.markupAction.markupData.markupItems) {
                this.markupAction.markupData.markupItems.forEach(item => item.setSelected(true))
                this.viewer.markupManager.refreshMarkup();
            }
        }
    }

    clearSelection(): void {
        if (this.viewer && this.markupAction && this.markupAction.markupData) {
            const markup = this.viewer.markupManager;
            if (this.markupAction.markupData.markupItems) {
                this.markupAction.markupData.markupItems.forEach(item => item.setSelected(false))
                markup.refreshMarkup();
            }
        }
        if (this.viewer && this.selectionOperatorId) {
            const selectionOperator = this.viewer.operatorManager.getOperator(this.selectionOperatorId);
            if (selectionOperator instanceof MarkupSelectionOperator) {
                const oper = selectionOperator as MarkupSelectionOperator;
                oper.clearSelection();
            }
        }
    }
    getMoveGripPointItem(): MarkupBaseItem | null {
        if (this.viewer && this.selectionOperatorId) {
            const selectionOperator = this.viewer.operatorManager.getOperator(this.selectionOperatorId);
            if (selectionOperator instanceof MarkupSelectionOperator) {
                const oper = selectionOperator as MarkupSelectionOperator;
                const item = oper.getMoveGripPointItem();
                oper.clearSelection();
                return item;
            }
        }
        return null;
    }

    getTextEditItem(): MarkupBaseItem | null {
        if (this.viewer && this.selectionOperatorId) {
            const selectionOperator = this.viewer.operatorManager.getOperator(this.selectionOperatorId);
            if (selectionOperator instanceof MarkupSelectionOperator) {
                const oper = selectionOperator as MarkupSelectionOperator;
                const item = oper.getTextEditItem();
                oper.clearSelection();
                return item;
            }
        }
        return null;
    }

    resetMoveGripPoint(): void {
        if (this.viewer && this.selectionOperatorId) {
            const selectionOperator = this.viewer.operatorManager.getOperator(this.selectionOperatorId);
            if (selectionOperator instanceof MarkupSelectionOperator) {
                const oper = selectionOperator as MarkupSelectionOperator;
                oper.doResetMoveGrip();
            }
        }
    }

    endEditNoteItem(): void {
        if (this.viewer && this.selectionOperatorId) {
            const selectionOperator = this.viewer.operatorManager.getOperator(this.selectionOperatorId);
            if (selectionOperator instanceof MarkupSelectionOperator) {
                const oper = selectionOperator as MarkupSelectionOperator;
                oper.endEditNoteItem();
            }
        }
    }
}




