/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */

import { NameMarkupItem } from 'common/define';
import { IconName } from "common/define-name-icon";
import { GlobalState } from 'common/global';
import { LineStyle, UserInfo } from 'common/type-markup';
import MathHelper from 'container/pdf-viewer/helper/math.helper';
import { RGBColor } from 'react-color';
import Utils from 'utils/utils';
import { defaultLineFormatSetting, defaultTextFormatSetting } from 'utils/utils-extend';
import { GripPoint, MarkupBaseBounding } from '../markup-canvas-elements/markup.bounding.element';
import { RotateTooltipElement } from '../markup-canvas-elements/markup.rotate-tooltip.element';
export class MarkupBaseItem extends Communicator.Markup.Redline.RedlineItem {
    protected _viewer: Communicator.WebViewer;
    public _point1: Communicator.Point3 | null = null;
    public _point2: Communicator.Point3 | null = null;
    public _firstPoint: Communicator.Point3 | null = null;
    public _secondPoint: Communicator.Point3 | null = null;
    protected _isVisible = true;
    protected _isJiggingMode = false;
    public _lineColor: RGBColor;
    public _lineWeight: number;
    public _lineOpacity: number;
    public _fillColor: RGBColor;
    public _fillColorOption: boolean;
    public _textFontSize: number;
    public _textColor: RGBColor;
    public _textFontFamily: string;
    public _textIsBold = false;
    public _textIsItalic = false;
    public _textIsUnderline = false;
    public _lineStyle: LineStyle;
    static _snapTolerance = 10.0;
    protected _snapPointIndex = 0;
    protected _moveGripPointMode = false;
    protected _markupItemId: string | null = null;
    public isSelected = false;
    public isHovered = false;
    public iconName: IconName;
    public shapeName: NameMarkupItem;
    public uniqueId: Communicator.Uuid = Communicator.UUID.create();
    public uniqueIdGroup: Communicator.Uuid = '';
    public boundingGrips: HTMLDivElement[] = [];
    public isHiding = false;
    public _redlineElementId: string | null = null;
    public boundingIds: string[] | null = null;
    public boundingHoverIds: string[] | null = null;
    public _isReady = false;
    public _previousDragPlanePosition = Communicator.Point3.zero();
    public redlineBounding: MarkupBaseBounding | null = null;
    public gripPointDragType: GripPoint | null = null;
    public previousGripDragPoint: Communicator.Point2 | null = null;
    public isUpdate = false;
    protected _isCanEdit = true;
    public _startLineShapeType: number;
    public _endLineShapeType: number;
    public _rotation: number;
    private _isMarkupVisible = true;
    public fPoint2: Communicator.Point2 = Communicator.Point2.zero();
    public mPoint2: Communicator.Point2 = Communicator.Point2.zero();
    private _vertices: Communicator.Point2[] = [];
    public childBounding: MarkupBaseBounding | null = null;
    public rotateTooltip: RotateTooltipElement | null = null;
    public rotateTooltipId: string | null = null;
    public _modifiedDate: string = new Date().toISOString();
    private _center = Communicator.Point2.zero();
    public _lastModifiedBy: UserInfo;
    private _isMarkup3D = false;
    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        const { lineColor, lineWeight, lineOpacity, fillColor, fillColorOption, startLineShapeType, endLineShapeType, lineStyle, rotation } = defaultLineFormatSetting;
        const { textFontSize, textColor, textFontFamily, textIsBold, textIsItalic, textIsUnderline } = defaultTextFormatSetting;
        this._viewer = viewer;
        this._lineColor = lineColor;
        this._lineWeight = lineWeight;
        this._lineOpacity = lineOpacity;
        this._fillColor = fillColor;
        this._fillColorOption = fillColorOption;
        this._startLineShapeType = startLineShapeType;
        this._endLineShapeType = endLineShapeType;
        this._lineStyle = lineStyle

        this._textFontSize = textFontSize;
        this._textColor = textColor;
        this._textFontFamily = textFontFamily;
        this._textIsBold = textIsBold;
        this._textIsItalic = textIsItalic;
        this._textIsUnderline = textIsUnderline;
        this._rotation = rotation;
        this.iconName = 'markupRectangle';
        this.shapeName = 'MarkupBaseItem';
        this._lastModifiedBy = Utils.getUserInfo();
    }
    public setVisible(vis: boolean): void {
        this._isVisible = vis;
    }
    public getVisible(): boolean {
        return this._isVisible;
    }
    public setSelected(select: boolean): void {
        this.isSelected = select;

        this._viewer.markupManager.selectMarkup(select ? this : null);
        let currListSelected = GlobalState.list3DMarkupSelected$.getValue();
        if (select) {
            currListSelected = Array.from(new Set([...currListSelected, ...[this]]));
        } else {
            currListSelected = currListSelected.filter(v => v.uniqueId !== this.uniqueId);
        }
        GlobalState.list3DMarkupSelected$.next(currListSelected);
    }
    public setHovered(select: boolean): void {
        this.isHovered = select;
        this._viewer.markupManager.selectMarkup(select ? this : null);
    }
    public setJiggingMode(vis: boolean): void {
        this._isJiggingMode = vis;
    }
    public getSnapPointIndex(): number {
        return this._snapPointIndex;
    }
    public setMoveGripPointMode(vis: boolean): void {
        this._moveGripPointMode = vis;
    }
    public setMoveGripPoint(point: Communicator.Point2): void {
        point as Communicator.Unreferenced;
    }
    setMarkupItemId(id: string | null): void {
        this._markupItemId = id;
    }
    getMarkupItemId(): string | null {
        return this._markupItemId;
    }
    public getClassName(): string {
        return "Communicator.Markup.MarkupBaseItem";
    }
    onEditText(): boolean {
        return false;
    }

    endedEditText(): boolean {
        return false;
    }

    setLineFormat(
        lineColor: RGBColor,
        lineWeight: number,
        lineOpacity: number,
        fillColor: RGBColor,
        fillColorOption: boolean,
        lineStyle?: LineStyle): void {
        this._lineColor = lineColor;
        this._lineWeight = lineWeight;
        this._lineOpacity = lineOpacity;
        this._fillColor = fillColor;
        this._fillColorOption = fillColorOption;
        lineStyle && (this._lineStyle = lineStyle);
    }
    setTextFormat(
        textFontSize: number,
        textColor: RGBColor,
        textFontFamily: string,
        _textIsBold: boolean,
        _textIsItalic: boolean,
        _textIsUnderline: boolean): void {
        this._textFontSize = textFontSize;
        this._textColor = textColor;
        this._textFontFamily = textFontFamily;
        this._textIsBold = _textIsBold;
        this._textIsItalic = _textIsItalic;
        this._textIsUnderline = _textIsUnderline;
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    toJson(): any {
        return null;
    }
    fromJson(data: any): void {
        return;
    }

    gripPointDragStartCallback(point: Communicator.Point2, type: GripPoint) {
        this.isUpdate = false;
        this.gripPointDragType = type;
        this.previousGripDragPoint = point;
    }
    onBoundingBoxDragEndCallback(point: Communicator.Point2) {
        if (this.isUpdate) {
            this.isUpdate = false;
            this.triggerOnMarkupUpdated();
        }
    }
    onClickBoundingCallBack(event: MouseEvent): void {
        if (event.ctrlKey) return
        if (this.redlineBounding) {
            const allSelectedMarkups = this.redlineBounding.getAllSelectedMarkup();
            allSelectedMarkups.forEach(v => {
                if (v.uniqueId === this.uniqueId)
                    v.setSelected(true)
                else
                    v.setSelected(false)
            })
        }
    }

    remove(): void {
        if (this._redlineElementId) {
            this._viewer.markupManager.removeMarkupElement(this._redlineElementId);
            this._redlineElementId = null;
        }
        if (this.boundingIds) {
            this.boundingIds.forEach((value) => {
                this._viewer.markupManager.removeMarkupElement(value);
            });
            this.boundingIds = null;
        }
        if (this.boundingHoverIds) {
            this.boundingHoverIds.forEach((value) => {
                this._viewer.markupManager.removeMarkupElement(value);
            });
            this.boundingHoverIds = null;
        }
        if (this.rotateTooltipId) {
            this._viewer.markupManager.removeMarkupElement(this.rotateTooltipId);
            this.rotateTooltipId = null;
        }
    }
    handleBoundingRectInteraction(customFnc?: () => void) {
        if (this.isHovered && !this.isSelected && this.redlineBounding && !this.isHiding) {
            this.boundingHoverIds = [];
            const id = this._viewer.markupManager.addMarkupElement(this.redlineBounding.getBoundingHoverElement());
            this.redlineBounding.setTargetingMarkup(this);
            this.boundingHoverIds.push(id);
            if (this.childBounding) {
                const id = this._viewer.markupManager.addMarkupElement(this.childBounding.getBoundingHoverElement());
                this.childBounding.setTargetingMarkup(this);
                this.boundingHoverIds.push(id);
            }
        } else if (this.isSelected && this.redlineBounding && !this.isHiding) {
            this.redlineBounding.setTargetingMarkup(this);
            this.boundingIds = [];
            const redlineBound = this.redlineBounding.getBoundingGripElement();
            redlineBound && redlineBound.forEach((value) => {
                if (value && this.boundingIds) {
                    const id = this._viewer.markupManager.addMarkupElement(value);
                    this.boundingIds.push(id);
                }
            });
            if (this.childBounding) {
                const grBound = this.childBounding.getBoundingGripElement();
                grBound && grBound.forEach((value) => {
                    if (value && this.boundingIds) {
                        const id = this._viewer.markupManager.addMarkupElement(value);
                        this.boundingIds.push(id);
                    }
                });
            }
            customFnc && customFnc();
            if (this.boundingHoverIds) {
                this.boundingHoverIds.forEach((value) => {
                    this._viewer.markupManager.removeMarkupElement(value);
                });
                this.boundingHoverIds = null;
            }
        } else {
            if (this.boundingIds) {
                this.boundingIds.forEach((value) => {
                    this._viewer.markupManager.removeMarkupElement(value);
                });
                this.boundingIds = null;
            }
            if (this.boundingHoverIds) {
                this.boundingHoverIds.forEach((value) => {
                    this._viewer.markupManager.removeMarkupElement(value);
                });
                this.boundingHoverIds = null;
            }
        }
    }
    triggerOnMarkupUpdated() {
        this._modifiedDate = new Date().toISOString();
        this._lastModifiedBy = Utils.getUserInfo();
        GlobalState.markupUpdated$.next(this);
    }

    // GET SET markup line format
    setLineColor(lineColor: RGBColor): void {
        this._lineColor = lineColor;
    }
    getLineColor(): RGBColor {
        return this._lineColor;
    }
    setLineWeight(lineWeight: number): void {
        this._lineWeight = lineWeight;
    }
    getLineWeight(): number {
        return this._lineWeight;
    }
    setLineOpacity(lineOpacity: number): void {
        this._lineOpacity = lineOpacity;
    }
    getLineOpacity(): number {
        return this._lineOpacity;
    }
    setFillColor(fillColor: RGBColor): void {
        this._fillColor = fillColor;
    }
    getFillColor(): RGBColor {
        return this._fillColor;
    }
    setFillColorOption(fillColorOption: boolean): void {
        this._fillColorOption = fillColorOption;
    }
    getFillColorOption(): boolean {
        return this._fillColorOption;
    }
    setLineStyle(lineStyle: LineStyle): void {
        this._lineStyle = lineStyle;
    }
    getLineStyle(): LineStyle {
        return this._lineStyle;
    }

    // GET SET text format
    setTextFontSize(textFontSize: number): void {
        this._textFontSize = textFontSize;
    }
    getTextFontSize(): number {
        return this._textFontSize;
    }
    setTextColor(textColor: RGBColor): void {
        this._textColor = textColor;
    }
    getTextColor(): RGBColor {
        return this._textColor;
    }
    setTextFontFamily(textFontFamily: string): void {
        this._textFontFamily = textFontFamily;
    }
    getTextFontFamily(): string {
        return this._textFontFamily;
    }
    setTextIsBold(textIsBold: boolean): void {
        this._textIsBold = textIsBold;
    }
    getTextIsBold(): boolean {
        return this._textIsBold;
    }
    setTextIsItalic(textIsItalic: boolean): void {
        this._textIsItalic = textIsItalic;
    }
    getTextIsItalic(): boolean {
        return this._textIsItalic;
    }
    setTextIsUnderline(textIsUnderline: boolean): void {
        this._textIsUnderline = textIsUnderline;
    }
    getTextIsUnderline(): boolean {
        return this._textIsUnderline;
    }

    // GET SET start cap end cap
    setStartLineShape(type: number): void {
        this._startLineShapeType = type;
    }
    getStartLineShape(): number {
        return this._startLineShapeType;
    }
    setEndLineShape(type: number): void {
        this._endLineShapeType = type;
    }
    getEndLineShape(): number {
        return this._endLineShapeType;
    }
    // GET SET rotation

    setRotation(deg: number): void {
        this._rotation = deg;
    }
    getRotation(): number {
        return this._rotation;
    }

    // END GET SET
    hit(point: Communicator.Point2) {
        return super.hit(point);
    }
    setMarkupVisible(visible: boolean): void {
        if (this.redlineBounding) this.redlineBounding.setVisibleBounding(visible);
        this._isMarkupVisible = visible;
    }
    getMarkupVisible(): boolean {
        return this._isMarkupVisible;
    }
    getHTMLElement(): HTMLElement | null {
        return null;
    }
    getHTMLBounding() {
        if (!this.redlineBounding) return null;
        return this.redlineBounding.getBoundingElement();
    }
    rotateGripPointDragEndCallback(point: Communicator.Point2) {
        if (this.isUpdate) {
            this.isUpdate = false;
            this.triggerOnMarkupUpdated();
            if (this.redlineBounding) this.redlineBounding.updateGripPointCursor();
            if (this.rotateTooltip) this.rotateTooltip.setVisible(false);
            this.gripPointDragType = null;
        }
    }
    updateOppositePoint() {
        const { view } = this._viewer;
        const fPoint = Communicator.Point2.fromPoint3(view.projectPoint(this._point1!.copy()));
        const sPoint = Communicator.Point2.fromPoint3(view.projectPoint(this._point2!.copy()));
        const width = Math.abs(fPoint.x - sPoint.x);
        const height = Math.abs(fPoint.y - sPoint.y);
        const top = Math.min(fPoint.y, sPoint.y);
        const left = Math.min(fPoint.x, sPoint.x);
        const cenPoint = new Communicator.Point2((fPoint.x + sPoint.x) / 2, (fPoint.y + sPoint.y) / 2);
        const radians = this.redlineBounding!.getRotation() / 180 * Math.PI;

        switch (this.gripPointDragType) {
            case GripPoint.top:
                {
                    const topPoint = new Communicator.Point2(left + width / 2, top);
                    const botPoint = new Communicator.Point2(left + width / 2, top + height);
                    this.fPoint2 = MathHelper.rotatePoint(botPoint, cenPoint, radians);
                    this.mPoint2 = MathHelper.rotatePoint(topPoint, cenPoint, radians);
                    break;
                }
            case GripPoint.left:
                {
                    const leftPoint = new Communicator.Point2(left, top + height / 2);
                    const rightPoint = new Communicator.Point2(left + width, top + height / 2);
                    this.fPoint2 = MathHelper.rotatePoint(rightPoint, cenPoint, radians);
                    this.mPoint2 = MathHelper.rotatePoint(leftPoint, cenPoint, radians);
                    break;
                }
            case GripPoint.right:
                {
                    const leftPoint = new Communicator.Point2(left, top + height / 2);
                    const rightPoint = new Communicator.Point2(left + width, top + height / 2);
                    this.fPoint2 = MathHelper.rotatePoint(leftPoint, cenPoint, radians);
                    this.mPoint2 = MathHelper.rotatePoint(rightPoint, cenPoint, radians);
                    break;
                }
            case GripPoint.bottom:
                {
                    const topPoint = new Communicator.Point2(left + width / 2, top);
                    const botPoint = new Communicator.Point2(left + width / 2, top + height);
                    this.fPoint2 = MathHelper.rotatePoint(topPoint, cenPoint, radians);
                    this.mPoint2 = MathHelper.rotatePoint(botPoint, cenPoint, radians);
                    break;
                }
            default:
                break;
        }
    }
    updateDefinePoints() {
        if (!this._point1 || !this._point2) return;
        const { view } = this._viewer;
        const firstPoint = Communicator.Point2.fromPoint3(
            view.projectPoint(this._point1.copy()),
        );
        const secondPoint = Communicator.Point2.fromPoint3(
            view.projectPoint(this._point2.copy()),
        );

        if (firstPoint && secondPoint) {
            switch (this.gripPointDragType) {
                case GripPoint.top:
                case GripPoint.bottom:
                case GripPoint.left:
                case GripPoint.right:
                    {
                        const minPnt = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y));
                        const maxPnt = new Communicator.Point2(Math.max(firstPoint.x, secondPoint.x), Math.max(firstPoint.y, secondPoint.y));
                        this._point1.assign(view.getCamera().getCameraPlaneIntersectionPoint(minPnt, view)!);
                        this._point2.assign(view.getCamera().getCameraPlaneIntersectionPoint(maxPnt, view)!);
                        break;
                    }
                // Set pick point to second point and opposite to first point
                case GripPoint.topLeft:
                    {
                        const frstPoint = new Communicator.Point2(Math.max(firstPoint.x, secondPoint.x), Math.max(firstPoint.y, secondPoint.y));
                        const scndPoint = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y));
                        this._point1.assign(view.getCamera().getCameraPlaneIntersectionPoint(frstPoint, view)!);
                        this._point2.assign(view.getCamera().getCameraPlaneIntersectionPoint(scndPoint, view)!);
                        break;
                    }
                case GripPoint.topRight:
                    {
                        const frstPoint = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.max(firstPoint.y, secondPoint.y));
                        const scndPoint = new Communicator.Point2(Math.max(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y));
                        this._point1.assign(view.getCamera().getCameraPlaneIntersectionPoint(frstPoint, view)!);
                        this._point2.assign(view.getCamera().getCameraPlaneIntersectionPoint(scndPoint, view)!);
                        break;
                    }
                case GripPoint.bottomLeft:
                    {
                        const frstPoint = new Communicator.Point2(Math.max(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y));
                        const scndPoint = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.max(firstPoint.y, secondPoint.y));
                        this._point1.assign(view.getCamera().getCameraPlaneIntersectionPoint(frstPoint, view)!);
                        this._point2.assign(view.getCamera().getCameraPlaneIntersectionPoint(scndPoint, view)!);
                        break;
                    }
                case GripPoint.bottomRight:
                    {
                        const frstPoint = new Communicator.Point2(Math.min(firstPoint.x, secondPoint.x), Math.min(firstPoint.y, secondPoint.y));
                        const scndPoint = new Communicator.Point2(Math.max(firstPoint.x, secondPoint.x), Math.max(firstPoint.y, secondPoint.y));
                        this._point1.assign(view.getCamera().getCameraPlaneIntersectionPoint(frstPoint, view)!);
                        this._point2.assign(view.getCamera().getCameraPlaneIntersectionPoint(scndPoint, view)!);
                        break;
                    }
                case GripPoint.rotate:
                    {
                        break;
                    }
                default:
                    break;
            }
        }
    }

    gripPointDragMoveCallback(point: Communicator.Point2) {
        if (this.gripPointDragType === null || this.redlineBounding === null) return;
        if (this.redlineBounding) this.redlineBounding.setTextboxBoundingPointerEvent(true);
        if (!this.isUpdate) this.updateDefinePoints();
        this.isUpdate = true;
        const a = this._viewer.view;
        const b = a.getCamera().getCameraPlaneIntersectionPoint(point, a);

        if (b) {
            const fPoint = Communicator.Point2.fromPoint3(a.projectPoint(this._point1!.copy()));
            const sPoint = Communicator.Point2.fromPoint3(a.projectPoint(this._point2!.copy()));
            const firstPoint = new Communicator.Point2(Math.min(fPoint.x, sPoint.x), Math.min(fPoint.y, sPoint.y));
            const size = new Communicator.Point2(Math.abs(fPoint.x - sPoint.x), Math.abs(fPoint.y - sPoint.y));
            const center = new Communicator.Point2(firstPoint.x + size.x / 2, firstPoint.y + size.y / 2);
            const radians = this.redlineBounding.getRotation() / 180 * Math.PI;
            const pad = this.redlineBounding.padding + this._lineWeight / 2;
            const limit = 0;
            switch (this.gripPointDragType) {
                case GripPoint.top:
                    {
                        const mVec = point.copy().subtract(this.mPoint2.copy());
                        const mAng = Math.atan2(mVec.y, mVec.x) - Math.PI / 2;
                        const dAng = mAng - radians;
                        const dMove = mVec.length() * Math.cos(dAng) + pad;

                        // New Center
                        const cVec = this.mPoint2.copy().subtract(this.fPoint2.copy());
                        const nVec = cVec.scale(1 / (cVec.length()));
                        size.y -= dMove;
                        if (size.y < limit) {
                            size.y = Math.abs(size.y);
                            this.gripPointDragType = GripPoint.bottom;
                        }
                        const newCenter = center.copy().add(nVec.copy().scale((-dMove) / 2));
                        const newMPoint2 = this.mPoint2.copy().add(nVec.scale(-dMove));
                        const newFPoint = new Communicator.Point2(newCenter.x - size.x / 2, newCenter.y - size.y / 2);
                        const newSPoint = new Communicator.Point2(newCenter.x + size.x / 2, newCenter.y + size.y / 2);

                        this._point1!.assign(a.getCamera().getCameraPlaneIntersectionPoint(newFPoint, a)!);
                        this._point2!.assign(a.getCamera().getCameraPlaneIntersectionPoint(newSPoint, a)!);
                        this.mPoint2.assign(newMPoint2);
                        break;
                    }
                case GripPoint.bottom:
                    {
                        const mVec = point.copy().subtract(this.mPoint2.copy());
                        const mAng = Math.atan2(mVec.y, mVec.x) - Math.PI / 2;
                        const dAng = mAng - radians;
                        const dMove = mVec.length() * Math.cos(dAng) - pad;

                        // New Center
                        const cVec = this.mPoint2.copy().subtract(this.fPoint2.copy());
                        const nVec = cVec.scale(1 / (cVec.length()));
                        size.y += dMove;
                        if (size.y < limit) {
                            size.y = Math.abs(size.y);
                            this.gripPointDragType = GripPoint.top;
                        }
                        const newCenter = center.copy().add(nVec.copy().scale(dMove / 2));
                        const newMPoint2 = this.mPoint2.copy().add(nVec.scale(dMove));
                        const newFPoint = new Communicator.Point2(newCenter.x - size.x / 2, newCenter.y - size.y / 2);
                        const newSPoint = new Communicator.Point2(newCenter.x + size.x / 2, newCenter.y + size.y / 2);
                        this._point1!.assign(a.getCamera().getCameraPlaneIntersectionPoint(newFPoint, a)!);
                        this._point2!.assign(a.getCamera().getCameraPlaneIntersectionPoint(newSPoint, a)!);
                        this.mPoint2.assign(newMPoint2);
                        break;
                    }
                case GripPoint.right:
                    {
                        const mVec = point.copy().subtract(this.mPoint2.copy());
                        const mAng = Math.atan2(mVec.y, mVec.x) - Math.PI / 2;
                        const dAng = mAng - radians;
                        const dMove = mVec.length() * Math.sin(dAng) + pad;

                        // New Center
                        const cVec = this.mPoint2.copy().subtract(this.fPoint2.copy());
                        const nVec = cVec.scale(1 / (cVec.length()));
                        size.x -= dMove;
                        if (size.x < limit) {
                            size.x = Math.abs(size.x);
                            this.gripPointDragType = GripPoint.left;
                        }
                        const newCenter = center.copy().add(nVec.copy().scale((-dMove) / 2));
                        const newMPoint2 = this.mPoint2.copy().add(nVec.copy().scale((-dMove)));
                        const newFPoint = new Communicator.Point2(newCenter.x - size.x / 2, newCenter.y - size.y / 2);
                        const newSPoint = new Communicator.Point2(newCenter.x + size.x / 2, newCenter.y + size.y / 2);
                        this._point1!.assign(a.getCamera().getCameraPlaneIntersectionPoint(newFPoint, a)!);
                        this._point2!.assign(a.getCamera().getCameraPlaneIntersectionPoint(newSPoint, a)!);
                        this.mPoint2.assign(newMPoint2);
                        break;
                    }
                case GripPoint.left:
                    {
                        const mVec = point.copy().subtract(this.mPoint2.copy());
                        const mAng = Math.atan2(mVec.y, mVec.x) - Math.PI / 2;
                        const dAng = mAng - radians;
                        const dMove = mVec.length() * Math.sin(dAng) - pad;

                        // New Center
                        const cVec = this.mPoint2.copy().subtract(this.fPoint2.copy());
                        const nVec = cVec.scale(1 / (cVec.length()));
                        size.x += dMove;
                        if (size.x < limit) {
                            size.x = Math.abs(size.x);
                            this.gripPointDragType = GripPoint.right;
                        }
                        const newCenter = center.copy().add(nVec.copy().scale(dMove / 2));
                        const newMPoint2 = this.mPoint2.copy().add(nVec.scale(dMove));
                        const newFPoint = new Communicator.Point2(newCenter.x - size.x / 2, newCenter.y - size.y / 2);
                        const newSPoint = new Communicator.Point2(newCenter.x + size.x / 2, newCenter.y + size.y / 2);
                        this._point1!.assign(a.getCamera().getCameraPlaneIntersectionPoint(newFPoint, a)!);
                        this._point2!.assign(a.getCamera().getCameraPlaneIntersectionPoint(newSPoint, a)!);
                        this.mPoint2.assign(newMPoint2);
                        break;
                    }
                case GripPoint.topLeft:
                case GripPoint.bottomRight:
                case GripPoint.topRight:
                case GripPoint.bottomLeft:
                    {
                        const num = [GripPoint.topRight, GripPoint.bottomLeft].includes(this.gripPointDragType) ? -1 : 1;
                        const vec2 = center.copy().subtract(point.copy());
                        const rad = Math.atan(size.x / size.y);
                        const vec1 = MathHelper.calVector(vec2, (Math.PI * 3 / 4 + rad) * num);
                        point.add(vec1.scale(-pad * Math.sqrt(2) / vec1.length()));
                        const curDeg = rad * 180 / Math.PI;
                        this.changeGripPoint(this.gripPointDragType, curDeg)

                        const rtFPoint = MathHelper.rotatePoint(fPoint, center, radians);
                        const vec = point.copy().subtract(rtFPoint.copy());
                        const newCenter = rtFPoint.copy().add(vec.scale(1 / 2));
                        const newFPoint2 = MathHelper.rotatePoint(rtFPoint, newCenter, -radians);
                        const newFPoint3 = a.getCamera().getCameraPlaneIntersectionPoint(newFPoint2, a);
                        this._point1!.assign(newFPoint3!);
                        const newSPoint2 = MathHelper.rotatePoint(point, newCenter, -radians);
                        const newSPoint3 = a.getCamera().getCameraPlaneIntersectionPoint(newSPoint2, a);
                        this._point2!.assign(newSPoint3!);
                        break;
                    }
                default:
                    break;
            }
        }
        this._viewer.markupManager.refreshMarkup();
    }

    changeGripPoint(currentGrip: GripPoint, curDeg: number) {
        const grips = [GripPoint.topLeft, GripPoint.topRight, GripPoint.bottomRight, GripPoint.bottomLeft];
        const deg = 2;
        const i = grips.findIndex(v => v === currentGrip);
        if (curDeg < deg) this.gripPointDragType = grips[(i + 3) % 4];
        if (90 - curDeg < deg) this.gripPointDragType = grips[(i + 5) % 4];
    }

    gripPointDragEndCallback(point: Communicator.Point2) {
        this.gripPointDragType = null;
        this.previousGripDragPoint = null;
        if (this.isUpdate) {
            this.isUpdate = false;
            this.triggerOnMarkupUpdated();
        }
    }
    updateRotateTransform(rotateAngle: number) {
        const transformStr = `rotate(${rotateAngle}deg)`;
        const el = this.getHTMLElement();
        if (el) el.style.transform = transformStr;
    }
    setBoundingGripPointVisible(vis: boolean): void {
        this.redlineBounding && this.redlineBounding.setVisibleGripPoints(vis);
    }
    setBoundingPointerEvent(acceptEvent: boolean): void {
        this.redlineBounding && this.redlineBounding.setBoundingBoxMouseEvent(acceptEvent);
    }

    public get vertices(): Communicator.Point2[] {
        return this._vertices;
    }
    public set vertices(vertices: Communicator.Point2[]) {
        this._vertices = vertices;
    }
    public get center(): Communicator.Point2 {
        return this._center
    }
    public set center(ctPoint: Communicator.Point2) {
        this._center = ctPoint;
    }
    public get modifiedDate(): string {
        return this._modifiedDate;
    }
    public set modifiedDate(modifiedDate: string) {
        this._modifiedDate = modifiedDate;
    }
    public get lastModifiedBy(): UserInfo {
        return this._lastModifiedBy;
    }
    public set lastModifiedBy(lastModifiedBy: UserInfo) {
        this._lastModifiedBy = lastModifiedBy;
    }

    public set isMarkup3D(is3D: boolean) {
        this._isMarkup3D = is3D;
    }
    public get isMarkup3D(): boolean {
        return this._isMarkup3D;
    }
    setPoint1(point: Communicator.Point3): void {
        this._point1 = point;
    }
    setPoint2(point: Communicator.Point3): void {
        this._point2 = point;
    }
    setFirstPoint(point: Communicator.Point3): void {
        this._firstPoint = point;
    }
    setSecondPoint(point: Communicator.Point3): void {
        this._secondPoint = point;
    }
}

