import {FileInfo} from "common/define";
import {APIHosts, ApiReduxHelpers} from "./reduxHelpers";

class FileApiController extends ApiReduxHelpers {
    constructor() {
        super();
    }

    ApiHost = APIHosts.File

    getUrlStreamfile(file: FileInfo): string {
        return `${this.getHost(this.ApiHost)}/${file.streamLocation.replace('./', '')}/${file.cacheFilename}`;
    }
}

export const FileApi = new FileApiController()
