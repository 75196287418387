import { createSelector } from "@reduxjs/toolkit";
import { selectSelfSetting } from "./self.selector";

export const getNavigationSetting = createSelector(
    selectSelfSetting,
    (state) => state.navigation
)
export const getGeneralSetting = createSelector(
    selectSelfSetting,
    (state) => state.general
)
export const getEffectSetting = createSelector(
    selectSelfSetting,
    (state) => state.effect
)
export const getColorSetting = createSelector(
    selectSelfSetting,
    (state) => state.color
)
export const getAllSetting = createSelector(
    selectSelfSetting,
    (state) => state
)
export const getFetchingStatus = createSelector(
    selectSelfSetting,
    (state) => state.fetchSettingFail
)