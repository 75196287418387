import { createSelector } from "@reduxjs/toolkit";
import { selectSelfMultiViewer, selectSelfPdfViewer } from "./self.selector";

export const getCurrentPageIndex = createSelector(
    selectSelfPdfViewer,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => state.currentPageIndex[viewId]
)
export const getPdfOperatorByViewId = createSelector(
    selectSelfPdfViewer,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => state.operator[viewId]
)
export const getPdfModeByViewId = createSelector(
    selectSelfPdfViewer,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => state.mode[viewId]
)
export const getPdfViewerRenderByViewId = createSelector(
    selectSelfPdfViewer,
    (_: unknown, viewId: ViewId) => viewId,
    (state, viewId) => state.render[viewId]
)
export const getPdfViewerLoading = createSelector(
    selectSelfPdfViewer,
    (state) => state.loading
)
export const getCurrentPagePdfViewerActice = createSelector(
    selectSelfMultiViewer,
    selectSelfPdfViewer,
    (multiState, state) => {
        const viewActiveId = multiState.viewActive.viewId;
        return (state.currentPageIndex[viewActiveId] || 0) + 1
    }
)

export const getCurrentNoteSelected = createSelector(
    selectSelfPdfViewer,
    (state) => state.currentNote
)

export const getEnableMouseWheel = createSelector(
    selectSelfPdfViewer,
    (state) => state.enableMouseWheel
)