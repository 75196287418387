/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable indent */
import { BaseElementCanvas } from "./markup.base-canvas.element";
import noteIcon from 'images/noteIcon.svg';
import MathHelper from "container/pdf-viewer/helper/math.helper";
export class NoteElementCanvas extends BaseElementCanvas {
    private _noteSize = 25;
    private _noteSecondPoint = Communicator.Point2.zero();
    private notePosition: Communicator.Point2 = Communicator.Point2.zero();
    private noteWindowPosition: Communicator.Point2 = Communicator.Point2.zero();
    constructor() {
        super();
        this.offSet = 2;
        this.noteCanvas = document.createElement('img');
        this.noteCanvas.style.position = 'absolute';
        this.noteCanvas.style.zIndex = '2';
        this.noteCanvas.style.width = `${this._noteSize}`;
        this.noteCanvas.style.height = `${this._noteSize}`;
        this.noteCanvas.style.cursor = "pointer";
        this.noteCanvas.style.pointerEvents = 'none';
        this.noteCanvas.style.border = 'none';
    }

    getNoteCanvas(): HTMLElement {
        this.draw();
        return this.noteCanvas!;
    }
    setNoteSecondPoint(point: Communicator.Point2): void {
        this._noteSecondPoint = point;
    }
    draw(): void {
        if (!this.firstPoint || !this.size) return;
        if (!this.noteCanvas) return;
        const { x, y } = MathHelper.calculateNotePosition(this.firstPoint, this._noteSecondPoint, this._noteSize);
        this.setNotePosition(new Communicator.Point2(x, y));
        const windowPos = this.noteCanvas.getBoundingClientRect()
        this.setNoteWindowPosition(new Communicator.Point2(windowPos.x, windowPos.y))
        this.noteCanvas.style.left = `${x}px`;
        this.noteCanvas.style.top = `${y}px`;
        this.noteCanvas.style.width = `${this._noteSize}px`;
        this.noteCanvas.style.height = `${this._noteSize}px`;
        this.noteCanvas.src = noteIcon;
    }
    updateNotePosition(): void {
        const { x, y } = MathHelper.calculateNotePosition(this.firstPoint, this._noteSecondPoint, this._noteSize);
        this.setNotePosition(new Communicator.Point2(x, y));
    }
    getNotePosition(): Communicator.Point2 {
        return this.notePosition;
    }
    setNotePosition(point2: Communicator.Point2): void {
        this.notePosition = point2;
    }
    getNoteWindowPosition(): Communicator.Point2 {
        return this.noteWindowPosition;
    }
    setNoteWindowPosition(point2: Communicator.Point2): void {
        this.noteWindowPosition = point2;
    }
    public get noteSize(): number {
        return this._noteSize;
    }
}