import { getLogInToken } from 'redux/selectors';
import { useSelectorRoot } from 'redux/store';
import Utils from 'utils/utils';

const useCheckLoggedIn = (): boolean => {
    const token = useSelectorRoot(getLogInToken);
    let isLoggedin = false;
    if (token) {
        isLoggedin = true;
    } else {
        const tokenStorage = Utils.getValueLocalStorage('token');
        if (tokenStorage) {
            isLoggedin = true;
        } else {
            isLoggedin = false;
        }
    }
    return isLoggedin;
}

export default useCheckLoggedIn;