import { ePageSize, PrintingState } from "common/define";

export const defaultPrinting: PrintingState = {
    pageArea: 0,
    layout: 0,
    scaling: 0,
    scalingValue: 100,
    color: 0,
    pageSize: ePageSize.A4,
}

export default class PrintingHelper {

}
