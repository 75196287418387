import { Badge, Button, notification } from 'antd';
import { PayloadOriginFile, StreamFile, UploadFileStatus } from 'common/define';
import { GlobalState } from 'common/global';
import CIconSvg from 'components/CIconSvg';
import CLoading from 'components/CLoading';
import { useContextListOpenFile } from 'context/listopenfile.context';
import { cachedFiles, mapStreamFile, openFileHelper, uploadFiles } from 'helper/dashboard.helper';
import useHandleListFile from 'hooks/useHandleListFile';
import React, { useCallback, useEffect, useState } from 'react';
import BodyTable from './BodyTable';
import DrawerListFileSelected from './DrawerListFileSelected';
import FolderPath from './FolderPath';

export type TableFileProps = {
    onCloseFromMainViewer?: () => void;
    onOKDialog?: (data: PayloadOriginFile[]) => void;
    fromMainViewer?: boolean
}

export enum MainOpenField {
    Name = 'name',
    CachedDate = 'cachedDate',
    Size = 'size',
    Status = 'status'
}



export default function TableFile(props: TableFileProps): JSX.Element {
    const [visbleDrawer, setvisbleDrawer] = useState(false);
    const lstTemporation = useHandleListFile();
    const [listFile, setListFile] = useState(lstTemporation);
    const {state: {searchText, currentPath, loading, arrSelected}, dispatchContext} = useContextListOpenFile();
    const [sortFieldState, setSortFieldState] = useState({
        name: true,
        cachedDate: false,
        size: false,
        status: false
    });

    useEffect(() => {
        setListFile(lstTemporation);
    }, [lstTemporation]);

    const uploadFile = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.addEventListener('change', event => {
            const target = event.target as HTMLInputElement;
            const upload: File = (target.files as FileList)[0];
            const formData = new FormData();
            formData.append('file', upload);
            dispatchContext({ type: 'UPLOAD_FILE', payload: {
                filename: upload.name,
                status: UploadFileStatus.Uploading
            } });
            uploadFiles(formData, currentPath).then(_ => {
                const uploadFile: StreamFile = {
                    filename: upload.name, status: {size: upload.size}, isDirectory: false,
                    baseFileId: Communicator.UUID.create(), child: [], cacheStatus: 0,
                    baseMajorRev: 0,
                    baseMinorRev: 0,
                    isChecked: false,
                    createdDate: '',
                    modelFileId: '',
                    id: '',
                    originalFilePath: currentPath,
                    convertOptions: {
                        convert3DModel: 1,
                        convert2DSheet: 0,
                        extractProperties: 1,
                        childModels: 0
                    },
                    drawingConvertStatus: {
                        convert2DSheet: 0,
                        convert3DModel: 0,
                        extractProperties:0
                    }
                };
                dispatchContext({ type: 'UPLOAD_FILE', payload: {
                    filename: upload.name,
                    status: UploadFileStatus.Success
                }});
                const {all, index} = GlobalState.addFile(uploadFile, currentPath, searchText);
                GlobalState.updateListFiles(all);
                if (index !== -1)
                {
                    dispatchContext({ type: 'SET_SCROLL_IDX', payload: index });
                    dispatchContext({ type: 'SET_RECENT_UPLOADED_FILE', payload: uploadFile });
                    setTimeout(() => {
                        dispatchContext({ type: 'SET_RECENT_UPLOADED_FILE', payload: null });
                    }, 10000);
                }
            }).catch(() => {
                dispatchContext({ type: 'UPLOAD_FILE', payload: {
                    filename: upload.name,
                    status: UploadFileStatus.Error
                } });
                notification.open({
                    placement: 'bottomRight',
                    message: 'Upload',
                    description: `${upload.name} upload error!`,
                });
            }).finally(() => {
                dispatchContext({ type: 'SET_LOADING', payload: false });
            });
        });
        fileInput.click();
    };

    const openFile = useCallback(async () => {
        dispatchContext({type: "ARR_SELECTED", payload: []});
        // dispatchContext({ type: 'SET_LOADING', payload: true });
        dispatchContext({ type: 'SET_LOADING_FILES', payload: arrSelected });
        const fileCached = await cachedFiles(arrSelected, currentPath, searchText)
        // dispatchContext({ type: 'SET_LOADING', payload: false });
        dispatchContext({ type: 'SET_LOADING_FILES', payload: [] });
        let isChange = false;
        fileCached.forEach((item) => {
            const index = listFile.findIndex(file => file.baseFileId === item.baseFileId);
            if (index !== -1) {
                listFile[index] = item;
                isChange = true;
            }
        })
        
        isChange && setListFile([...listFile]);
        if (!props.fromMainViewer) {
            openFileHelper(fileCached);
        } else {
            if (props.onOKDialog) {
                const mapStream = mapStreamFile(fileCached);
                props.onOKDialog(mapStream)
            }
        }
    }, [arrSelected, currentPath, dispatchContext, listFile, props, searchText]);

    const sortField = (field: MainOpenField) : void | undefined => {
        switch (field) {
        case MainOpenField.Name:
            setListFile(GlobalState.sortField(listFile, MainOpenField.Name, !sortFieldState.name));
            setSortFieldState({...sortFieldState, name: !sortFieldState.name});
            break;
        case MainOpenField.CachedDate:
            setListFile(GlobalState.sortField(listFile, MainOpenField.CachedDate, !sortFieldState.cachedDate));
            setSortFieldState({...sortFieldState, cachedDate: !sortFieldState.cachedDate});
            break;
        case MainOpenField.Size:
            setListFile(GlobalState.sortField(listFile, MainOpenField.Size, !sortFieldState.size));
            setSortFieldState({...sortFieldState, size: !sortFieldState.size});
            break;
        case MainOpenField.Status:
            setListFile(GlobalState.sortField(listFile, MainOpenField.Status, !sortFieldState.status));
            setSortFieldState({...sortFieldState, status: !sortFieldState.status});
            break;
        default:
            break;
        }
    }

    return (
        <div 
            className="mt-1 h-full w-full grid gap-y-2" 
            style={{ gridTemplateRows: 'auto auto 1fr auto'}}>
            <FolderPath />
            <div className="grid grid-cols-12">
                <div className='col-span-7 flex'>
                    <span>Name</span>
                    <CIconSvg 
                        onClick={() => sortField(MainOpenField.Name)} 
                        tooltip={{title: 'Sort by Name'}} 
                        colorSvg={'default'} 
                        name="sort_2triangle" 
                        svgSize="medium" 
                        className="cursor-pointer"/>
                </div>
                <div className='col-span-5'>
                    <div className="grid grid-cols-12">
                        <div className='col-span-4 flex'>
                            <span>Cached Date</span>
                            <CIconSvg 
                                onClick={() => sortField(MainOpenField.CachedDate)} 
                                tooltip={{title: 'Sort by Cached Date'}} 
                                colorSvg={'default'} 
                                name="sort_2triangle" 
                                svgSize="medium" 
                                className="cursor-pointer"/>
                        </div>
                        <div className='col-span-4 flex'>
                            <span>Size</span>
                            <CIconSvg 
                                tooltip={{title: 'Sort by Size'}} 
                                onClick={() => sortField(MainOpenField.Size)} 
                                colorSvg={'default'} 
                                name="sort_2triangle" 
                                svgSize="medium" 
                                className="cursor-pointer"/>
                        </div>
                        <div className='col-span-2 flex'>
                            <span>Status</span>
                            <CIconSvg 
                                tooltip={{title: 'Sort by Status'}} 
                                onClick={() => sortField(MainOpenField.Status)}
                                colorSvg={'default'} 
                                name="sort_2triangle" 
                                svgSize="medium" 
                                className="cursor-pointer"/>
                        </div>
                        <Badge 
                            count={arrSelected.length} 
                            offset={[-10, 0]}>
                            <div 
                                className='col-span-1' 
                                onClick={() => setvisbleDrawer(!visbleDrawer)}>
                                <CIconSvg 
                                    tooltip={{title: 'List File'}} 
                                    colorSvg={'default'} 
                                    name="view_list" 
                                    svgSize="medium" 
                                    className="cursor-pointer" />
                            </div>
                        </Badge>
                        <div className='col-span-1'>
                            <CIconSvg 
                                tooltip={{title: 'Upload File'}} 
                                onClick={uploadFile} 
                                colorSvg={'default'} 
                                name="arrowUpward" 
                                svgSize="medium" 
                                className="cursor-pointer"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative bg-white overflow-hidden rounded-sm">
                <CLoading 
                    visible={loading} 
                    noOpacityBackground 
                    size='small'>
                    <div className="h-full w-full absolute inset-0 custom-scrollbar">
                        <BodyTable lists={listFile}/>
                    </div>
                </CLoading>
                <DrawerListFileSelected 
                    visible={visbleDrawer} 
                    onClose={() => setvisbleDrawer(false)} 
                    onOpen={openFile} />
            </div>
            <div className="flex justify-center items-center space-x-2">
                <Button 
                    type="primary" 
                    onClick={openFile}>
                        View
                </Button>
                {
                    props.fromMainViewer && props.onCloseFromMainViewer && 
                    <Button 
                        type="default" 
                        onClick={props.onCloseFromMainViewer}>
                        Close
                    </Button>}
            </div>
        </div>
    )
}
