import {SettingState} from "common/define";
import {SystemConstants} from "common/system-constants";
import {Login, Register} from "common/type-state";
import {Observable} from 'rxjs/internal/Observable';
import {map} from "rxjs/operators";
import HttpClient from "./http-client";
import {APIHosts, ApiReduxHelpers} from "./reduxHelpers";




export interface ResponseLoginRegister {
    errorCode: number;
    msg: string;
    token: string;
    setting: unknown;
    info: {
        id: string;
        userName: string;
        email: string;
        phoneNumber: string;
        roles: string[];
    };
}

class IdentityApiController extends ApiReduxHelpers {

    constructor() {
        super();

    }

    ApiHost = APIHosts.Identity;

    login(data: Login): Observable<ResponseLoginRegister | null> {

        return HttpClient.post(`${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_IDENTITY.API.IDENTITY.LOGIN}`, data).pipe(
            map((res) => res as ResponseLoginRegister || null)
        );
    }

    register(data: Register): Observable<ResponseLoginRegister | null> {
        return HttpClient.post(`${this.getHost(this.ApiHost)}/${SystemConstants.SERVER_IDENTITY.API.IDENTITY.REGISTER}`, data).pipe(
            map((res) => res as ResponseLoginRegister || null)
        );
    }

    getSettings(): Observable<string> {
        const api = SystemConstants.SERVER_IDENTITY.API.IDENTITY.SETTING;
        return HttpClient.get(`${this.getHost(this.ApiHost)}/${api}`).pipe(map(res => res as string));
    }

    saveSettings(data: SettingState): Observable<string> {
        const api = SystemConstants.SERVER_IDENTITY.API.IDENTITY.SETTING;
        return HttpClient.put(`${this.getHost(this.ApiHost)}/${api}`, JSON.stringify(JSON.stringify(data))).pipe(map(res => res as string));
    }
}

export const IdentityApi = new IdentityApiController();




