import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CombineChild } from "common/define";
import { GlobalState } from "common/global";
import { RootEpic } from "common/type-state";
import _ from 'lodash';
import { merge } from "rxjs";
import { catchError, concatMap, filter, mergeMap, withLatestFrom } from "rxjs/operators";
import { Lodash } from "utils/utils";
import { noProcess, setAppLoading } from "./app.slice";
import { CombineHelper } from './helper';
import { loadSheetDataMergeMulti, unLoadSheetDataMerge } from "./sheets.slice";
import { fetchDataTreeFlat } from "./tree.slice";

const initState: CombineHelper.CombineState = {
    mapCombineModel: {},
}
const combineSlice = createSlice({
    name: 'combine-model',
    initialState: initState,
    reducers: {
        addCombineModel(state, action: PayloadAction<ViewId[]>) { return },
        loadCurrentCombine(state, action: PayloadAction<ViewId>) { return },
        deleteChildCombined(state, action: PayloadAction<CombineChild>) { return },
        deleteMultiChild(state, action: PayloadAction<CombineChild[] | undefined>) { return },
        handleCallbackCombine(state, action: PayloadAction<CombineChild>) { return },
        setMapCombineModel(state, action: PayloadAction<{ viewId: ViewId, combineChild: CombineChild[] }>) {
            const { viewId, combineChild } = action.payload;
            state.mapCombineModel[viewId] = combineChild;
        },
        removeMapCombineModel(state, action) {
            const { viewId, child } = action.payload;
            const mapCombine = Lodash.cloneDeep(state.mapCombineModel[viewId]);
            state.mapCombineModel[viewId] = mapCombine.filter(x => x.viewId !== child.viewId);
        }
    }
})

/**
 * Add list combine with root model is model active
 * @param action$ 
 * @param state$ 
 * @returns 
 */
const addCombineModel$: RootEpic = (action$, state$) => action$.pipe(
    filter(addCombineModel.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const viewIdActive = state.multiViewer.viewActive.viewId;
        const viewIdFinal = GlobalState.getViewIdCombine(viewIdActive);
        const resultCompare = CombineHelper.addModelCombineHelper(
            action.payload,
            viewIdFinal,
            state.combineModel.mapCombineModel[viewIdFinal] || []
        );
        const fileInfoRoot = state.filesList.filesList.find(f => f.viewId === viewIdFinal); // => base mode 3D // what if file has no 3D
        if (resultCompare && fileInfoRoot) {
            const { webViewerRoot, listViewIdAdd, viewIdFinal } = resultCompare;
            const filterFileInfo = state.filesList.filesList.filter(f => listViewIdAdd.includes(f.viewId));

            const baseViewIds = filterFileInfo.map(v => GlobalState.getViewId(v.viewId));
            // const baseMergeViewId = viewIdFinal;// GlobalState.getViewIdCombine(state.multiViewer.viewActive.viewId as string);
            GlobalState.addMergeFileCombine(baseViewIds, viewIdFinal);
            const listMerge = filterFileInfo.map(v => {
                return { viewId: v.viewId, mergeViewId: viewIdFinal, childViewIds: [GlobalState.getViewId(v.viewId)] }
            });
            return merge(
                [setAppLoading(true)],
                CombineHelper.addSubModel3DHelper$(webViewerRoot, filterFileInfo, viewIdFinal).pipe(
                    concatMap(val => {
                        const pre = state.combineModel.mapCombineModel[viewIdFinal] || [];
                        const newVal = _.unionWith(pre, val, _.isEqual);
                        const listRuning = [];
                        listRuning.push(combineSlice.actions.setMapCombineModel({ viewId: viewIdFinal, combineChild: newVal }));
                        listRuning.push(loadSheetDataMergeMulti(listMerge));
                        newVal.forEach(v => {
                            listRuning.push(fetchDataTreeFlat({
                                fileInfo: v.fileInfo,
                                rootNode: v.nodeRootChild[0],
                            }));
                        })
                        listRuning.push(setAppLoading(false));
                        return [...listRuning];
                    }),
                    catchError(err => {
                        return [setAppLoading(false)]
                    })
                ),                          
                
            )
        }
        return []
    })
)

/**
 * Effect load current combine by viewid actice
 * @param action$ 
 * @param state$ 
 * @returns 
 */
// const loadCurrentCombine$: RootEpic = (action$, state$) => action$.pipe(
//     filter(loadCurrentCombine.match),
//     withLatestFrom(state$),
//     switchMap(([action, state]) => {
//         const viewId = action.payload;
//         const viewIdFinal = GlobalState.getViewId(viewId);
//         const fileInfo = state.filesList.filesList.find(f => f.viewId === viewIdFinal);
//         const children = CombineHelper.getCurrentCombine(viewIdFinal);
//         const currentCombine: CombineHelper.CurrentCombine = {
//             rootId: viewId,
//             children,
//             modelFileIdRoot: fileInfo?.modelFileId || ''
//         }
//         return [combineSlice.actions.updateCombineModelComplete(currentCombine)]
//     })
// )

/**
 * Delete One child in Mode Combine
 * @param action$ 
 * @param state$ 
 * @returns 
 */
const deleteChildCombined$: RootEpic = (action$, state$) => action$.pipe(
    filter(deleteChildCombined.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const { isMulti } = action.payload;
        const viewIdActive = action.payload.viewId;
        const viewIdFinal = GlobalState.getViewIdCombine(viewIdActive);
        const currentCombine = state.combineModel.mapCombineModel[viewIdFinal];
        // const parentMergeViewId = viewIdFinal;//GlobalState.getViewIdCombine(viewIdFinal);
        const parentViewIds = GlobalState.getViewId(action.payload.viewId);
        GlobalState.removeMergeFileCombine([parentViewIds], viewIdFinal);

        return merge(
            isMulti ? [] : [setAppLoading(true)],
            [unLoadSheetDataMerge({ viewId: viewIdActive, mergeViewId: viewIdFinal, childViewIds: [parentViewIds] })],
            CombineHelper.deleteChildCombined$(viewIdFinal, action.payload, currentCombine).pipe(
                mergeMap(re => {
                    if (isMulti) return [];
                    return [
                        combineSlice.actions.setMapCombineModel({ viewId: viewIdFinal, combineChild: re || []}),
                        setAppLoading(false),
                    ]
                }),
                catchError(err => [setAppLoading(false)])
            )
        )
    })
)

const deleteMultiChild$: RootEpic = (action$, state$) => action$.pipe(
    filter(deleteMultiChild.match),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
        const childs = action.payload;
        if (!childs) return [noProcess()];
        const mapRet: any[] = [setAppLoading(true)];
        childs.forEach(child => {
            const viewIdFinal = GlobalState.getViewIdCombine(child.viewId);
            mapRet.push(deleteChildCombined({...child, isMulti: true}));
            mapRet.push(combineSlice.actions.removeMapCombineModel({ viewId: viewIdFinal, child }));
        });
        mapRet.push(setAppLoading(false));
        return mapRet;
    })
)
export const CombineModelEpics = [
    addCombineModel$,
    // loadCurrentCombine$,
    deleteChildCombined$,
    deleteMultiChild$,
]
export const {
    addCombineModel,
    // loadCurrentCombine,
    deleteChildCombined,
    deleteMultiChild,
} = combineSlice.actions;
export default combineSlice.reducer