/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseOperator, { ModeOperator } from "./base.operator";

export default class MarkupOperator extends BaseOperator {
    public static OPERATOR = "MARKUP";

    constructor(
        viewer: Communicator.WebViewer,
        operatorID: Communicator.OperatorId
    ) {
        super(viewer);

        this.Mode = ModeOperator.Markup;
        this.Id = operatorID;
        if (
            operatorID >= Communicator.OperatorId.None &&
            operatorID <= Communicator.OperatorId.AxisTriad
        )
            this.Name = Communicator.OperatorId[operatorID] as any;
        else this.Name = MarkupOperator.OPERATOR as any;
    }

    /**
     * Active
     */
    public Active(): void {
        if (this.Status === false) {
            //
            this.Viewer.operatorManager.clear();
            this.Viewer.operatorManager.set(this.Id, 0);
            this.Status = true;
        }
    }

    /**
     * InActive
     */
    public InActive(): void {
        if (this.Status === true) {
            this.ResetDefautOperators();
            // this.Viewer.operatorManager.set(this.firstOperator,0);
            this.Id = -1;
            this.Status = false;
        }
    }
}
