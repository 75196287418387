import { combineReducers } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import appReducer, { AppEpics } from "./app.slice";
import bookmarkReducer, { BookmarkEpics } from './bookmark.slice';
import bootstrapReducer, { BootstrapEpics } from "./bootstrap.slice";
import combineReducer, { CombineModelEpics } from './combine.slice';
import dashboardReducer from "./dashboard.slice";
import filesListReducer, { FilesListEpics } from "./filesList.slice";
import { handleAllAction$ } from "./helper";
import loginReducer, { loginEpic } from "./login.slice";
import markupReducer, { MarkupActionEpics } from "./markup.slice";
import markupLineFormatSlice from './markupLineFormat.slice';
import markupPanelReducer from "./markupPanel.slice";
import markupTextFormatSlice from './markupTextFormat.slice';
import markupToolbarSlice, { MarkupToolbarActionEpics } from './markupToolbar.slice';
import multiViewerReducer, { MultiViewerEpics } from "./multiViewer.slice";
import panelVisibleReducer, { PanelVisibleEpics } from "./panelVisible.slice";
import pdfViewerReducer, { PdfViewerActionEpics } from "./pdfViewer.slice";
import propertiesReduce, { PropertiesEpics } from "./properties.slice";
import RFIstatusSlice, { RFIstatusEpics } from "./RFIstatus.slice";
import notepinActiveSlice, { NotepinActionEpics } from "./notepin.slice";
import panelAppReducer from './rightPanelApp.slice';
import settingReducer, { SettingActionEpics } from './setting.slice';
import sheetsReducer, { SheetsEpics } from "./sheets.slice";
import treeReducer, { TreeEpics } from "./tree.slice";
import viewer3DReducer, { Viewer3DEpics } from "./viewer3D.slice";
import { ViewsEpics, viewsReducer } from "./views.slice";
import markup3dActiveSlice, { Markup3dActionEpics } from "./markup3d.slice";
import leftPanelAppSlice from "./leftPanel.slice";
import printingReducer, { PrintingActionEpics } from "./printing.slice";
import issuesReducer, { IssuesActionEpics } from "./issues.slice";

const rootReducer = combineReducers({
    app: appReducer,
    login: loginReducer,
    bootstrap: bootstrapReducer,
    panelVisible: panelVisibleReducer,
    multiViewer: multiViewerReducer,
    viewer3D: viewer3DReducer,
    filesList: filesListReducer,
    tree: treeReducer,
    markupPanel: markupPanelReducer,
    markupToolbar: markupToolbarSlice,
    markupLineFormat: markupLineFormatSlice,
    markupTextFormat: markupTextFormatSlice,
    dashboard: dashboardReducer,
    pdfViewer: pdfViewerReducer,
    sheets: sheetsReducer,
    properties: propertiesReduce,
    bookmark: bookmarkReducer,
    views: viewsReducer,
    markup: markupReducer,
    setting: settingReducer,
    combineModel: combineReducer,
    RFIstatus: RFIstatusSlice,
    notepin: notepinActiveSlice,
    panelApp: panelAppReducer,
    markup3d: markup3dActiveSlice,
    leftPanelApp: leftPanelAppSlice,
    printing: printingReducer,
    issues: issuesReducer,
});

export const rootEpic = combineEpics(
    handleAllAction$,
    ...AppEpics,
    ...loginEpic,
    ...BootstrapEpics,
    ...Viewer3DEpics,
    ...TreeEpics,
    ...PanelVisibleEpics,
    ...SheetsEpics,
    ...FilesListEpics,
    ...MultiViewerEpics,
    ...PropertiesEpics,
    ...BookmarkEpics,
    ...ViewsEpics,
    ...CombineModelEpics,
    ...MarkupActionEpics,
    ...SettingActionEpics,
    ...RFIstatusEpics,
    ...NotepinActionEpics,
    ...PdfViewerActionEpics,
    ...MarkupToolbarActionEpics,
    ...Markup3dActionEpics,
    ...PrintingActionEpics,
    ...IssuesActionEpics,
);

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
