/* eslint-disable @typescript-eslint/no-explicit-any */
import { StreamApi } from "api/stream.api";
import { FileInfo } from "common/define";
import { GlobalState } from "common/global";
import Utils from "utils/utils";

export class JsonSetting {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public jsonSetting: any;
    public _defaultViewNode: any;
    public viewer: Communicator.WebViewer
    public _defaultCamera: any;
    public mapActiveView = new Map<ViewId, any>();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor(viewer: Communicator.WebViewer, private fileInfo: FileInfo) {
        this.viewer = viewer;

    }
    async apply(viewer: Communicator.WebViewer): Promise<void> {
        this.viewer = viewer
        this.getSettingApi().subscribe(data => {
            this.mapActiveView.set(this.fileInfo?.viewId, data)
            this.jsonSetting = data
            this.applySetting(data, viewer)
            GlobalState.mapJsonSetting.set(this.fileInfo?.viewId, data)
        });
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    applyLineStyle(viewer: Communicator.WebViewer, jsonSetting: any) {
        this.jsonSetting = jsonSetting;
        this.callApplyLineStyle(viewer);
    }
    callApplyLineStyle(viewer: Communicator.WebViewer): void {
        const { jsonSetting } = this;
        // 1
        if (jsonSetting && jsonSetting.lineStyleArray && jsonSetting.lineStyleArray.ls1) {
            const style1 = jsonSetting.lineStyleArray.ls1;
            viewer.model.setNodesLinePattern(style1, [1, 0, 0, 0, 0, 0, 0], 0.006,
                Communicator.LinePatternLengthUnit.ProportionOfScreenWidth);
        }
        // 2
        if (jsonSetting && jsonSetting.lineStyleArray && jsonSetting.lineStyleArray.ls2) {
            const style2 = jsonSetting.lineStyleArray.ls2;
            viewer.model.setNodesLinePattern(style2, [1, 1, 1, 1, 0, 0, 0, 0], 0.007,
                Communicator.LinePatternLengthUnit.ProportionOfScreenWidth);
        }
        // 3
        if (jsonSetting && jsonSetting.lineStyleArray && jsonSetting.lineStyleArray.ls3) {
            const style3 = jsonSetting.lineStyleArray.ls3;
            viewer.model.setNodesLinePattern(style3, [1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0], 0.009,
                Communicator.LinePatternLengthUnit.ProportionOfScreenWidth);
        }
        // 4
        if (jsonSetting && jsonSetting.lineStyleArray && jsonSetting.lineStyleArray.ls4) {
            const style4 = jsonSetting.lineStyleArray.ls4;
            viewer.model.setNodesLinePattern(style4, [1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0], 0.011,
                Communicator.LinePatternLengthUnit.ProportionOfScreenWidth);
        }
        // 5
        if (jsonSetting && jsonSetting.lineStyleArray && jsonSetting.lineStyleArray.ls5) {
            const style5 = jsonSetting.lineStyleArray.ls5;
            viewer.model.setNodesLinePattern(style5, [1, 1, 0, 0, 0, 0], 0.008,
                Communicator.LinePatternLengthUnit.ProportionOfScreenWidth);
        }
        // 6
        if (jsonSetting && jsonSetting.lineStyleArray && jsonSetting.lineStyleArray.ls6) {
            const style6 = jsonSetting.lineStyleArray.ls6;
            viewer.model.setNodesLinePattern(style6, [1, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 1, 0, 0, 0], 0.014,
                Communicator.LinePatternLengthUnit.ProportionOfScreenWidth);
        }
        // 7
        if (jsonSetting && jsonSetting.lineStyleArray && jsonSetting.lineStyleArray.ls7) {
            const style7 = jsonSetting.lineStyleArray.ls7;
            viewer.model.setNodesLinePattern(style7, [1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 1, 1, 0, 0, 0], 0.013,
                Communicator.LinePatternLengthUnit.ProportionOfScreenWidth);
        }
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    changeColorBlackWhite(hwv: Communicator.WebViewer, jsonSetting: any): void {
        if (!jsonSetting) return;
        const colorWhite = new Communicator.Color(255, 255, 255);
        const colorBlack = new Communicator.Color(0, 0, 0);
        if (jsonSetting.listNodeModelBlackWhite) {
            const mapColorBlack = new Map();
            const mapColorWhite = new Map();
            jsonSetting.listNodeModelBlackWhite.forEach((element: any) => {
                mapColorWhite.set(element, colorWhite);
                mapColorBlack.set(element, colorBlack);
            });
            const bgColorGra = hwv.view.getBackgroundColor();
            const topColor = bgColorGra.top;
            if (topColor) {
                const lum = (topColor.r * 0.2126) / 255 + (topColor.g * 0.7152) / 255 + (topColor.b * 0.0722) / 255;
                if (lum > 0.3) {
                    hwv.model.setNodesColors(mapColorBlack, true);
                } else {
                    hwv.model.setNodesColors(mapColorWhite, true);
                }
            } else {
                hwv.view.setBackgroundColor(colorWhite, colorWhite);
                hwv.model.setNodesColors(mapColorBlack, true);
            }
        }

        if (jsonSetting.listNodeSheetBlackWhite) {
            const mapColorBlackSheet = new Map();
            const mapColorWhiteSheet = new Map();
            jsonSetting.listNodeSheetBlackWhite.forEach((element: any) => {
                mapColorWhiteSheet.set(element, colorWhite);
                mapColorBlackSheet.set(element, colorBlack);
            });

            let bgColorSheet = hwv.sheetManager.getSheetColor();
            if (!hwv.sheetManager.getBackgroundSheetEnabled()) {
                bgColorSheet = hwv.sheetManager.getSheetBackgroundColor();
            }
            if (bgColorSheet) {
                const lumSheet = (bgColorSheet.r * 0.2126) / 255 + (bgColorSheet.g * 0.7152) / 255 + (bgColorSheet.b * 0.0722) / 255;
                if (lumSheet > 0.3) {
                    hwv.model.setNodesColors(mapColorBlackSheet, true);
                } else {
                    hwv.model.setNodesColors(mapColorWhiteSheet, true);
                }
            } else {
                hwv.sheetManager.setSheetColors(colorWhite, colorWhite, colorWhite);
                hwv.model.setNodesColors(mapColorBlackSheet, true);
            }
        }
    }
    setDefaultView(defaultViewNode: number): any {
        return this._defaultViewNode = defaultViewNode;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async activateCadView(viewer: Communicator.WebViewer, cadViewId: number) {
        if (this.jsonSetting && this.jsonSetting.listCadviewSheet) {
            let layoutId: number | null = null;
            const currentLayoutId = viewer.sheetManager.getActiveSheetId();

            const keys = Object.keys(this.jsonSetting.listCadviewSheet);
            keys && keys.forEach((x) => {
                if (cadViewId === Number.parseInt(x, 10)) layoutId = Number.parseInt(this.jsonSetting.listCadviewSheet[x], 10);
            });

            // {0: 2}
            const keyModels = this.jsonSetting.listModelNode && Object.keys(this.jsonSetting.listModelNode);
            // {1: 1192, 2: 2485, 3: 3778}
            if (keyModels && keyModels.length > 0) {
                keyModels.forEach((x: string | number) => {
                    const idModel = Number.parseInt(this.jsonSetting.listModelNode[x], 10);
                    if (idModel === layoutId) layoutId = null;
                });
            }

            if (layoutId !== currentLayoutId) {
                if (layoutId === null) {
                    await viewer.sheetManager.deactivateSheets();
                } else {
                    await viewer.sheetManager.setActiveSheetId(layoutId);
                }
            }
        }
        return viewer.model.activateCadView(cadViewId);
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    activeViewNode(jsonSetting: any): void {
        if (Utils.isAvaiableId(jsonSetting.activeViewNode) && jsonSetting.activeViewNode > 0) {
            this.setDefaultView(jsonSetting.activeViewNode);
        } else { // dung infor camera để zoom to // https://redmine.anybim.vn/issues/3959
            // eslint-disable-next-line no-lonely-if
            if (jsonSetting.cameraInfo && jsonSetting.cameraInfo.length > 0 && jsonSetting.activeLayoutNode > 2) {
                jsonSetting.cameraInfo.forEach((element: { nodeId: any; width: any; height: any; projection: number; position: any; target: any; upVector: any; }) => {
                    if (element.nodeId === jsonSetting.activeLayoutNode) {
                        const w = element.width;
                        const h = element.height;
                        const proj = element.projection === 2 ? Communicator.Projection.Orthographic : Communicator.Projection.Perspective;
                        let arr = JSON.parse(`[${element.position}]`);
                        if (arr && arr.length < 3) return;
                        const pos = new Communicator.Point3(arr[0], arr[1], arr[2]);
                        arr = JSON.parse(`[${element.target}]`);
                        if (arr && arr.length < 3) return;
                        const at = new Communicator.Point3(arr[0], arr[1], arr[2]);
                        arr = JSON.parse(`[${element.upVector}]`);
                        if (arr && arr.length < 3) return;
                        const up = new Communicator.Point3(arr[0], arr[1], arr[2]);
                        const cam = this.viewer?.view.getCamera();
                        cam?.setWidth(w);
                        cam?.setHeight(h);
                        cam?.setPosition(pos);
                        cam?.setTarget(at);
                        cam?.setUp(up);
                        cam?.setProjection(proj);
                        this.setDefaultCamera({
                            width: w, height: h, position: pos, target: at, up, projection: proj,
                        });
                        if (cam)
                            this.viewer?.view.setCamera(cam);
                    }
                });
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setDefaultCamera(cam: any): any {
        return this._defaultCamera = cam;
    }

    // private
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async applySetting(jsonSetting: any, viewer: Communicator.WebViewer) {
        this.applyLineStyle(viewer, jsonSetting);
        this.changeColorBlackWhite(viewer, jsonSetting)
        const showLineWeight = GlobalState.mapLineWeight.get(this.fileInfo.viewId)
        if (showLineWeight !== undefined) {
            this.callApplyLineWeight(viewer, jsonSetting, showLineWeight)
        }
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    callApplyLineWeight(viewer: Communicator.WebViewer, jsonSetting: any, showDwgDgnLineWeight: boolean) {
        if (showDwgDgnLineWeight === null || showDwgDgnLineWeight === undefined) {
            return
        }
        const sheetActive = viewer.sheetManager.getActiveSheetId();
        if (jsonSetting && (sheetActive && sheetActive > 0)) {
            if (jsonSetting.listSheetNodeLineWeightShowHide && Object.keys(jsonSetting.listSheetNodeLineWeightShowHide).length > 0) {
                for (const key of Object.keys(jsonSetting.listSheetNodeLineWeightShowHide)) {
                    if (sheetActive === parseInt(key)) {
                        viewer.model.setNodesVisibility(jsonSetting.listSheetNodeLineWeightShowHide[key], showDwgDgnLineWeight);
                    }
                }
            }
            if (jsonSetting.listSheetNodeLineCenterWithInternalIDShowHide && Object.keys(jsonSetting.listSheetNodeLineCenterWithInternalIDShowHide).length > 0) {
                for (const key of Object.keys(jsonSetting.listSheetNodeLineCenterWithInternalIDShowHide)) {
                    if (sheetActive === parseInt(key)) {
                        viewer.model.setNodesVisibility(jsonSetting.listSheetNodeLineCenterWithInternalIDShowHide[key], !showDwgDgnLineWeight);
                    }
                }
            }

        }
    }
    private getSettingApi() {
        const fileName = Utils.getFileNameWithoutExtension(this.fileInfo?.filename);
        return StreamApi.getContent({ path: `${this.fileInfo?.streamLocation}/${fileName}_setting.json` })
    }
}
