/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSelector } from "@reduxjs/toolkit";
import { selectSelfCombine, selectSelfMultiViewer } from "./self.selector";
export const getMapCombineModel = createSelector(
    selectSelfCombine,
    state => state.mapCombineModel
)
export const getViewActiveMapCombineModel = createSelector(
    selectSelfCombine,
    selectSelfMultiViewer,
    (combine, multiViewer) => {
        const { viewId } = multiViewer.viewActive;
        return combine.mapCombineModel[viewId]
    }
)