import CDialogInput from 'components/CDialogInput';
import { useContextListOpenFile } from 'context/listopenfile.context';
import React from 'react';

interface FolderDialogHandleProps extends Props {
    handleCloseDialog: () => void
}

export default function FolderDialogHandle({handleCloseDialog}: FolderDialogHandleProps): JSX.Element {
    const {callActions} = useContextListOpenFile();
    
    function handleYesDialog(nameFolder: string) {
        callActions({type: 'add-folder', val: nameFolder})
    }

    return (
        <CDialogInput 
            title="Add folder"
            description={'Enter name folder'}
            arrText={['']}
            loading={false}
            handleYesDialog={handleYesDialog}
            handleCloseDialog={handleCloseDialog}>
        </CDialogInput>
    )
}