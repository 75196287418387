import { MarkupBaseItem } from '../markup-items/markup.base.item';
import { MarkupCloudItem } from '../markup-items/markup.cloud.item';
import { MarkupBaseOperator } from './markup.base.operator';


export class MarkupCloudOperator extends MarkupBaseOperator {

    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
    }

    public createMarkupItem(position: Communicator.Point2): void {
        const view = this._viewer.view;
        const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(position, view);
        this._markupItem = new MarkupCloudItem(this._viewer);
        this._markupItem.setLineFormat(this._lineColor, this._lineWeight, this._lineOpacity, this._fillColor, this._fillColorOption);
        if (cameraPoint !== null) {
            (this._markupItem as MarkupCloudItem).setPoint1(cameraPoint);
            (this._markupItem as MarkupCloudItem).setPoint2(cameraPoint);
        }
        const itemId = this._viewer.markupManager.registerMarkup(this._markupItem);
        this._markupItem.setMarkupItemId(itemId);
    }
    public updateMarkupItem(position: Communicator.Point2): void {
        if (this._markupItem) {
            const view = this._viewer.view;
            const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(position, view);
            if (cameraPoint !== null) {
                (this._markupItem as MarkupCloudItem).setPoint2(cameraPoint);
            }
            this._viewer.markupManager.refreshMarkup();
        }
    }
    setMoveGripPointItem(item: MarkupBaseItem): void {
        this._markupItem = item;
        this._markupItem.setMoveGripPointMode(true);
        this._moveGripPointMode = true;
    }
}

