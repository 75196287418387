import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RGBColor } from 'react-color';
import { MarkupLineFormat } from 'common/define';
import { LineStyle } from 'common/type-markup';
import { defaultLineFormatSetting } from 'utils/utils-extend';

const { lineColor, lineWeight, lineOpacity, fillColor, fillColorOption, startLineShapeType, endLineShapeType, lineStyle } = defaultLineFormatSetting;

const defaultValue: MarkupLineFormat = {
    lineColor: lineColor,
    lineWeight: lineWeight,
    lineOpacity: lineOpacity,
    fillColor: fillColor,
    fillColorOption: fillColorOption,
    startLineShapeType: startLineShapeType,
    endLineShapeType: endLineShapeType,
    lineStyle: lineStyle,
    rect: {
        x: 0,
        y: 0,
        width: 10,
        height: 10,
    },
    ratio: 1,
};

export const markupLineFormatSlice = createSlice({
    name: 'markupLineFormat',
    initialState: defaultValue,
    reducers: {
        setLineColor: (state, action: PayloadAction<RGBColor>) => {
            state.lineColor = action.payload;
        },
        setLineWeight: (state, action: PayloadAction<number>) => {
            state.lineWeight = action.payload;
        },
        setLineOpacity: (state, action: PayloadAction<number>) => {
            state.lineOpacity = action.payload;
        },
        setFillColor: (state, action: PayloadAction<RGBColor>) => {
            state.fillColor = action.payload;
        },
        setFillColorOption: (state, action: PayloadAction<boolean>) => {
            state.fillColorOption = action.payload;
        },
        setStartLineShapeType: (state, action: PayloadAction<number>) => {
            state.startLineShapeType = action.payload;
        },
        setEndLineShapeType: (state, action: PayloadAction<number>) => {
            state.endLineShapeType = action.payload;
        },
        setLineType: (state, action: PayloadAction<number>) => {
            state.lineStyle = action.payload;
        },
        setLineStyle: (state, action: PayloadAction<LineStyle>) => {
            state.lineStyle = action.payload;
        },
        setRect: (state, action: PayloadAction<Rectangle>) => {
            state.rect = action.payload;
        },
        setRatio: (state, action: PayloadAction<number>) => {
            state.ratio = action.payload;
        },
    },
});
export const {
    setLineColor,
    setLineWeight,
    setLineOpacity,
    setFillColor,
    setFillColorOption,
    setStartLineShapeType,
    setEndLineShapeType,
    setLineType,
    setLineStyle,
    setRect,
    setRatio,

} = markupLineFormatSlice.actions;

export default markupLineFormatSlice.reducer;