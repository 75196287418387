import { MarkupBaseOperator } from './markup.base.operator';
import { MarkupLineItem } from '../markup-items/markup.line.item';
export class MarkupLineOperator extends MarkupBaseOperator {
    public constructor(viewer: Communicator.WebViewer) {
        super(viewer);
        this.cursorSprite = true;
    }

    public createMarkupItem(position: Communicator.Point2): void {
        const view = this._viewer.view;
        this._markupItem = new MarkupLineItem(this._viewer);
        this._markupItem.setLineFormat(
            this._lineColor,
            this._lineWeight,
            this._lineOpacity,
            this._fillColor,
            this._fillColorOption,
            this._lineStyle);
        (this._markupItem as MarkupLineItem).setStartLineShape(this._startLineShapeType);
        (this._markupItem as MarkupLineItem).setEndLineShape(this._endLineShapeType);
        const itemId = this._viewer.markupManager.registerMarkup(this._markupItem);
        this._markupItem.setMarkupItemId(itemId);
        const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(position, view);
        if (cameraPoint !== null) {
            (this._markupItem as MarkupLineItem).setFirstPoint(cameraPoint);
            (this._markupItem as MarkupLineItem).setSecondPoint(cameraPoint);
        }
    }
    public updateMarkupItem(position: Communicator.Point2): void {
        if (this._markupItem) {
            const view = this._viewer.view;
            const cameraPoint = view.getCamera().getCameraPlaneIntersectionPoint(position, view);
            if (cameraPoint !== null) {
                (this._markupItem as MarkupLineItem).setSecondPoint(cameraPoint);
                this._viewer.markupManager.refreshMarkup();
            }
        }
    }

}

