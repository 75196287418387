
import { CheckCircleOutlined, ExclamationCircleOutlined, SyncOutlined, WarningOutlined, SettingOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import clsx from 'clsx';
import { StreamFile } from 'common/define';
import { GlobalState } from 'common/global';
import { CTooltip } from 'components/CTooltip';
import { useContextListOpenFile } from 'context/listopenfile.context';
import React, { useState, useCallback } from 'react';
import Utils from "utils/utils";
type PropsRow = StreamFile;

const RowBodyTableFileComponent = (props: PropsRow): JSX.Element => {
    const {cacheStatus} = props;
    const {state: {arrSelected, searchText, recentUploadFile, loadingFiles}, dispatchContext} = useContextListOpenFile();
    const arrSelectedMapId = arrSelected.map(f => f.baseFileId);
    const [includes2D, setIncludes2D] = useState<boolean>(false);
    const [includesProps, setIncludesProps] = useState<boolean>(true);
    const [cacheOptionsVisible, setCacheOptionsVisible] = useState<boolean>(false);

    function onChange(props: StreamFile) {
        dispatchContext({type: 'SELECT_ITEM', payload: props})
    }

    const openFileLocation = () => {
        dispatchContext({type: "OPEN_FILE_LOCATION", payload: props})
    }
    function handleContext(event: React.MouseEvent) {
        dispatchContext({type: 'DATA_CONTEXT', payload: {
            event,
            visible: true,
            from: 'file',
            itemSelected: props
        }})
    }
    
    const tooltipTitle = () => {
        const cacheStatusDetails = props.drawingConvertStatus;
        const includes = [];
        if (cacheStatusDetails) {
            if (cacheStatusDetails.convert3DModel === 2) includes.push('3D model')
            if (cacheStatusDetails.convert2DSheet === 2) includes.push('sheets')
            if (cacheStatusDetails.extractProperties === 2) includes.push('properties');
        }
        const includesDetails = includes.map(opt => ` ${opt}`);
        if (props.cacheStatus !== 2) {
            if (arrSelectedMapId.includes(props.baseFileId)) {
                let result = 'Include 3D model, sheets, properties';
                if (!includes2D) result = result.replace(', sheets', '')
                if (!includesProps) result = result.replace(', properties', '')
                return result;
            }
            return 'No cache'
        }
        return `Includes ${includesDetails}`
    }


    const setIncludeSheets = useCallback((event: CheckboxChangeEvent) => {
        const checked = event.target.checked;
        setIncludes2D(checked);
        GlobalState.mapIncludes2D.set(props.baseFileId, checked)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setIncludeProperties = useCallback((event: CheckboxChangeEvent) => {
        const checked = event.target.checked;
        setIncludesProps(checked);
        GlobalState.mapIncludesProps.set(props.baseFileId, checked)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const openCacheOptionsPopup = () => {
        setCacheOptionsVisible(true)
    }

    const cacheOptsMenu = () => {
        return (
            <div className='bg-white w-44 shadow-md py-2'>
                <div className='pl-4'>Cache options</div>
                <hr className="solid" />
                <Checkbox 
                    style={{alignItems:'center', margin: '0 auto', height: '2rem', paddingLeft: '1rem'}} 
                    checked={true} disabled>
                    3D model
                </Checkbox>
                <Checkbox 
                    style={{alignItems:'center', margin: '0 auto', height: '2rem', paddingLeft: '1rem'}} 
                    checked={includes2D} onChange={setIncludeSheets}>
                    Include sheets
                </Checkbox>
                <Checkbox 
                    style={{alignItems:'center', margin: '0 auto', height: '2rem', paddingLeft: '1rem'}} 
                    checked={includesProps} onChange={setIncludeProperties}>
                    Include properties
                </Checkbox>
            </div>
        )
    }

    return (
        <div 
            className={clsx(
                Utils.convertStatus(cacheStatus) === 'Cached' && 'bg-blue-100',
                recentUploadFile && props.baseFileId === recentUploadFile.baseFileId && 'border-2 border-blue-400',
                'rounded'
            )} onContextMenu={handleContext} >
            {
                props.isDirectory === false &&
                <div className={clsx(
                    arrSelectedMapId.includes(props.baseFileId) && 'bg-gray-100'
                )}>
                    <div className="grid grid-cols-12">
                        <div className='col-span-6 cursor-pointer' onClick={() => onChange(props)}>
                            <div className="grid grid-cols-12">
                                <div className='col-span-1 flex justify-center pt-2'>
                                    {(cacheStatus === 2 && !props.isDirectory) &&
                                        <CTooltip title="Cached">
                                            <CheckCircleOutlined />
                                        </CTooltip>
                                    }
                                    {(cacheStatus === 1 && !props.isDirectory) &&
                                        <CTooltip title="Caching">
                                            <WarningOutlined />
                                        </CTooltip>
                                    }
                                    {((cacheStatus === 0 || cacheStatus === 3) && !props.isDirectory) &&
                                        <CTooltip title="No-cache">
                                            <ExclamationCircleOutlined />
                                        </CTooltip>
                                    }
                                </div>
                                <div className='col-span-11 pt-1'>
                                    <div>
                                        <div>
                                            <p className="mb-0 truncate ...">
                                                {props.filename}
                                            </p>
                                        </div>
                                        <div>
                                            {searchText !== '' &&
                                                <div className="flex justify-start text-blue-500">
                                                    <CTooltip title={props.originalFilePath}>
                                                        <p className="mb-0 hover:underline truncate ..." onClick={(e) => {
                                                            e.stopPropagation()
                                                            openFileLocation()
                                                        }}>{props.originalFilePath}</p>
                                                    </CTooltip>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-span-6'>
                            <div className="grid grid-cols-12  pb-1">
                                <div className='col-span-4 cursor-pointer pt-1' onClick={() => onChange(props)}>
                                    {Utils.formatTimeAgo(props.createdDate)}
                                </div>
                                <div className='col-span-4 cursor-pointer pt-1' onClick={() => onChange(props)}>
                                    {Utils.convertBytes(props.status.size)}
                                </div>
                                <CTooltip
                                    title={tooltipTitle()}
                                >
                                    <div className='col-span-2 cursor-pointer pt-1'
                                        onClick={() => onChange(props)}
                                    >
                                        {Utils.convertStatus(cacheStatus)}
                                    </div>
                                </CTooltip>
                                <div className='col-span-1 cursor-pointer pt-1 flex items-center'>
                                    {
                                        loadingFiles.map(v=> v.baseFileId).includes(props.baseFileId) ?
                                            <SyncOutlined spin className="text-xl"/> 
                                            :
                                            <Checkbox
                                                checked={arrSelectedMapId.includes(props.baseFileId)}
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={(e) => onChange(props)}/>
                                    }
                                </div>
                                <Dropdown
                                    overlay={cacheOptsMenu}
                                    visible={cacheOptionsVisible}
                                    onVisibleChange={(vis) => setCacheOptionsVisible(vis)}
                                    disabled={(
                                        !arrSelectedMapId.includes(props.baseFileId) 
                                        || Utils.convertStatus(cacheStatus) === 'Cached'
                                    )}
                                    trigger={['click']}>
                                    <CTooltip
                                        title={'Cache options'}
                                    >
                                        <div className='col-span-1 cursor-pointer'>
                                            <i className={clsx((!arrSelectedMapId.includes(props.baseFileId) ||
                                                Utils.convertStatus(cacheStatus) === 'Cached') && 'hidden')}
                                            onClick={openCacheOptionsPopup}
                                            >
                                                <SettingOutlined />
                                            </i>
                                        </div>
                                    </CTooltip>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
const RowBodyTableFile = React.memo(RowBodyTableFileComponent);
export default RowBodyTableFile;