import React from "react";
import { Provider } from "react-redux";
import BootstrapSystem from "pages/Bootstrap";
import "./App.scss";
import "./theme/theme.less";
import MainRouter from "router/RouterMain";
import store from "redux/store";
import MainThemeProvider from "theme/ThemeProvider";

function App(): JSX.Element {
    return (
        <Provider store={store}>
            <MainThemeProvider>
                <BootstrapSystem>
                    <MainRouter />
                </BootstrapSystem>
            </MainThemeProvider>
        </Provider>
    );
}

export default App;
